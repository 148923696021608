import { DropdownItem, DropdownSelect, FieldContainer, FieldContainerProps, Validatable } from 'ui';
import React, { useImperativeHandle, useState } from 'react';
import { translate } from 'utilities';

export interface DropdownSelectFieldProps {
    width?: number;
    labelWidth?: number;
    labelTextKey?: string;
    inputWidth?: number;
    items?: DropdownItem[];
    extraItems?: DropdownItem[];
    selectedItem?: DropdownItem;
    onSelect?: (item: DropdownItem) => void;
    errorText?: string;
    required?: boolean;
}

export const DropdownSelectField = React.forwardRef<Validatable, DropdownSelectFieldProps>((props, ref) => {
    const {
        width,
        labelWidth,
        labelTextKey,
        inputWidth,
        items,
        extraItems,
        onSelect,
        selectedItem,
        errorText,
        required,
    } = props;
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);

    const containerProps: FieldContainerProps = {
        width,
        labelWidth,
        labelTextKey,
        inputWidth,
        errorText: errorMessage,
    };

    const onSelectHandler = (item: DropdownItem) => {
        if (!onSelect) {
            return;
        }

        setIsValid(true);
        setErrorMessage('');

        onSelect(item);
    };

    useImperativeHandle<Validatable, Validatable>(
        ref,
        () => ({
            validate: () => {
                if (required && !selectedItem) {
                    setIsValid(false);
                    setErrorMessage(errorText ?? translate('ValueNotSelected'));

                    return false;
                }

                return true;
            },
        }),
        [required, selectedItem],
    );

    return (
        <FieldContainer {...containerProps}>
            <DropdownSelect
                width={inputWidth}
                items={items}
                extraItems={extraItems}
                selectedItem={selectedItem}
                onSelect={onSelectHandler}
                error={!isValid}
            />
        </FieldContainer>
    );
});

DropdownSelectField.displayName = 'DropdownSelectField';
