export interface TreeData {
    topNodesKeys: string[];
    nodes: Record<string, TreeNode>;
}

export interface TreeNode {
    key: string;
    id: number;
    level: number;
    type: EntityType;
    text: string;
    parentKey: string;
    childrenKeys: string[];
    excluded?: boolean;
}

export interface DeviceTreeNode extends TreeNode {
    imei: string;
}

export interface TreeGeozoneNode extends TreeNode {
    geozoneType: GeozoneType;
}

export enum EntityType {
    Company = 1,
    Group = 2,
    Device = 3,
    Retranslation = 4,
    GeoGroup = 5,
    GeoZone = 6,
    UserGroup = 7,
    User = 8,
    Driver = 9,
    Event = 10,
    Account = 11,
    AccountGroup = 12,
    // Любое новое значение нужно добавить в функцию parseApiId
    // Если добавляется группа, её нужно добавить в функцию isGroup
    Unknown = 99,
}

export enum GeozoneType {
    Point = 0,
    Line = 1,
    Polygon = 2,
    Unknown = 99,
}

export function createEmptyTreeData(): TreeData {
    return {
        topNodesKeys: [],
        nodes: {},
    };
}

export function getParentCompanyId(tree: TreeData, key: string): number {
    let item = tree.nodes[key];

    if (!item) {
        return 0;
    }

    while (item && item.type != EntityType.Company) {
        item = tree.nodes[item.parentKey];
    }

    if (!item) {
        return 0;
    }

    return item.id;
}

export interface TreeState {
    expandedKeys: string[];
    checkedKeys: string[];
    selectedKey: string;
}

export function createEmptyTreeState(): TreeState {
    return {
        expandedKeys: [],
        checkedKeys: [],
        selectedKey: '',
    };
}

export function createTreeKey(type: EntityType, id: number): string {
    return `${type}-${id}`;
}

export function convertToTreeKeys(type: EntityType, ids: number[]): string[] {
    return ids.map((id) => createTreeKey(type, id));
}

interface ExtractResult {
    originalTree: TreeData;
    extractedTree: TreeData;
}

interface ExtractCheckedObjectsProps {
    treeData: TreeData;
    treeState: TreeState;
    nodeType?: EntityType;
}
export function extractCheckedObjects(props: ExtractCheckedObjectsProps): ExtractResult {
    let { treeData, treeState, nodeType = EntityType.Device } = props;

    let originalTree: TreeData = {
        nodes: { ...treeData.nodes },
        topNodesKeys: [...treeData.topNodesKeys],
    };
    let extractedTree: TreeData = {
        nodes: {},
        topNodesKeys: [],
    };
    for (let key of treeState.checkedKeys) {
        let node = treeData.nodes[key];
        if (!node || node.type != nodeType) {
            continue;
        }

        let treeBranch = getTreeBranch(treeData, node.key);
        injectTree(extractedTree, treeBranch);
        removeNode(originalTree, node);
    }

    return {
        originalTree,
        extractedTree,
    };
}

interface ExtractObjectsProps {
    treeData: TreeData;
    ids: number[];
    entityType?: EntityType;
}

export function extractObjects(props: ExtractObjectsProps): ExtractResult {
    let originalTree: TreeData = {
        nodes: { ...props.treeData.nodes },
        topNodesKeys: [...props.treeData.topNodesKeys],
    };
    let extractedTree: TreeData = {
        nodes: {},
        topNodesKeys: [],
    };
    const typeToExtract = props.entityType ?? EntityType.Device;
    for (let id of props.ids) {
        let key = createTreeKey(typeToExtract, id);
        let node = props.treeData.nodes[key];
        if (!node || node.type != typeToExtract) {
            continue;
        }

        let treeBranch = getTreeBranch(props.treeData, node.key);
        injectTree(extractedTree, treeBranch);
        removeNode(originalTree, node);
    }

    return {
        originalTree,
        extractedTree,
    };
}

function getTreeBranch(treeData: TreeData, key: string): TreeData {
    let result: TreeData = {
        nodes: {},
        topNodesKeys: [],
    };

    let item = treeData.nodes[key];
    let lastItem: TreeNode | null = null;
    while (item) {
        result.nodes[item.key] = {
            ...item,
            childrenKeys: lastItem === null ? [] : [lastItem.key],
        };
        lastItem = item;
        item = treeData.nodes[lastItem.parentKey];
    }

    for (let key of treeData.topNodesKeys) {
        if (result.nodes[key]) {
            result.topNodesKeys.push(key);
            break;
        }
    }

    return result;
}

function injectTree(targetTree: TreeData, sourceTree: TreeData): void {
    let nodesToInsert = Object.keys(sourceTree.nodes).map((key) => sourceTree.nodes[key]);
    sortNodesByLevelDescending(nodesToInsert);
    targetTree.nodes = { ...targetTree.nodes };
    for (let node of nodesToInsert) {
        if (!targetTree.nodes[node.key]) {
            targetTree.nodes[node.key] = node;
            continue;
        }

        let targetNode = { ...targetTree.nodes[node.key] };
        targetNode.childrenKeys = [...targetNode.childrenKeys];
        for (let key of node.childrenKeys) {
            if (targetNode.childrenKeys.includes(key)) {
                continue;
            }
            insertNodeInOrder(targetTree, targetNode.childrenKeys, sourceTree.nodes[key]);
        }
        targetTree.nodes[node.key] = targetNode;
    }

    targetTree.topNodesKeys = [...targetTree.topNodesKeys];
    for (let key of sourceTree.topNodesKeys) {
        if (targetTree.topNodesKeys.includes(key)) {
            continue;
        }

        insertNodeInOrder(targetTree, targetTree.topNodesKeys, sourceTree.nodes[key]);
    }
}

export function insertNodeInOrder(targetTree: TreeData, keysArray: string[], node: TreeNode): void {
    let indexToInsert = keysArray.findIndex((key) => compareNodes(targetTree.nodes[key], node) > 0);

    if (indexToInsert < 0) {
        keysArray.push(node.key);
        return;
    }

    keysArray.splice(indexToInsert, 0, node.key);
}

function compareNodes(node1: TreeNode, node2: TreeNode): number {
    if (isCompany(node1) && !isCompany(node2)) {
        return -1;
    }

    if (isGroup(node1) && !isGroupOrCompany(node2)) {
        return -1;
    }

    if (isCompany(node2) && !isCompany(node1)) {
        return 1;
    }

    if (isGroup(node2) && !isGroupOrCompany(node1)) {
        return 1;
    }

    if (node2.text > node1.text) {
        return -1;
    }

    if (node1.text > node2.text) {
        return 1;
    }

    return 0;
}

function isCompany(node: TreeNode): boolean {
    return node.type === EntityType.Company;
}

function isGroup(node: TreeNode): boolean {
    return (
        node.type === EntityType.Group ||
        node.type === EntityType.GeoGroup ||
        node.type === EntityType.UserGroup ||
        node.type === EntityType.AccountGroup
    );
}

export function isGroupOrCompany(node: TreeNode): boolean {
    return isCompany(node) || isGroup(node);
}

function removeNode(treeData: TreeData, node: TreeNode): void {
    delete treeData.nodes[node.key];

    let currentNode = node;
    let newParentNode = treeData.nodes[currentNode.parentKey];
    let parentNode = newParentNode ? { ...newParentNode } : undefined;
    while (parentNode) {
        parentNode.childrenKeys = parentNode.childrenKeys.filter((key) => key != currentNode.key);
        treeData.nodes[parentNode.key] = parentNode;
        if (parentNode.childrenKeys.length > 0) {
            break;
        }
        currentNode = parentNode;
        newParentNode = treeData.nodes[currentNode.parentKey];
        parentNode = newParentNode ? { ...newParentNode } : undefined;
        delete treeData.nodes[currentNode.key];
        if (treeData.topNodesKeys.includes(currentNode.key)) {
            treeData.topNodesKeys = treeData.topNodesKeys.filter((item) => item != currentNode.key);
        }
    }

    if (treeData.topNodesKeys.includes(node.key)) {
        treeData.topNodesKeys = treeData.topNodesKeys.filter((item) => item != node.key);
    }
}

export function mergeTrees(tree1: TreeData, tree2: TreeData): TreeData {
    let result = { ...tree1 };
    injectTree(result, tree2);
    return result;
}

function sortNodesByLevelDescending(nodes: TreeNode[]): void {
    nodes.sort((node1, node2) => {
        if (node1.level > node2.level) {
            return -1;
        }

        if (node1.level < node2.level) {
            return 1;
        }

        return 0;
    });
}

export function removeEmptyGroups(treeData: TreeData): void {
    let nodesToRemove: TreeNode[] = [];
    let allNodes = Object.keys(treeData.nodes).map((key) => treeData.nodes[key]);
    for (let node of allNodes) {
        if (isGroupOrCompany(node) && node.childrenKeys.length == 0) {
            nodesToRemove.push(node);
        }
    }

    for (let node of nodesToRemove) {
        removeNode(treeData, node);
    }
}

export function parseApiId(id: string): { type: EntityType; id: number } {
    let result = {
        type: EntityType.Unknown,
        id: 0,
    };

    let values = id.split('-');
    switch (parseInt(values[0])) {
        case 2:
            result.type = EntityType.Company;
            break;
        case 3:
            result.type = EntityType.UserGroup;
            break;
        case 4:
            result.type = EntityType.User;
            break;
        case 8:
            result.type = EntityType.AccountGroup;
            break;
        case 9:
            result.type = EntityType.Account;
            break;
        case 14:
            result.type = EntityType.Group;
            break;
        case 15:
            result.type = EntityType.Device;
            break;
        case 16:
            result.type = EntityType.GeoGroup;
            break;
        case 17:
            result.type = EntityType.GeoZone;
            break;
        case 18:
            result.type = EntityType.Event;
            break;
        case 19:
            result.type = EntityType.Driver;
            break;
        default:
            return result;
    }
    result.id = parseInt(values[1]);

    return result;
}

export function fillLevels(treeData: TreeData, nodeKey: string, level: number): void {
    let node = treeData.nodes[nodeKey];

    if (!node) {
        return;
    }

    node.level = level;

    let newLevel = level + 1;
    for (let childKey of node.childrenKeys) {
        fillLevels(treeData, childKey, newLevel);
    }
}

export const instanceOfDeviceTreeNode = (node: TreeNode): node is DeviceTreeNode => node.type === EntityType.Device;

export const instanceOfTreeGeozoneNode = (node: TreeNode): node is TreeGeozoneNode => node.type === EntityType.GeoZone;

export function findAllNestedGroupsIds(tree: TreeData, selectedGroupId: string, allNestedGroupsIds: string[] = []) {
    allNestedGroupsIds.push(String(tree.nodes[selectedGroupId].id));

    tree.nodes[selectedGroupId].childrenKeys.forEach((childId) => {
        findAllNestedGroupsIds(tree, childId, allNestedGroupsIds);
    });

    return allNestedGroupsIds;
}

export function getStateForChecked(treeData: TreeData, treeState: TreeState, checkedKeys: string[]): TreeState {
    const keysToExpandCache: Set<string> = new Set();
    const keysToCheckCache: Set<string> = new Set();
    checkedKeys.forEach((key) => {
        const node = treeData.nodes[key];
        if (!node) {
            return;
        }

        checkExpandWithParents({ treeData, key, keysToExpandCache, keysToCheckCache });
    });

    return {
        checkedKeys: Array.from(keysToCheckCache),
        expandedKeys: Array.from(keysToExpandCache),
        selectedKey: treeState.selectedKey,
    };
}

interface CheckExpandWithParentsProps {
    treeData: TreeData;
    key: string;
    keysToExpandCache: Set<string>;
    keysToCheckCache: Set<string>;
}

function checkExpandWithParents(props: CheckExpandWithParentsProps): void {
    if (isAllChildrenChecked(props)) {
        props.keysToCheckCache.add(props.key);
        expandAllParents(props);
    }
}

interface IsAllChildrenCheckedProps {
    treeData: TreeData;
    key: string;
    keysToCheckCache: Set<string>;
}

function isAllChildrenChecked(props: IsAllChildrenCheckedProps): boolean {
    const node = props.treeData.nodes[props.key];
    if (!node) {
        return false;
    }

    for (let i = 0; i < node.childrenKeys.length; ++i) {
        const childKey = node.childrenKeys[i];
        if (props.keysToCheckCache.has(childKey)) {
            return false;
        }
    }

    return true;
}

interface ExpandAllParentsProps {
    treeData: TreeData;
    key: string;
    keysToExpandCache: Set<string>;
}

function expandAllParents(props: ExpandAllParentsProps): void {
    const node = props.treeData.nodes[props.key];
    if (!node) {
        return;
    }

    if (node.childrenKeys.length > 0) {
        props.keysToExpandCache.add(props.key);
    }

    expandAllParents({ ...props, key: node.parentKey });
}
