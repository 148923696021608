import * as React from 'react';
import Calendar, { OnChangeDateCallback } from 'react-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/styles';

import { SvgIconType, SvgIcon, FlexFiller, ContainedButton } from 'ui';
import { translate } from 'utilities';
import { InputIconSize } from '../../constants';
import Theme from '../../theme/fm-theme';

import TimeInput from './time';

const FormatKey = 'MaskDate';

const createStyles = makeStyles((theme: Theme) => ({
    inputContainer: { ...theme.custom?.dateTimeField.inputContainer },
    inputContainerWithFocus: { ...theme.custom?.dateTimeField.inputContainerInFocus },
    inputContainerWithError: { ...theme.custom?.dateTimeField.inputContainerWithError },
    input: { ...theme.custom?.dateTimeField.input },
    popover: { ...theme.custom?.dateTimeField.popoverContainer },
    calendarContainer: { ...theme.custom?.dateTimeField.calendarContainer },
    timeContainer: { ...theme.custom?.dateTimeField.timeContainer },
    timeLabel: { ...theme.custom?.dateTimeField.timeLabel },
}));

interface DateTimeFieldProps {
    initialValue?: Date;
    width?: number;
    onChange?: (valid: boolean, lastValidValue: Date) => void;
}
export const DateTimeSelect: React.FC<DateTimeFieldProps> = (props) => {
    let { initialValue, width, onChange } = props;
    let classes = createStyles();

    let inputContainerRef = React.useRef<HTMLDivElement>(null);
    let inputRef = React.useRef<HTMLInputElement>(null);
    let [value, setValue] = React.useState<Date>(initialValue || new Date());
    let [textValue, setTextValue] = React.useState(getTextValue(value));
    let [anchor, setAnchor] = React.useState<HTMLDivElement | null>(null);
    let [focused, setFocused] = React.useState<boolean>(false);
    let [popoverJustClosed, setPopoverJustClosed] = React.useState<boolean>(false);
    let [valid, setValid] = React.useState<boolean>(true);

    const showPopover = (): void => {
        setAnchor(inputContainerRef.current);
    };

    const closePopover = () => {
        setAnchor(null);
        setPopoverJustClosed(true);
        setTimeout(() => {
            setPopoverJustClosed(false);
            inputRef.current?.focus();
        }, 50);
    };

    const callOnChange = (value: Date, valid: boolean) => {
        if (onChange) {
            onChange(valid, value);
        }
    };

    const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newTextValue = event.target.value;
        let isValid = true;
        let newValue: Date = new Date();
        try {
            newValue = parse(newTextValue, translate(FormatKey), new Date());
        } catch {
            isValid = false;
        }
        if (newValue.toString() == 'Invalid Date') {
            isValid = false;
        }

        if (isValid) {
            setValid(isValid);
            setValue(newValue);
            setTextValue(newTextValue);
            callOnChange(newValue, true);
            return;
        }

        setValid(isValid);
        setTextValue(newTextValue);
        callOnChange(value, isValid);
    };

    const updateValue = (newValue: Date, valid: boolean) => {
        setValue(newValue);
        setTextValue(getTextValue(newValue));
        callOnChange(newValue, valid);
    };

    const onDateChange: OnChangeDateCallback = (newDate) => {
        let newValue = new Date(value);
        newValue.setDate(newDate.getDate());
        newValue.setMonth(newDate.getMonth());
        newValue.setFullYear(newDate.getFullYear());
        updateValue(newValue, true);
    };

    const onTimeChange = (newTime: Date) => {
        let newValue = new Date(value);
        newValue.setHours(newTime.getHours());
        newValue.setMinutes(newTime.getMinutes());
        newValue.setSeconds(newTime.getSeconds());
        updateValue(newValue, true);
    };

    let containerStyle: React.CSSProperties = {};
    if (width != undefined) {
        containerStyle.width = width;
    }
    let chevronType: SvgIconType = anchor ? 'chevron-up' : 'chevron-down';
    let containerStyles =
        focused || anchor || popoverJustClosed
            ? `${classes.inputContainer} ${classes.inputContainerWithFocus}`
            : classes.inputContainer;
    if (!valid) {
        containerStyles += ` ${classes.inputContainerWithError}`;
    }
    return (
        <>
            <div ref={inputContainerRef} className={containerStyles} style={containerStyle}>
                <SvgIcon type="calendar" size={InputIconSize} />
                <input
                    ref={inputRef}
                    className={classes.input}
                    value={textValue}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onChange={onInputValueChange}
                />
                <div className="icon-button" onClick={showPopover}>
                    <SvgIcon type={chevronType} size={InputIconSize} />
                </div>
            </div>
            <Popover
                keepMounted
                elevation={0}
                getContentAnchorEl={null}
                open={Boolean(anchor)}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={`${classes.popover} ${classes.calendarContainer}`}
                onClose={closePopover}
            >
                <Calendar value={value} onChange={onDateChange} />
                <div className={classes.timeContainer}>
                    <div className={classes.timeLabel}>{translate('Time')}</div>
                    <TimeInput value={value} onChange={onTimeChange} />
                    <FlexFiller />
                    <ContainedButton disableMinWidth textKey="Ready" onClick={closePopover} />
                </div>
            </Popover>
        </>
    );
};

function getTextValue(value: Date): string {
    let valueFormat = translate(FormatKey);
    if (valueFormat == FormatKey) {
        valueFormat = 'dd.MM.yyyy HH:mm:ss';
    }

    return format(value, valueFormat);
}
