import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    root: { ...theme.custom?.svgIcon.timeDivider },
}));
/**Иконка разделителя времени */
export const TimeDividerIcon: React.FC = () => {
    let { root } = createStyles();

    return (
        <svg className={root} width={3} height={12}>
            <path d="M1.52239 3.03882C2.32836 3.03882 3 2.39556 3 1.50832C3 0.621072 2.32836 0 1.52239 0C0.69403 0 0 0.621072 0 1.50832C0 2.39556 0.69403 3.03882 1.52239 3.03882ZM1.52239 12C2.32836 12 3 11.3567 3 10.4695C3 9.58226 2.32836 8.96118 1.52239 8.96118C0.69403 8.96118 0 9.58226 0 10.4695C0 11.3567 0.69403 12 1.52239 12Z" />
        </svg>
    );
};
