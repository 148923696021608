import { DropdownItem } from './inputs';

export { List } from './lists/list';
export { ListItem } from './lists/list-item';
export { Icon, IconType, IconColorType } from './icons/icon';
export { IconButton } from './buttons/icon-button';
export { CheckBox } from './buttons/check-box';
export { Loading } from './loading/loading';
export { IconColored, IconColoredWithRef } from './icons/icon-colored';
export * from './icons';
export * from './dialogs';
export * from './form';
export * from './empty-pane';
export * from './buttons';
export * from './notifications';
export * from './inputs';
export * from './toolbar';
export * from './flex-filler';
export * from './popups';
export * from './tree';
export * from './details';
export * from './modal-notification';
export * from './tab-pane';
export * from './date-time';
export * from './flex-divider';
export * from './two-pane-tree-select';
export * from './list';
export * from './two-pane-list-select';
export { TextTagWithClose } from './tag';
export * from './table';
export * from './error-pane';
export * from './main-block';
export * from './splitter/splitter';

export enum Colors {
    Primary = 'Primary',
    PrimaryText = 'PrimaryText',
    Error = 'Error',
    Warning = 'Warning',
    Info = 'Info',
    Debug = 'Debug',
    Success = 'Success',
    Divider = 'Divider',
}

export interface Size {
    /**Ширина */
    width: number;
    /**Высота */
    height: number;
}

export interface TableHeaderProps {
    columnWidths: number[];
}

export interface TableRowProps extends TableHeaderProps {
    index: number;
    lastRow?: boolean;
}

export interface Validatable {
    validate: () => boolean;
}

export const getTableCellStyle = (columnWidths: number[], columnIndex: number): React.CSSProperties => ({
    flex: `0 0 ${columnWidths[columnIndex]}px`,
    width: columnWidths[columnIndex],
});

export const getMergedTableCellStyle = (columnWidths: number[], ...indexes: number[]): React.CSSProperties => {
    let width = 0;
    for (let index of indexes) {
        width += columnWidths[index];
    }
    return {
        flex: `0 0 ${width}px`,
    };
};

export interface TagData<T> {
    type: T;
    key: string;
    value: string;
}

export function appendTagsByDropdownItems<T>(items: DropdownItem[], target: TagData<T>[], tagType: T): void {
    for (let item of items) {
        target.push({
            type: tagType,
            key: item.key,
            value: item.value,
        });
    }
}

export enum LoadingState {
    InProgress,
    Success,
    Failure,
}

export function getLoadingState(...loadersStates: LoadingState[]): LoadingState {
    let isThereFailure = false;

    for (let loaderState of loadersStates) {
        if (loaderState === LoadingState.InProgress) {
            return LoadingState.InProgress;
        }
        if (loaderState === LoadingState.Failure) {
            isThereFailure = true;
        }
    }

    if (isThereFailure) {
        return LoadingState.Failure;
    }

    return LoadingState.Success;
}

getLoadingState(LoadingState.Failure);
