export enum SortOrder {
    Ascension = 1,
    Descension = 2,
}

interface SortTableViewProps<T> {
    view: T[];
    field: keyof T;
    oldField?: keyof T;
    order: SortOrder;
}
export function sortTableView<T>(props: SortTableViewProps<T>): T[] {
    let {
        view,
        field,
        oldField,
        order,
    } = props;

    let result = [...view];

    if (field == oldField) {
        result.reverse();
        return result;
    }

    result.sort((item1, item2) => {
        let value1 = typeof item1[field] == 'string'
            ? (item1[field] as unknown as string).toLowerCase()
            : item1[field];
        let value2 = typeof item2[field] == 'string'
            ? (item2[field] as unknown as string).toLowerCase()
            : item2[field];

        if (value1 > value2) {
            return order == SortOrder.Ascension ? 1 : -1;
        }

        if (value1 < value2) {
            return order == SortOrder.Ascension ? -1 : 1;
        }

        return 0;
    });

    return result;
};

export interface TableFilterSettings<T> {
    checkedColumns: T[];
    caseSensitive: boolean;
    wholeMatch: boolean;
}

interface FilterTableViewProps<T> extends TableFilterSettings<keyof T> {
    view: T[];
    filterText: string;
    /** Если вернёт false, элемент исключается из выборки. Сделано так, как работает стандартный фильтр в JS. */
    additionalFilters?: ((item: T) => boolean)[];
}
export function filterTableView<T>(props: FilterTableViewProps<T>): T[] {
    let {
        view,
        filterText,
        checkedColumns,
        caseSensitive,
        wholeMatch,
        additionalFilters = [],
    } = props;

    if (filterText.length == 0 && additionalFilters.length == 0) {
        return view;
    }

    let filterValue = filterText;
    if (!caseSensitive) {
        filterValue = filterValue.toLowerCase();
    }

    let result = view.filter(item => {
        for (let i = 0; i < additionalFilters.length; ++i) {
            if (!additionalFilters[i](item)) {
                return false;
            }
        }

        if (additionalFilters.length > 0 && filterText.length == 0) {
            return true;
        }

        let filterPassed = false;
        for (let column of checkedColumns) {
            let value = String(item[column]);
            if (!caseSensitive) {
                value = value.toLowerCase();
            }
            if (wholeMatch) {
                filterPassed = value == filterValue;
            }
            else {
                filterPassed = value.includes(filterValue);
            }
            if (filterPassed) {
                return true;
            }
        }
        return false;
    });

    return result;
}

interface FilterSortTableViewProps<T> {
    view: T[];
    filterSettings: Omit<FilterTableViewProps<T>, 'view'>;
    sortSettings: Omit<SortTableViewProps<T>, 'view'>;
};
export function filterSortTableView<T>(props: FilterSortTableViewProps<T>): T[] {
    let {
        view,
        filterSettings,
        sortSettings,
    } = props;

    let newView = filterTableView({
        ...filterSettings,
        view,
    });
    newView = sortTableView({
        ...sortSettings,
        view: newView,
    });

    return newView;
}
