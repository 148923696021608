import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { translate } from 'utilities';
import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    default: { ...theme.custom?.buttons.defaultTab },
    selected: { ...theme.custom?.buttons.selectedTab },
}));

export interface TabButtonProps {
    /**Текст в кнопке, будет весь в верхнем регистре  */
    textKey: string;
    /**Параметр, отображает дополнительные стили, если кнопка выбрана */
    selected?: boolean;
    /**Обработчик клика */
    onClick?: () => void;
}
/** @deprecated Вроде как не используется. */
export const TabButton: React.FC<TabButtonProps> = (props) => {
    let { textKey, selected = false, onClick } = props;

    let classes = createStyles();

    let className = selected ? classes.selected : classes.default;
    return (
        <div className={className} onClick={onClick}>
            {translate(textKey)}
        </div>
    );
};
