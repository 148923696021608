import React from 'react';
import formatDate from 'date-fns/format';
import { makeStyles } from '@material-ui/styles';

import { TimeDividerIcon } from 'ui';
import { Theme, combineStyles } from '../../theme';

const MinTimeValue = 0;
const MaxHoursValue = 23;
const MaxMinutesValue = 59;

const useStyles = makeStyles((theme: Theme) => ({
    timeInputContainer: { ...theme.custom?.dateTimeField.timeInputContainer },
    timeInputContainerInFocus: { ...theme.custom?.dateTimeField.timeInputContainerInFocus },
    timeInput: { ...theme.custom?.dateTimeField.timeInput },
    timeInputContainerError: {
        borderColor: `${theme.custom?.palette.levels.error} !important`,
    },
}));

export interface TimeInputProps {
    /**Время */
    value?: Date;
    /**Обработчик изменения времени */
    onChange?: (value: Date) => void;
    /**Параметр устанавливает красные границы у инпутов */
    valid?: boolean;
}
/**Компонент для задания времени */
export const TimeInput: React.FC<TimeInputProps> = (props) => {
    let { value = new Date(), onChange, valid = true } = props;
    let classes = useStyles();

    let [hoursFocused, setHoursFocused] = React.useState<boolean>(false);
    let [minutesFocused, setMinutesFocused] = React.useState<boolean>(false);
    let [secondsFocused, setSecondsFocused] = React.useState<boolean>(false);

    const callOnChange = (value: Date) => {
        if (onChange) {
            onChange(value);
        }
    };

    const updateValue = (newValue: Date) => {
        callOnChange(newValue);
    };

    const updateHours = (numberValue: number) => {
        numberValue = correctValue(numberValue, MinTimeValue, MaxHoursValue);
        let newValue = new Date(value);
        newValue.setHours(numberValue);
        updateValue(newValue);
    };

    const onHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let numberValue = getValue(event.target.value, MaxHoursValue);
        updateHours(numberValue);
    };

    const onHoursWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        let numberValue = value.getHours();
        numberValue = modifyValueByAction(numberValue, event.deltaY);
        updateHours(numberValue);
    };

    const onHoursKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let actionValue = getActionValueFromKeyEvent(event);
        if (actionValue == 0) {
            return;
        } else {
            event.preventDefault();
        }
        let numberValue = value.getHours();
        numberValue = modifyValueByAction(numberValue, actionValue);
        updateHours(numberValue);
    };

    const updateMinutes = (numberValue: number) => {
        numberValue = correctValue(numberValue, MinTimeValue, MaxMinutesValue);
        let newValue = new Date(value);
        newValue.setMinutes(numberValue);
        updateValue(newValue);
    };

    const onMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let numberValue = getValue(event.target.value);
        updateMinutes(numberValue);
    };

    const onMinutesWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        let numberValue = value.getMinutes();
        numberValue = modifyValueByAction(numberValue, event.deltaY);
        updateMinutes(numberValue);
    };

    const onMinutesKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let actionValue = getActionValueFromKeyEvent(event);
        if (actionValue == 0) {
            return;
        } else {
            event.preventDefault();
        }
        let numberValue = value.getMinutes();
        numberValue = modifyValueByAction(numberValue, actionValue);
        updateMinutes(numberValue);
    };

    const updateSeconds = (numberValue: number) => {
        numberValue = correctValue(numberValue, MinTimeValue, MaxMinutesValue);
        let newValue = new Date(value);
        newValue.setSeconds(numberValue);
        updateValue(newValue);
    };

    const onSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let numberValue = getValue(event.target.value);
        updateSeconds(numberValue);
    };

    const onSecondsWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        let numberValue = value.getSeconds();
        numberValue = modifyValueByAction(numberValue, event.deltaY);
        updateSeconds(numberValue);
    };

    const onSecondsKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let actionValue = getActionValueFromKeyEvent(event);
        if (actionValue == 0) {
            return;
        } else {
            event.preventDefault();
        }
        let numberValue = value.getSeconds();
        numberValue = modifyValueByAction(numberValue, actionValue);
        updateSeconds(numberValue);
    };

    let selectedStyles = combineStyles(classes.timeInputContainer, classes.timeInputContainerInFocus);
    let defaultStyles = valid
        ? classes.timeInputContainer
        : combineStyles(classes.timeInputContainer, classes.timeInputContainerError);
    let hoursContainerStyles = hoursFocused ? selectedStyles : defaultStyles;
    let minutesContainerStyles = minutesFocused ? selectedStyles : defaultStyles;
    let secondsContainerStyles = secondsFocused ? selectedStyles : defaultStyles;
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div className={hoursContainerStyles}>
                <input
                    className={classes.timeInput}
                    value={formatDate(value, 'HH')}
                    onFocus={() => setHoursFocused(true)}
                    onBlur={() => setHoursFocused(false)}
                    onWheel={onHoursWheel}
                    onKeyUp={onHoursKeyDown}
                    onChange={onHoursChange}
                />
            </div>
            <TimeDividerIcon />
            <div className={minutesContainerStyles}>
                <input
                    className={classes.timeInput}
                    value={formatDate(value, 'mm')}
                    onFocus={() => setMinutesFocused(true)}
                    onBlur={() => setMinutesFocused(false)}
                    onWheel={onMinutesWheel}
                    onKeyUp={onMinutesKeyDown}
                    onChange={onMinutesChange}
                />
            </div>
            <TimeDividerIcon />
            <div className={secondsContainerStyles}>
                <input
                    className={classes.timeInput}
                    value={formatDate(value, 'ss')}
                    onFocus={() => setSecondsFocused(true)}
                    onBlur={() => setSecondsFocused(false)}
                    onWheel={onSecondsWheel}
                    onKeyUp={onSecondsKeyDown}
                    onChange={onSecondsChange}
                />
            </div>
        </div>
    );
};

function getValue(textValue: string, max = MaxMinutesValue): number {
    let result = parseInt(textValue);
    if (isNaN(result)) {
        result = 0;
    }
    return correctValue(result, 0, max);
}

function correctValue(value: number, min: number, max: number): number {
    if (value < min) {
        return max;
    }
    if (value > max) {
        return min;
    }
    return value;
}

function modifyValueByAction(value: number, actionValue: number): number {
    if (actionValue > 0) {
        --value;
    }
    if (actionValue < 0) {
        ++value;
    }
    return value;
}

function getActionValueFromKeyEvent(event: React.KeyboardEvent) {
    let result = 0;
    if (event.key == 'ArrowUp') {
        result = -1;
    }
    if (event.key == 'ArrowDown') {
        result = 1;
    }
    return result;
}

export default TimeInput;
