import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { SvgIconType, SvgIcon } from 'ui';
import { translate } from 'utilities';
import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    menuItem: { ...theme.custom?.popupMenu.item },
    icon: { ...theme.custom?.popupMenu.icon },
}));

interface MenuItemProps {
    frontIconType?: SvgIconType;
    textKey: string;
    onClick?: () => void;
}
export const MenuItem: React.FC<MenuItemProps> = (props) => {
    let { frontIconType, textKey, onClick } = props;
    let classes = createStyles();

    return (
        <div className={classes.menuItem} onClick={onClick}>
            {frontIconType && <SvgIcon className={classes.icon} type={frontIconType} />}
            {translate(textKey)}
        </div>
    );
};
