import * as React from 'react';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core';
import { Colors, SvgIcon } from 'ui';
import { defaultTheme as theme } from 'theme';

import { FieldContainer } from './field-container';
import { DefaultTableCellIconSize } from '../../constants';
import { translate } from 'utilities';

const useStyles = makeStyles(() => ({
    fieldContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: '15px',
        cursor: 'pointer',
    },
}));

export interface PasswordFieldProps {
    /**Значения поля ввода */
    value?: string;
    /**Обработчик изменения поля ввода */
    onChange?: (value: string) => void;
    /**Ширина поля ввода */
    inputWidth?: number;
    /**Ширина поля описания */
    labelWidth?: number;
    /**Текст поля описания */
    labelTextKey?: string;
    /**Параметр отвечает за автофокус на поле ввода */
    autoFocus?: boolean;
    /**Параметр отвечает за  отключение отступов */
    disablePadding?: boolean;
    /**Максимальная длина поля */
    maxLength?: number;
    /**Параметр отвечает за наличие ошибок */
    error?: boolean;
}

interface ErrorBorderStyle {
    border?: string;
}
/**Поле для ввода пароля */
export const PasswordField: React.FC<PasswordFieldProps> = (props) => {
    let {
        value = '',
        onChange,
        inputWidth,
        labelWidth,
        labelTextKey,
        autoFocus,
        disablePadding,
        maxLength,
        error,
    } = props;

    const [passwordVisibility, setPasswordVisibility] = React.useState<boolean>(true);
    const [typeInput, setTypeInput] = React.useState<string>('password');
    let [valid, setValid] = React.useState<boolean>(true);
    let [errorText, setErrorText] = React.useState<string>('');

    let classes = useStyles();

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (!onChange) {
            return;
        }

        let value = event.target.value;
        if (typeof maxLength == 'number' && value.length > maxLength) {
            value = value.slice(0, maxLength);
        }

        if (value.length > 0) {
            setValid(true);
            setErrorText('');
        }

        onChange(value);
    }, []);

    let containerProps = {
        inputWidth,
        labelWidth,
        labelTextKey,
        disablePadding,
        valid,
        errorText,
    };

    let style: ErrorBorderStyle = {};

    if (error || !valid) {
        style.border = `1px solid ${theme.custom?.colors.selectedBackground.error}`;
    }

    const onPasswordVisibilityChange = React.useCallback(() => {
        if (!passwordVisibility) {
            setPasswordVisibility(true);
            setTypeInput('password');
        } else {
            setPasswordVisibility(false);
            setTypeInput('text');
        }
    }, [passwordVisibility]);

    React.useEffect(() => {
        if (error && valid) {
            setValid(false);
            setErrorText(translate('DifferentPassword'));
        }
        if (!error && value.length > 0) {
            setValid(true);
            setErrorText('');
        }
    }, [error, value, valid]);

    function validate(): void {
        if (value.length > 0 && !error) {
            setValid(true);
            setErrorText('');
            return;
        }

        setValid(false);
        if (value.length == 0) {
            setErrorText(translate('FieldCanNotBeEmpty'));
        } else {
            setErrorText(translate('DifferentPassword'));
        }
    }

    let iconColor = Colors.Divider;
    return (
        <FieldContainer {...containerProps}>
            <div className={classes.fieldContainer}>
                <Input
                    disableUnderline
                    fullWidth
                    autoFocus={autoFocus}
                    value={value}
                    onChange={onChangeHandler}
                    style={style}
                    type={typeInput}
                    onBlur={validate}
                />
                <div
                    className={classes.iconContainer}
                    onClick={onPasswordVisibilityChange}
                    title={passwordVisibility ? translate('Show') : translate('Hide')}
                >
                    {passwordVisibility ? (
                        <SvgIcon type={'eye'} size={DefaultTableCellIconSize} color={iconColor} />
                    ) : (
                        <SvgIcon type={'eye-off'} size={DefaultTableCellIconSize} color={iconColor} />
                    )}
                </div>
            </div>
        </FieldContainer>
    );
};
