import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    root: theme.custom?.dialog.toolbar || {},
}));
/**Контейнер для отображения туллбара в окне */
export const DialogToolbar: React.FC<React.PropsWithChildren> = (props) => {
    let { children } = props;
    let classes = createStyles();

    return <div className={classes.root}>{children}</div>;
};
