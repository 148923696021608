import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Theme from '../../../theme/login-theme';
import { SvgIcon } from './svg-icon';

const createStyles = makeStyles((theme: Theme) => ({
    errorIcon: theme.custom?.errorIcon || {},
}));

export const ErrorIcon: React.FC = () => {
    let classes = createStyles();

    return (
        <div className={classes.errorIcon}>
            <SvgIcon type="error-cross" />
        </div>
    );
};
