import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { translate } from 'utilities';
import { Theme, combineStyles } from 'theme';

const createStyles = makeStyles((theme: Theme) => ({
    tabRoot: { ...theme.custom?.dialog.tabTitle.tab },
    text: { ...theme.custom?.dialog.tabTitle.text },
    activeText: { ...theme.custom?.dialog.tabTitle.activeText },
    marker: { ...theme.custom?.dialog.tabTitle.marker },
    activeMarker: { ...theme.custom?.dialog.tabTitle.activeMarker },
}));

export interface DialogTextTabProps {
    /**Название вкладки */
    textKey: string;
    /**Состояние активности вкладки  */
    active?: boolean;
    /**Обработчик клика при нажатии на вкладку */
    onClick?: () => void;
}
/**Компонент для создания вкладок в окне */
export const DialogTextTab: React.FC<React.PropsWithChildren<DialogTextTabProps>> = (props) => {
    let { textKey, active, onClick } = props;
    let classes = createStyles();

    let textClasses = classes.text;
    let markerClasses = classes.marker;
    if (active) {
        textClasses = combineStyles(textClasses, classes.activeText);
        markerClasses = combineStyles(markerClasses, classes.activeMarker);
    }
    return (
        <div className={classes.tabRoot} onClick={onClick}>
            <div className={textClasses}>{translate(textKey)}</div>
            <div className={markerClasses} />
        </div>
    );
};
