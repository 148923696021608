import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { getExcelData, getExcelFile } from 'entities';
import { Theme } from 'theme';
import { downloadExcelFile, toast } from 'utilities';

import {
    AnyColumn,
    CustomFilter,
    FilterColumn,
    TableColumn,
    TableColumnWithChildren,
    TableGridProps,
    ToolbarProps as ExternalToolbarProps,
} from '.';
import BaseTable from './base-table';
import { TableProps, ToolbarProps } from './entities';
import Toolbar from './toolbar';

const useStyles = makeStyles((theme: Theme) => ({
    tableContainer: {
        flex: '1 1 0',
        minWidth: 200,
        minHeight: 300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: theme.custom?.spacing(2),
    },
}));

export interface TableContainerProps<T> {
    /**Колонна таблицы по который будут браться ID для строк */
    idKey: keyof T;
    /**Данные для отображения*/
    records: T[];
    /**Колонны таблицы*/
    columns: AnyColumn<T>[];
    /**Параметры таблицы*/
    tableProps: TableGridProps<T>;
    /**Параметры туллбара*/
    toolbarProps: ExternalToolbarProps<T>;
    /**Параметр задает имя экспортируемого файла*/
    fileNameForExport?: string;
    /**Параметр отвечает за отображение границы таблицы*/
    showBorder?: boolean;
    /**Параметр, отвечающий за отображение тулбара */
    showToolbar?: boolean;
    /**Параметр, отвечающий за отображение дополнительной инофрмации в футере таблицы */
    needAppendFooterItems?: boolean;
    /**Параметр, отвечающий за отображение суммы элементов в числовой колонне */
    showTotalRow?: boolean;
    /**Коллбек функция вызывается при изменении состояния тулбара */
    onToolbarPropsChange: (props: ExternalToolbarProps<T>) => void;
    showExportButton?: boolean;
}

/**Компонент контейнера таблицы, содержит тулбар и таблицу */
export const TableContainer = <T,>(props: TableContainerProps<T>): JSX.Element => {
    let {
        idKey,
        records,
        columns,
        tableProps,
        showBorder = false,
        showToolbar = true,
        needAppendFooterItems = true,
    } = props;
    let classes = useStyles();

    let recordsForExport = React.useRef<T[]>([]);
    let [previousToolbarProps, setPreviousToolbarProps] = React.useState<ExternalToolbarProps<T>>(props.toolbarProps);
    let [customFilters, setCustomFilters] = React.useState<CustomFilter<T>[]>([]);
    let [customFiltersData, setCustomFiltersData] = React.useState<Record<string, boolean>>({});
    let [filteredRecords, setFilteredRecords] = React.useState<T[]>([]);
    let [recordsReady, setRecordsReady] = React.useState(false);
    let [excelLoading, setExcelLoading] = React.useState<boolean>(false);

    const updateCustomFiltersData = React.useCallback(
        (filterId: string) => {
            setCustomFiltersData({
                ...customFiltersData,
                [filterId]: !customFiltersData[filterId],
            });
        },
        [customFiltersData],
    );

    const updateSortedRecords = React.useCallback((records: T[]) => {
        recordsForExport.current = records;
    }, []);

    const exportRecords = React.useCallback(() => {
        try {
            if (excelLoading) {
                return;
            }

            let fieldsForExport: (keyof T)[] = [];
            let captionsForExport: string[] = [];
            let renderers: { [key in keyof T]?: (value: T) => string | number } = {};
            const fillFieldsAndCaptions = (column: AnyColumn<T>, prefix: string): void => {
                if (prefix) {
                    prefix += ' ';
                }
                let text = prefix + column.title;
                if (column instanceof TableColumnWithChildren) {
                    column.children.forEach((child) => fillFieldsAndCaptions(child, text));
                    return;
                }

                column = column as TableColumn<T>;
                if (!column.visible) {
                    return;
                }

                fieldsForExport.push(column.key);
                captionsForExport.push(column.exportTitle ?? text);

                if (column.textRenderer) {
                    renderers[column.key] = column.textRenderer;
                }
                if (column.exportRenderer) {
                    renderers[column.key] = column.exportRenderer;
                }
            };
            columns.forEach((column) => fillFieldsAndCaptions(column, ''));

            let excelData = getExcelData({
                collection: recordsForExport.current,
                fields: fieldsForExport,
                captions: captionsForExport,
                renderers,
            });
            excelData.name = props.fileNameForExport || 'Excel Export';

            setExcelLoading(true);
            getExcelFile(excelData)
                .then((fileName) => downloadExcelFile(fileName))
                .catch((error) => toast.error(error.message))
                .finally(() => setExcelLoading(false));
        } catch (error) {
            console.error(error);
            toast.error((error as Error).message);
        }
    }, []);

    let toolbarProps: ToolbarProps<T> = {
        excelLoading,
        filterText: props.toolbarProps.filterText,
        customFilters,
        customFiltersData,
        updateCustomFiltersData,
        filterColumns: props.toolbarProps.filterColumns,
        caseSensitivity: props.toolbarProps.caseSensitivity,
        wholeMatch: props.toolbarProps.wholeMatch,
        showCreateButton: props.toolbarProps.showCreateButton ?? true,
        showExportButton: props.toolbarProps.showExportButton ?? true,
        onToolbarPropsChange: props.onToolbarPropsChange,
        onCreateClick: props.toolbarProps.onCreateClick,
        onExportClick: exportRecords,
        customButtons: props.toolbarProps.customButtons,
        alternativeToolbar: props.toolbarProps.alternativeToolbar,
        tags: props.toolbarProps.tags,
        onRemoveTag: props.toolbarProps.onRemoveTag,
    };

    React.useEffect(() => {
        setCustomFilters(props.toolbarProps.customFilters || []);
    }, [props.toolbarProps.customFilters]);

    React.useEffect(() => {
        let activeFilters = getActiveCustomFilters(customFilters, customFiltersData);
        if (!props.toolbarProps.filterText && activeFilters.length == 0) {
            setFilteredRecords(records);
            setPreviousToolbarProps(props.toolbarProps);
            return;
        }

        let recordsForFilter: T[];
        if (props.toolbarProps.filterText.includes(previousToolbarProps.filterText)) {
            recordsForFilter = filteredRecords;
        } else {
            recordsForFilter = records;
        }

        if (filterPropsChanged(props.toolbarProps, previousToolbarProps)) {
            recordsForFilter = records;
        }

        let newFilteredRecords = filterRecords({
            records: recordsForFilter,
            settings: {
                ...props.toolbarProps,
                customFilters: activeFilters,
            },
        });

        setPreviousToolbarProps(props.toolbarProps);
        setFilteredRecords(newFilteredRecords);
    }, [
        props.toolbarProps.filterText,
        props.toolbarProps.customFilters,
        props.toolbarProps.filterColumns,
        props.toolbarProps.caseSensitivity,
        props.toolbarProps.wholeMatch,
    ]);

    React.useEffect(() => {
        if (tableProps.showLoading) {
            setRecordsReady(false);
        }
    }, [tableProps.showLoading]);

    React.useEffect(() => {
        if (tableProps.showLoading) {
            return;
        }

        let activeFilters = getActiveCustomFilters(customFilters, customFiltersData);

        let newFilteredRecords = filterRecords({
            records: records,
            settings: {
                ...props.toolbarProps,
                customFilters: activeFilters,
            },
        });

        setFilteredRecords(newFilteredRecords);
        setRecordsReady(true);
    }, [records, customFilters, customFiltersData]);

    let baseTableProps: TableProps<T> = {
        ...tableProps,
        idKey,
        records: filteredRecords,
        columns,
        updateRecordsForExport: updateSortedRecords,
        showBorder,
        needAppendFooterItems,
        showTotalRow: props.showTotalRow ?? false,
        showLoading: !recordsReady,
    };

    return (
        <div className={classes.tableContainer}>
            {showToolbar && <Toolbar {...toolbarProps} />}
            <BaseTable {...baseTableProps} />
        </div>
    );
};

const getActiveCustomFilters = <T,>(
    customFilters: CustomFilter<T>[],
    customFiltersData: Record<string, boolean>,
): CustomFilter<T>[] => {
    let result: CustomFilter<T>[] = [];

    for (let filter of customFilters) {
        if (customFiltersData[filter.id]) {
            result.push(filter);
        }
    }

    return result;
};

interface FilterRecordsProps<T> {
    records: T[];
    settings: {
        customFilters: CustomFilter<T>[];
        filterText: string;
        filterColumns: FilterColumn<T>[];
        caseSensitivity: boolean;
        wholeMatch: boolean;
    };
}

const filterRecords = <T,>(props: FilterRecordsProps<T>): T[] => {
    let { records, settings } = props;

    let newFilteredRecords: T[] | undefined;
    if (settings.customFilters.length > 0) {
        newFilteredRecords = records.filter((record) => {
            for (let filter of settings.customFilters) {
                if (!filter.filterFunction(record)) {
                    return false;
                }
            }
            return true;
        });
    }

    newFilteredRecords ??= records;
    if (props.settings.filterText.length == 0) {
        return newFilteredRecords;
    }

    let filterValue = props.settings.filterText;
    if (!props.settings.caseSensitivity) {
        filterValue = filterValue.toLowerCase();
    }

    return newFilteredRecords.filter((item) => {
        for (let column of props.settings.filterColumns) {
            if (!column.active) continue;

            let value = String(item[column.key]);

            if (!props.settings.caseSensitivity) {
                value = value.toLowerCase();
            }

            if (props.settings.wholeMatch) {
                if (value == filterValue) return true;

                continue;
            }

            if (value.includes(filterValue)) return true;
        }
        return false;
    });
};

const filterPropsChanged = <T,>(props: ExternalToolbarProps<T>, previousProps: ExternalToolbarProps<T>): boolean => {
    if (previousProps.caseSensitivity != props.caseSensitivity || previousProps.wholeMatch != props.wholeMatch) {
        return true;
    }

    let changedColumns = previousProps.filterColumns.filter((prevItem) => {
        let item = props.filterColumns.find((item) => item.key == prevItem.key);
        return prevItem.active != item?.active;
    });

    return changedColumns.length > 0;
};

export default TableContainer;
