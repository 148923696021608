import * as React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid } from 'react-window';
import { makeStyles } from '@material-ui/styles';

import { TreeData, TreeState, createEmptyTreeState, TreeNode } from 'entities';
import { SearchInput, EmptyListPane, SvgIcon } from 'ui';
import { DefaultTableRowHeight, DefaultScrollWidth } from '../../constants';
import { Theme, UiTreeStyles } from '../../theme';

import {
    ReactTreeData,
    ReactTreeSettings,
    ReactTreeNodeSettings,
    ReactTreeNodeActions,
    ReactTreeColorScheme,
    createEmptyReactTreeData,
    parseReactTreeProps,
    triggerExpand,
    createView,
    triggerCheckWithChildren,
    selectByKey,
    getExpandedKeys,
    getCheckedKeys,
    getSearchOrder,
    search,
    expandParents,
    canNodeTypeBeSelected,
    setExpandedForAllNodes,
    setCheckedForAllNodes,
    getCheckedItemsCount,
} from './entities';
import { TreeNode as TreeNodeComponent } from './tree-node';
import { translate } from 'utilities';

const createStyles = (colorScheme: ReactTreeColorScheme) =>
    makeStyles((theme: Theme) => {
        const treeStyles = getTreeStyles(theme, colorScheme);
        const iconButtonsSize = theme.custom?.mainSizes.page.iconSize;

        return {
            treeRoot: { ...treeStyles?.root },
            treeToolbar: {
                ...treeStyles?.toolbar,
                height: 'auto',
                padding: theme.custom?.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                gap: theme.custom?.spacing(1),
            },
            treePane: { ...treeStyles?.tree },
            titleContainer: {
                ...theme.custom?.typography.h4,
                paddingBottom: theme.custom?.spacing(2),
                color: theme.custom?.palette.text.primary,
            },
            toolbarIconContainer: {
                display: 'flex',
                gap: theme.custom?.spacing(1),
                '&>div': {
                    display: 'flex',
                    cursor: 'pointer',
                    '& svg': {
                        width: iconButtonsSize,
                        height: iconButtonsSize,
                    },
                    '& path': {
                        fill: theme.custom?.palette.text.secondary,
                    },
                    '&:hover path': {
                        fill: theme.custom?.palette.primary.main,
                    },
                },
            },
            checkedCountText: {
                ...theme.custom?.typography.text,
                lineHeight: `${iconButtonsSize}px`,
                color: theme.custom?.palette.text.primary,
            },
        };
    });

export function getTreeStyles(theme: Theme, colorScheme: ReactTreeColorScheme): UiTreeStyles | undefined {
    switch (colorScheme) {
        case ReactTreeColorScheme.Navigation:
            return theme.custom?.uiTreeDark;
        default:
            return theme.custom?.uiTreeDefault;
    }
}

export interface TreeProps extends Partial<ReactTreeSettings> {
    /**Данные для дерева */
    data: TreeData;
    /**Состояние дерева */
    state?: TreeState;
    /**Выбранное поле */
    selectedKey?: string;
    /**Обработчик изменения состояния */
    onStateChange?: (state: TreeState) => void;
    /**Обработчик при выделении объекта или при поиске  */
    onSelect?: (key: string, afterSearch: boolean) => void;
    /** Обработчик клика по ноде дерева */
    onNodeClick?: (node: TreeNode) => void;
    /**Параметр, отвечающий за скрытие тулбара */
    hideToolbar?: boolean;
    /**Параметр, отвечающий за отображение заголовка дерева  */
    title?: string;
    /** Показать панель с кнопками */
    showButtonsToolbar?: boolean;
}
/**Компонент отображния дерева, с возможностью поиска, выбором объектов, групп. Если данных нет, то будет отображена заглушка.*/
export const Tree: React.FC<TreeProps> = (props) => {
    let {
        data,
        allowExpand = false,
        allowCheck = false,
        allowSelect = false,
        forbidSelectCompany = false,
        forbidSelectGroup = false,
        colorScheme = ReactTreeColorScheme.Default,
        state,
        selectedKey,
        onStateChange,
        onSelect,
        onNodeClick,
        hideToolbar = false,
        title = '',
    } = props;
    let treeRef = React.useRef<FixedSizeGrid>(null);
    let [searchValue, setSearchValue] = React.useState<string>('');
    let [searchOrder, setSearchOrder] = React.useState<string[]>([]);
    let [treeData, setTreeData] = React.useState<ReactTreeData>(createEmptyReactTreeData());
    let [treeState, setTreeState] = React.useState<TreeState>(state || createEmptyTreeState());
    let [needScrollChangeFlag, setNeedScrollChangeFlag] = React.useState<boolean>(false);
    let classes = createStyles(colorScheme)();

    const updateSearchValue = (newValue: string) => {
        setSearchValue(newValue);
    };

    const onSearch = () => {
        let foundKey = search({
            searchValue,
            searchOrder,
            nodes: treeData.nodes,
            selectedKey: treeState.selectedKey,
        });

        if (foundKey.length == 0) {
            return;
        }

        onNodeSelect(foundKey, true);
    };

    const expandAllNodes = () => {
        const expandResult = setExpandedForAllNodes(treeData, treeState, true);
        setTreeData(expandResult.treeData);
        setTreeState(expandResult.treeState);
        onStateChange?.(expandResult.treeState);
    };

    const collapseAllNodes = () => {
        const collapseResult = setExpandedForAllNodes(treeData, treeState, false);
        setTreeData(collapseResult.treeData);
        setTreeState(collapseResult.treeState);
        onStateChange?.(collapseResult.treeState);
    };

    const checkAllNodes = () => {
        const checkResult = setCheckedForAllNodes(treeData, treeState, true);
        setTreeData(checkResult.treeData);
        setTreeState(checkResult.treeState);
        onStateChange?.(checkResult.treeState);
    };

    const uncheckAllNodes = () => {
        const uncheckResult = setCheckedForAllNodes(treeData, treeState, false);
        setTreeData(uncheckResult.treeData);
        setTreeState(uncheckResult.treeState);
        onStateChange?.(uncheckResult.treeState);
    };

    const onExpandClick = (key: string) => {
        let { topNodesKeys } = treeData;
        let nodes = triggerExpand(treeData.nodes, key);
        let viewKeys = createView(topNodesKeys, nodes);
        setTreeData({ topNodesKeys, nodes, viewKeys });

        let expandedKeys = getExpandedKeys(nodes);
        let newState = { ...treeState, expandedKeys };
        setTreeState(newState);
        if (onStateChange) {
            onStateChange(newState);
        }
    };

    const onCheckClick = (key: string) => {
        let nodes = triggerCheckWithChildren(treeData.nodes, key);
        setTreeData({ ...treeData, nodes });

        let checkedKeys = getCheckedKeys(nodes);
        let newState = { ...treeState, checkedKeys };
        setTreeState(newState);
        if (onStateChange) {
            onStateChange(newState);
        }
    };

    let treeSettings: ReactTreeSettings = {
        allowExpand,
        allowCheck,
        allowSelect,
        forbidSelectCompany,
        forbidSelectGroup,
        expandAll: props.expandAll || false,
        colorScheme,
    };

    const onNodeSelect = (key: string, afterSearch = false) => {
        let nodes = selectByKey(treeData.nodes, key, treeState.selectedKey);
        let newState = { ...treeState, selectedKey: key };
        if (afterSearch) {
            let expandResult = expandParents(nodes, key);
            nodes = expandResult.nodes;
            let viewKeys = createView(treeData.topNodesKeys, nodes);
            setTreeData({ ...treeData, nodes, viewKeys });
            setTreeState({ ...newState, expandedKeys: [...newState.expandedKeys, ...expandResult.expandedKeys] });
            setNeedScrollChangeFlag(!needScrollChangeFlag);
        }
        if (!allowSelect) {
            return;
        }

        let selectedNode = treeData.nodes[key];
        if (!canNodeTypeBeSelected(treeSettings, selectedNode.type)) {
            return;
        }

        if (!afterSearch) {
            setTreeData({ ...treeData, nodes: nodes });
            setTreeState(newState);
        }

        if (onStateChange) {
            onStateChange(newState);
        }
        if (onSelect) {
            onSelect(selectedNode.key, afterSearch);
        }
    };

    let nodeSettings: ReactTreeNodeSettings = {
        allowExpand,
        allowCheck,
        allowSelect,
        colorScheme,
    };

    let actions: ReactTreeNodeActions = { onExpandClick, onCheckClick, onSelect: onNodeSelect, onNodeClick };

    const renderRow = React.useCallback(
        (index: number, width: number) => {
            let rowData = treeData.nodes[treeData.viewKeys[index]];
            return <TreeNodeComponent data={rowData} settings={nodeSettings} actions={actions} width={width} />;
        },
        [treeData],
    );

    React.useEffect(() => {
        let rowIndex = treeData.viewKeys.indexOf((treeState.selectedKey || treeState.checkedKeys[0]) ?? '');
        rowIndex >= 0 && treeRef.current?.scrollToItem({ align: 'auto', rowIndex });
    }, [needScrollChangeFlag]);

    React.useEffect(() => {
        let newState = state ? { ...state } : createEmptyTreeState();
        if (selectedKey) {
            newState.selectedKey = selectedKey;
        }
        if (!data.nodes[newState.selectedKey]) {
            newState.selectedKey = '';
        }
        if (props.expandAll) {
            newState.expandedKeys = [];
            let allNodes = Object.keys(data.nodes).map((key) => data.nodes[key]);
            for (let node of allNodes) {
                if (node.childrenKeys.length > 0) {
                    newState.expandedKeys.push(node.key);
                }
            }
        }
        let newTreeData = parseReactTreeProps(data, newState, treeSettings);
        let newSearchOrder = getSearchOrder(newTreeData.nodes, newTreeData.topNodesKeys);
        setTreeData(newTreeData);
        setTreeState(newState);
        setSearchOrder(newSearchOrder);
        setNeedScrollChangeFlag(!needScrollChangeFlag);
    }, [data]);

    let forSidebar = colorScheme == ReactTreeColorScheme.Navigation;
    const checkedCount = getCheckedItemsCount(treeData.nodes);

    return (
        <div className={classes.treeRoot}>
            {Boolean(title) && <div className={classes.titleContainer}>{translate(title)}</div>}
            {!hideToolbar && (
                <div className={classes.treeToolbar}>
                    <SearchInput
                        fullWidth
                        forSidebar={forSidebar}
                        value={searchValue}
                        onChange={updateSearchValue}
                        onSearch={onSearch}
                    />
                    {props.showButtonsToolbar && (
                        <div className={classes.toolbarIconContainer}>
                            <div title={translate('expand_tree')} onClick={expandAllNodes}>
                                <SvgIcon type="expand" />
                            </div>
                            <div title={translate('collapse_tree')} onClick={collapseAllNodes}>
                                <SvgIcon type="collapse" />
                            </div>
                            <div title={translate('SelectAll')} onClick={checkAllNodes}>
                                <SvgIcon type="checkbox-filled" />
                            </div>
                            <div title={translate('UnselectAll')} onClick={uncheckAllNodes}>
                                <SvgIcon type="checkbox-empty" />
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <div className={classes.checkedCountText}>{`${translate(
                                'SelectedObjectsCount',
                            )}: ${checkedCount}`}</div>
                        </div>
                    )}
                </div>
            )}
            <div className={classes.treePane}>
                <AutoSizer>
                    {({ width, height }) => {
                        if (data.topNodesKeys.length == 0) {
                            return <EmptyListPane width={width} height={height} />;
                        }

                        let rowCount = treeData.viewKeys.length;
                        let isScrollShown = height < rowCount * DefaultTableRowHeight;
                        let rowWidth = isScrollShown ? width - DefaultScrollWidth : width;

                        return (
                            <FixedSizeGrid
                                ref={treeRef}
                                width={width}
                                height={height}
                                columnCount={1}
                                columnWidth={rowWidth}
                                rowCount={rowCount}
                                rowHeight={DefaultTableRowHeight}
                            >
                                {({ rowIndex, style }) => <div style={style}>{renderRow(rowIndex, rowWidth)}</div>}
                            </FixedSizeGrid>
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
};

export default Tree;
