import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { SortOrder } from 'entities';
import { Theme, combineStyles } from 'theme';
import { SvgIcon, SvgIconType } from 'ui';

import { AnyColumn, TableColumn, TableColumnDataType, TableColumnWithChildren } from '.';
import { getCellStyle, getMultilineHeaderCellWidth } from './entities';
import { CheckboxCell } from './cell';
import { CheckBoxState, StateCheckboxIcon } from '../icons/state-ckeckbox-icon';

let RowHeight = 36;
let ScrollWidth = 17;

const useStyles = makeStyles((theme: Theme) => {
    if (theme.custom) {
        RowHeight = theme.custom.mainSizes.table.rowHeight;
        ScrollWidth = theme.custom.mainSizes.common.scrollWidth;
    }

    return {
        header: {
            boxSizing: 'border-box',
            borderBottom: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
            display: 'flex',
            alignItems: 'stretch',
            overflow: 'hidden',
        },
        innerContainer: {
            display: 'flex',
        },
        headerCell: {
            ...theme.custom?.typography.text,
            boxSizing: 'border-box',
            minHeight: theme.custom?.mainSizes.table.headerHeight,
            padding: `${theme.custom?.spacing(1)}px ${theme.custom?.spacing(2)}px`,
            color: theme.custom?.palette.text.secondary,
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            cursor: 'default',
            '& svg': {
                flexShrink: 0,
                width: theme.custom?.mainSizes.table.iconSize,
                height: theme.custom?.mainSizes.table.iconSize,
            },
            '& path': {
                fill: theme.custom?.palette.text.secondary,
            },
        },
        multilineContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            '&>div:first-child': {
                boxSizing: 'border-box',
                borderBottom: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
                paddingLeft: theme.custom?.spacing(1),
                cursor: 'default',
            },
        },
        cellsContainer: {
            display: 'flex',
            '&>div:first-child': {
                paddingLeft: theme.custom?.spacing(1),
            },
        },
        innerCell: {
            display: 'flex',
            overflow: 'hidden',
        },
        iconCell: {
            padding: '0 !important',
            justifyContent: 'center',
        },
        centered: {
            justifyContent: 'center',
        },
        clickable: {
            cursor: 'pointer',
        },
    };
});

export interface HeaderProps<T> {
    /**Колонки таблицы */
    columns: AnyColumn<T>[];
    /**Ширина строки с заголовками */
    rowWidth: number;
    /**Ссылка на контейнер Header (задается внутри Header) */
    containerRef: React.RefObject<HTMLDivElement>;
    /**Ключ из колонок таблицы, по которому будет сортировка */
    sortingKey?: keyof T | null;
    /**Порядок сортировки (Enum: SortOrder.Ascension | SortOrder.Descension) */
    sortingOrder?: SortOrder;
    /**Параметр определяет есть скрол у таблицы или нет */
    hasScroll: boolean;
    /**Параметр определяет отдельный столбец под чекбоксы */
    showCheckboxes?: boolean;
    updateSortingSettings: (key: keyof T) => void;
    /**Параметр, отвечающий за статус чекбокса  */
    checkboxState?: CheckBoxState;
    /**Коллбек вызывается при нажатии на чекбокс */
    onCheckboxClick?: () => void;
}
/**Компонент заголовков столбцов таблицы */
export const Header = <T,>(props: HeaderProps<T>): JSX.Element => {
    let classes = useStyles();

    let sortingIcon: SvgIconType = props.sortingOrder == SortOrder.Ascension ? 'arrow-up' : 'arrow-down';

    const onCheckboxClick = () => {
        props.onCheckboxClick?.();
    };
    return (
        <div ref={props.containerRef} className={classes.header}>
            <div
                className={classes.innerContainer}
                style={{ width: props.hasScroll ? props.rowWidth + ScrollWidth : props.rowWidth }}
            >
                {props.showCheckboxes && (
                    <CheckboxCell style={{ flex: `0 0 ${RowHeight}px` }} onClick={onCheckboxClick}>
                        <StateCheckboxIcon checkboxState={props.checkboxState} />
                    </CheckboxCell>
                )}
                {props.columns.map(function renderColumn(column) {
                    let cellClasses = classes.headerCell;
                    if (column instanceof TableColumnWithChildren) {
                        let width = getMultilineHeaderCellWidth(column);
                        cellClasses = combineStyles(cellClasses, classes.centered);

                        return (
                            <div
                                key={column.id}
                                className={classes.multilineContainer}
                                style={{ flex: `0 0 ${width}px` }}
                            >
                                <div className={cellClasses}>{column.title}</div>
                                <div className={classes.cellsContainer}>
                                    {column.children.map((child) => renderColumn(child))}
                                </div>
                            </div>
                        );
                    }

                    column = column as TableColumn<T>;

                    if (!column.visible) {
                        return null;
                    }
                    if (column.centered) {
                        cellClasses = combineStyles(cellClasses, classes.centered);
                    }
                    if (props.sortingKey) {
                        cellClasses = combineStyles(cellClasses, classes.clickable);
                    }
                    if (column.valueType == TableColumnDataType.Icon) {
                        cellClasses = combineStyles(cellClasses, classes.iconCell);
                    }

                    let key = column.key;
                    return (
                        <div
                            key={column.id}
                            className={cellClasses}
                            style={getCellStyle(column, RowHeight)}
                            onClick={() => props.updateSortingSettings(key)}
                        >
                            {column.title && <div className={classes.innerCell}>{column.title}</div>}
                            <SvgIcon type={column.key == props.sortingKey ? sortingIcon : 'empty'} />
                        </div>
                    );
                })}
                {props.hasScroll && <div style={{ flex: `0 0 ${ScrollWidth}px` }} />}
            </div>
        </div>
    );
};

export default Header;
