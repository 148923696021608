import React from 'react';
import Calendar, { OnChangeDateCallback } from 'react-calendar';
import parse from 'date-fns/parse';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/styles';

import { DateTimeInputValue, getDateTimeInputValue } from 'entities';
import { SvgIconType, SvgIcon, FlexFiller, ContainedButton } from 'ui';
import { translate } from 'utilities';
import { InputIconSize } from '../../constants';
import Theme from '../../theme/fm-theme';

import TimeInput from './time';

const FormatKey = 'MaskDate';

const createStyles = makeStyles((theme: Theme) => ({
    inputContainer: { ...theme.custom?.dateTimeField.inputContainer },
    inputContainerWithFocus: { ...theme.custom?.dateTimeField.inputContainerInFocus },
    inputContainerWithError: { ...theme.custom?.dateTimeField.inputContainerWithError },
    input: { ...theme.custom?.dateTimeField.input },
    popover: { ...theme.custom?.dateTimeField.popoverContainer },
    calendarContainer: { ...theme.custom?.dateTimeField.calendarContainer },
    timeContainer: { ...theme.custom?.dateTimeField.timeContainer },
    timeLabel: { ...theme.custom?.dateTimeField.timeLabel },
    buttonsContainer: {
        marginTop: theme.custom?.spacing(1),
        borderTop: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
        paddingTop: theme.custom?.spacing(1),
        display: 'flex',
        justifyContent: 'space-around',
        '& button': {
            color: theme.custom?.palette.text.primary,
        },
    },
}));

export interface DateTimeFieldProps {
    value?: DateTimeInputValue;
    onChange?: (value: DateTimeInputValue) => void;
    width?: number;
    popoverAlign?: 'left' | 'right';
    allowEmpty?: boolean;
    externalValid?: boolean;
}
const DateTimeControlledSelect: React.FC<DateTimeFieldProps> = (props) => {
    let {
        value = getDateTimeInputValue(new Date()),
        onChange = () => undefined,
        width,
        popoverAlign = 'left',
        externalValid = true,
    } = props;

    let classes = createStyles();

    let inputContainerRef = React.useRef<HTMLDivElement>(null);
    let inputRef = React.useRef<HTMLInputElement>(null);
    let [anchor, setAnchor] = React.useState<HTMLDivElement | null>(null);
    let [focused, setFocused] = React.useState<boolean>(false);
    let [popoverJustClosed, setPopoverJustClosed] = React.useState<boolean>(false);

    const showPopover = (): void => {
        setAnchor(inputContainerRef.current);
    };

    const closePopover = () => {
        setAnchor(null);
        setPopoverJustClosed(true);
        setTimeout(() => {
            inputRef.current?.focus();
            setPopoverJustClosed(false);
        }, 50);
    };

    const onInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newTextValue = event.target.value;
        let isValid = true;
        let newDateValue = new Date();
        let empty = false;
        try {
            newDateValue = parse(newTextValue, translate(FormatKey), newDateValue);
        } catch {
            isValid = false;
        }
        if (newDateValue.toString() == 'Invalid Date') {
            isValid = false;
        }
        if (props.allowEmpty && newTextValue.length == 0) {
            isValid = true;
            empty = true;
        }

        onChange({
            valid: isValid,
            dateValue: isValid && !empty ? newDateValue : value.dateValue,
            textValue: newTextValue,
            empty,
        });
    };

    const updateValue = (newDateValue: Date) => {
        onChange(getDateTimeInputValue(newDateValue));
    };

    const onDateChange: OnChangeDateCallback = (newDate) => {
        let newDateValue = new Date(value.dateValue);
        newDateValue.setFullYear(newDate.getFullYear());
        newDateValue.setMonth(newDate.getMonth());
        newDateValue.setDate(newDate.getDate());
        updateValue(newDateValue);
    };

    const onTimeChange = (newTime: Date) => {
        let newDateValue = new Date(value.dateValue);
        newDateValue.setHours(newTime.getHours());
        newDateValue.setMinutes(newTime.getMinutes());
        newDateValue.setSeconds(newTime.getSeconds());
        updateValue(newDateValue);
    };

    const setToday = () => {
        const newDateValue = new Date();
        newDateValue.setHours(value.dateValue.getHours());
        newDateValue.setMinutes(value.dateValue.getMinutes());
        newDateValue.setSeconds(value.dateValue.getSeconds());
        updateValue(newDateValue);
        closePopover();
    };

    const clearValue = () => {
        props.onChange?.({
            valid: true,
            dateValue: value.dateValue,
            textValue: '',
            empty: true,
        });
        closePopover();
    };

    let containerClasses =
        focused || anchor || popoverJustClosed
            ? `${classes.inputContainer} ${classes.inputContainerWithFocus}`
            : classes.inputContainer;
    if (!value.valid || !externalValid) {
        containerClasses += ` ${classes.inputContainerWithError}`;
    }
    let containerStyle: React.CSSProperties = {};
    if (width != undefined) {
        containerStyle.width = width;
    }
    let chevronType: SvgIconType = anchor ? 'chevron-up' : 'chevron-down';
    return (
        <>
            <div ref={inputContainerRef} className={containerClasses} style={containerStyle}>
                <SvgIcon type="calendar" size={InputIconSize} />
                <input
                    ref={inputRef}
                    className={classes.input}
                    value={value.textValue}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onChange={onInputValueChange}
                />
                <div className="icon-button" onClick={showPopover}>
                    <SvgIcon type={chevronType} size={InputIconSize} />
                </div>
            </div>
            <Popover
                keepMounted
                elevation={0}
                getContentAnchorEl={null}
                open={Boolean(anchor)}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: popoverAlign == 'left' ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: popoverAlign == 'left' ? 'left' : 'right',
                }}
                className={`${classes.popover} ${classes.calendarContainer}`}
                onClose={closePopover}
            >
                <Calendar value={value.dateValue} onChange={onDateChange} />
                <div className={classes.timeContainer}>
                    <div className={classes.timeLabel}>{translate('Time')}</div>
                    <TimeInput value={value.dateValue} valid={value.valid} onChange={onTimeChange} />
                    <FlexFiller />
                    <ContainedButton disableMinWidth textKey="Ready" onClick={closePopover} />
                </div>
                <div className={classes.buttonsContainer}>
                    <button onClick={setToday}>{translate('Today')}</button>
                    {props.allowEmpty && <button onClick={clearValue}>{translate('clear')}</button>}
                </div>
            </Popover>
        </>
    );
};

export default DateTimeControlledSelect;
