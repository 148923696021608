import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Theme from '../../../theme/login-theme';

const createStyles = makeStyles((theme: Theme) => ({
    flagIcon: theme.custom?.flagIcon || {},
}));
interface FlagIconProps {
    path: string;
}

export const FlagIcon: React.FC<FlagIconProps> = ({ path }) => {
    let classes = createStyles();

    return <div className={classes.flagIcon} style={{ backgroundImage: `url("/${path}")` }} />;
};
