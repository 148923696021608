import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from '../../theme';

const createStyles = makeStyles((theme: Theme) => ({
    rootClassName: { ...theme.custom?.detailsPane.root },
    headerClassName: { ...theme.custom?.detailsPane.header },
    titleClassName: { ...theme.custom?.detailsPane.title },
}));

export interface DetailsHeaderProps {
    /**Заголовок */
    title: string;
}
/**Строка с заголовом. Негде не используется. */
export const DetailsHeader: React.FC<DetailsHeaderProps> = (props) => {
    let { title } = props;
    let { headerClassName, titleClassName } = createStyles();

    return (
        <div className={headerClassName}>
            <div className={titleClassName}>{title}</div>
        </div>
    );
};
