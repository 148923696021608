import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useCallback, useImperativeHandle } from 'react';
import FmTheme from '../../theme/fm-theme';
import { translate } from '../../utilities';
import { Validatable } from 'ui';

const ErrorClassName = 'error';

const useStyles = makeStyles((theme: FmTheme) => {
    const borderSizeDifference =
        (theme.custom?.mainSizes.common.thickBorderSize ?? 2) - (theme.custom?.mainSizes.common.defaultBorderSize ?? 1);

    return {
        emailInput: {
            ...theme.custom?.typography.input,
            flexGrow: 1,
            boxSizing: 'border-box',
            margin: borderSizeDifference,
            height: (theme.custom?.mainSizes.input.height ?? 36) - borderSizeDifference * 2,
            border: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
            borderRadius: theme.custom?.mainSizes.common.tinyBorderRadius,
            padding: `0 ${theme.custom?.spacing(1)}px`,
            backgroundColor: 'transparent',
            color: theme.custom?.palette.text.primary,
            outline: 'none',
            '&:hover': {
                borderColor: theme.custom?.palette.primary.main,
            },
            '&:focus': {
                border: `${theme.custom?.mainSizes.common.thickBorderSize}px solid ${theme.custom?.palette.primary.main}`,
                margin: 0,
                height: theme.custom?.mainSizes.input.height,
            },
            [`&.${ErrorClassName}`]: {
                borderColor: theme.custom?.palette.levels.error,
            },
        },
    };
});

export interface EmailInputProps {
    value: string;
    valueValid: boolean;
    autoFocus?: boolean;
    required?: boolean;
    maxLength?: number;
    onChange: (value: string, valid: boolean, errorText: string) => void;
}

export const EmailInput = React.forwardRef<Validatable, EmailInputProps>((props, ref) => {
    const classes = useStyles();

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            let newValue = event.target.value;
            const maxLength = props.maxLength ?? 255;
            if (newValue.length > maxLength) {
                newValue = newValue.substring(0, maxLength);
            }
            props.onChange(newValue, true, '');
        },
        [props.onChange],
    );

    const validate = useCallback(() => {
        if (props.value.length === 0) {
            if (props.required) {
                props.onChange(props.value, false, translate('FieldCanNotBeEmpty'));

                return false;
            } else {
                props.onChange(props.value, true, '');

                return true;
            }
        }

        const emailParts = props.value.split('@');

        if (emailParts.length !== 2) {
            props.onChange(props.value, false, translate('WrongEmail'));

            return false;
        }

        if (emailParts[0].length === 0 || emailParts[1].length === 0) {
            props.onChange(props.value, false, translate('WrongEmail'));

            return false;
        }

        return true;
    }, [props]);

    useImperativeHandle<Validatable, Validatable>(
        ref,
        () => ({
            validate: () => {
                return validate();
            },
        }),
        [props.required, props.value],
    );

    let inputClasses = classes.emailInput;
    if (!props.valueValid) {
        inputClasses = classNames(inputClasses, ErrorClassName);
    }

    return (
        <input
            autoFocus={props.autoFocus}
            className={inputClasses}
            value={props.value}
            onChange={onChange}
            onBlur={validate}
        />
    );
});

EmailInput.displayName = 'EmailInput';
