import { createMuiTheme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';
import {
    DefaultTableHeaderHeight,
    DefaultTableRowHeight,
    DefaultScrollWidth,
    DefaultTableBottomHeight,
    NavigationMenuFullWidth,
    NavigationMenuIconsOnlyWidth,
    NavigationMenuIconSize,
    NavigationMenuBottomIconSize,
    TableIconCellSize,
    TwoPaneSelectToolbarWidth,
    TreeIconBlockSize,
    RetranslatorPageSidebarWidth,
    MenuIconSize,
    InputIconSize,
    InputHeight,
    PageTitleHeight,
    DetailsTitleHeight,
    PathToImages,
    PageTitleIconSize,
} from '../constants';
import Theme, { MainSizes, Palette, Typography } from './fm-theme';

const LargeBorderRadius = 24;
const MediumBorderRadius = 12;
const SmallBorderRadius = 8;
const RetranslatorMenuPadding = 12;
const RetranslatorMenuTabWidth = (RetranslatorPageSidebarWidth - 3 * RetranslatorMenuPadding) / 2;

const DefaultPalette: Palette = {
    colors: {
        white: '#FFFFFF',
        grey10: '#E4E5E9',
        grey20: '#CACBD2',
        grey30: '#AFB1BC',
        grey40: '#9596A5',
        grey50: '#7A7C8F',
        grey60: '#636575',
        grey70: '#4C4E5B',
        grey80: '#363740',
        grey90: '#2A2B32',
        black: '#111111',
        color1: '#0E9594',
        color2: '#542E71',
        color3: '#9A031E',
        color4: '#E36414',
        color5: '#26547C',
        color6: '#D1AC00',
        color7: '#A27035',
        color8: '#0B6E4F',
        color9: '#562C2C',
        color10: '#494949',
        customColorsContrastText: '#FFFFFF',
        transparent: 'rgba(0, 0, 0, 0)',
        partialTransparent: 'rgba(54, 55, 64, 0.6)',
        shadow: '0px 4px 16px rgba(1, 23, 34, 0.16)',
        scroll: '#CACBD2',
        scrollBackground: '#E4E5E9',
    },
    primary: {
        main: '#389FCC',
        hover: '#2680A7',
        fm3: '#389FCC',
        contrastText: '#FFFFFF',
    },
    alert: {
        main: '#EB5757',
        hover: '#D14F4F',
    },
    actions: {
        hover: '#E4E5E9',
        select: '#CACBD2',
    },
    levels: {
        success: '#27AE60',
        error: '#EB5757',
        warning: '#E2B207',
        info: '#389FCC',
        debug: '#4C4E5B',
        system: '#9596A5',
    },
    background: {
        default: '#F1F1F1',
        main: '#FFFFFF',
        toolbar: '#E4E5E9',
        page: '#BDCFD7',
    },
    divider: '#D0DAE6',
    sidebar: {
        background: '#363740',
        hoverBackground: '#363740',
        selectBackground: '#4C4E5B',
        border: '#4C4E5B',
        titleText: '#AFB1BC',
        text: '#CACBD2',
        hoverText: '#FFFFFF',
        selectText: '#FFFFFF',
        buttonText: '#CACBD2',
        scroll: '#7A7C8F',
        scrollBackground: 'rgba(0, 0, 0, 0)',
        toolbar: '#E4E5E9',
    },
    text: {
        primary: '#4C4E5B',
        secondary: '#7A7C8F',
        disabled: '#AFB1BC',
        placeholder: '#AFB1BC',
    },
};

const DefaultTypography: Typography = {
    fontFamily: '"Montserrat", sans-serif',
    baseFontSize: 14,
    h1: {
        fontSize: 28,
        fontWeight: 700,
        letterSpacing: '-0.01em',
    },
    h2: {
        fontSize: 24,
        fontWeight: 700,
        letterSpacing: '-0.01em',
    },
    h3: {
        fontSize: 20,
        fontWeight: 700,
        letterSpacing: '-0.01em',
    },
    h4: {
        fontSize: 16,
        fontWeight: 700,
        letterSpacing: '-0.01em',
    },
    h5: {
        fontSize: 14,
        fontWeight: 700,
    },
    button: {
        fontSize: 14,
        fontWeight: 600,
    },
    input: {
        fontSize: 14,
        fontWeight: 500,
    },
    text: {
        fontSize: 14,
        fontWeight: 500,
    },
    smallText: {
        fontSize: 12,
        fontWeight: 500,
    },
    tinyText: {
        fontSize: 10,
        fontWeight: 500,
    },
    label: {
        fontWeight: 700,
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
    },
    infoMessage: {
        fontSize: 16,
        fontWeight: 600,
    },
};

export const splitter = {
    sizeDefault: 9,
    sizeWithCollapseButton: 11,
};

const DefaultMainSizes: MainSizes = {
    common: {
        baseSize: 8,
        largeBorderRadius: 24,
        mediumBorderRadius: 12,
        smallBorderRadius: 8,
        tinyBorderRadius: 4,
        tabMarkerHeight: 6,
        scrollWidth: DefaultScrollWidth,
        defaultBorderSize: 1,
        thickBorderSize: 2,
    },
    page: {
        contentPadding: 20,
        titleHeight: PageTitleHeight,
        iconSize: PageTitleIconSize,
    },
    list: {
        titleHeight: 13,
        rowHeight: 36,
        iconSize: NavigationMenuIconSize,
    },
    button: {
        height: 36,
        iconSize: 20,
        largeIconSize: NavigationMenuBottomIconSize,
    },
    menu: {
        itemHeight: 36,
        dividerItemHeight: 1,
        iconSize: MenuIconSize,
    },
    toolbar: {
        height: 62,
    },
    input: {
        height: InputHeight,
        iconSize: InputIconSize,
    },
    table: {
        headerHeight: DefaultTableHeaderHeight,
        footerHeight: DefaultTableBottomHeight,
        rowHeight: DefaultTableRowHeight,
        iconSize: 20,
    },
    dialog: {
        titleHeight: 80,
        titleCloseButtonSize: 24,
        footerHeight: 84,
    },
    details: {
        titleHeight: DetailsTitleHeight,
        rowHeight: 36,
    },
    admin: {
        navigationLogoHeight: 88,
        navigationPaneSize: NavigationMenuFullWidth,
        navigationPaneSmallSize: NavigationMenuIconsOnlyWidth,
    },
    tag: {
        height: 20,
        iconSize: 12,
        indent: 4,
    },
    verticalTab: {
        height: 40,
        borderRadius: 8,
        markerWidth: 6,
        markerBorderRadius: 6,
    },
    conditionBlock: {
        height: 28,
        switchButtonHeight: 24,
        switchButtonWidth: 54,
        smallButtonHeight: 28,
        smallButtonWidth: 50,
        blockMinHeight: 100,
        blockMinWidth: 377,
    },
    splitter: {
        size: splitter.sizeDefault,
        sizeWithCollapseButton: splitter.sizeWithCollapseButton,
        collapseButtonSize: 46,
        collapseButtonPadding: 2,
        collapseButtonRadius: 4,
    },
};

interface CreateThemeProps {
    primaryMainColor?: string;
    primaryHoverColor?: string;
    primaryContrastTextColor?: string;
    secondaryMainColor?: string;
    primaryTextColor?: string;
    mainBackgroundColor?: string;
    secondaryBackgroundColor?: string;
    secondaryAlterBackgroundColor?: string;
    hoverColor?: string;
    selectColor?: string;
    dividerColor?: string;
    errorBackgroundColor?: string;
    errorBackgroundHoverColor?: string;
    errorBackgroundSelectColor?: string;
    errorTextColor?: string;
    warningBackgroundColor?: string;
    warningBackgroundHoverColor?: string;
    warningBackgroundSelectColor?: string;
    warningTextColor?: string;
    informationBackgroundColor?: string;
    informationBackgroundHoverColor?: string;
    informationBackgroundSelectColor?: string;
    informationTextColor?: string;
    debugBackgroundColor?: string;
    debugBackgroundHoverColor?: string;
    debugBackgroundSelectColor?: string;
    debugTextColor?: string;
    successBackgroundColor?: string;
    successBackgroundHoverColor?: string;
    successBackgroundSelectColor?: string;
    successTextColor?: string;
    modalTransparentColor?: string;
    toolbarButtonBorderColor?: string;
    toolbarButtonTextColor?: string;
    defaultTabBackgroundColor?: string;
    defaultTabTextColor?: string;
    selectedTabBackgroundColor?: string;
    selectedTabTextColor?: string;
    primaryButtonTextColor?: string;
}

export function createTheme(
    props: CreateThemeProps,
    palette = DefaultPalette,
    typography = DefaultTypography,
    mainSizes = DefaultMainSizes,
): Theme {
    let {
        primaryMainColor = '#389FCC',
        primaryHoverColor = '#2680A7',
        secondaryMainColor = '#AFB1BC',
        primaryTextColor = '#363740',
        mainBackgroundColor = '#F1F1F1',
        secondaryBackgroundColor = '#FFFFFF',
        secondaryAlterBackgroundColor = 'rgba(0, 0, 0, 0.05)',
        hoverColor = '#E4E5E9',
        selectColor = '#CACBD2',
        dividerColor = '#DFE0EB',
        errorBackgroundColor: ErrorBackgroundColor = '#fdecea',
        errorBackgroundHoverColor: ErrorHoverBackgroundColor = '#fdd4d0',
        errorBackgroundSelectColor: ErrorSelectBackgroundColor = '#EB5757',
        errorTextColor: ErrorTextColor = '#611a15',
        warningBackgroundColor: WarningBackgroundColor = '#fff4e5',
        warningBackgroundHoverColor: WarningHoverBackgroundColor = '#ffe9c0',
        warningBackgroundSelectColor: WarningSelectBackgroundColor = '#ff9800',
        warningTextColor: WarningTextColor = '#663c00',
        informationBackgroundColor: InformationBackgroundColor = '#e8f4fd',
        informationBackgroundHoverColor: InformationHoverBackgroundColor = '#c3e5fd',
        informationBackgroundSelectColor: InformationSelectBackgroundColor = '#389FCC',
        informationTextColor: InformationTextColor = '#0d3c61',
        debugBackgroundColor: DebugBackgroundColor = '#f0f0f0',
        debugBackgroundHoverColor: DebugHoverBackgroundColor = '#cdcdcd',
        debugBackgroundSelectColor: DebugSelectBackgroundColor = '#444',
        debugTextColor: DebugTextColor = '#444',
        successBackgroundColor: SuccessBackgroundColor = '#edf7ed',
        successBackgroundHoverColor: SuccessHoverBackgroundColor = '#cdf7cd',
        successBackgroundSelectColor: SuccessSelectBackgroundColor = '#27AE60',
        successTextColor: SuccessTextColor = '#153c15',
        modalTransparentColor = 'rgba(54, 55, 64, 0.6)',
        toolbarButtonBorderColor = '#BAC4CF',
        toolbarButtonTextColor = '#363740',
        defaultTabBackgroundColor = '#E2F5FD',
        defaultTabTextColor = '#389FCC',
        selectedTabBackgroundColor = '#389FCC',
        selectedTabTextColor = '#FFFFFF',
        primaryButtonTextColor = '#FFFFFF',
    } = props;

    let theme: Theme = createMuiTheme({
        palette: {
            primary: {
                main: palette.primary.main,
                contrastText: palette.colors.white,
            },
            secondary: {
                main: palette.colors.grey30,
            },
            common: {
                black: palette.colors.black,
                white: palette.colors.white,
            },
            text: {
                primary: palette.text.primary,
                secondary: palette.text.secondary,
            },
            background: {
                default: palette.background.default,
                paper: palette.background.main,
            },
            type: 'light',
            action: {
                active: 'rgba(0, 0, 0, 0.54)',
                hover: palette.actions.hover,
                hoverOpacity: 0.08,
                selected: palette.actions.select,
                disabled: 'rgba(0, 0, 0, 0.26)',
                disabledBackground: 'rgba(0, 0, 0, 0.02)',
            },
            divider: palette.divider,
        },
        typography: {
            fontSize: 14,
            htmlFontSize: 16,
            caption: {
                fontSize: '12px',
                fontFamily: 'Roboto',
                fontWeight: 400,
            },
        },
        shape: {
            borderRadius: 12,
        },
        spacing: mainSizes.common.baseSize,
    });

    const DefaultBorderSize = 1;
    const DefaultBorder = `${DefaultBorderSize}px solid ${palette.divider}`;
    const DefaultPrimaryBorder = `${DefaultBorderSize}px solid ${palette.primary.main}`;
    const ThickBorderSize = 2;
    const ThickPrimaryBorder = `${ThickBorderSize}px solid ${palette.primary.main}`;
    const ThickSecondaryBorder = `${ThickBorderSize}px solid ${palette.colors.grey30}`;
    const spacing = theme.spacing;

    theme.overrides = {
        MuiContainer: {
            root: {
                backgroundColor: theme.palette.background.paper,
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 4,
                padding: 4,
                '&:hover': {
                    borderColor: theme.palette.primary.main,
                },
                margin: 1,
                overflow: 'hidden',
            },
            disabled: {
                '&:hover': {
                    borderColor: 'inherit',
                },
            },
            input: {
                ...typography.input,
                height: 20,
                padding: 2,
                cursor: 'text',
            },
            focused: {
                margin: 0,
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
            },
            multiline: {
                padding: 4,
            },
            error: {
                borderColor: palette.alert.main,
                '&:hover': {
                    borderColor: palette.alert.hover,
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
            },
        },
        MuiTypography: {
            caption: {
                display: 'inline-flex',
                verticalAlign: 'middle',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
            },
        },
        MuiButton: {
            root: {
                minWidth: '100px',
                padding: `0 ${spacing(1)}px !important`,
                textTransform: 'none',
            },
            outlined: {
                backgroundColor: theme.palette.background.paper,
            },
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: theme.palette.background.paper,
                },
            },
            icon: {
                '& path': {
                    fill: palette.text.primary,
                },
            },
        },
        MuiDialog: {
            root: {
                flexShrink: 1,
            },
        },
        MuiDialogTitle: {
            root: {
                padding: `${spacing(0.5)}px ${spacing(1)}px`,
                borderBottom: DefaultBorder,
                backgroundColor: theme.palette.background.default,
            },
        },
        MuiDialogContent: {
            root: {
                padding: `${spacing(0.5)}px ${spacing(1)}px`,
            },
        },
        MuiDialogActions: {
            root: {
                borderTop: DefaultBorder,
                backgroundColor: theme.palette.background.default,
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 6,
                border: '1px solid ' + theme.palette.divider,
            },
        },
        MuiPopover: {
            root: {
                overflow: 'hidden',
                '& div, & input': {
                    fontFamily: typography.fontFamily,
                },
                '& *': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${palette.colors.scroll} ${palette.colors.scrollBackground}`,
                },
                '& *::-webkit-scrollbar': {
                    width: mainSizes.common.scrollWidth,
                },
                '& *::-webkit-scrollbar-track': {
                    background: palette.colors.scrollBackground,
                },
                '& *::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.colors.scroll,
                    borderRadius: mainSizes.common.scrollWidth / 2,
                },
            },
        },
    };

    let defaultStatsTextWithIcon: CSSProperties = {
        ...typography.text,
        flexGrow: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& svg': {
            marginRight: spacing(0.5),
        },
    };
    let defaultStatsHighlight: CSSProperties = {
        boxSizing: 'border-box',
        minWidth: 20,
        height: 20,
        borderRadius: mainSizes.common.tinyBorderRadius,
        padding: `0 ${spacing(0.5)}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: palette.colors.customColorsContrastText,
        fontSize: 'inherit',
        fontWeight: 'inherit',
    };

    theme.custom = {
        spacing: (value) => value * mainSizes.common.baseSize,
        palette,
        typography,
        mainSizes,
        colors: {
            text: {
                error: ErrorTextColor,
                warning: WarningTextColor,
                information: InformationTextColor,
                debug: DebugTextColor,
                success: SuccessTextColor,
            },
            background: {
                error: ErrorBackgroundColor,
                warning: WarningBackgroundColor,
                information: InformationBackgroundColor,
                debug: DebugBackgroundColor,
                tableRowAlter: secondaryAlterBackgroundColor,
                success: SuccessBackgroundColor,
            },
            hoveredBackground: {
                error: ErrorHoverBackgroundColor,
                warning: WarningHoverBackgroundColor,
                information: InformationHoverBackgroundColor,
                debug: DebugHoverBackgroundColor,
                success: SuccessHoverBackgroundColor,
            },
            selectedBackground: {
                error: ErrorSelectBackgroundColor,
                warning: WarningSelectBackgroundColor,
                information: InformationSelectBackgroundColor,
                debug: DebugSelectBackgroundColor,
                success: SuccessSelectBackgroundColor,
            },
        },
        stats: {
            block: {
                margin: theme.spacing(4),
            },
            blockTitle: {
                borderBottom: DefaultPrimaryBorder,
            },
            blockItem: {
                marginTop: theme.spacing(0.5),
                borderBottom: DefaultBorder,
            },
        },
        splitPane: {
            root: {
                minHeight: '200px',
            },
            Resizer: {
                width: 3,
                backgroundColor: theme.palette.divider,
                '&:hover': {
                    cursor: 'col-resize',
                },
            },
        },
        loading: {
            root: {
                flex: '1 1 auto',
                borderRadius: '2px',
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[5],
                outline: 'none',
                '&:focus': {
                    border: 'none',
                },
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            transparentRoot: {
                flex: '1 1 auto',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.background.paper,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        adminPageContainer: {
            root: {
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'stretch',
                '& div, & input': {
                    fontFamily: typography.fontFamily,
                },
                '& *': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${palette.colors.scroll} ${palette.colors.scrollBackground}`,
                },
                '& *::-webkit-scrollbar': {
                    width: mainSizes.common.scrollWidth,
                    height: mainSizes.common.scrollWidth,
                },
                '& *::-webkit-scrollbar-track': {
                    background: palette.colors.scrollBackground,
                },
                '& *::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.colors.scroll,
                    borderRadius: mainSizes.common.scrollWidth / 2,
                },
            },
        },
        toolbar: {
            root: {
                marginBottom: spacing(2),
                height: mainSizes.toolbar.height,
                padding: `0 ${spacing(1.5)}px`,
                borderRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.background.toolbar,
                display: 'flex',
                alignItems: 'center',
            },
            log: {
                marginBottom: spacing(2),
                height: mainSizes.toolbar.height,
                padding: `0 ${spacing(1.5)}px`,
                borderRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.background.toolbar,
                display: 'flex',
                alignItems: 'center',
                gap: spacing(1),
            },
            notFixedHeightRoot: {
                marginBottom: spacing(2),
                padding: spacing(1.5),
                borderRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.background.toolbar,
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(0.5),
            },
        },
        button: {
            root: {
                margin: spacing(1),
            },
        },
        formField: {
            root: {
                padding: spacing(1),
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexGrow: 1,
            },
            label: {
                justifyContent: 'flex-start',
                flexShrink: 0,
            },
        },
        adminPage: {
            root: {
                flex: '1 1 0',
                padding: mainSizes.page.contentPadding,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                backgroundColor: palette.background.default,
            },
            title: {
                flex: `0 0 ${mainSizes.page.titleHeight}px`,
                padding: `0 ${spacing(2)}px`,
                color: palette.text.primary,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            titleText: {
                ...typography.h1,
                color: 'inherit',
            },
            titleButton: {
                height: mainSizes.button.height,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: `0 ${spacing(1)}px`,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '& p': {
                    ...typography.button,
                    marginLeft: spacing(1),
                },
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    backgroundColor: palette.background.toolbar,
                },
            },
        },
        windowTable: {
            root: {
                flex: '1 1',
                borderRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.background.main,
                overflow: 'hidden',
            },
            headerRow: {
                display: 'flex',
            },
            headerCell: {
                ...typography.text,
                boxSizing: 'border-box',
                height: mainSizes.table.headerHeight,
                borderBottom: DefaultBorder,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.secondary,
                cursor: 'default',
                '& svg': {
                    width: mainSizes.table.iconSize,
                    height: mainSizes.table.iconSize,
                    flex: `0 0 ${mainSizes.table.iconSize}px`,
                },
                '& path': {
                    fill: palette.text.secondary,
                },
            },
            headerIconCell: {
                boxSizing: 'border-box',
                height: mainSizes.table.headerHeight,
                borderBottom: DefaultBorder,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                    width: mainSizes.table.iconSize,
                    height: mainSizes.table.iconSize,
                },
                '& path': {
                    fill: palette.text.secondary,
                },
            },
            headerCheckboxCell: {
                ...typography.text,
                boxSizing: 'border-box',
                height: mainSizes.table.headerHeight,
                borderBottom: DefaultBorder,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.secondary,
                cursor: 'default',
                '& path': {
                    fill: palette.text.secondary,
                },
            },
            headerClickableCell: {
                cursor: 'pointer',
            },
            headerClickableIconCell: {
                padding: 0,
                cursor: 'pointer',
            },
            oddRow: {
                display: 'flex',
                backgroundColor: palette.background.main,
                color: palette.text.primary,
                '&:hover': {
                    backgroundColor: palette.actions.hover,
                },
            },
            evenRow: {
                display: 'flex',
                backgroundColor: palette.background.main,
                color: palette.text.primary,
                '&:hover': {
                    backgroundColor: palette.actions.hover,
                },
            },
            selectedRow: {
                backgroundColor: palette.actions.select,
                '&:hover': {
                    backgroundColor: palette.actions.select,
                },
            },
            cell: {
                ...typography.text,
                boxSizing: 'border-box',
                height: mainSizes.table.rowHeight,
                borderBottom: DefaultBorder,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                color: 'inherit',
                cursor: 'default',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            clickableCell: {
                cursor: 'pointer',
            },
            scrollCell: {
                height: DefaultTableRowHeight,
                padding: '0 16px',
                borderBottom: DefaultBorder,
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.text.primary,
                boxSizing: 'border-box',
                fontSize: 14,
                fontWeight: 500,
                cursor: 'default',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                flex: `0 0 ${DefaultScrollWidth}px`,
                borderLeft: 'none',
            },
            innerCell: {
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            bottomPane: {
                ...typography.text,
                height: mainSizes.table.footerHeight,
                borderTop: DefaultBorder,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                boxSizing: 'border-box',
                color: palette.text.secondary,
                '& p': {
                    ...typography.label,
                    margin: `0 ${spacing(2)}px 0 ${spacing(1)}px`,
                    fontSize: 'inherit',
                },
            },
            iconCell: {
                padding: 0,
                borderBottom: DefaultBorder,
                height: mainSizes.table.rowHeight,
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'default',
                '& svg': {
                    width: mainSizes.table.iconSize,
                    height: mainSizes.table.iconSize,
                },
            },
            clickableIconCell: {
                cursor: 'pointer',
            },
            lastRowCell: {
                borderBottom: 'none',
            },
            numberCell: {
                justifyContent: 'flex-end',
            },
            checkboxCell: {
                justifyContent: 'start-end',
                cursor: 'pointer',
            },
            switcherCell: {
                height: mainSizes.table.rowHeight,
                borderBottom: DefaultBorder,
                boxSizing: 'border-box',
            },
            actionsContainer: {
                height: '335px',
                width: '600px',
                border: DefaultBorder,
                borderRadius: mainSizes.common.smallBorderRadius,
                overflow: 'hidden',
                backgroundColor: palette.background.main,
            },
            dropdownCell: {
                textDecoration: 'underline',
                color: palette.primary.main,
            },
        },
        detailsTable: {
            root: {
                borderRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.background.main,
                overflow: 'hidden',
                border: DefaultBorder,
            },
            headerCell: {
                ...typography.text,
                boxSizing: 'border-box',
                borderBottom: DefaultBorder,
                height: mainSizes.table.headerHeight,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.secondary,
                cursor: 'default',
                '& svg': {
                    width: mainSizes.table.iconSize,
                    height: mainSizes.table.iconSize,
                },
                '& path': {
                    fill: palette.text.secondary,
                },
            },
            headerRow: {
                display: 'flex',
            },
            row: {
                display: 'flex',
                backgroundColor: palette.background.main,
                color: palette.text.primary,
                '&:hover': {
                    backgroundColor: palette.actions.hover,
                },
            },
            lastRowCell: {
                borderBottom: 'none',
            },
            cell: {
                ...typography.text,
                boxSizing: 'border-box',
                height: mainSizes.table.rowHeight,
                borderBottom: DefaultBorder,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                color: 'inherit',
                cursor: 'default',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            clickableCell: {
                cursor: 'pointer',
            },
            centerCell: {
                justifyContent: 'center',
            },
            iconCell: {
                padding: 0,
                borderBottom: DefaultBorder,
                height: mainSizes.table.rowHeight,
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'default',
                '& svg': {
                    width: mainSizes.table.iconSize,
                    height: mainSizes.table.iconSize,
                },
            },
            innerCell: {
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            attentionIcon: {
                padding: `0 ${spacing(1.5)}px`,
                display: 'flex',
                alignItems: 'center',
                color: palette.levels.warning,
                '& path': {
                    fill: palette.levels.warning,
                },
                width: mainSizes.table.iconSize,
                height: mainSizes.table.iconSize,
            },
            bottomPane: {
                ...typography.text,
                height: mainSizes.table.footerHeight,
                borderTop: DefaultBorder,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                boxSizing: 'border-box',
                color: palette.text.secondary,
                '& p': {
                    ...typography.label,
                    margin: `0 ${spacing(2)}px 0 ${spacing(1)}px`,
                    fontSize: 'inherit',
                },
            },
            bigCell: {
                ...typography.text,
                boxSizing: 'border-box',
                height: '42px',
                borderBottom: DefaultBorder,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                color: 'inherit',
                cursor: 'default',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
        reactToast: {
            info: {
                container: {
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                },
                body: {
                    color: theme.palette.text.primary,
                },
                progress: {
                    background: InformationSelectBackgroundColor,
                },
            },
            error: {
                container: {
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                },
                body: {
                    color: theme.palette.text.primary,
                },
                progress: {
                    background: ErrorSelectBackgroundColor,
                },
            },
            success: {
                container: {
                    border: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                },
                body: {
                    color: theme.palette.text.primary,
                },
                progress: {
                    background: SuccessSelectBackgroundColor,
                },
            },
        },
        svgIcon: {
            colorFm3: {
                '& path': {
                    fill: palette.primary.fm3,
                },
            },
            colorPrimary: {
                '& path': {
                    fill: palette.primary.main,
                },
            },
            colorPrimaryText: {
                '& path': {
                    fill: palette.text.primary,
                },
            },
            colorSecondary: {
                '& path': {
                    fill: theme.palette.secondary.main,
                },
            },
            colorGrey: {
                '& path': {
                    fill: palette.colors.grey30,
                },
            },
            colorSuccess: {
                '& path': {
                    fill: palette.levels.success,
                },
            },
            colorError: {
                '& path': {
                    fill: palette.levels.error,
                },
            },
            colorWarning: {
                '& path': {
                    fill: palette.levels.warning,
                },
            },
            colorInfo: {
                '& path': {
                    fill: palette.levels.info,
                },
            },
            colorDebug: {
                '& path': {
                    fill: palette.levels.debug,
                },
            },
            clickable: {
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    '& path': {
                        fill: palette.primary.main,
                    },
                },
            },
            clickableSuccess: {
                '& path': {
                    fill: SuccessTextColor,
                },
                '&:hover': {
                    '& path': {
                        fill: SuccessSelectBackgroundColor,
                    },
                },
            },
            clickableInfo: {
                '& path': {
                    fill: InformationTextColor,
                },
                '&:hover': {
                    '& path': {
                        fill: InformationSelectBackgroundColor,
                    },
                },
            },
            clickableError: {
                '& path': {
                    fill: ErrorTextColor,
                },
                '&:hover': {
                    '& path': {
                        fill: ErrorSelectBackgroundColor,
                    },
                },
            },
            timeDivider: {
                margin: '0 4px',
                '& path': {
                    fill: palette.text.secondary,
                },
            },
            activeSwitcher: {
                '& rect': {
                    fill: palette.primary.main,
                },
                '& circle': {
                    fill: palette.primary.contrastText,
                },
            },
            disabledSwitcher: {
                '& rect': {
                    fill: palette.text.secondary,
                },
                '& circle': {
                    fill: palette.primary.contrastText,
                },
            },
            checkbox: {
                '& rect': {
                    fill: palette.background.main,
                    stroke: palette.text.secondary,
                },
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    '& rect': {
                        stroke: palette.primary.main,
                    },
                    '& path': {
                        fill: palette.primary.main,
                    },
                },
            },
            clickableErrorIcon: {
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    '& path': {
                        fill: ErrorSelectBackgroundColor,
                    },
                },
            },
            clickableInfoIcon: {
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    '& path': {
                        fill: InformationSelectBackgroundColor,
                    },
                },
            },
        },
        emptyListPanel: {
            ...typography.infoMessage,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: palette.text.disabled,
        },
        defaultContainer: {
            backgroundColor: theme.palette.background.paper,
        },
        infoPane: {
            root: {
                flex: '0 0 50%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignContent: 'stretch',
            },
            body: {
                flexGrow: 1,
            },
        },
        leftNavMenu: {
            root: {
                padding: `${spacing(2.5)}px 0 ${spacing(2.5)}px ${spacing(2.5)}px`,
                flex: `0 0 ${mainSizes.admin.navigationPaneSize}px`,
                backgroundColor: palette.background.default,
                display: 'flex',
                alignItems: 'stretch',
            },
            rootNarrow: {
                flex: `0 0 ${mainSizes.admin.navigationPaneSmallSize}px`,
            },
            background: {
                flex: 1,
                boxSizing: 'border-box',
                borderRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.background.main,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            },
            logoPane: {
                flex: `0 0 ${mainSizes.admin.navigationLogoHeight}px`,
                padding: `0 ${spacing(1.5)}px`,
                display: 'flex',
                alignItems: 'center',
            },
            listContainer: {
                marginBottom: spacing(3),
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: `${palette.colors.scroll} ${palette.colors.scrollBackground} !important`,
                '&::-webkit-scrollbar-track': {
                    background: `${palette.colors.scrollBackground} !important`,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: `${palette.colors.scroll} !important`,
                },
            },
            listCaption: {
                ...typography.tinyText,
                ...typography.label,
                marginBottom: spacing(1),
                flex: `0 0 ${mainSizes.list.titleHeight}px`,
                padding: `0 ${spacing(3)}px`,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.secondary,
                letterSpacing: '0.1em',
                cursor: 'default',
            },
            listItemsContainer: {
                marginBottom: spacing(3),
                display: 'flex',
                flexDirection: 'column',
                '&:last-child': {
                    marginBottom: 0,
                },
            },
            listItem: {
                ...typography.text,
                margin: `0 ${spacing(1.5)}px`,
                height: mainSizes.list.rowHeight,
                padding: `0 ${spacing(1.25)}px`,
                borderRadius: mainSizes.common.smallBorderRadius,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.primary,
                cursor: 'pointer',
                '& svg': {
                    marginRight: spacing(1.5),
                    width: mainSizes.list.iconSize,
                    height: mainSizes.list.iconSize,
                    '&:last-child': {
                        marginRight: 0,
                    },
                },
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    backgroundColor: palette.actions.hover,
                },
            },
            selectedListItem: {
                backgroundColor: palette.actions.select,
                color: palette.text.primary,
                '&:hover': {
                    backgroundColor: palette.actions.select,
                },
            },
            collapseButtonPane: {
                marginBottom: spacing(3),
                display: 'flex',
                justifyContent: 'center',
            },
            collapseButton: {
                height: mainSizes.button.height,
                padding: `0 ${spacing(2)}px`,
                display: 'flex',
                alignItems: 'center',
                border: `${mainSizes.common.thickBorderSize}px solid ${palette.divider}`,
                borderRadius: mainSizes.common.smallBorderRadius,
                cursor: 'pointer',
                boxSizing: 'border-box',
                '& svg': {
                    marginRight: spacing(0.5),
                    width: mainSizes.button.largeIconSize,
                    height: mainSizes.button.largeIconSize,
                    '&:last-child': {
                        marginRight: 0,
                    },
                },
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    backgroundColor: palette.actions.select,
                },
            },
            narrowCollapseButton: {
                width: mainSizes.button.height,
                padding: 0,
                justifyContent: 'center',
                alignItems: 'center',
            },
            buttonText: {
                ...typography.button,
                color: palette.text.secondary,
            },
        },
        searchField: {
            root: {
                width: 455,
                display: 'flex',
                gap: spacing(1),
            },
            filterRoot: {
                boxSizing: 'border-box',
                width: 455,
                height: mainSizes.input.height,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: `0 ${spacing(1)}px`,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.background.main,
            },
            fullWidth: {
                flexGrow: 1,
            },
            inputBlock: {
                boxSizing: 'border-box',
                flexGrow: 1,
                height: mainSizes.input.height,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: `0 ${spacing(1)}px`,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.background.main,
            },
            input: {
                ...typography.input,
                flexGrow: 1,
                border: 'none',
                backgroundColor: palette.background.main,
                outline: 'none',
                color: palette.text.primary,
                '&::placeholder': {
                    color: palette.text.placeholder,
                },
            },
            resetIconBlock: {
                display: 'flex',
                cursor: 'pointer',
                '& svg': {
                    width: mainSizes.input.iconSize,
                    height: mainSizes.input.iconSize,
                },
                '& svg path': {
                    fill: palette.text.secondary,
                },
                '& svg:hover path': {
                    fill: palette.levels.error,
                },
            },
            typeIconBlock: {
                marginRight: spacing(0.5),
                display: 'flex',
                '& svg': {
                    width: mainSizes.input.iconSize,
                    height: mainSizes.input.iconSize,
                },
                '& svg path': {
                    fill: palette.text.secondary,
                },
            },
            actionIconBlock: {
                marginLeft: 4,
                height: 36,
                borderRadius: `0 ${SmallBorderRadius}px ${SmallBorderRadius}px 0`,
                padding: '0 16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: primaryMainColor,
                color: primaryButtonTextColor,
                fontSize: 14,
                fontWeight: 500,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: primaryHoverColor,
                },
            },
        },
        buttons: {
            contained: {
                ...typography.button,
                marginRight: spacing(1.5),
                boxSizing: 'border-box',
                minWidth: 105,
                height: mainSizes.button.height,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: `0 ${spacing(1.5)}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: palette.primary.main,
                color: palette.primary.contrastText,
                cursor: 'pointer',
                '&:last-child': {
                    marginRight: 0,
                },
                '&:hover': {
                    backgroundColor: palette.primary.hover,
                },
                '& svg': {
                    marginRight: spacing(1),
                },
                '& path': {
                    fill: palette.primary.contrastText,
                },
            },
            containedIcon: {
                minWidth: 0,
                width: mainSizes.button.height,
                padding: 0,
                '& svg': {
                    marginRight: 0,
                },
            },
            toolbar: {
                boxSizing: 'border-box',
                height: 36,
                border: `2px solid ${toolbarButtonBorderColor}`,
                borderRadius: SmallBorderRadius,
                padding: '0 12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: toolbarButtonTextColor,
                fontSize: 14,
                fontWeight: 600,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: toolbarButtonBorderColor,
                },
                '& svg': {
                    marginRight: 8,
                },
                '& path': {
                    fill: toolbarButtonTextColor,
                },
            },
            toolbarIcon: {
                boxSizing: 'border-box',
                width: 36,
                height: 36,
                border: `2px solid ${toolbarButtonBorderColor}`,
                borderRadius: SmallBorderRadius,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: toolbarButtonTextColor,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: toolbarButtonBorderColor,
                },
                '& path': {
                    fill: toolbarButtonTextColor,
                },
            },
            toolbarMain: {
                height: 36,
                borderRadius: SmallBorderRadius,
                paddingRight: 12,
                display: 'flex',
                alignItems: 'center',
                background: 'linear-gradient(180deg, #42B2E2 0%, #389DCA 100%)',
                color: theme.palette.primary.contrastText,
                fontSize: 14,
                fontWeight: 600,
                cursor: 'pointer',
                '&:hover': {
                    background: 'linear-gradient(180deg, #389DCA 0%, #42B2E2 100%)',
                },
                '&:active': {
                    backgroundColor: theme.palette.action.hover,
                },
                '& svg': {
                    margin: 8,
                },
                '& path': {
                    fill: theme.palette.primary.contrastText,
                },
            },
            delete: {
                ...typography.button,
                marginRight: spacing(1.5),
                minWidth: 105,
                height: 36,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: '0 12px',
                border: `2px solid ${palette.alert.main}`,
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.background.default,
                color: palette.alert.main,
                cursor: 'pointer',
                '&:hover': {
                    borderColor: palette.alert.hover,
                    color: palette.alert.hover,
                    '& path': {
                        fill: palette.alert.hover,
                    },
                },
                '& svg': {
                    marginRight: spacing(1),
                },
                '& path': {
                    fill: palette.alert.main,
                },
            },
            outlined: {
                ...typography.button,
                marginRight: spacing(1.5),
                minWidth: 105,
                height: mainSizes.button.height,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: `0 ${spacing(1.5)}px`,
                border: ThickSecondaryBorder,
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: palette.background.default,
                color: palette.text.primary,
                cursor: 'pointer',
                '&:last-child': {
                    marginRight: 0,
                },
                '&:hover': {
                    borderColor: palette.primary.hover,
                    color: palette.primary.hover,
                    '& path': {
                        fill: palette.primary.hover,
                    },
                },
                '& svg': {
                    marginRight: spacing(1),
                },
                '& path': {
                    fill: palette.text.primary,
                },
            },
            outlinedIcon: {
                minWidth: 0,
                width: mainSizes.button.height,
                padding: 0,
                '& svg': {
                    marginRight: 0,
                },
            },
            defaultTab: {
                marginRight: 8,
                boxSizing: 'border-box',
                minWidth: 120,
                height: 36,
                borderRadius: LargeBorderRadius,
                padding: '2px 18px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: defaultTabBackgroundColor,
                color: defaultTabTextColor,
                fontSize: 16,
                fontWeight: 600,
                textTransform: 'uppercase',
                cursor: 'pointer',
            },
            selectedTab: {
                marginRight: 8,
                boxSizing: 'border-box',
                minWidth: 120,
                height: 36,
                borderRadius: LargeBorderRadius,
                padding: '2px 18px 0',
                border: `2px solid ${theme.palette.primary.main}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: selectedTabBackgroundColor,
                color: selectedTabTextColor,
                fontSize: 16,
                fontWeight: 600,
                textTransform: 'uppercase',
                cursor: 'pointer',
            },
        },
        notifications: {
            container: {
                border: DefaultBorder,
                borderRadius: 8,
                padding: '24px 36px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.background.paper,
            },
            icon: {
                width: 48,
                height: 48,
                viewBox: '0 0 48 48',
            },
            successIcon: {
                '& circle': {
                    fill: '#27AE60',
                },
                '& path': {
                    fill: theme.palette.background.paper,
                },
            },
            errorIcon: {
                '& path': {
                    fill: theme.palette.background.paper,
                },
                '& path:first-child': {
                    fill: '#EB5757',
                    stroke: '#EB5757',
                },
            },
            dataPane: {
                marginLeft: 16,
                display: 'flex',
                flexDirection: 'column',
            },
            titlePane: {
                ...typography.h3,
                color: palette.text.primary,
            },
            textPane: {
                ...typography.smallText,
                color: palette.text.primary,
            },
            modalContainer: {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: modalTransparentColor,
            },
        },
        dialog: {
            container: {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'stretch',
                backgroundColor: palette.colors.partialTransparent,
            },
            innerContainer: {
                boxSizing: 'border-box',
                padding: `0 ${mainSizes.page.contentPadding}px`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'stretch',
            },
            root: {
                height: 'calc(100vh - 72px)',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                overflow: 'hidden',
                borderTopLeftRadius: mainSizes.common.mediumBorderRadius,
                borderTopRightRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.background.main,
                '& div, & input': {
                    fontFamily: typography.fontFamily,
                },
                '& *': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${palette.colors.scroll} ${palette.colors.scrollBackground}`,
                },
                '& *::-webkit-scrollbar': {
                    width: mainSizes.common.scrollWidth,
                },
                '& *::-webkit-scrollbar-track': {
                    background: palette.colors.scrollBackground,
                },
                '& *::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.colors.scroll,
                    borderRadius: mainSizes.common.scrollWidth / 2,
                },
            },
            title: {
                boxSizing: 'border-box',
                flexShrink: 0,
                height: mainSizes.dialog.titleHeight,
                borderBottom: DefaultBorder,
                padding: `0 ${spacing(6)}px`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            titleText: {
                ...typography.h3,
                color: palette.text.primary,
            },
            titleCloseButton: {
                display: 'flex',
                cursor: 'pointer',
                '& svg': {
                    width: mainSizes.dialog.titleCloseButtonSize,
                    height: mainSizes.dialog.titleCloseButtonSize,
                },
                '& path': {
                    fill: palette.text.primary,
                },
            },
            titleButtons: {
                display: 'flex',
            },
            tabTitle: {
                root: {
                    boxSizing: 'border-box',
                    flexShrink: 0,
                    height: 80,
                    borderBottom: DefaultBorder,
                    padding: '0 48px',
                    display: 'flex',
                },
                tabsPane: {
                    flexGrow: 1,
                    display: 'flex',
                },
                tab: {
                    marginLeft: 29,
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    '&:first-child': {
                        marginLeft: 0,
                    },
                },
                text: {
                    ...typography.h3,
                    boxSizing: 'border-box',
                    flexGrow: 1,
                    paddingTop: 6,
                    display: 'flex',
                    alignItems: 'center',
                    color: palette.text.secondary,
                },
                activeText: {
                    color: palette.text.primary,
                },
                marker: {
                    height: 6,
                    borderRadius: '6px 6px 0 0',
                },
                activeMarker: {
                    backgroundColor: palette.primary.main,
                },
                closePane: {
                    flexShrink: 0,
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': {
                        cursor: 'pointer',
                    },
                    '& path': {
                        fill: palette.text.primary,
                    },
                },
            },
            content: {
                padding: `${spacing(2)}px ${spacing(6)}px`,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                overflow: 'auto',
            },
            toolbar: {
                padding: `0 ${spacing(6)}px`,
                flex: `0 0 ${mainSizes.dialog.footerHeight}px`,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.background.toolbar,
            },
        },
        switcherField: {
            label: {
                ...typography.text,
                cursor: 'pointer',
            },
        },
        fieldContainer: {
            root: {
                marginBottom: spacing(2),
                display: 'flex',
                alignItems: 'center',
                '&:last-child': {
                    marginBottom: 0,
                },
            },
            rootNoPadding: {
                display: 'flex',
                alignItems: 'center',
            },
            label: {
                ...typography.text,
                color: palette.text.primary,
                boxSizing: 'border-box',
                paddingRight: spacing(4),
            },
            rightLabel: {
                ...typography.text,
                color: palette.text.primary,
                boxSizing: 'border-box',
                paddingLeft: spacing(1.5),
            },
            inputPane: {
                display: 'flex',
            },
            error: {
                ...typography.text,
                color: palette.text.primary,
                boxSizing: 'border-box',
                paddingLeft: spacing(1.5),
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                    marginRight: spacing(1),
                    width: mainSizes.input.iconSize,
                    height: mainSizes.input.iconSize,
                },
                '& path': {
                    fill: palette.levels.error,
                },
            },
            simpleText: {
                ...typography.text,
                padding: `0 ${spacing(1)}px`,
                color: palette.text.primary,
                height: mainSizes.input.height,
                display: 'flex',
                alignItems: 'center',
            },
            checkboxInput: {
                height: mainSizes.input.height,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
            },
        },
        userGroupsEditDialog: {
            mapsList: {
                flexGrow: 1,
                width: '360px',
                height: '500px',
            },
            mapsSettingsContainer: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'justify',
                overflow: 'auto',
                height: '560px',
            },
            mapContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'justify',
            },
            mapTypeRow: {
                display: 'flex',
                alignItems: 'center',
                marginTop: '16px',
            },
            mapCheckbox: {
                marginBottom: 0,
            },
            mapCustomApiKeyRow: {
                paddingLeft: 24,
                display: 'flex',
                alignItems: 'center',
            },
            checkboxIcon: {
                padding: 0,
                height: DefaultTableRowHeight,
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                '& svg': {
                    width: mainSizes.table.iconSize,
                    height: mainSizes.table.iconSize,
                },
                color: theme.palette.primary.main,
                '& path': {
                    fill: theme.palette.primary.main,
                },
            },
        },
        detailsPane: {
            root: {
                display: 'flex',
                flexDirection: 'column',
            },
            header: {
                marginLeft: spacing(1.5),
                height: mainSizes.details.titleHeight,
                display: 'flex',
                alignItems: 'center',
            },
            title: {
                ...typography.h2,
                color: palette.text.primary,
            },
        },
        detailsList: {
            root: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            },
            row: {
                flex: `0 0 ${mainSizes.details.rowHeight}px`,
                display: 'flex',
            },
            label: {
                ...typography.text,
                padding: '9px 0',
                display: 'flex',
                gap: spacing(1),
                color: palette.text.primary,
                '& svg': {
                    width: mainSizes.table.iconSize,
                    height: mainSizes.table.iconSize,
                },
            },
            data: {
                ...typography.text,
                padding: '9px 0',
                paddingLeft: spacing(2),
                color: palette.text.primary,
            },
            multilineData: {
                ...typography.text,
                boxSizing: 'border-box',
                padding: '9px 0',
                maxHeight: spacing(6),
                color: palette.text.primary,
                overflow: 'auto',
                overflowWrap: 'anywhere',
            },
            formattedData: {
                ...typography.text,
                padding: '9px 0',
                color: palette.text.primary,
                overflow: 'auto',
                overflowWrap: 'anywhere',
                whiteSpace: 'pre-wrap',
            },
            retranslationStatus: {
                ...typography.text,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.primary,
                '& svg': {
                    marginRight: spacing(1),
                },
            },
            retranslationError: {
                padding: spacing(1),
                borderRadius: mainSizes.common.smallBorderRadius,
                backgroundColor: palette.background.default,
                color: palette.text.primary,
                whiteSpace: 'pre-wrap',
            },
        },
        retranslations: {
            diffPointsNoLagCell: {
                color: SuccessSelectBackgroundColor,
            },
            diffPointsMediumLagCell: {
                color: WarningSelectBackgroundColor,
            },
            diffPointsHighLagCell: {
                color: ErrorSelectBackgroundColor,
            },
        },
        tree: {
            row: {
                boxSizing: 'border-box',
                height: DefaultTableRowHeight,
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                backgroundColor: secondaryBackgroundColor,
                '&:hover': {
                    backgroundColor: hoverColor,
                },
            },
            selectedRow: {
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                backgroundColor: selectColor,
            },
            clickableIconCell: {
                boxSizing: 'border-box',
                width: TableIconCellSize,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                '& path': {
                    fill: secondaryMainColor,
                },
            },
            primaryIconCell: {
                boxSizing: 'border-box',
                width: TableIconCellSize,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& path': {
                    fill: primaryMainColor,
                },
            },
            secondaryIconCell: {
                boxSizing: 'border-box',
                width: TableIconCellSize,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& path': {
                    fill: secondaryMainColor,
                },
            },
            textCell: {
                fontSize: 14,
                fontWeight: 500,
            },
        },
        twoPaneSelect: {
            panesRoot: {
                display: 'flex',
                alignContent: 'stretch',
            },
            pane: {
                boxSizing: 'border-box',
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                boxShadow: '0px 4px 16px rgba(1, 23, 34, 0.16)',
            },
            paneHeader: {
                boxSizing: 'border-box',
                height: DefaultTableHeaderHeight,
                borderBottom: `1px solid ${dividerColor}`,
                padding: '8px 16px 0',
                display: 'flex',
                alignItems: 'center',
                fontSize: 16,
                fontWeight: 700,
            },
            panesToolbar: {
                width: TwoPaneSelectToolbarWidth,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: spacing(1.5),
            },
            growingContainer: {
                flexGrow: 1,
                display: 'flex',
            },
            treePane: {
                flexGrow: 1,
                borderRadius: mainSizes.common.mediumBorderRadius,
                padding: spacing(2),
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(1.5),
                boxShadow: palette.colors.shadow,
                overflow: 'hidden',
            },
            treePaneHeader: {
                ...typography.h4,
                color: palette.text.primary,
            },
        },
        serverStats: {
            containers: {
                root: {
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    overflow: 'auto',
                },
                section: {
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                },
                primaryBlock: {
                    marginBottom: spacing(3),
                    boxSizing: 'border-box',
                    width: 700,
                    borderRadius: mainSizes.common.mediumBorderRadius,
                    padding: spacing(2),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    backgroundColor: palette.background.main,
                    boxShadow: '0px 4px 16px rgba(1, 23, 34, 0.08)',
                },
                secondaryBlock: {
                    marginBottom: spacing(1.5),
                    boxSizing: 'border-box',
                    width: 500,
                    borderRadius: mainSizes.common.mediumBorderRadius,
                    padding: spacing(2),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    backgroundColor: palette.background.main,
                    boxShadow: '0px 4px 16px rgba(1, 23, 34, 0.08)',
                },
                blockHeader: {
                    ...typography.h3,
                    height: 36,
                    display: 'flex',
                    alignItems: 'center',
                    color: palette.text.primary,
                },
                metric: {
                    boxSizing: 'border-box',
                    height: 36,
                    borderBottom: DefaultBorder,
                    display: 'flex',
                    alignItems: 'center',
                },
                metricLabel: {
                    ...typography.text,
                    flexGrow: 3,
                    display: 'flex',
                    alignItems: 'center',
                    color: palette.text.primary,
                },
                toolbar: {
                    paddingTop: spacing(2),
                    display: 'flex',
                },
            },
            textValues: {
                regular: {
                    ...typography.text,
                    flexGrow: 2,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    color: palette.text.primary,
                },
                withGreyIcon: {
                    ...defaultStatsTextWithIcon,
                    color: palette.text.primary,
                    '& path': {
                        fill: palette.text.secondary,
                    },
                },
                withRedIcon: {
                    ...defaultStatsTextWithIcon,
                    color: palette.text.primary,
                    '& path': {
                        fill: palette.levels.error,
                    },
                },
                greyHighlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.text.secondary,
                },
                greenHighlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.levels.success,
                },
                blueHighlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.levels.info,
                },
                orangeHighlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.levels.warning,
                },
                redHighlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.levels.error,
                },
                color1Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color1,
                },
                color2Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color2,
                },
                color3Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color3,
                },
                color4Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color4,
                },
                color5Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color5,
                },
                color6Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color6,
                },
                color7Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color7,
                },
                color8Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color8,
                },
                color9Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color9,
                },
                color10Highlight: {
                    ...defaultStatsHighlight,
                    backgroundColor: palette.colors.color10,
                },
            },
            infographics: {
                metricContainer: {
                    marginBottom: spacing(1.5),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                },
                headerContainer: {
                    ...typography.h5,
                    display: 'flex',
                    alignItems: 'center',
                    color: palette.text.primary,
                },
                headerLabel: {
                    flexGrow: 3,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                },
                headerValue: {
                    flexGrow: 2,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                },
                stripContainer: {
                    margin: `${spacing(1)}px 0`,
                    height: 6,
                    borderRadius: mainSizes.common.tinyBorderRadius,
                    display: 'flex',
                    alignItems: 'stretch',
                    overflow: 'hidden',
                },
                greyPart: {
                    backgroundColor: palette.text.disabled,
                },
                greenPart: {
                    backgroundColor: palette.levels.success,
                },
                bluePart: {
                    backgroundColor: palette.levels.info,
                },
                orangePart: {
                    backgroundColor: palette.levels.warning,
                },
                redPart: {
                    backgroundColor: palette.levels.error,
                },
                color1Part: {
                    backgroundColor: palette.colors.color1,
                },
                color2Part: {
                    backgroundColor: palette.colors.color2,
                },
                color3Part: {
                    backgroundColor: palette.colors.color3,
                },
                color4Part: {
                    backgroundColor: palette.colors.color4,
                },
                color5Part: {
                    backgroundColor: palette.colors.color5,
                },
                color6Part: {
                    backgroundColor: palette.colors.color6,
                },
                color7Part: {
                    backgroundColor: palette.colors.color7,
                },
                color8Part: {
                    backgroundColor: palette.colors.color8,
                },
                color9Part: {
                    backgroundColor: palette.colors.color9,
                },
                color10Part: {
                    backgroundColor: palette.colors.color10,
                },
                allPartsContainer: {
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                },
                partContainer: {
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: spacing(0.5),
                },
                partLabel: {
                    ...typography.text,
                    marginRight: spacing(0.5),
                    color: palette.text.primary,
                },
                partValue: {
                    ...typography.smallText,
                    marginRight: spacing(1.5),
                    color: palette.text.primary,
                },
            },
        },
        actionsTable: {
            switcher: {
                display: 'flex',
                cursor: 'pointer',
                height: '36px',
                fontSize: '14px',
                minWidth: '50px',
                boxSizing: 'border-box',
                alignItems: 'center',
                fontWeight: 500,
                borderRadius: '8px',
            },
        },
        popupMenu: {
            container: {
                '& .MuiPaper-root': {
                    borderRadius: mainSizes.common.smallBorderRadius,
                    padding: spacing(1),
                    border: 'none',
                    boxShadow: palette.colors.shadow,
                },
            },
            item: {
                ...typography.text,
                boxSizing: 'border-box',
                minWidth: 160,
                height: mainSizes.menu.itemHeight,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: spacing(1),
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                    marginRight: spacing(0.75),
                },
                '& path': {
                    fill: palette.text.primary,
                },
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: palette.actions.hover,
                },
            },
            dividerItem: {
                margin: `${spacing(1)}px 0`,
                height: mainSizes.menu.dividerItemHeight,
                backgroundColor: palette.divider,
            },
            icon: {
                width: mainSizes.menu.iconSize,
                height: mainSizes.menu.iconSize,
            },
        },
        dateTimeField: {
            inputContainer: {
                boxSizing: 'border-box',
                height: 36,
                border: DefaultBorder,
                borderRadius: 4,
                padding: 1,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.background.main,
                '&:hover': {
                    border: DefaultPrimaryBorder,
                },
                '& svg': {
                    margin: 8,
                    '& path': {
                        fill: palette.text.secondary,
                    },
                },
                '& .icon-button': {
                    display: 'flex',
                    cursor: 'pointer',
                    '&:hover': {
                        '& path': {
                            fill: palette.primary.main,
                        },
                    },
                },
            },
            inputContainerInFocus: {
                border: `2px solid ${palette.primary.main}`,
                padding: 0,
                '&:hover': {
                    border: `2px solid ${palette.primary.main}`,
                },
            },
            inputContainerWithError: {
                borderColor: palette.alert.main,
                padding: 0,
                '&:hover': {
                    borderColor: palette.alert.main,
                },
            },
            input: {
                ...typography.text,
                boxSizing: 'border-box',
                flexGrow: 1,
                minWidth: 0,
                border: 'none',
                backgroundColor: palette.background.main,
                color: palette.text.primary,
                outline: 'none',
            },
            popoverContainer: {
                '& .MuiPaper-root': {
                    padding: 8,
                    borderRadius: mainSizes.common.tinyBorderRadius,
                },
            },
            calendarContainer: {
                '& button': {
                    boxSizing: 'border-box',
                    border: 'none',
                    borderRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: palette.background.main,
                    fontSize: 14,
                    fontWeight: 500,
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: palette.actions.hover,
                    },
                },
                '& .react-calendar': {
                    width: 292,
                },
                '& .react-calendar__navigation': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                '& .react-calendar__navigation__arrow': {
                    width: 24,
                    height: 24,
                    padding: '0 0 6px',
                    fontSize: 24,
                    color: palette.text.primary,
                },
                '& .react-calendar__navigation__label__labelText': {
                    ...typography.text,
                    color: palette.text.primary,
                },
                '& .react-calendar__month-view__weekdays__weekday': {
                    ...typography.text,
                    margin: '8px 0',
                    marginRight: 16,
                    flex: '0 0 28px !important',
                    height: 28,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: palette.text.primary,
                    '& abbr': {
                        color: palette.text.secondary,
                        textDecoration: 'none',
                        textTransform: 'capitalize',
                        cursor: 'default',
                    },
                    '&:nth-child(7n)': {
                        marginRight: 0,
                    },
                },
                '& .react-calendar__month-view__days__day': {
                    ...typography.text,
                    marginBottom: 8,
                    marginRight: 16,
                    flex: '0 0 28px !important',
                    height: 28,
                    color: palette.text.primary,
                    '&:nth-child(7n)': {
                        marginRight: 0,
                    },
                },
                '& .react-calendar__month-view__days__day--neighboringMonth': {
                    ...typography.text,
                    color: palette.text.disabled,
                },
                '& .react-calendar__tile--now': {
                    border: `2px solid ${palette.colors.grey30}`,
                },
                '& .react-calendar__tile--active': {
                    border: 'none',
                    backgroundColor: palette.primary.main,
                    color: palette.primary.contrastText,
                    '&:hover': {
                        backgroundColor: palette.primary.main,
                    },
                },
                '& .react-calendar__year-view__months__month': {
                    ...typography.text,
                    marginBottom: 16,
                    height: 28,
                    color: palette.text.primary,
                    '& abbr': {
                        textTransform: 'capitalize',
                    },
                },
                '& .react-calendar__decade-view__years__year': {
                    ...typography.text,
                    marginBottom: 16,
                    height: 28,
                    color: palette.text.primary,
                },
                '& .react-calendar__century-view__decades__decade': {
                    ...typography.text,
                    marginBottom: 16,
                    height: 28,
                    color: palette.text.primary,
                },
            },
            timeContainer: {
                height: 52,
                borderTop: DefaultBorder,
                paddingTop: 8,
                display: 'flex',
                alignItems: 'center',
            },
            timeLabel: {
                ...typography.text,
                marginRight: 8,
                color: palette.text.secondary,
            },
            timeInputContainer: {
                boxSizing: 'border-box',
                width: 36,
                height: 36,
                border: DefaultBorder,
                borderRadius: 4,
                padding: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                    borderColor: palette.primary.main,
                },
            },
            timeInputContainerInFocus: {
                borderWidth: 2,
                padding: 0,
                borderColor: palette.primary.main,
            },
            timeInput: {
                ...typography.text,
                type: 'hidden',
                width: 20,
                border: 'none',
                outline: 'none',
                textAlign: 'center',
                color: palette.text.primary,
                backgroundColor: palette.background.main,
            },
        },
        retranslatorPage: {
            root: {
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'stretch',
                backgroundColor: mainBackgroundColor,
                '& div, & input': {
                    fontFamily: typography.fontFamily,
                },
                '& *': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${palette.colors.scroll} ${palette.colors.scrollBackground}`,
                },
                '& *::-webkit-scrollbar': {
                    width: DefaultScrollWidth,
                },
                '& *::-webkit-scrollbar-track': {
                    background: palette.colors.scrollBackground,
                },
                '& *::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.colors.scroll,
                    borderRadius: DefaultScrollWidth / 2,
                },
            },
            content: {
                flexGrow: 1,
                padding: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            },
            contentHeader: {
                boxSizing: 'border-box',
                height: 52,
                paddingLeft: 16,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            contentHeaderTitle: {
                color: primaryTextColor,
                fontSize: 28,
                fontWeight: 700,
            },
            contentHeaderButton: {
                borderRadius: SmallBorderRadius,
                boxSizing: 'border-box',
                height: 36,
                paddingRight: 8,
                display: 'flex',
                alignItems: 'center',
                color: primaryTextColor,
                fontSize: 14,
                fontWeight: 600,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: palette.background.toolbar,
                },
                '& svg': {
                    margin: 8,
                    width: mainSizes.button.iconSize,
                    height: mainSizes.button.iconSize,
                },
                '& path': {
                    fill: primaryTextColor,
                },
            },
            contentBody: {
                flexGrow: 1,
                borderRadius: MediumBorderRadius,
                padding: `${spacing(2)}px ${spacing(4)}px`,
                backgroundColor: secondaryBackgroundColor,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            },
            contentBodyToolbar: {
                boxSizing: 'border-box',
                flex: `0 0 ${mainSizes.page.titleHeight}px`,
                borderTop: DefaultBorder,
                paddingTop: spacing(1.5),
                display: 'flex',
            },
        },
        retranslatorMainMenu: {
            root: {
                flexShrink: 0,
                width: RetranslatorPageSidebarWidth,
                borderRadius: `0 ${mainSizes.common.largeBorderRadius}px ${mainSizes.common.largeBorderRadius}px 0`,
                background: palette.sidebar.background,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            },
            logoPane: {
                flexShrink: 0,
                height: 88,
                paddingLeft: RetranslatorMenuPadding,
                display: 'flex',
                alignItems: 'center',
            },
            logo: {
                width: 210,
                height: 41,
                backgroundImage: `url("${PathToImages}fmr_logo.png")`,
                backgroundSize: '100%',
            },
            tabsPane: {
                flexShrink: 0,
                padding: `0 ${RetranslatorMenuPadding}px`,
                display: 'flex',
                justifyContent: 'space-between',
            },
            tabButton: {
                ...typography.button,
                boxSizing: 'border-box',
                width: RetranslatorMenuTabWidth,
                height: 36,
                borderRadius: mainSizes.common.largeBorderRadius,
                padding: `0 ${RetranslatorMenuPadding}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'uppercase',
                cursor: 'pointer',
            },
            primaryTab: {
                border: 'none',
                backgroundColor: palette.primary.main,
                color: palette.primary.contrastText,
                cursor: 'default',
            },
            secondaryTab: {
                border: `2px solid ${palette.sidebar.border}`,
                backgroundColor: 'inherit',
                color: palette.sidebar.buttonText,
                '&:hover': {
                    backgroundColor: palette.sidebar.border,
                },
            },
            content: {
                marginTop: 24,
                boxSizing: 'border-box',
                flexGrow: 1,
                padding: `0 ${RetranslatorMenuPadding}px`,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: `${palette.sidebar.scroll} ${palette.sidebar.scrollBackground} !important`,
                '&::-webkit-scrollbar': {
                    width: mainSizes.common.scrollWidth,
                },
                '&::-webkit-scrollbar-track': {
                    background: `${palette.sidebar.scrollBackground} !important`,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: `${palette.sidebar.scroll} !important`,
                    borderRadius: mainSizes.common.scrollWidth / 2,
                },
            },
            toolbar: {
                flexShrink: 0,
                height: 76,
                padding: `0 ${RetranslatorMenuPadding}px`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            addButton: {
                ...typography.button,
                boxSizing: 'border-box',
                height: 36,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: `0 ${RetranslatorMenuPadding}px`,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.primary.main,
                color: palette.primary.contrastText,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: palette.primary.hover,
                },
                '& svg': {
                    marginRight: 4,
                },
                '& path': {
                    fill: palette.primary.contrastText,
                },
            },
            reloadButton: {
                ...typography.button,
                boxSizing: 'border-box',
                width: mainSizes.button.height,
                height: mainSizes.button.height,
                border: `2px solid ${palette.sidebar.border}`,
                borderRadius: mainSizes.common.smallBorderRadius,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'inherit',
                color: palette.sidebar.text,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: palette.sidebar.border,
                },
                '& svg': {
                    width: mainSizes.button.iconSize,
                    height: mainSizes.button.iconSize,
                },
                '& path': {
                    fill: palette.sidebar.buttonText,
                },
            },
        },
        uiTreeDefault: {
            root: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: palette.background.main,
            },
            toolbar: {
                marginBottom: spacing(2),
                height: mainSizes.toolbar.height,
                padding: `0 ${spacing(1.5)}px`,
                borderRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.background.toolbar,
                display: 'flex',
                alignItems: 'center',
            },
            tree: {
                flex: '1 0 0',
                overflow: 'hidden',
            },
            node: {
                boxSizing: 'border-box',
                height: mainSizes.table.rowHeight,
                border: `2px solid ${palette.background.main}`,
                borderRadius: mainSizes.common.smallBorderRadius,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.primary,
                '&:hover': {
                    borderColor: palette.actions.hover,
                    backgroundColor: palette.actions.hover,
                },
            },
            selected: {
                borderColor: palette.actions.select,
                backgroundColor: palette.actions.select,
            },
            selectedBlink: {
                borderColor: palette.actions.select,
                backgroundColor: palette.background.main,
            },
            nodeLevel: {
                flexShrink: 0,
            },
            nodeIcon: {
                flexShrink: 0,
                width: TreeIconBlockSize,
                height: TreeIconBlockSize,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& path': {
                    fill: palette.text.secondary,
                },
            },
            nodeText: {
                ...typography.text,
                flexGrow: 1,
                boxSizing: 'border-box',
                paddingRight: spacing(2),
                color: 'inherit',
                cursor: 'default',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            actionIcon: {
                '& rect': {
                    fill: palette.background.main,
                    stroke: palette.divider,
                },
                '& path': {
                    fill: palette.text.secondary,
                },
            },
            excluded: {
                color: palette.text.disabled,
                '& path': {
                    fill: `${palette.text.disabled} !important`,
                },
            },
        },
        uiTreeDark: {
            root: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                '& div': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${palette.sidebar.scroll} ${palette.sidebar.scrollBackground}`,
                    '&::-webkit-scrollbar': {
                        width: mainSizes.common.scrollWidth,
                    },
                    '&::-webkit-scrollbar-track': {
                        background: palette.sidebar.scrollBackground,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: palette.sidebar.scroll,
                        borderRadius: mainSizes.common.scrollWidth / 2,
                    },
                },
            },
            toolbar: {
                marginBottom: spacing(2),
                height: mainSizes.toolbar.height,
                padding: `0 ${spacing(1.5)}px`,
                borderRadius: mainSizes.common.mediumBorderRadius,
                backgroundColor: palette.sidebar.toolbar,
                display: 'flex',
                alignItems: 'center',
            },
            tree: {
                flex: '1 0 0',
                overflow: 'hidden',
            },
            node: {
                height: mainSizes.table.rowHeight,
                borderRadius: mainSizes.common.smallBorderRadius,
                display: 'flex',
                alignItems: 'center',
                color: palette.sidebar.text,
                '&:hover': {
                    color: palette.sidebar.hoverText,
                },
            },
            selected: {
                color: palette.sidebar.selectText,
                backgroundColor: palette.sidebar.selectBackground,
            },
            selectedBlink: {},
            nodeLevel: {
                flexShrink: 0,
            },
            nodeIcon: {
                flexShrink: 0,
                width: TreeIconBlockSize,
                height: TreeIconBlockSize,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& path': {
                    fill: palette.sidebar.text,
                },
            },
            nodeText: {
                ...typography.text,
                flexGrow: 1,
                boxSizing: 'border-box',
                paddingRight: spacing(2),
                color: 'inherit',
                cursor: 'default',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            actionIcon: {
                '& rect': {
                    fill: palette.sidebar.background,
                    stroke: palette.sidebar.buttonText,
                },
                '& path': {
                    fill: palette.sidebar.buttonText,
                },
            },
            excluded: {
                color: palette.text.disabled,
                '& path': {
                    fill: `${palette.text.disabled} !important`,
                },
            },
        },
        generalStyles: {
            clickable: { cursor: 'pointer' },
            noMargin: { margin: 0 },
            noPadding: { padding: 0 },
            withBorder: { border: DefaultBorder },
            h4: {
                ...typography.h4,
            },
        },
        retranslatorDeviceDetailsPage: {
            header: {
                marginLeft: spacing(1.5),
                height: mainSizes.page.titleHeight,
                display: 'flex',
                alignItems: 'center',
            },
            title: {
                ...typography.h2,
                color: palette.text.primary,
            },
            topPane: {
                display: 'flex',
                alignItems: 'flex-start',
            },
            detailsPane: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(1.5),
            },
            mapPane: {
                marginTop: mainSizes.page.titleHeight,
                marginLeft: spacing(2),
                width: 300,
                height: 300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 0,
                overflow: 'hidden',
                '& .leaflet-marker-icon': {
                    cursor: 'grab',
                },
                '& .leaflet-control-attribution.leaflet-control>a>svg': {
                    display: 'none !important',
                },
            },
            retranslationsPane: {
                margin: spacing(1.5),
            },
        },
        selectField: {
            muiPopover: {
                '& .MuiPopover-paper': {
                    padding: spacing(1),
                },
            },
            inputPane: {
                margin: 1,
                boxSizing: 'border-box',
                flexGrow: 1,
                height: mainSizes.input.height - 2,
                border: DefaultBorder,
                borderRadius: mainSizes.common.tinyBorderRadius,
                padding: `0 ${spacing(1)}px`,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: palette.background.main,
                outline: 'none',
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    borderColor: palette.primary.main,
                    '& path': {
                        fill: palette.primary.main,
                    },
                },
                '&:focus': {
                    margin: 0,
                    height: mainSizes.input.height,
                    borderWidth: ThickBorderSize,
                    borderColor: palette.primary.main,
                    '& path': {
                        fill: palette.primary.main,
                    },
                },
            },
            focusedInput: {
                margin: 0,
                height: mainSizes.input.height,
                borderWidth: ThickBorderSize,
                borderColor: palette.primary.main,
                '& path': {
                    fill: palette.primary.main,
                },
            },
            text: {
                ...typography.text,
                flexGrow: 1,
                color: palette.text.primary,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            icon: {
                display: 'flex',
                '& svg': {
                    width: mainSizes.input.iconSize,
                    height: mainSizes.input.iconSize,
                },
            },
            itemsContainer: {
                maxHeight: '60vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            },
            item: {
                marginRight: spacing(1),
                boxSizing: 'border-box',
                flex: `0 0 ${mainSizes.input.height}px`,
                borderRadius: mainSizes.common.smallBorderRadius,
                padding: `0 ${spacing(1)}px`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: palette.actions.hover,
                },
            },
            selectedItem: {
                backgroundColor: palette.actions.select,
                '&:hover': {
                    backgroundColor: palette.actions.select,
                },
            },
            itemText: {
                ...typography.text,
                color: palette.text.primary,
            },
            itemIcon: {
                display: 'flex',
                '& svg': {
                    width: mainSizes.input.iconSize,
                    height: mainSizes.input.iconSize,
                },
                '& path': {
                    fill: palette.text.primary,
                },
            },
        },
        dropdownTree: {
            input: {
                ...typography.input,
                boxSizing: 'border-box',
                flexGrow: 1,
                height: mainSizes.input.height,
                border: DefaultBorder,
                borderRadius: mainSizes.common.tinyBorderRadius,
                padding: 5,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.primary,
                cursor: 'pointer',
                overflow: 'hidden',
                '& svg': {
                    flexShrink: 0,
                    width: mainSizes.input.iconSize,
                    height: mainSizes.input.iconSize,
                },
                '& path': {
                    fill: palette.text.primary,
                },
                '&:hover': {
                    border: DefaultPrimaryBorder,
                    '& path': {
                        fill: palette.primary.main,
                    },
                },
                '&:focus': {
                    border: ThickPrimaryBorder,
                    padding: 4,
                    outline: 'none',
                    '& path': {
                        fill: palette.primary.main,
                    },
                },
            },
            focusedInput: {
                border: ThickPrimaryBorder,
                padding: 4,
                '& path': {
                    fill: palette.primary.main,
                },
            },
            errorInput: {
                borderColor: palette.alert.main,
                '& path': {
                    fill: palette.alert.hover,
                },
                '&:hover': {
                    borderColor: palette.alert.hover,
                    '& path': {
                        fill: palette.alert.hover,
                    },
                },
                '&:focus': {
                    borderColor: palette.alert.hover,
                    '& path': {
                        fill: palette.alert.hover,
                    },
                },
            },
            textContainer: {
                flexGrow: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            treePane: {
                width: '50vw',
                height: '50vh',
                display: 'flex',
                flexDirection: 'column',
            },
        },
        tabPane: {
            root: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
            },
            tabHeader: {
                boxSizing: 'border-box',
                height: mainSizes.page.titleHeight,
                borderBottom: DefaultBorder,
                display: 'flex',
                gap: spacing(4),
            },
            textTab: {
                display: 'flex',
                flexDirection: 'column',
                '&:first-child': {
                    paddingLeft: 0,
                },
            },
            text: {
                ...typography.h3,
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                color: palette.text.secondary,
            },
            activeText: {
                color: palette.text.primary,
            },
            marker: {
                flex: `0 0 ${mainSizes.common.tabMarkerHeight}px`,
                borderRadius: `${mainSizes.common.tabMarkerHeight}px ${mainSizes.common.tabMarkerHeight}px 0 0`,
                cursor: 'pointer',
            },
            activeMarker: {
                backgroundColor: palette.primary.main,
            },
            tabBody: {
                minHeight: 0,
                flex: '1 1 0',
                padding: `${spacing(1)} 0`,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            },
        },
        flexDivider: {
            flex: '0 0 1px',
            backgroundColor: palette.divider,
        },
        dateTimePane: {
            root: {
                display: 'flex',
                gap: spacing(6),
            },
        },
        listPane: {
            root: {
                flex: '1 1 0',
                display: 'flex',
                flexDirection: 'column',
            },
            listBlock: {
                flex: '1 1 0',
            },
            item: {
                marginRight: spacing(1),
                borderRadius: mainSizes.common.smallBorderRadius,
                height: mainSizes.list.rowHeight,
                display: 'flex',
                '&:hover': {
                    backgroundColor: palette.actions.hover,
                },
            },
            iconBlock: {
                flex: `0 0 ${mainSizes.list.rowHeight}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                    width: mainSizes.list.iconSize,
                    height: mainSizes.list.iconSize,
                },
                '& path': {
                    fill: palette.text.primary,
                },
            },
            textBlock: {
                ...typography.text,
                flex: '1 1 0',
                display: 'flex',
                alignItems: 'center',
                color: palette.text.primary,
            },
        },
        deleteQuestion: {
            root: {
                borderRadius: mainSizes.common.smallBorderRadius,
                maxWidth: 500,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: palette.background.main,
                overflow: 'hidden',
            },
            header: {
                ...typography.h3,
                boxSizing: 'border-box',
                height: 60,
                borderBottom: DefaultBorder,
                padding: `0 ${spacing(4)}px`,
                display: 'flex',
                alignItems: 'center',
                color: palette.text.primary,
            },
            mainText: {
                ...typography.text,
                padding: `${spacing(2)}px ${spacing(4)}px`,
                color: palette.text.primary,
            },
            deleteText: {
                ...typography.text,
                padding: `0 ${spacing(4)}px`,
                color: palette.text.primary,
            },
            inputContainer: {
                padding: `0 ${spacing(4)}px`,
            },
            toolbar: {
                marginTop: spacing(2),
                height: 60,
                padding: `0 ${spacing(4)}px`,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.background.toolbar,
            },
        },
    };

    return theme;
}
