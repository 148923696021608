import React from 'react';
import { translate } from 'utilities';
import { TimeSpan } from '../inputs';
import { FieldContainerProps, FieldContainer, FieldLayout } from './field-container';

interface TimeSpanFieldProps {
    value: number;
    onChange: (value: number) => void;
    labelWidth?: number;
    labelTextKey?: string;
    inputWidth?: number;
    showDays?: boolean;
    showHours?: boolean;
    showMinutes?: boolean;
    showSeconds?: boolean;
    position?: string;
    checkValid?: boolean;
    layout?: FieldLayout;
}

export const TimeSpanField = (props: TimeSpanFieldProps) => {
    let {
        value = 0,
        onChange,
        labelWidth,
        labelTextKey,
        inputWidth,
        showDays,
        showHours,
        showMinutes,
        showSeconds,
        position,
        checkValid = false,
        layout,
    } = props;

    let [errorText, setErrorText] = React.useState<string>('');

    const onChangeHandler = (value: number) => {
        if (!onChange) {
            return;
        }

        onChange(value);
    };

    React.useEffect(() => {
        if (!checkValid) {
            return;
        }

        if (value === 0) {
            setErrorText(translate('InvalidDataValue'));
        } else {
            setErrorText('');
        }
    }, [value]);

    let containerProps: FieldContainerProps = {
        labelTextKey,
        labelWidth,
        inputWidth,
        errorText,
        layout,
    };

    return (
        <FieldContainer {...containerProps}>
            <TimeSpan
                width={inputWidth}
                value={value}
                showDays={showDays}
                showHours={showHours}
                showMinutes={showMinutes}
                showSeconds={showSeconds}
                position={position}
                onChange={onChangeHandler}
            />
        </FieldContainer>
    );
};
