import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from '../../theme';

import { FieldContainer } from './field-container';

const createStyles = makeStyles((theme: Theme) => ({
    textClassName: { ...theme.custom?.fieldContainer.simpleText },
}));

export interface TextWithLabelProps {
    /**Значение поля  */
    value?: string;
    /**Ширина поля */
    width?: number;
    /**Ширина поля описания */
    labelWidth?: number;
    /**Текст поля описания */
    labelTextKey?: string;
    /**Параметр отключает отступы */
    disablePadding?: boolean;
    /** Ширина значения поля*/
    textWidth?: number;
}
/**Поле с текстом */
export const TextWithLabel: React.FC<TextWithLabelProps> = (props) => {
    let { value = '', width, labelWidth, labelTextKey, disablePadding, textWidth } = props;
    let { textClassName } = createStyles();

    let containerProps = {
        width,
        labelWidth,
        labelTextKey,
        inputWidth: textWidth,
        disablePadding,
    };
    return (
        <FieldContainer {...containerProps}>
            <div className={textClassName}>{value}</div>
        </FieldContainer>
    );
};
