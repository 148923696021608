import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { TreeIconBlockSize, TreeIconSize } from '../../constants';
import { Theme } from '../../theme';

import { ReactTreeNode as Data, ReactTreeNodeSettings, ReactTreeNodeActions, ReactTreeColorScheme } from './entities';
import { getTreeStyles } from './tree';
import { CheckboxIcon } from './checkbox-icon';
import { ExpandIcon } from './expand-icon';
import { TypeIcon } from './type-icon';
import { instanceOfDeviceTreeNode, instanceOfTreeGeozoneNode } from '../../entities';
import classNames from 'classnames';

const ImeiBlockWidth = 130;

const createStyles = (colorScheme: ReactTreeColorScheme) =>
    makeStyles((theme: Theme) => {
        let treeStyles = getTreeStyles(theme, colorScheme);

        return {
            node: { ...treeStyles?.node },
            selected: { ...treeStyles?.selected },
            selectedBlink: { ...treeStyles?.selectedBlink },
            nodeLevel: { ...treeStyles?.nodeLevel },
            nodeIcon: { ...treeStyles?.nodeIcon },
            nodeText: { ...treeStyles?.nodeText },
            excluded: { ...treeStyles?.excluded },
            clickable: { ...theme.custom?.generalStyles.clickable },
            selectedInfo: {
                ...theme.custom?.typography.tinyText,
                flex: `0 0 ${ImeiBlockWidth}px`,
                paddingRight: theme.custom?.spacing(0.5),
                display: 'flex',
                justifyContent: 'end',
            },
            unclickable: {
                '&:hover': {
                    borderColor: 'transparent',
                    backgroundColor: 'inherit',
                },
            },
        };
    });

interface TreeNodeProps {
    data: Data;
    settings: ReactTreeNodeSettings;
    actions: ReactTreeNodeActions;
    width: number;
}
export const TreeNode: React.FC<TreeNodeProps> = (props) => {
    let { data, settings, actions } = props;
    let classes = createStyles(settings.colorScheme)();

    const expandNode = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        actions.onExpandClick(data.key);
    };

    const checkNode = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        actions.onCheckClick(data.key);
    };

    const onClick = () => {
        if (actions.onNodeClick) {
            actions.onNodeClick(data);
        }

        if (settings.allowSelect) {
            actions.onSelect(data.key);
            return;
        }

        if (settings.allowCheck) {
            actions.onCheckClick(data.key);
        }
    };

    const onDoubleClick = () => {
        if (settings.allowSelect) {
            return;
        }

        actions.onExpandClick(data.key);
    };

    let nodeStyle = classes.node;
    if (data.selected) {
        if (data.disabled) {
            nodeStyle = classNames(nodeStyle, classes.selectedBlink);
        } else {
            nodeStyle = classNames(nodeStyle, classes.selected);
        }
    }
    if (!settings.allowCheck && !settings.allowSelect && !settings.allowExpand) {
        nodeStyle = classNames(nodeStyle, classes.unclickable);
    }
    let showExpand = data.childrenKeys.length > 0;
    let iconClasses = classes.nodeIcon;
    let textClasses = classes.nodeText;
    if (data.excluded) {
        iconClasses = classNames(iconClasses, classes.excluded);
        textClasses = classNames(textClasses, classes.excluded);
    }
    const levelIndent = data.level * TreeIconBlockSize;
    const iconsWidth = 2 * TreeIconBlockSize;
    const remainWidth = props.width - levelIndent - iconsWidth;
    const showImeiBlock = remainWidth - ImeiBlockWidth > 200;
    return (
        <div className={nodeStyle} onClick={onClick} onDoubleClick={onDoubleClick}>
            <div className={classes.nodeLevel} style={{ width: levelIndent }} />
            {settings.allowExpand && showExpand && (
                <div className={`${classes.nodeIcon} ${classes.clickable}`} onClick={expandNode}>
                    <ExpandIcon size={TreeIconSize} colorScheme={settings.colorScheme} expanded={data.expanded} />
                </div>
            )}
            {settings.allowExpand && !showExpand && <div className={classes.nodeIcon} />}
            {settings.allowCheck && !data.excluded && (
                <div className={`${classes.nodeIcon} ${classes.clickable}`} onClick={checkNode}>
                    <CheckboxIcon size={TreeIconSize} colorScheme={settings.colorScheme} checked={data.checked} />
                </div>
            )}
            {settings.allowCheck && data.excluded && (
                <div className={iconClasses}>
                    <CheckboxIcon size={TreeIconSize} colorScheme={settings.colorScheme} checked={data.checked} />
                </div>
            )}
            <div className={iconClasses}>
                <TypeIcon
                    type={data.type}
                    geozoneType={instanceOfTreeGeozoneNode(data) ? data.geozoneType : undefined}
                />
            </div>
            <div className={textClasses} title={data.text}>
                {data.text}
            </div>
            {showImeiBlock && data.selected && instanceOfDeviceTreeNode(data) && (
                <div className={classes.selectedInfo}>{`IMEI: ${data.imei}`}</div>
            )}
        </div>
    );
};
