import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { QuestionNotification, NotificationType, SvgIconType } from 'ui';
import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    modalContainer: theme.custom?.notifications.modalContainer || {},
}));

export interface ModalQuestionProps {
    /**Заголовок */
    titleKey: string;
    /**Описание */
    textKey: string;
    /**Текст на кнопке принятия */
    noButtonTextKey: string;
    noButtonIcon?: SvgIconType;
    /**Текст на кнопке отмены */
    yesButtonTextKey: string;
    yesButtonIcon?: SvgIconType;
    showLoadingOnYesButton?: boolean;
    /**Обработчик клика по кнопке отмены */
    noButtonAction?: () => void;
    /**Обработчик клика по кнопке принятия */
    yesButtonAction?: () => void;
}
/**Модальное окно подтверждением действия*/
export const ModalQuestion: React.FC<ModalQuestionProps> = (props) => {
    let {
        titleKey,
        textKey,
        noButtonTextKey,
        yesButtonTextKey,
        noButtonAction,
        yesButtonAction,
        noButtonIcon = 'close',
        yesButtonIcon = 'delete',
        showLoadingOnYesButton,
    } = props;

    let classes = createStyles();

    return (
        <div className={classes.modalContainer}>
            <QuestionNotification
                type={NotificationType.Error}
                {...{
                    titleKey,
                    textKey,
                    noButtonTextKey,
                    yesButtonTextKey,
                    noButtonAction,
                    yesButtonAction,
                    noButtonIcon,
                    yesButtonIcon,
                    showLoadingOnYesButton,
                }}
            />
        </div>
    );
};
