import { LoadingState } from 'ui';

export interface SharedDataStorage {
    showOldInterfaceSwitcher: boolean;
    news: NewsView[];
    languages: Language[];
}

export interface SharedDataStorageState {
    sharedDataStorage: SharedDataStorage;
    loadingState: LoadingState;
    textErrorMessage: string;

    translates: Translations;
    currentNewsIndex: number;
    intervalId: number;
}

export interface News {
    caption: string;
    previewImagePath: string;
    imageHDPath: string;
    imageFullHDPath: string;
    imageQuadHDPath: string;
    image4KPath: string;
    body: string[];
    link: string;
}

export interface NewsApi extends News {
    index: number;
}

export interface NewsView extends News {
    selected: boolean;
}

export function createView(news: News[]): NewsView[] {
    let result = news.map((item) => {
        return {
            caption: item.caption,
            previewImagePath: item.previewImagePath,
            imageHDPath: item.imageHDPath,
            imageFullHDPath: item.imageFullHDPath,
            imageQuadHDPath: item.imageQuadHDPath,
            image4KPath: item.image4KPath,
            body: item.body,
            link: item.link,
            selected: false,
        };
    });

    return result;
}

export interface ApplySelectionToViewProps {
    newsView: NewsView[];
    newCurrentNewsIndex: number;
    oldCurrentNewsIndex: number;
}

export function applySelectionToView(props: ApplySelectionToViewProps): NewsView[] {
    let { newsView, newCurrentNewsIndex, oldCurrentNewsIndex } = props;
    let result = [...newsView];

    if (newsView.length != 0) {
        result[oldCurrentNewsIndex].selected = false;
        result[newCurrentNewsIndex].selected = true;
    }

    return result;
}

export function getBackgroundImagePath(windowWidth: number, currentNews: News): string {
    if (windowWidth <= 1366) return currentNews?.imageHDPath;

    if (windowWidth <= 1920) return currentNews?.imageFullHDPath;

    if (windowWidth <= 2560) return currentNews?.imageQuadHDPath;

    return currentNews?.image4KPath;
}

export interface Language {
    name: string;
    code: string;
    image: string;
    translates: Translations;
    isDefault: boolean;
}

export interface Translations {
    Enter: string;
    EnterLoginData: string;
    ErrorEmptyPassword: string;
    ErrorLoginEmpty: string;
    Login: string;
    MoreDetails: string;
    OldInterface: string;
    Password: string;
    Welcome: string;
    SoftwareName: string;
    Hide: string;
    Show: string;
}

export const defaultTranslates = {
    Enter: 'Войти',
    EnterLoginData: 'Пожалуйста, введите данные для входа',
    ErrorEmptyPassword: 'Поле "Пароль" не может быть пустым',
    ErrorLoginEmpty: 'Поле "Логин" не может быть пустым',
    Login: 'Логин',
    MoreDetails: 'Подробнее',
    OldInterface: 'Старый интерфейс',
    Password: 'Пароль',
    Welcome: 'Добро пожаловать',
    SoftwareName: 'FortMonitor',
    Hide: 'Скрыть',
    Show: 'Показать',
};

export const defaultLanguage = {
    name: 'Русский',
    code: 'ru-ru',
    image: 'img/russianfederation.png',
    isDefault: false,
    translates: defaultTranslates,
};

export function returnLanguage(languages: Language[], browserLanguage: string, localStorageLang: string): string {
    const availableLanguages = languages.map((item) => item.code);
    const defaultLanguage = languages.find((item) => item.isDefault === true);
    const existLang = languages.some((item) => item.code === localStorageLang);
    let language = '';
    if (!existLang) {
        language = 'ru-ru';
    }

    if (localStorageLang !== '') {
        return localStorageLang;
    }

    if (defaultLanguage) {
        return defaultLanguage.code;
    }

    if (availableLanguages.includes(browserLanguage)) {
        return browserLanguage;
    }

    return language;
}
