import * as React from 'react';
import * as DateFns from 'date-fns';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextInput from '@material-ui/core/Input';

import { getDotNetDateTimeString } from 'utilities';
import { translate } from 'utilities';

enum DateTimePeriods {
    Last3Hours = 1,
    Last24Hours = 2,
    CurrentDay = 3,
    LastDay = 4,
    CurrentWeek = 5,
    LastWeek = 6,
    CurrentMonth = 7,
    LastMonth = 8,
}

export interface DateTimePeriodValue {
    startDateTime: string;
    stopDateTime: string;
}

function getDateTimePeriod(value: DateTimePeriods): DateTimePeriodValue {
    let current = new Date();
    let startDateTime: Date;
    let stopDateTime: Date;

    switch (value) {
        case DateTimePeriods.Last3Hours:
            startDateTime = DateFns.subHours(current, 3);
            stopDateTime = new Date();
            break;
        case DateTimePeriods.Last24Hours:
            startDateTime = DateFns.subHours(current, 24);
            stopDateTime = new Date();
            break;
        case DateTimePeriods.CurrentDay:
            startDateTime = DateFns.startOfToday();
            stopDateTime = DateFns.endOfToday();
            break;
        case DateTimePeriods.LastDay:
            startDateTime = DateFns.startOfYesterday();
            stopDateTime = DateFns.endOfYesterday();
            break;
        case DateTimePeriods.CurrentWeek:
            startDateTime = DateFns.startOfWeek(current);
            stopDateTime = DateFns.endOfWeek(current);
            break;
        case DateTimePeriods.LastWeek:
            let lastWeek = DateFns.subWeeks(current, 1);
            startDateTime = DateFns.startOfWeek(lastWeek);
            stopDateTime = DateFns.endOfWeek(lastWeek);
            break;
        case DateTimePeriods.CurrentMonth:
            startDateTime = DateFns.startOfMonth(current);
            stopDateTime = DateFns.endOfMonth(current);
            break;
        case DateTimePeriods.LastMonth:
            let lastMonth = DateFns.subMonths(current, 1);
            startDateTime = DateFns.startOfMonth(lastMonth);
            stopDateTime = DateFns.endOfMonth(lastMonth);
            break;
        default:
            startDateTime = new Date();
            stopDateTime = new Date();
            break;
    }

    return {
        startDateTime: getDotNetDateTimeString(startDateTime),
        stopDateTime: getDotNetDateTimeString(stopDateTime),
    };
}

export interface Props {
    /**Обработчик изменения периода */
    periodChanged: (newValue: DateTimePeriodValue) => void;
}

interface EventValue {
    name?: string | undefined;
    value: unknown;
}

const DefaultPeriod = DateTimePeriods.Last3Hours;

/**Компонент с выбором и отображением периода, не понятно где исползуется */
export const DateTimePeriodSelect: React.FC<Props> = (props: Props) => {
    let { periodChanged } = props;
    let [period, setPeriod] = React.useState<DateTimePeriodValue>({ startDateTime: '', stopDateTime: '' });

    const updatePeriod = React.useCallback((event: React.ChangeEvent<EventValue>): void => {
        let selectedPeriod = event.target.value as DateTimePeriods;
        let newValue = getDateTimePeriod(selectedPeriod);

        setPeriod(newValue);
        periodChanged(newValue);
    }, []);

    React.useEffect(() => {
        let newPeriod = getDateTimePeriod(DefaultPeriod);
        setPeriod(newPeriod);
        periodChanged(newPeriod);
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: 160, marginRight: 10 }}>
                <TextInput disableUnderline fullWidth value={period.startDateTime} />
            </div>
            <Select disableUnderline value={0} onChange={updatePeriod}>
                <MenuItem value={0} disabled>
                    {translate('Period')}
                </MenuItem>
                <MenuItem value={DateTimePeriods.Last3Hours}>{translate('last3hour')}</MenuItem>
                <MenuItem value={DateTimePeriods.Last24Hours}>{translate('last24hour')}</MenuItem>
                <MenuItem value={DateTimePeriods.CurrentDay}>{translate('this_day')}</MenuItem>
                <MenuItem value={DateTimePeriods.LastDay}>{translate('last_day')}</MenuItem>
                <MenuItem value={DateTimePeriods.CurrentWeek}>{translate('this_week')}</MenuItem>
                <MenuItem value={DateTimePeriods.LastWeek}>{translate('last_week')}</MenuItem>
                <MenuItem value={DateTimePeriods.CurrentMonth}>{translate('this_month')}</MenuItem>
                <MenuItem value={DateTimePeriods.LastMonth}>{translate('last_month')}</MenuItem>
            </Select>
            <div style={{ width: 160 }}>
                <TextInput disableUnderline fullWidth value={period.stopDateTime} />
            </div>
        </div>
    );
};
