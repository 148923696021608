import { getFullDateString, getFullDateTimeString } from 'utilities';

import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    subDays,
    subHours,
    subMonths,
    subWeeks,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from 'date-fns';

export interface TimePeriod {
    type: TimePeriodType;
    start: Date;
    end: Date;
}

export enum TimePeriodType {
    Custom = 'CustomPeriod',
    Last3Hours = 'Last3Hours',
    Last24Hours = 'Last24Hours',
    CurrentDay = 'CurrentDay',
    LastDay = 'LastDay',
    CurrentWeek = 'CurrentWeek',
    LastWeek = 'LastWeek',
    CurrentMonth = 'CurrentMonth',
    LastMonth = 'LastMonth',
}

export function getLast3Hours(): TimePeriod {
    let end = new Date();
    let start = subHours(end, 3);

    return { type: TimePeriodType.Last3Hours, start, end };
}

export function getLast24Hours(): TimePeriod {
    let end = new Date();
    let start = subHours(end, 24);

    return { type: TimePeriodType.Last24Hours, start, end };
}

export function getCurrentDay(): TimePeriod {
    let start = startOfDay(new Date());
    let end = endOfDay(start);

    return { type: TimePeriodType.CurrentDay, start, end };
}

export function getLastDay(): TimePeriod {
    let start = startOfDay(subDays(new Date(), 1));
    let end = endOfDay(start);

    return { type: TimePeriodType.LastDay, start, end };
}

export function getCurrentWeek(): TimePeriod {
    let start = startOfWeek(new Date(), { weekStartsOn: 1 });
    let end = endOfWeek(start, { weekStartsOn: 1 });

    return { type: TimePeriodType.CurrentWeek, start, end };
}

export function getLastWeek(): TimePeriod {
    let start = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
    let end = endOfWeek(start, { weekStartsOn: 1 });

    return { type: TimePeriodType.LastWeek, start, end };
}

export function getCurrentMonth(): TimePeriod {
    let start = startOfMonth(new Date());
    let end = endOfMonth(start);

    return { type: TimePeriodType.CurrentMonth, start, end };
}

export function getLastMonth(): TimePeriod {
    let start = startOfMonth(subMonths(new Date(), 1));
    let end = endOfMonth(start);

    return { type: TimePeriodType.LastMonth, start, end };
}

export function getCustomTimePeriod(source?: TimePeriod): TimePeriod {
    if (!source) {
        source = getCurrentDay();
    }

    return { ...source, type: TimePeriodType.Custom };
}

export function getTimePeriod(type: TimePeriodType): TimePeriod {
    switch (type) {
        case TimePeriodType.Last3Hours:
            return getLast3Hours();
        case TimePeriodType.Last24Hours:
            return getLast24Hours();
        case TimePeriodType.CurrentDay:
            return getCurrentDay();
        case TimePeriodType.LastDay:
            return getLastDay();
        case TimePeriodType.CurrentWeek:
            return getCurrentWeek();
        case TimePeriodType.LastWeek:
            return getLastWeek();
        case TimePeriodType.CurrentMonth:
            return getCurrentMonth();
        case TimePeriodType.LastMonth:
            return getLastMonth();
        case TimePeriodType.Custom:
            return getCustomTimePeriod();
        default:
            return getCurrentDay();
    }
}

export interface DateTimeInputValue {
    valid: boolean;
    dateValue: Date;
    textValue: string;
    empty?: boolean;
}

export function getDateTimeInputValue(value: Date): DateTimeInputValue {
    return {
        valid: true,
        dateValue: value,
        textValue: getFullDateTimeString(value),
        empty: false,
    };
}

export function getDateInputValue(value: Date): DateTimeInputValue {
    return {
        valid: true,
        dateValue: value,
        textValue: getFullDateString(value),
        empty: false,
    };
}

export interface TimePeriodInputValue {
    valid: boolean;
    type: TimePeriodType;
    start: DateTimeInputValue;
    end: DateTimeInputValue;
}

export function getTimePeriodInputValue(value: TimePeriod): TimePeriodInputValue {
    return {
        valid: true,
        type: value.type,
        start: getDateTimeInputValue(value.start),
        end: getDateTimeInputValue(value.end),
    };
}

export function getStartOfDay(value: Date): Date {
    return startOfDay(value);
}

export function getEndOfDay(value: Date): Date {
    return endOfDay(value);
}

export const secondsToTime = (secs: number): string => {
    secs = Math.round(secs);

    let hours = Math.floor(secs / (60 * 60));

    const dividerForMinutes = secs % (60 * 60);
    let minutes = Math.floor(dividerForMinutes / 60);

    const dividerForSeconds = dividerForMinutes % 60;
    let seconds = Math.ceil(dividerForSeconds);

    const hh = hours < 10 ? `0${hours}` : hours;
    const mm = minutes < 10 ? `0${minutes}` : minutes;
    const ss = seconds < 10 ? `0${seconds}` : seconds;

    return `${hh}:${mm}:${ss}`;
};
