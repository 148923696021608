import axios from 'axios';
import { checkAxiosError, getRootUrl } from 'utilities';
import { AuthData, SigninData } from './entities';

export const auth = async (authData: AuthData): Promise<SigninData> => {
    const response = await axios.post(`${getRootUrl()}api/v2/session/signin`, authData);

    checkAxiosError(response);

    let result = response.data;
    result.sessionId = response.headers['sessionid'];

    return result;
};

export const getConfig = async (): Promise<boolean> => {
    const response = await axios.get<boolean>(`${getRootUrl()}api/v2/haveconfig`);
    checkAxiosError(response);

    return response.data;
};
