import * as React from 'react';
import { default as MaterialListItem } from '@material-ui/core/ListItem';

export interface Props {
    /** Потомки компонента */
    children: React.ReactNode;
    /** Кликабельность, если true, то по элементу можно кликнуть */
    clickable?: boolean;
    /** Обработчик события клик, может быть добавлено только при clickable=true */
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function ListItem(props: Props): JSX.Element {
    const { clickable, onClick = () => undefined, ...rest } = props;

    if (Boolean(clickable)) {
        return <MaterialListItem button={true} onClick={onClick} {...rest} />;
    } else {
        return <MaterialListItem {...rest} />;
    }
}

ListItem.defaultProps = {
    clickable: false,
};
