import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { translate } from 'utilities';
import { Size } from '.';
import Theme from '../theme/fm-theme';

const createClasses = makeStyles((theme: Theme) => ({
    container: theme.custom?.emptyListPanel || {},
}));

export interface EmptyListPaneProps extends Partial<Size> {
    /**Сообщение для отображения (будет переведено) */
    translationKey?: string;
}

export const EmptyListPane: React.FC<EmptyListPaneProps> = (props) => {
    let { width, height, translationKey } = props;

    let classes = createClasses();

    let style: React.CSSProperties = {};
    if (width === undefined || height === undefined) {
        style.flex = '1 1 0';
    } else {
        style.width = width;
        style.height = height;
    }

    return (
        <div className={classes.container} style={style}>
            {translate(translationKey || 'NoData')}
        </div>
    );
};
