import React, { CSSProperties } from 'react';

interface SplitterArrowProps {
    style?: CSSProperties | undefined;
    className?: string | undefined;
}

export const SplitterArrow: React.FC<SplitterArrowProps> = (props: SplitterArrowProps) => {
    const { style, className } = props;

    return (
        <svg width="7" height="4" viewBox="0 0 7 4" style={{ ...style }} className={className}>
            <path opacity="0.75" d="M3.5 4L0.5 0L6.5 -2.38498e-08L3.5 4Z" />
        </svg>
    );
};
