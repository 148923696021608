import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    activeStyle: {
        ...theme.custom?.svgIcon.activeSwitcher,
        display: 'block',
    },
    disabledStyle: {
        ...theme.custom?.svgIcon.disabledSwitcher,
        display: 'block',
    },
}));

export interface SwitcherProps {
    /**Состояние переключателя */
    active: boolean;
}

/**Иконка переключателя */
export const MenuSwitcherIcon: React.FC<SwitcherProps> = (props) => {
    let { active } = props;
    let { activeStyle, disabledStyle } = createStyles();

    let svgStyle = active ? activeStyle : disabledStyle;
    let circleX = active ? 22 : 8;
    return (
        <svg
            className={svgStyle}
            width="34"
            height="23"
            viewBox="0 0 34 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="3" width="30" height="16" rx="8" />
            <circle cx={circleX} cy="11" r="7" />
        </svg>
    );
};

export const InputSwitcherIcon: React.FC<SwitcherProps> = (props) => {
    let { active } = props;
    let { activeStyle, disabledStyle } = createStyles();

    let svgStyle = active ? activeStyle : disabledStyle;
    let circleX = active ? 29 : 11;
    return (
        <svg
            className={svgStyle}
            width="43"
            height="29"
            viewBox="0 0 43 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="3" width="40" height="22" rx="11" />
            <circle cx={circleX} cy="14" r="10" />
        </svg>
    );
};
