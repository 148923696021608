import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { PathToImages } from '../../../constants';
import Theme from '../../../theme/login-theme';

const createStyles = makeStyles((theme: Theme) => ({
    logo: theme.custom?.logo || {},
}));

export const Logo: React.FC = () => {
    let classes = createStyles();

    return <div className={classes.logo} style={{ backgroundImage: `url("${PathToImages}logo_full.png")` }} />;
};
