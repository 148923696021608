import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../theme';

const createStyles = makeStyles((theme: Theme) => ({
    tabHeaderClassName: { ...theme.custom?.tabPane.tabHeader },
}));

export const TabHeader: React.FC<React.PropsWithChildren> = (props) => {
    let { children } = props;
    let { tabHeaderClassName } = createStyles();

    return <div className={tabHeaderClassName}>{children}</div>;
};
