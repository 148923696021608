import React from 'react';
import Popover from '@material-ui/core/Popover';

import { combineStyles } from 'theme';
import { FilterInput, Loading, SvgIcon, SvgIconType, Toolbar } from 'ui';
import { translate } from 'utilities';

import { DropdownItem, ItemsCountToShowFilter, useStyles } from './dropdown-select';

export interface DropdownMultiselectProps {
    /**Данные выпадающего списка */
    items?: DropdownItem[];
    /**Выбранные данные выпадающего списка */
    selectedItems?: DropdownItem[];
    /**Обработчик изменения выбранных данных */
    onSelectionChange?: (items: DropdownItem[]) => void;
    /**Заголовок*/
    title: string;
    /**Ширина */
    width?: number;
    /**Отображение лоудера в выпадающем списке */
    showLoading?: boolean;
}
/**Выпадающий список с возможность выбрать множество элементов. */
export const DropdownMultiselect: React.FC<DropdownMultiselectProps> = (props) => {
    let {
        items = [],
        selectedItems = [],
        onSelectionChange = () => undefined,
        title,
        width = 200,
        showLoading = false,
    } = props;
    let classes = useStyles();

    let inputRef = React.useRef<HTMLDivElement>(null);
    let [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
    let [filterValue, setFilterValue] = React.useState<string>('');
    let [itemsView, setItemsView] = React.useState<DropdownItem[]>([]);

    const openPopover = () => setPopoverOpen(true);

    const closePopover = () => setPopoverOpen(false);

    const onItemClick = (item: DropdownItem) => {
        let newSelectedItems: DropdownItem[];
        if (selectedItems.find((innerItem) => innerItem.key == item.key)) {
            newSelectedItems = selectedItems.filter((temp) => temp.key != item.key);
        } else {
            newSelectedItems = [...selectedItems, item];
        }
        onSelectionChange(newSelectedItems);
    };

    React.useEffect(() => {
        if (filterValue == '') {
            setItemsView([...items]);
            return;
        }

        let lowerCaseFilterValue = filterValue.toLowerCase();
        let newItemsView = items.filter((item) => item.value.toLowerCase().includes(lowerCaseFilterValue));
        setItemsView(newItemsView);
    }, [items, filterValue]);

    let inputClasses = classes.container;
    if (popoverOpen) {
        inputClasses = combineStyles(classes.container, classes.containerWithFocus);
    }
    let inputStyle: React.CSSProperties = { width };

    let inputIconType: SvgIconType = popoverOpen ? 'chevron-up' : 'chevron-down';

    let itemsContainerStyle: React.CSSProperties = {
        minWidth: width,
    };

    return (
        <>
            <div ref={inputRef} className={inputClasses} style={inputStyle} tabIndex={0} onClick={openPopover}>
                <div className={classes.inputText}>{title}</div>
                <SvgIcon type={inputIconType} />
            </div>
            <Popover
                anchorEl={inputRef.current}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                elevation={0}
                className={classes.muiPopover}
                open={popoverOpen}
                getContentAnchorEl={null}
                onClose={closePopover}
            >
                {showLoading && <Loading transparent width={width} height={width} />}
                {!showLoading && items.length == 0 && (
                    <div className={classes.item}>
                        <div className={classes.itemText}>{translate('NoData')}</div>
                        <SvgIcon type={'empty'} />
                    </div>
                )}
                {!showLoading && items.length > 0 && (
                    <div className={classes.popoverInnerContainer}>
                        {items.length > ItemsCountToShowFilter && (
                            <Toolbar>
                                <FilterInput fullWidth value={filterValue} onChange={setFilterValue} />
                            </Toolbar>
                        )}
                        <div className={classes.itemsContainer} style={itemsContainerStyle}>
                            {itemsView.map((item) => {
                                const onClick = () => onItemClick(item);

                                let selected =
                                    selectedItems.find((innerItem) => innerItem.key == item.key) != undefined;

                                let itemClasses = selected
                                    ? combineStyles(classes.item, classes.selectedItem)
                                    : classes.item;

                                return (
                                    <div key={item.key} className={itemClasses} onClick={onClick}>
                                        <div className={classes.itemText}>{item.value}</div>
                                        <SvgIcon type={selected ? 'accept' : 'empty'} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </Popover>
        </>
    );
};
