import React, { CSSProperties, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Theme from '../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    toolbarRoot: { ...theme.custom?.toolbar.root },
    toolbarAlterRoot: { ...theme.custom?.toolbar.notFixedHeightRoot },
}));

interface ToolbarProps {
    fixedHeight?: boolean;
    disabledMarginBottom?: boolean;
}
export const Toolbar: React.FC<PropsWithChildren<ToolbarProps>> = (props) => {
    let { children, fixedHeight = true, disabledMarginBottom = false } = props;
    let classes = createStyles();

    let rootClasses = fixedHeight ? classes.toolbarRoot : classes.toolbarAlterRoot;

    const styles: CSSProperties = {};
    if (disabledMarginBottom) {
        styles.marginBottom = 0;
    }
    return (
        <div className={rootClasses} style={styles}>
            {children}
        </div>
    );
};
