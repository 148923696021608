import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    rootStyles: { ...theme.custom?.detailsList.label },
}));

export interface DetailsTextValueProps {
    /**Устанавливает значение CSS свойства flex-grow */
    fullWidth?: boolean;
    /**Устанавливает CSS свойства flex  */
    flex?: string;
}
/**Отображение дочерних компонентов в строку с отступами. */
export const DetailsTextValue: React.FC<React.PropsWithChildren<DetailsTextValueProps>> = (props) => {
    let { fullWidth, flex } = props;
    let { rootStyles } = createStyles();

    let style: React.CSSProperties = { flex };
    if (fullWidth) {
        style.flexGrow = 1;
    }
    return (
        <div className={rootStyles} style={style}>
            {props.children}
        </div>
    );
};
