import * as React from 'react';
import Input from '@material-ui/core/Input';

import { FieldContainerProps, FieldContainer, FieldLayout } from './field-container';

const getValue = (textValue: string, precision: number): number => {
    if (textValue.length === 0) {
        return 0;
    }
    return Math.floor(Number(textValue) * Math.pow(10, precision)) / Math.pow(10, precision);
};

export interface NumberFieldProps {
    /**Значение поля ввода */
    value?: number;
    /**Обработчик на изменеие поля ввода */
    onChange?: (value: number) => void;
    /**Ширина компонента */
    width?: number;
    /**Ширина поля описания */
    labelWidth?: number;
    /**Текст поля описания */
    labelTextKey?: string;
    /**Ширина поля ввода */
    inputWidth?: number;
    /**Ширина поля описания справа */
    rightLabelWidth?: number;
    /**Текст поля описания справа*/
    rightLabelText?: string;
    /**Установка автофокуса */
    autoFocus?: boolean;
    /**Параметр отключает отступы */
    disablePadding?: boolean;
    /**Минимальное значение, проверяется при срабатывании Blur */
    minValue?: number;
    /**Максимальное значение, проверяется при срабатывании Blur */
    maxValue?: number;
    /**Количество цифр после запятой */
    fractionalDigitsCount?: number;
    /** Расположение составляющих поля, по умолчанию - горизонтальное */
    layout?: FieldLayout;
}
/**Поле для числовых значений */
export const NumberField: React.FC<NumberFieldProps> = (props) => {
    let {
        value = 0,
        onChange,
        width,
        labelWidth,
        labelTextKey,
        inputWidth,
        rightLabelWidth,
        rightLabelText,
        autoFocus,
        disablePadding,
        minValue,
        maxValue,
        fractionalDigitsCount = 0,
        layout,
    } = props;

    let [textValue, setTextValue] = React.useState('');
    let [updateValue, setUpdateValue] = React.useState(false);

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTextValue(event.target.value);
    }, []);

    const onBlurHandler = React.useCallback(() => {
        if (!onChange) {
            return;
        }

        let value: number = getValue(textValue, fractionalDigitsCount);

        if (typeof minValue == 'number' && value < minValue) {
            value = minValue;
        }

        if (typeof maxValue == 'number' && value > maxValue) {
            value = maxValue;
        }

        onChange(value);
        setUpdateValue(!updateValue);
    }, [textValue]);

    React.useEffect(() => {
        setTextValue(value.toString());
    }, [value, updateValue]);

    let fieldContainerProps: FieldContainerProps = {
        width,
        labelWidth,
        labelTextKey,
        inputWidth,
        disablePadding,
        rightLabelWidth,
        rightLabelText,
        layout,
    };

    return (
        <FieldContainer {...fieldContainerProps}>
            <Input
                disableUnderline
                fullWidth
                autoFocus={autoFocus}
                value={textValue}
                type="number"
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
            />
        </FieldContainer>
    );
};
