import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import { translate } from 'utilities';

export interface YesNoDialogProps {
    /**Параметр отвечает за отображение компонента */
    open: boolean;
    /**Заголовок */
    title: string;
    /**Описание */
    text: string;
    /**Текст на кнопке подтверждения */
    yesText?: string;
    /**Текст на кнопке отказа */
    noText?: string;
    /**Обработчик клика на кнопку подтверждения */
    yesAction: () => void;
    /**Обработчик клика на кнопку отказа */
    noAction: () => void;
}
/** @deprectated Use ModalQuestion instead. */
export const YesNoDialog: React.FC<YesNoDialogProps> = (props: YesNoDialogProps) => {
    let { open, title, text, yesText = translate('Yes'), noText = translate('No'), yesAction, noAction } = props;

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div style={{ minWidth: 300, minHeight: 100, whiteSpace: 'pre-line' }}>{text}</div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={noAction}>
                    {noText}
                </Button>
                <Button variant="contained" color="primary" onClick={yesAction}>
                    {yesText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
