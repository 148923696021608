import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { CheckboxIcon } from 'ui';
import { translate } from 'utilities';
import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    menuItem: { ...theme.custom?.popupMenu.item },
    activeIcon: { ...theme.custom?.svgIcon.colorPrimary },
    inactiveIcon: { ...theme.custom?.svgIcon.colorGrey },
}));

export interface CheckboxMenuItemProps {
    /**Параметр отвечате за состояние checkbox */
    active: boolean;
    /**label для  checkbox, задается ключ для перевода*/
    textKey: string;
    /**Коллбек функция при нажатии на checkbox*/
    onClick?: (value: boolean) => void;
}
/**Компонент Menu, содержащий checkbox  */
export const CheckboxMenuItem: React.FC<CheckboxMenuItemProps> = (props) => {
    let { active, textKey, onClick } = props;
    let classes = createStyles();

    return (
        <div className={classes.menuItem} onClick={() => onClick && onClick(!active)}>
            <CheckboxIcon checked={active} />
            {translate(textKey)}
        </div>
    );
};
