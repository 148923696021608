import React, { CSSProperties } from 'react';

interface SplitterDotsProps {
    style?: CSSProperties | undefined;
    className?: string | undefined;
}

export const SplitterDots: React.FC<SplitterDotsProps> = (props: SplitterDotsProps) => {
    const { style, className } = props;

    return (
        <svg width="13" height="3" viewBox="0 0 13 3" style={{ ...style }} className={className}>
            <circle cx="1.5" cy="1.5" r="1.5" />
            <circle cx="6.5" cy="1.5" r="1.5" />
            <circle cx="11.5" cy="1.5" r="1.5" />
        </svg>
    );
};
