import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { SvgIcon } from 'ui';
import { translate } from 'utilities';
import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    root: theme.custom?.dialog.title || {},
    text: theme.custom?.dialog.titleText || {},
    button: theme.custom?.dialog.titleCloseButton || {},
    svg: { ...theme.custom?.svgIcon.clickable },
}));

export interface DialogTitleProps {
    /**Заголовок */
    textKey: string;
    /**Обработчик закрытия окна */
    onClose: () => void;
}
/**Компонент для создания заголовка в окне */
export const DialogTitle: React.FC<React.PropsWithChildren<DialogTitleProps>> = (props) => {
    let { textKey, onClose } = props;
    let classes = createStyles();

    return (
        <div className={classes.root}>
            <div className={classes.text}>{translate(textKey)}</div>
            <div className={classes.button} onClick={onClose}>
                <SvgIcon type="dialog-close" className={classes.svg} />
            </div>
        </div>
    );
};
