import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    rootStyles: { ...theme.custom?.detailsList.label },
}));

export interface DetailsLabelProps {
    /**Ширина */
    width?: number;
    /**Флекс стили (Пример: '0 0 400px') */
    flex?: string;
}
/**Строка для описания значения. Принимает children. */
export const DetailsLabel: React.FC<React.PropsWithChildren<DetailsLabelProps>> = (props) => {
    let { width, flex } = props;
    let { rootStyles } = createStyles();

    let style: React.CSSProperties = { width, flex };
    return (
        <div className={rootStyles} style={style}>
            {props.children}
        </div>
    );
};
