import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    rootStyles: { ...theme.custom?.detailsList.root },
}));

export interface DetailsListProps {
    /**Задание CSS свойста Flex-grow */
    flexGrow?: number;
}
/**Список(обертка) для других компонентов со значениями */
export const DetailsList: React.FC<React.PropsWithChildren<DetailsListProps>> = (props) => {
    let { flexGrow } = props;
    let { rootStyles } = createStyles();

    let style: React.CSSProperties = {
        flexGrow,
    };
    return (
        <div className={rootStyles} style={style}>
            {props.children}
        </div>
    );
};
