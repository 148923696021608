import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

import { ReactTreeColorScheme } from './entities';
import { getTreeStyles } from './tree';

const createStyles = (colorScheme: ReactTreeColorScheme) =>
    makeStyles((theme: Theme) => {
        let treeSyles = getTreeStyles(theme, colorScheme);

        return {
            root: { ...treeSyles?.actionIcon },
        };
    });

interface ExpandIconProps {
    size?: number;
    colorScheme: ReactTreeColorScheme;
    expanded: boolean;
}
export const ExpandIcon: React.FC<ExpandIconProps> = (props) => {
    let { size = 16, colorScheme, expanded } = props;
    let { root } = createStyles(colorScheme)();

    return (
        <svg className={root} width={size} height={size} viewBox="0 0 16 16">
            <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" />
            {!expanded && (
                <path d="M8 3C7.44772 3 7 3.44772 7 4V7L4 7C3.44772 7 3 7.44771 3 8C3 8.55228 3.44772 9 4 9H7V12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12V9H12C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7L9 7V4C9 3.44772 8.55228 3 8 3Z" />
            )}
            {expanded && (
                <path d="M7 7L4 7C3.44772 7 3 7.44772 3 8C3 8.55229 3.44772 9 4 9H7C7.5 8.99995 8 8.99995 9 9H12C12.5523 9 13 8.55229 13 8C13 7.44772 12.5523 7 12 7L9 7C8.5 6.99995 7.5 6.99991 7 7Z" />
            )}
        </svg>
    );
};
