import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { DeleteButton, OutlinedButton, TextField } from 'ui';
import { translate } from 'utilities';

const useStyles = makeStyles((theme: Theme) => ({
    modalContainer: { ...theme.custom?.notifications.modalContainer },
    deleteQuestionContainer: { ...theme.custom?.deleteQuestion.root },
    header: { ...theme.custom?.deleteQuestion.header },
    mainText: { ...theme.custom?.deleteQuestion.mainText },
    deleteText: { ...theme.custom?.deleteQuestion.deleteText },
    inputContainer: { ...theme.custom?.deleteQuestion.inputContainer },
    toolbar: { ...theme.custom?.deleteQuestion.toolbar },
}));

export interface ModalDeleteQuestionProps {
    /**Заголовок */
    titleTextKey: string;
    /**Описание */
    mainTextKey: string;
    /**Обработчик нажатия кнопки "Удалить" */
    acceptAction?: () => void;
    /**Обрботчик нажтия кнопки "Отмена" */
    cancelAction?: () => void;
}
/**Модальный вопрос для удаление чего-либо. При написании 'delete' появляется кнопка "Удалить". */
export const ModalDeleteQuestion: React.FC<ModalDeleteQuestionProps> = (props) => {
    let { titleTextKey, mainTextKey, acceptAction, cancelAction } = props;
    let classes = useStyles();

    let [inputValue, setInputValue] = React.useState<string>('');
    let [showDeleteButton, setShowDeleteButton] = React.useState<boolean>(false);

    const onAcceptClick = React.useCallback(() => {
        if (acceptAction) {
            acceptAction();
        }
    }, []);

    const onCancelClick = React.useCallback(() => {
        if (cancelAction) {
            cancelAction();
        }
    }, []);

    React.useEffect(() => {
        if (inputValue.toLowerCase() == 'delete') {
            setShowDeleteButton(true);
        } else {
            setShowDeleteButton(false);
        }
    }, [inputValue]);

    return (
        <div className={classes.modalContainer}>
            <div className={classes.deleteQuestionContainer}>
                <div className={classes.header}>{translate(titleTextKey)}</div>
                <div className={classes.mainText}>{translate(mainTextKey)}</div>
                <div className={classes.deleteText}>{translate('PrintDeleteToDelete')}</div>
                <div className={classes.inputContainer}>
                    <TextField autoFocus inputWidth={200} value={inputValue} onChange={setInputValue} />
                </div>
                <div className={classes.toolbar}>
                    <OutlinedButton frontIconType="close" textKey="Cancel" onClick={onCancelClick} />
                    {showDeleteButton && <DeleteButton onClick={onAcceptClick} />}
                </div>
            </div>
        </div>
    );
};
