import * as React from 'react';
import { toast as reactToast } from 'react-toastify';
import { makeStyles, useTheme } from '@material-ui/core';

import { Toast, setToast } from 'utilities';
import Theme from '../../theme/fm-theme';

const createInfoClasses = makeStyles((theme: Theme) => ({
    className: theme.custom?.reactToast?.info?.container || {},
    bodyClassName: theme.custom?.reactToast?.info?.body || {},
    progressClassName: theme.custom?.reactToast?.info?.progress || {},
}));

const createErrorClasses = makeStyles((theme: Theme) => ({
    className: theme.custom?.reactToast?.error?.container || {},
    bodyClassName: theme.custom?.reactToast?.error?.body || {},
    progressClassName: theme.custom?.reactToast?.error?.progress || {},
}));

const createSuccessClasses = makeStyles((theme: Theme) => ({
    className: theme.custom?.reactToast?.success?.container || {},
    bodyClassName: theme.custom?.reactToast?.success?.body || {},
    progressClassName: theme.custom?.reactToast?.success?.progress || {},
}));

export const ToastProvider: React.FC = () => {
    let info = createInfoClasses();
    let error = createErrorClasses();
    let success = createSuccessClasses();
    let theme = useTheme();

    React.useEffect(() => {
        let newToast = function (text: string): void {
            reactToast(text);
        } as Toast;
        newToast.success = (text) => reactToast(text, success);
        newToast.error = (text) => reactToast(text, error);
        newToast.info = (text) => reactToast(text, info);
        setToast(newToast);
    }, [theme]);

    return null;
};
