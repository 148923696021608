import { ReactNode } from 'react';
import {
    AnyColumn,
    instanceOfTableColumn,
    TableColumn,
    TableColumnDataType,
    TableColumnWithChildren,
    TableGridProps,
    ToolbarProps as ExternalToolbarProps,
} from '.';

export interface TextTag {
    key: number,
    colorNumber: number,
    text: string,
}

export interface ToolbarProps<T> extends ExternalToolbarProps<T> {
    /**Хеш таблица из кастомных фильтров */
    customFiltersData: Record<string, boolean>;
    /**Параметр, отвечающих за загрузку данных для экспорта*/
    excelLoading: boolean;
    /**Коллбек функция при нажатии на кастомный фильтр */
    updateCustomFiltersData: (filterId: string) => void;
    /**Коллбек функция при нажатии на кастомный фильтр */
    onExportClick: () => void;
    /**Коллбек функция при изменении поля поиска, фильтров */
    onToolbarPropsChange: (props: ExternalToolbarProps<T>) => void;
    alternativeToolbar?: ReactNode;
    tags?: TextTag[];
    onRemoveTag?: (key: number) => void;
}

export interface FilterColumn<T> {
    key: keyof T;
    active: boolean;
    label: string;
}

export interface TableProps<T> extends TableGridProps<T> {
    /**Колонна таблицы по который будут браться ID для строк */
    idKey: keyof T;
    /**Записи таблицы */
    records: T[];
    /**Колонны таблицы */
    columns: AnyColumn<T>[];
    /**Параметр, отвечающий за отображение границы таблицы */
    showBorder: boolean;
    /**Параметр, отвечающий за отображение дополнительной информации в Footer'e таблицы */
    needAppendFooterItems: boolean;
    /**Параметр, отвечающий за отображение суммы элементов в числовой колонне */
    showTotalRow: boolean;
    /**Коллбек функция вызывается при изменении состояния сортировки записей */
    updateRecordsForExport?: (records: T[]) => void;
}

interface GetRowWidthProps<T> {
    columns: AnyColumn<T>[];
    width: number;
    hasScroll: boolean;
    scrollWidth: number;
    iconCellWidth: number;
}

export const getRowWidth = <T>(props: GetRowWidthProps<T>): number => {
    let availableWidth = props.hasScroll ? props.width - props.scrollWidth : props.width;

    let columnsToDisplay: TableColumn<T>[] = [];
    props.columns.forEach((column) => fillColumnsToDisplay(column, columnsToDisplay));

    let minRequiredWidth = columnsToDisplay.reduce<number>((accumulator, value) => {
        if (value.valueType == TableColumnDataType.Icon) {
            return accumulator + props.iconCellWidth;
        }
        return value.visible ? accumulator + value.minWidth : accumulator;
    }, 0);

    return Math.max(availableWidth, minRequiredWidth);
};

export const fillColumnsToDisplay = <T>(column: AnyColumn<T>, result: TableColumn<T>[]): void => {
    if (column instanceof TableColumnWithChildren) {
        column.children.forEach((child) => fillColumnsToDisplay(child, result));
    }

    if (instanceOfTableColumn(column)) {
        result.push(column);
    }
};

export const getMultilineHeaderCellWidth = <T>(column: TableColumnWithChildren<T>): number => {
    let columnsToDisplay: TableColumn<T>[] = [];
    fillColumnsToDisplay(column, columnsToDisplay);
    return columnsToDisplay.reduce<number>((acc, column) => acc + column.minWidth, 0);
};

export const getCellStyle = <T>(column: AnyColumn<T>, iconCellWidth: number): React.CSSProperties => {
    let result: React.CSSProperties = {};

    if (instanceOfTableColumn(column)) {
        let baseWidth = column.minWidth;
        if (column.valueType == TableColumnDataType.Icon) {
            baseWidth = iconCellWidth;
        }
        result.flex = `${column.widthScalable ? '1 0' : '0 0'} ${baseWidth}px`;
    }

    return result;
};
