import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Theme } from 'theme';

const createClasses = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.custom?.spacing(1.5),
        borderRadius: theme.custom?.mainSizes.common.mediumBorderRadius,
        backgroundColor: theme.custom?.palette.background.main,
    },
}));

interface MainBlockProps {
    children: ReactNode;
    className?: string;
}
export function MainBlock({ children, className = '' }: MainBlockProps) {
    const classes = createClasses();
    return <div className={classNames(classes.container, className)}>{children}</div>;
}
