import React from 'react';

import { TimePeriodType } from 'entities';
import { DropdownList, DropdownListItem, FieldContainer } from 'ui';
import { translate } from 'utilities';

export interface TimePeriodListFieldProps {
    /**Значение выпадающего списка */
    value: TimePeriodType;
    /**Скрыть варианты с часами */
    hideHours?: boolean;
    /**Скрыть варианты с днями */
    hideDays?: boolean;
    /**Скрыть варианты с месяцами */
    hideMonths?: boolean;
    /**Скрыть кастомный период */
    hideCustom?: boolean;
    /**Обработчик изменения значения */
    onChange?: (value: TimePeriodType) => void;
}
/**Поле с выпадающим списком для выбора периода */
export const TimePeriodListField: React.FC<TimePeriodListFieldProps> = (props) => {
    let { value, hideHours, hideDays, hideMonths, hideCustom, onChange } = props;

    const onPeriodChange = (periodValue: DropdownListItem) => {
        let periodType = periodValue.id as TimePeriodType;

        if (onChange) {
            onChange(periodType);
        }
    };

    let items: DropdownListItem[] = [];
    if (!hideHours) {
        items.push(
            { id: TimePeriodType.Last3Hours, name: translate(TimePeriodType.Last3Hours) },
            { id: TimePeriodType.Last24Hours, name: translate(TimePeriodType.Last24Hours) },
        );
    }
    if (!hideDays) {
        items.push(
            { id: TimePeriodType.CurrentDay, name: translate(TimePeriodType.CurrentDay) },
            { id: TimePeriodType.LastDay, name: translate(TimePeriodType.LastDay) },
        );
    }
    items.push(
        { id: TimePeriodType.CurrentWeek, name: translate(TimePeriodType.CurrentWeek) },
        { id: TimePeriodType.LastWeek, name: translate(TimePeriodType.LastWeek) },
    );
    if (!hideMonths) {
        items.push(
            { id: TimePeriodType.CurrentMonth, name: translate(TimePeriodType.CurrentMonth) },
            { id: TimePeriodType.LastMonth, name: translate(TimePeriodType.LastMonth) },
        );
    }
    if (!hideCustom) {
        items.push({ id: TimePeriodType.Custom, name: translate('CustomPeriodName') });
    }
    return (
        <FieldContainer labelTextKey="Period" labelWidth={300} inputWidth={300}>
            <DropdownList hideFilter noMarginItems selectedId={value} items={items} onChange={onPeriodChange} />
        </FieldContainer>
    );
};
