import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Container } from './container';
import Theme from '../../theme/fm-theme';
import { ContainerWithLoader } from '../loading/container-with-loader';

const createStyles = makeStyles((theme: Theme) => ({
    root: theme.custom?.dialog.root || {},
}));

export interface DialogProps {
    /**Параметр отвечает за отображение окна */
    open?: boolean;
    /**Параметр отвечает за отступ слева в окне */
    left?: number;
    /**Обработчик клика при нажатии на фон окна */
    onClose?: () => void;
    /**Параметр отвеает за прозрачность фона */
    transparentContainer?: boolean;
    /**Параметр отвечает за отображение лоадера */
    showLoader?: boolean;
}
/**Модальное окно */
export const Dialog: React.FC<React.PropsWithChildren<DialogProps>> = (props) => {
    let { children, open = true, left, onClose, transparentContainer, showLoader = false } = props;
    let classes = createStyles();

    const stopPropagation = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    }, []);

    const container = (content: React.ReactNode) => (
        <Container transparent={transparentContainer} left={left} onClick={onClose}>
            <div className={classes.root} onClick={stopPropagation}>
                {content}
            </div>
        </Container>
    );

    if (!open) {
        return null;
    }

    return (
        <ContainerWithLoader container={container} open={showLoader}>
            {children}
        </ContainerWithLoader>
    );
};
