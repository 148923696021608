import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Theme, combineStyles } from 'theme';

const createStyles = makeStyles((theme: Theme) => ({
    dialogContent: theme.custom?.dialog.content || {},
    noPadding: { ...theme.custom?.generalStyles.noPadding },
}));

export interface DialogContentProps {
    /**Параметр отключает внутренние отступы элемента */
    disablePadding?: boolean;
}
/**Контейнер для содержимого окна */
export const DialogContent: React.FC<PropsWithChildren<DialogContentProps>> = (props) => {
    let { children, disablePadding } = props;
    let classes = createStyles();

    let rootClasses = classes.dialogContent;
    if (disablePadding === true) {
        rootClasses = combineStyles(rootClasses, classes.noPadding);
    }
    return <div className={rootClasses}>{children}</div>;
};
