import * as React from 'react';

interface FlexFillerProps {
    flexGrow?: number;
    flexShrink?: number;
    flexBasisInPx?: number;
}
export const FlexFiller: React.FC<FlexFillerProps> = (props) => {
    let { flexGrow = 1, flexShrink = 0, flexBasisInPx = 0 } = props;

    let style: React.CSSProperties = {
        flexGrow,
        flexShrink,
        flexBasis: `${flexBasisInPx}px`,
    };
    return <div style={style} />;
};
