import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
    divider: { ...theme.custom?.flexDivider },
}));

export const FlexDivider: React.FC = () => {
    let classes = useStyles();

    return <div className={classes.divider} />;
};
