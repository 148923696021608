import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { TreeData, TreeState, TreeNode, getParentCompanyId } from 'entities';
import { SvgIconType, SvgIcon, Menu, Tree } from 'ui';
import { translate } from 'utilities';
import { Theme, combineStyles } from '../../theme';

import FieldContainer from './field-container';

const createStyles = makeStyles((theme: Theme) => ({
    inputClassName: { ...theme.custom?.dropdownTree.input },
    focusedInputClassName: { ...theme.custom?.dropdownTree.focusedInput },
    errorInputClassName: { ...theme.custom?.dropdownTree.errorInput },
    textContainerClassName: { ...theme.custom?.dropdownTree.textContainer },
    treePaneClassName: { ...theme.custom?.dropdownTree.treePane },
}));

export interface DropdownTreeProps {
    /**Ширина поля описания */
    labelWidth: number;
    /**Текст поля описания */
    labelTextKey: string;
    /**Ширина инпута */
    inputWidth: number;
    /**Данные для выпадающего дерева */
    data: TreeData;
    /**Состояние дерева */
    state?: TreeState;
    /**Параметр отвечает за запрет возможности выбора компании */
    forbidSelectCompany?: boolean;
    /**Обработчик при изменении выбранного варианта */
    onChange?: (item: TreeNode, parentCompanyId: number) => void;
}

/**Поле для выбора объекта из дерева */
export const DropdownTree: React.FC<DropdownTreeProps> = (props) => {
    let { labelWidth, labelTextKey, inputWidth, data, state, forbidSelectCompany, onChange } = props;
    let { inputClassName, focusedInputClassName, errorInputClassName, textContainerClassName, treePaneClassName } =
        createStyles();
    let [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    let inputRef = React.useRef<HTMLDivElement>(null);
    let [text, setText] = React.useState<string>('');
    let [selectedKey, setSelectedKey] = React.useState<string>('');
    let [errorText, setErrorText] = React.useState<string>('');

    const openMenu = () => setMenuOpen(true);

    const closeMenu = () => setMenuOpen(false);

    const onTreeSelect = (key: string, afterSearch: boolean) => {
        let selectedNode = data.nodes[key];
        if (selectedNode) {
            setText(selectedNode.text);
            setSelectedKey(selectedNode.key);
            if (!afterSearch) {
                closeMenu();
            }
            if (onChange) {
                let parentCompanyId = getParentCompanyId(data, selectedNode.key);
                onChange(selectedNode, parentCompanyId);
            }
        }
    };

    const checkValue = () => {
        if (!menuOpen && text.length == 0) {
            setErrorText(translate('FieldCanNotBeEmpty'));
        } else {
            setErrorText('');
        }
    };

    React.useEffect(() => {
        let selectedNode = data.nodes[`${state?.selectedKey}`];
        if (selectedNode) {
            setText(selectedNode.text);
            setSelectedKey(selectedNode.key);
        }
    }, [data, state]);

    let containerProps = {
        labelWidth,
        labelTextKey,
        inputWidth,
        errorText,
    };

    let inputClasses = inputClassName;
    if (menuOpen) {
        inputClasses = combineStyles(inputClasses, focusedInputClassName);
    }
    if (errorText.length > 0) {
        inputClasses = combineStyles(inputClasses, errorInputClassName);
    }
    let iconType: SvgIconType = menuOpen ? 'chevron-up' : 'chevron-down';
    return (
        <FieldContainer {...containerProps}>
            <div tabIndex={0} ref={inputRef} className={inputClasses} onClick={openMenu} onBlur={checkValue}>
                <div className={textContainerClassName}>{text || translate('SelectGroup')}</div>
                <SvgIcon type={iconType} />
            </div>
            {menuOpen && (
                <Menu
                    anchor={inputRef.current}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                    transformOrigin={{
                        horizontal: 'left',
                        vertical: 'top',
                    }}
                    onClose={closeMenu}
                >
                    <div className={treePaneClassName}>
                        <Tree
                            allowExpand
                            allowSelect
                            forbidSelectCompany={forbidSelectCompany}
                            data={data}
                            state={state}
                            selectedKey={selectedKey}
                            onSelect={onTreeSelect}
                        />
                    </div>
                </Menu>
            )}
        </FieldContainer>
    );
};
