import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { ContainedButton } from 'ui';
import { toast, translate } from 'utilities';

import { ListItemData, ListPane, insertItemInOrder } from './list';

const initialCheckedIds: string[] = [];

const useStyles = makeStyles((theme: Theme) => ({
    twoPaneSelectRoot: { ...theme.custom?.twoPaneSelect.growingContainer },
    pane: { ...theme.custom?.twoPaneSelect.treePane },
    paneHeader: { ...theme.custom?.twoPaneSelect.treePaneHeader },
    panesToolbar: { ...theme.custom?.twoPaneSelect.panesToolbar },
}));

export interface TwoPaneListSelectProps {
    /**Данные для отображения */
    items: ListItemData[];
    /**Заголовок левой панели */
    sourceHeaderKey: string;
    /**Заголовок правой панели */
    targetHeaderKey: string;
    /**При попытке перенести из панели в панель, если данные не выбраны, то будет уведомление с текстом из этого параметра */
    noSelectKey: string;
    /**Обработчик переноса данных */
    onSelect: (selectedIds: string[]) => void;
    /**Выбраные данные */
    selectedItems?: string[];
}
/**Компонент для выбора данных ввиде списка */
export const TwoPaneListSelect: React.FC<TwoPaneListSelectProps> = (props) => {
    let { items: allItems, sourceHeaderKey, targetHeaderKey, noSelectKey, onSelect, selectedItems = [] } = props;
    let classes = useStyles();

    let [sourceListItems, setSourceListItems] = React.useState<ListItemData[]>([]);
    let [sourceCheckedIds, setSourceCheckedIds] = React.useState<string[]>([]);
    let [targetListItems, setTargetListItems] = React.useState<ListItemData[]>([]);
    let [targetCheckedIds, setTargetCheckedIds] = React.useState<string[]>([]);

    const updateSourceCheckedIds = (ids: string[]) => setSourceCheckedIds(ids);
    const updateTargetCheckedIds = (ids: string[]) => setTargetCheckedIds(ids);

    const onSelectItems = () => {
        if (sourceCheckedIds.length == 0) {
            toast.info(translate(noSelectKey));
            return;
        }

        let newSourceItems: ListItemData[] = [];
        let newTargetItems: ListItemData[] = [...targetListItems];
        for (let item of sourceListItems) {
            if (sourceCheckedIds.includes(item.id)) {
                insertItemInOrder(newTargetItems, item);
            } else {
                newSourceItems.push(item);
            }
        }

        setSourceListItems(newSourceItems);
        setSourceCheckedIds([]);
        setTargetListItems(newTargetItems);
        setTargetCheckedIds([]);

        onSelect(newTargetItems.map((item) => item.id));
    };

    const onDeselectItems = () => {
        if (targetCheckedIds.length == 0) {
            toast.info(translate(noSelectKey));
            return;
        }

        let newSourceItems: ListItemData[] = [...sourceListItems];
        let newTargetItems: ListItemData[] = [];
        for (let item of targetListItems) {
            if (targetCheckedIds.includes(item.id)) {
                insertItemInOrder(newSourceItems, item);
            } else {
                newTargetItems.push(item);
            }
        }

        setSourceListItems(newSourceItems);
        setSourceCheckedIds([]);
        setTargetListItems(newTargetItems);
        setTargetCheckedIds([]);

        onSelect(newTargetItems.map((item) => item.id));
    };

    React.useEffect(() => {
        let newSourceItems: ListItemData[] = [];
        let newTargetItems: ListItemData[] = [];

        for (let item of allItems) {
            if (selectedItems.includes(item.id)) {
                insertItemInOrder(newTargetItems, item);
            } else {
                newSourceItems.push(item);
            }
        }

        setSourceListItems(newSourceItems);
        setTargetListItems(newTargetItems);
    }, [allItems]);

    return (
        <div className={classes.twoPaneSelectRoot}>
            <div className={classes.pane}>
                <div className={classes.paneHeader}>{translate(sourceHeaderKey)}</div>
                <ListPane
                    items={sourceListItems}
                    checkedIds={initialCheckedIds}
                    onCheckedChange={updateSourceCheckedIds}
                />
            </div>
            <div className={classes.panesToolbar}>
                <ContainedButton noMargin frontIconType="chevron-right" onClick={onSelectItems} />
                <ContainedButton frontIconType="chevron-left" onClick={onDeselectItems} />
            </div>
            <div className={classes.pane}>
                <div className={classes.paneHeader}>{translate(targetHeaderKey)}</div>
                <ListPane
                    items={targetListItems}
                    checkedIds={initialCheckedIds}
                    onCheckedChange={updateTargetCheckedIds}
                />
            </div>
        </div>
    );
};
