import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    root: theme.custom?.dialog.container || {},
    inner: { ...theme.custom?.dialog.innerContainer },
}));

export interface ContainerProps {
    /**Отступ слева для Children */
    left?: number;
    /**Обработчик клика по контейнеру */
    onClick?: () => void;
    /**Устанавливает прозрачность фона */
    transparent?: boolean;
}
/**Контейнер для диалогового окна с затемнением фона */
export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = (props) => {
    let { children, left = 0, onClick, transparent = false } = props;
    let classes = createStyles();

    let style: React.CSSProperties = {};
    if (transparent) {
        style.backgroundColor = 'transparent';
    }
    let flex = `0 0 calc(100% - ${left}px)`;
    return (
        <div className={classes.root} style={style} onClick={onClick}>
            <div className={classes.inner} style={{ flex, overflow: 'hidden' }}>
                {children}
            </div>
        </div>
    );
};
