import * as React from 'react';
import { default as MaterialList } from '@material-ui/core/List';

export interface Props {
    /** Потомки компонента */
    children: React.ReactNode;
}

export function List(props: Props): JSX.Element {
    return <MaterialList {...props} />;
}
