import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';

import { FooterItem } from '.';

const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        ...theme.custom?.typography.text,
        boxSizing: 'border-box',
        flex: `0 0 ${theme.custom?.mainSizes.table.footerHeight}px`,
        borderTop: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
        padding: theme.custom?.spacing(1),
        paddingLeft: theme.custom?.spacing(2),
        color: theme.custom?.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        '&>span': {
            ...theme.custom?.typography.h5,
            marginLeft: theme.custom?.spacing(0.5),
            marginRight: theme.custom?.spacing(2),
            padding: 0,
            color: 'inherit',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
}));

export interface FooterProps {
    /**Данные для отображения */
    items: FooterItem[];
}
/**Компонент для отображения дополнительной информации под таблицей */
export const Footer = (props: FooterProps): JSX.Element => {
    let classes = useStyles();

    return (
        <div className={classes.footer}>
            {props.items.map((item, index) => (
                <React.Fragment key={index}>
                    {item.label}:<span>{item.value}</span>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Footer;
