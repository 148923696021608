import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { ContainedButton, FilterInput, OutlinedButton } from 'ui';

import { TextTag, ToolbarProps } from './entities';
import FilterSettings from './filter-settings';
import { TextTagWithClose, TextTag as Tag } from '../tag';

const useStyles = makeStyles((theme: Theme) => ({
    toolbar: {
        borderRadius: theme.custom?.mainSizes.common.mediumBorderRadius,
        padding: theme.custom?.spacing(1.5),
        backgroundColor: theme.custom?.palette.background.toolbar,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        gap: theme.custom?.spacing(1),
    },
    toolbarContainerPane: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.custom?.spacing(1),
    },
    toolbarBlockPane: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    toolbarFilterPane: {
        display: 'flex',
        gap: theme.custom?.spacing(1),
    },
    toolbarButtonsPane: {
        display: 'flex',
        gap: theme.custom?.spacing(1),
    },
    tagsContainer: {
        display: 'flex',
        gap: theme.custom?.spacing(1),
        flexWrap: 'wrap',
        cursor: 'default',
    },
}));

/**Компонент toolbar с фильтрацией, кнопкой экспорта и добавить */
export const Toolbar = <T,>(props: ToolbarProps<T>): JSX.Element => {
    let classes = useStyles();

    let onFilterTextChange = (text: string) => {
        props.onToolbarPropsChange({ ...props, filterText: text });
    };
    const [showAlternative, setShowAlternative] = useState(false);

    const onRemoveTag = (tag: TextTag) => {
        if (props.onRemoveTag) {
            props.onRemoveTag(tag.key);
        }

        if (tag.key > 1) {
            setShowAlternative(true);
        }
    };

    return (
        <div className={classes.toolbar}>
            <div className={classes.toolbarContainerPane}>
                <div className={classes.toolbarBlockPane}>
                    {props.alternativeToolbar && (
                        <OutlinedButton
                            frontIconType="filter-multiple-outline"
                            onClick={() => setShowAlternative(!showAlternative)}
                        />
                    )}
                    {showAlternative ? (
                        props.alternativeToolbar
                    ) : (
                        <div className={classes.toolbarFilterPane}>
                            <FilterInput value={props.filterText} onChange={onFilterTextChange} />
                            <FilterSettings {...props} />
                        </div>
                    )}
                </div>
                <div className={classes.toolbarButtonsPane}>
                    {props.showCreateButton && (
                        <ContainedButton noMargin frontIconType="plus" textKey="Add" onClick={props.onCreateClick} />
                    )}

                    {props.customButtons}

                    {props.showExportButton && (
                        <OutlinedButton
                            noMargin
                            frontIconType="excel"
                            showLoadingIcon={props.excelLoading}
                            textKey="Export"
                            onClick={props.onExportClick}
                        />
                    )}
                </div>
            </div>
            {props.tags !== undefined && props.tags?.length !== 0 && (
                <div className={classes.tagsContainer}>
                    {props.tags?.map((tag) => {
                        let onRemove = () => {
                            onRemoveTag(tag);
                        };
                        if (tag.key !== 5) {
                            return (
                                <TextTagWithClose
                                    key={tag.key}
                                    colorNumber={tag.colorNumber}
                                    text={tag.text}
                                    onClose={onRemove}
                                />
                            );
                        } else {
                            return <Tag key={tag.key} colorNumber={tag.colorNumber} text={tag.text} />;
                        }
                    })}
                </div>
            )}
        </div>
    );
};

export default React.memo(Toolbar) as typeof Toolbar;
