import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from '../../theme';

const createStyles = makeStyles((theme: Theme) => ({
    tabBodyClassName: { ...theme.custom?.tabPane.tabBody },
}));

export const TabBody: React.FC<React.PropsWithChildren> = (props) => {
    let { children } = props;
    let { tabBodyClassName } = createStyles();

    return <div className={tabBodyClassName}>{children}</div>;
};
