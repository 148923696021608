import * as React from 'react';
import { translate } from 'utilities';
import { FieldContainerProps, FieldContainer } from './field-container';
import DateTimeControlledSelect from '../inputs/date-time-controlled';
import { DateTimeInputValue, getDateTimeInputValue } from 'entities';

export interface DateFieldProps {
    /**Состояние  поля. Правильность, строка с временем и объект Date.*/
    value?: DateTimeInputValue;
    /**Обработчик изменения состояния  */
    onChange?: (value: DateTimeInputValue) => void;
    /**Не используется */
    width?: number;
    /**Ширина описания */
    labelWidth?: number;
    /**Текст описания */
    labelTextKey?: string;
    /**Ширина инпута */
    inputWidth?: number;
    /**Не используется */
    disablePadding?: boolean;
    /**Сторона начала анимации календаря */
    popoverAlign?: 'left' | 'right';
    /**Сообщение ошибки */
    externalErrorText?: string;
}
/**Поле для выбора даты и времени */
export const DateField: React.FC<DateFieldProps> = (props) => {
    let {
        value = getDateTimeInputValue(new Date()),
        onChange,
        labelWidth,
        labelTextKey,
        inputWidth,
        popoverAlign,
        externalErrorText = '',
    } = props;

    let [errorText, setErrorText] = React.useState<string>('');

    const onChangeHandler = React.useCallback(
        (value: DateTimeInputValue) => {
            if (!onChange) {
                return;
            }

            onChange(value);
        },
        [value],
    );

    React.useEffect(() => {
        if (!value.valid) {
            setErrorText(translate('InvalidDataValue'));
        } else if (value.valid && externalErrorText?.length !== 0) {
            setErrorText(translate(`${externalErrorText}`));
        } else {
            setErrorText(translate(''));
        }
    }, [value, externalErrorText]);

    let containerProps: FieldContainerProps = {
        labelTextKey,
        labelWidth,
        inputWidth,
        errorText,
    };
    return (
        <FieldContainer {...containerProps}>
            <DateTimeControlledSelect
                value={value}
                onChange={onChangeHandler}
                width={inputWidth}
                popoverAlign={popoverAlign}
                externalValid={!externalErrorText}
            />
        </FieldContainer>
    );
};
