import { Theme } from '@material-ui/core/styles';

export interface ThemesStateSlice {
    themes: ThemesState;
}

export const DefaultTheme = 'default';

export interface ThemesState {
    themes: ThemesType;
    currentTheme: string;
}

export interface ThemesType {
    [theme: string]: LoginTheme;
}

export interface LoginTheme {
    themeName: string;
    value: Theme;
}
