import React from 'react';

import { EntityType, GeozoneType } from 'entities';
import { SvgIconType, SvgIcon } from 'ui';
import { TreeIconSize } from '../../constants';

interface TypeIconProps {
    type: EntityType;
    geozoneType?: GeozoneType;
}
export const TypeIcon: React.FC<TypeIconProps> = (props) => {
    let { type, geozoneType } = props;

    let iconType: SvgIconType;
    switch (type) {
        case EntityType.Company:
            iconType = 'company';
            break;
        case EntityType.Group:
        case EntityType.GeoGroup:
            iconType = 'group';
            break;
        case EntityType.Device:
            iconType = 'device';
            break;
        case EntityType.Retranslation:
            iconType = 'retranslations';
            break;
        case EntityType.Driver:
            iconType = 'user';
            break;
        case EntityType.Event:
            iconType = 'error';
            break;
        case EntityType.Account:
            iconType = 'accounts';
            break;
        case EntityType.AccountGroup:
            iconType = 'account-groups';
            break;
        default:
            iconType = 'empty';
            break;
    }
    if (typeof geozoneType === 'number') {
        switch (geozoneType) {
            case GeozoneType.Point:
                iconType = 'geozone-point';
                break;
            case GeozoneType.Line:
                iconType = 'geozone-line';
                break;
            case GeozoneType.Polygon:
                iconType = 'geozone-polygon';
                break;
            default:
                iconType = 'bug';
                break;
        }
    }
    return <SvgIcon type={iconType} size={TreeIconSize} />;
};
