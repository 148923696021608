import * as React from 'react';
import Input from '@material-ui/core/Input';
import { FieldContainerProps, FieldContainer } from 'ui';

export interface RfidFieldProps {
    value: string;
    onChange: (value: string) => void;
    width?: number;
    labelWidth?: number;
    labelTextKey?: string;
    inputWidth?: number;
    rightLabelWidth?: number;
    rightLabelText?: string;
    autoFocus?: boolean;
    disablePadding?: boolean;
    warningText?: string;
    maxLength?: number;
}

export const RfidField: React.FC<RfidFieldProps> = (props) => {
    let {
        value,
        onChange,
        width,
        labelWidth,
        labelTextKey,
        inputWidth,
        rightLabelWidth,
        rightLabelText,
        autoFocus,
        disablePadding,
        warningText,
        maxLength,
    } = props;

    const [textValue, setTextValue] = React.useState<string>('');

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/\D/g, '');

        if (typeof maxLength === 'number' && value.length > maxLength) {
            return;
        }

        setTextValue(value);
        onChange(value === '' ? '0' : value);
    }, []);

    React.useEffect(() => {
        setTextValue(value);
    }, [value]);

    let fieldContainerProps: FieldContainerProps = {
        width,
        labelWidth,
        labelTextKey,
        inputWidth,
        disablePadding,
        rightLabelWidth,
        rightLabelText,
        warningText,
    };

    return (
        <FieldContainer {...fieldContainerProps}>
            <Input disableUnderline fullWidth autoFocus={autoFocus} value={textValue} onChange={onChangeHandler} />
        </FieldContainer>
    );
};
