import * as React from 'react';
import { default as CheckboxMUI } from '@material-ui/core/Checkbox';
import { Icon } from 'ui';

export interface Props {
    /** Поставлена/убрана галочка */
    checked: boolean;
    /** Обработчик клика */
    onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
    /** Уменьшенная иконка */
    iconMini?: boolean;
    /** Сделать элемент активным/не активным */
    disabled?: boolean;
}

/** @deprecated Use CheckboxIcon instead. */
export function CheckBox(props: Props): JSX.Element {
    const ICON_MINI = 20;
    const ICON_NORMAL = 24;
    let size = ICON_NORMAL;
    if (Boolean(props.iconMini)) {
        size = ICON_MINI;
    }

    return (
        <CheckboxMUI
            checkedIcon={<Icon type="checked" color="primary" size={size} />}
            icon={<Icon type="unchecked" color="primary" size={size} />}
            checked={props.checked}
            onClick={props.onClick}
            disabled={props.disabled}
        />
    );
}

CheckBox.defaultProps = {
    checked: true,
    iconMini: true,
    disabled: false,
};
