import React from 'react';
import Calendar, { OnChangeDateCallback } from 'react-calendar';
import { makeStyles } from '@material-ui/styles';

import { TimePeriod } from 'entities';
import { DetailsHeader, FieldContainer, TimeInput } from 'ui';
import { copyDate, copyTime, translate } from 'utilities';
import { Theme } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
    dateTimePaneRoot: { ...theme.custom?.dateTimePane.root },
    calendarContainer: { ...theme.custom?.dateTimeField.calendarContainer },
}));

export interface TimePeriodPaneProps {
    /**Состояние периода(тип периода, начало периода, конец периода) */
    period: TimePeriod;
    /**Обработчик изменения периода */
    onChange: (value: TimePeriod) => void;
}
/**Компонент для выбора даты и времени периода */
export const TimePeriodPane: React.FC<TimePeriodPaneProps> = (props) => {
    let { period, onChange } = props;

    let classes = useStyles();

    const onPeriodStartDateChange: OnChangeDateCallback = (value) => {
        let newPeriodStart = copyDate(period.start, value);
        onChange({ ...period, start: newPeriodStart });
    };

    const onPeriodStartTimeChange = (value: Date) => {
        let newPeriodStart = copyTime(period.start, value);
        onChange({ ...period, start: newPeriodStart });
    };

    const onPeriodEndDateChange: OnChangeDateCallback = (value) => {
        let newPeriodEnd = copyDate(period.end, value);
        onChange({ ...period, end: newPeriodEnd });
    };

    const onPeriodEndTimeChange = (value: Date) => {
        let newPeriodEnd = copyTime(period.end, value);
        onChange({ ...period, end: newPeriodEnd });
    };

    return (
        <div className={classes.dateTimePaneRoot}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <DetailsHeader title={translate('PeriodStart')} />
                <div className={classes.calendarContainer}>
                    <Calendar value={period.start} onChange={onPeriodStartDateChange} />
                </div>
                <FieldContainer labelTextKey="Time" labelWidth={120}>
                    <TimeInput value={period.start} onChange={onPeriodStartTimeChange} />
                </FieldContainer>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <DetailsHeader title={translate('PeriodEnd')} />
                <div className={classes.calendarContainer}>
                    <Calendar value={period.end} onChange={onPeriodEndDateChange} />
                </div>
                <FieldContainer labelTextKey="Time" labelWidth={100}>
                    <TimeInput value={period.end} onChange={onPeriodEndTimeChange} />
                </FieldContainer>
            </div>
        </div>
    );
};
