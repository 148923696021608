import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { loginTheme as themesReducer } from './theme/slice';
import { sharedDataStorageReducer } from './sharedData/slice';
import { SharedDataStorageState } from './sharedData/entities';
import { AuthState } from './auth/entities';
import { authorizationReducer } from './auth/slice';
import { ThemesStateSlice } from './theme/entities';

export interface CombinedState extends ThemesStateSlice {
    sharedData: SharedDataStorageState;
    auth: AuthState;
}

const rootReducer = combineReducers({
    themes: themesReducer,
    sharedData: sharedDataStorageReducer,
    auth: authorizationReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;
export const useLoginDispatch: () => AppDispatch = useDispatch;
