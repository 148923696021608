import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    menuItem: { ...theme.custom?.popupMenu.dividerItem },
}));
export const DividerMenuItem: React.FC = () => {
    let classes = createStyles();

    return <div className={classes.menuItem} />;
};
