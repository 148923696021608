import React from 'react';
import { CheckboxMenuItem, DividerMenuItem, Menu, OutlinedButton, SwitcherMenuItem } from '..';
import { FilterColumn } from '.';
import { ToolbarProps } from './entities';
/**Компонент для настройки фильтров в таблице */
export const FilterSettings = <T,>(props: ToolbarProps<T>): JSX.Element => {
    let {
        customFilters,
        customFiltersData,
        updateCustomFiltersData,
        filterColumns,
        caseSensitivity,
        wholeMatch,
        onToolbarPropsChange,
    } = props;

    let [menuAnchor, setMenuAnchor] = React.useState<HTMLDivElement | null>(null);

    const updateFilterColumns = (key: keyof T): void => {
        let targetIndex: number = filterColumns.findIndex((item) => item.key == key);
        let targetItem: FilterColumn<T> = filterColumns[targetIndex];

        let newItem: FilterColumn<T> = {
            ...targetItem,
            active: !targetItem.active,
        };

        let newFilterColumns: FilterColumn<T>[] = [...filterColumns];
        newFilterColumns[targetIndex] = newItem;

        onToolbarPropsChange({
            ...props,
            filterColumns: newFilterColumns,
        });
    };

    const updateCaseSensitiveFilter = (): void => {
        onToolbarPropsChange({
            ...props,
            caseSensitivity: !caseSensitivity,
        });
    };

    const updateWholeMatchOnlyFilter = (): void => {
        onToolbarPropsChange({
            ...props,
            wholeMatch: !wholeMatch,
        });
    };

    return (
        <>
            <OutlinedButton frontIconType="filter-settings" onClick={(event) => setMenuAnchor(event.currentTarget)} />
            <Menu
                anchor={menuAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setMenuAnchor(null)}
            >
                {filterColumns.map((item) => (
                    <CheckboxMenuItem
                        key={String(item.key)}
                        active={item.active}
                        textKey={item.label}
                        onClick={() => updateFilterColumns(item.key)}
                    />
                ))}
                {filterColumns.length > 0 && <DividerMenuItem />}
                {customFilters?.map((filter) => (
                    <SwitcherMenuItem
                        key={filter.id}
                        active={customFiltersData[filter.id]}
                        textKey={filter.label}
                        onClick={() => updateCustomFiltersData(filter.id)}
                    />
                ))}
                {customFilters && customFilters.length > 0 && <DividerMenuItem />}
                <SwitcherMenuItem
                    active={caseSensitivity}
                    textKey="ConsiderCase"
                    onClick={() => updateCaseSensitiveFilter()}
                />
                <SwitcherMenuItem
                    active={wholeMatch}
                    textKey="WholeMatch"
                    onClick={() => updateWholeMatchOnlyFilter()}
                />
            </Menu>
        </>
    );
};

export default React.memo(FilterSettings) as typeof FilterSettings;
