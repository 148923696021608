import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';

import { Theme } from 'theme';
import { FilterInput, SvgIcon, SvgIconProps, SvgIconType, Toolbar } from 'ui';
import { translate } from 'utilities';
import classNames from 'classnames';

export const ItemsCountToShowFilter = 15;
const ItemMargin = 1;
let ItemHeight = 36 + ItemMargin * 2;

export const useStyles = makeStyles((theme: Theme) => {
    if (theme.custom) {
        ItemHeight = theme.custom?.mainSizes.list.rowHeight + ItemMargin * 2;
    }

    return {
        muiPopover: {
            '& .MuiPopover-paper': {
                borderRadius: theme.custom?.mainSizes.common.tinyBorderRadius,
            },
        },
        container: {
            ...theme.custom?.typography.input,
            boxSizing: 'border-box',
            height: theme.custom?.mainSizes.input.height,
            border: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
            borderRadius: theme.custom?.mainSizes.common.tinyBorderRadius,
            padding: `0 ${
                (theme.custom?.mainSizes.common.baseSize || 9) +
                ((theme.custom?.mainSizes.common.thickBorderSize || 2) -
                    (theme.custom?.mainSizes.common.defaultBorderSize || 1))
            }px`,
            display: 'flex',
            alignItems: 'center',
            gap: theme.custom?.mainSizes.common.baseSize,
            backgroundColor: theme.custom?.palette.background.main,
            color: theme.custom?.palette.text.primary,
            cursor: 'pointer',
            overflow: 'hidden',
            outline: 'none',
            '&:hover': {
                borderColor: theme.custom?.palette.primary.main,
            },
            '&:focus': {
                padding: `0 ${theme.custom?.mainSizes.common.baseSize}px`,
                borderColor: theme.custom?.palette.primary.main,
                borderWidth: theme.custom?.mainSizes.common.thickBorderSize,
            },
            '& svg': {
                flex: `0 0 ${theme.custom?.mainSizes.input.iconSize}px`,
                width: theme.custom?.mainSizes.input.iconSize,
                height: theme.custom?.mainSizes.input.iconSize,
            },
        },
        containerWithFocus: {
            padding: `0 ${theme.custom?.mainSizes.common.baseSize}px`,
            borderColor: theme.custom?.palette.primary.main,
            borderWidth: theme.custom?.mainSizes.common.thickBorderSize,
        },
        inputText: {
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        placeholder: {
            color: theme.custom?.palette.text.placeholder,
        },
        inputIcon: {
            flex: `0 0 ${theme.custom?.mainSizes.input.iconSize}px`,
        },
        itemsContainer: {
            maxHeight: '60vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
        },
        item: {
            ...theme.custom?.typography.text,
            margin: ItemMargin,
            flex: `0 0 ${theme.custom?.mainSizes.list.rowHeight}px`,
            borderRadius: theme.custom?.mainSizes.common.smallBorderRadius,
            padding: `0 ${theme.custom?.mainSizes.common.baseSize}px`,
            display: 'flex',
            alignItems: 'center',
            gap: theme.custom?.mainSizes.common.baseSize,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.custom?.palette.actions.hover,
            },
            '& svg': {
                width: theme.custom?.mainSizes.input.iconSize,
                height: theme.custom?.mainSizes.input.iconSize,
            },
        },
        selectedItem: {
            backgroundColor: theme.custom?.palette.actions.select,
            '&:hover': {
                backgroundColor: theme.custom?.palette.actions.select,
            },
        },
        itemText: {
            flexGrow: 1,
        },
        popoverInnerContainer: {
            boxSizing: 'border-box',
            padding: theme.custom?.spacing(1),
            display: 'flex',
            flexDirection: 'column',
        },
        dividerItem: {
            margin: theme.custom?.spacing(1),
            flex: `0 0 ${theme.custom?.mainSizes.common.defaultBorderSize}px`,
            backgroundColor: theme.custom?.palette.divider,
        },
    };
});

export interface DropdownItem {
    key: string;
    value: string;
    divider?: boolean;
    tooltipText?: string;
    icon?: SvgIconProps;
}

export const getDividerDropdownItem = (): DropdownItem => ({ key: '', value: '', divider: true });

export interface DropdownSelectProps {
    /** Элементы, которые будут предложены для выбора.*/
    items?: DropdownItem[];
    /** Коллбек вызвается при выборе элемента.*/
    onSelect?: (item: DropdownItem) => void;
    /** Заголовок выпадающего списка, будет показан пока selectedItem не установлен.*/
    title?: string;
    /** Ширина компонента.*/
    width?: number;
    /** Элемент получит flex-grow: 1 */
    fullWidth?: boolean;
    /** Выбранный элемент.*/
    selectedItem?: DropdownItem;
    /** Если задать этот элемент, то при раскрытии список будет прокручен до этого элемента не зависимо
     * от того, есть ли выбранный элемент. По умолчанию прокрутка идёт до selectedItem.*/
    defaultItemToScrollTo?: DropdownItem;
    /** Параметр отвечающий за отображение кнопки "Добавить".*/
    showAddOption?: boolean;
    /** Коллбек функция, будет вызвана при нажатии на кнопку "Добавить".*/
    onAddClick?: () => void;
    /** Есть или нет ошибка  */
    error?: boolean;
    /** Дополнительные элементы для выбора, будут разделены с элементами основного выбора */
    extraItems?: DropdownItem[];
}

/** Компонент для выбора элемента в выпадающем списке. Если элементов больше чем 15, то автаматически отображается фильтр.
 * Если установлен пропс defaultItemToScrollTo, то всегда будет скролить к нему независимо от выбранного элемента.
 */
export const DropdownSelect: React.FC<DropdownSelectProps> = (props) => {
    let {
        items = [],
        onSelect = () => undefined,
        title,
        width = 200,
        fullWidth,
        selectedItem,
        defaultItemToScrollTo,
        showAddOption,
        onAddClick,
        error,
        extraItems = [],
    } = props;
    let classes = useStyles();

    let inputRef = React.useRef<HTMLDivElement>(null);
    let itemsDivRef = React.useRef<HTMLDivElement>(null);
    let [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
    let [filterValue, setFilterValue] = React.useState<string>('');
    let [itemsView, setItemsView] = React.useState<DropdownItem[]>([]);

    const openPopover = () => setPopoverOpen(true);

    const closePopover = () => setPopoverOpen(false);

    const selectItem = (item: DropdownItem) => {
        closePopover();
        onSelect(item);
    };

    const addClickHandler = () => {
        closePopover();
        onAddClick && onAddClick();
    };

    const scrollToItem = () => {
        if (!popoverOpen || !itemsDivRef.current) {
            return;
        }

        let itemToScrollTo = defaultItemToScrollTo ?? selectedItem;
        if (!itemToScrollTo) {
            return;
        }

        let targetIndex = items.findIndex((item) => item.key == itemToScrollTo?.key);
        if (targetIndex < 0) {
            return;
        }

        let scrollTo = 0;
        let itemsCountBeyondTarget = 3;
        if (targetIndex > itemsCountBeyondTarget) {
            scrollTo = ItemHeight * (targetIndex - itemsCountBeyondTarget);
        }

        itemsDivRef.current.scrollTop = scrollTo;
    };

    React.useEffect(() => {
        if (filterValue == '') {
            setItemsView([...items]);
            return;
        }

        let lowerCaseFilterValue = filterValue.toLowerCase();
        let newItemsView = items.filter((item) => {
            if (item.divider) {
                return true;
            }

            return item.value.toLowerCase().includes(lowerCaseFilterValue);
        });

        while (newItemsView[0]?.divider) {
            newItemsView.shift();
        }
        while (newItemsView[newItemsView.length - 1]?.divider) {
            newItemsView.pop();
        }

        setItemsView(newItemsView);
    }, [items, filterValue]);

    let inputClasses = classes.container;
    if (popoverOpen) {
        inputClasses = classNames(classes.container, classes.containerWithFocus);
    }
    let errorStyle: React.CSSProperties = error ? { borderColor: 'red' } : {};
    let inputStyle: React.CSSProperties = { ...errorStyle };
    if (fullWidth) {
        inputStyle.flexGrow = 1;
    } else {
        inputStyle.width = width;
    }

    let inputIconType: SvgIconType = popoverOpen ? 'chevron-up' : 'chevron-down';

    let popoverContainerStyle: React.CSSProperties = {
        minWidth: width,
    };

    let inputText = selectedItem ? selectedItem?.value : title;
    let inputTextStyle = classes.inputText;
    if (!selectedItem) {
        inputTextStyle = classNames(classes.inputText, classes.placeholder);
    }

    return (
        <>
            <div
                ref={inputRef}
                className={inputClasses}
                style={inputStyle}
                tabIndex={0}
                onClick={openPopover}
                title={inputText}
            >
                {selectedItem?.icon && <SvgIcon color={selectedItem.icon.color} type={selectedItem.icon.type} />}

                <div className={inputTextStyle}>{inputText}</div>
                <SvgIcon type={inputIconType} />
            </div>
            <Popover
                anchorEl={inputRef.current}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                elevation={0}
                className={classes.muiPopover}
                open={popoverOpen}
                getContentAnchorEl={null}
                onClose={closePopover}
                onRendered={scrollToItem}
            >
                <div className={classes.popoverInnerContainer} style={popoverContainerStyle}>
                    {items.length > ItemsCountToShowFilter && (
                        <Toolbar>
                            <FilterInput fullWidth value={filterValue} onChange={setFilterValue} />
                        </Toolbar>
                    )}
                    <div ref={itemsDivRef} className={classes.itemsContainer}>
                        {showAddOption && (
                            <div className={classes.item} onClick={addClickHandler}>
                                <SvgIcon type="plus" />
                                <div className={classes.itemText}>{translate('Add')}</div>
                            </div>
                        )}
                        {showAddOption && (itemsView.length > 0 || extraItems.length > 0) && (
                            <div className={classes.dividerItem} />
                        )}
                        {extraItems.map((item) => {
                            const onClick = () => selectItem(item);

                            const selected = item.key === selectedItem?.key;
                            let itemClasses = classes.item;
                            if (selected) {
                                itemClasses = classNames(itemClasses, classes.selectedItem);
                            }
                            return (
                                <div key={item.key} className={itemClasses} onClick={onClick} title={item.tooltipText}>
                                    {item.icon && <SvgIcon color={item.icon.color} type={item.icon.type} />}

                                    <div className={classes.itemText}>{item.value}</div>
                                    <SvgIcon type={selected ? 'accept' : 'empty'} />
                                </div>
                            );
                        })}
                        {extraItems.length > 0 && <div className={classes.dividerItem} />}
                        {itemsView.map((item, index) => {
                            if (item.divider) {
                                return <div key={`divider${index}`} className={classes.dividerItem} />;
                            }

                            const onClick = () => selectItem(item);

                            let selected = item.key === selectedItem?.key;
                            let itemClasses = classes.item;
                            if (selected) {
                                itemClasses = classNames(itemClasses, classes.selectedItem);
                            }
                            return (
                                <div key={item.key} className={itemClasses} onClick={onClick} title={item.tooltipText}>
                                    {item.icon && <SvgIcon color={item.icon.color} type={item.icon.type} />}

                                    <div className={classes.itemText}>{item.value}</div>
                                    <SvgIcon type={selected ? 'accept' : 'empty'} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Popover>
        </>
    );
};
