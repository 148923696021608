import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Theme from '../../../theme/login-theme';
import { FlagIcon } from '../icons/flagIcon';
import { getLanguages } from '../../store/sharedData/selectors';

const createStyles = makeStyles((theme: Theme) => ({
    typeIconBlock: theme.custom?.inputField.typeIconBlock || {},
    containerBlock: theme.custom?.languageSelect.containerBlock || {},
    itemSelect: theme.custom?.languageSelect.itemSelect || {},
    itemFlag: theme.custom?.languageSelect.itemFlag || {},
    itemName: theme.custom?.languageSelect.itemName || {},
    select: theme.custom?.languageSelect.select || {},
    menuItem: theme.custom?.languageSelect.menuItem || {},
    dividerBlock: theme.custom?.dividerBlock || {},
}));

interface SelectData {
    value: unknown;
}

interface LanguageSelectProps {
    value: string;
    onChange?: (value: string) => void;
    flagIconPath: string;
}

export const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {
    let { value, onChange, flagIconPath } = props;
    const classes = createStyles();
    const languages = useSelector(getLanguages);

    const updateLanguage = (event: React.ChangeEvent<SelectData>): void => {
        if (onChange) {
            onChange(event.target.value as string);
        }
    };

    React.useEffect(() => {
        if (languages.length === 0) {
            return;
        }

        const defaultLanguage = languages.find((item) => item.isDefault === true);

        let localStorageLang = localStorage.getItem('lang')?.toLocaleLowerCase() || '';
        let availableLanguages = languages.map((item) => item.code);
        let browserLanguage = window.navigator.language.toLocaleLowerCase();

        if (!onChange) {
            return;
        }

        let existLang = languages.some((item) => item.code === localStorageLang);

        if (!existLang) {
            onChange('ru-ru');
            return;
        }

        if (localStorageLang !== '') {
            onChange(localStorageLang);
            return;
        }

        if (defaultLanguage) {
            onChange(defaultLanguage.code);
            return;
        }
        if (availableLanguages.includes(browserLanguage)) {
            onChange(browserLanguage);
            return;
        }
        onChange('ru-ru');
    }, [languages, onChange]);

    return (
        <>
            <div className={classes.typeIconBlock}>
                <FlagIcon path={flagIconPath} />
            </div>
            <div className={classes.dividerBlock} />
            <div className={classes.containerBlock}>
                {
                    <Select
                        fullWidth
                        disableUnderline
                        value={value}
                        onChange={updateLanguage}
                        className={classes.select}
                    >
                        {languages.map((item, index) => (
                            <MenuItem key={index} value={item.code} className={classes.menuItem}>
                                <div className={classes.itemSelect}>
                                    <div className={classes.itemFlag + ' itemFlag'}>
                                        <img src={`/${item.image}`} alt="" />
                                    </div>
                                    <div className={classes.itemName}>{item.name}</div>
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                }
            </div>
        </>
    );
};
