import * as React from 'react';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    menu: { ...theme.custom?.popupMenu.container },
}));

export enum MenuLocation {
    BottomLeft = 1,
    BottomRight = 2,
}

export interface MenuProps {
    /**Содержимое всплывающего окна */
    children: React.ReactNode | React.ReactNodeArray;
    /**Элемент, относительно которого отображается окно. Если параметер равен null, окно не отображается */
    anchor: HTMLDivElement | null;
    /**Позиция окна относительно anchor*/
    anchorOrigin: PopoverOrigin;
    /**С какой позиции начинается анимация появления*/
    transformOrigin: PopoverOrigin;
    /**Коллбек функция при закрытии окна*/
    onClose?: () => void;
}
/**Компонент для отображения всплывающего окна */
export const Menu: React.FC<MenuProps> = (props) => {
    let { children, anchor, anchorOrigin, transformOrigin, onClose } = props;
    let classes = createStyles();

    return (
        <Popover
            keepMounted
            elevation={0}
            getContentAnchorEl={null}
            open={Boolean(anchor)}
            anchorEl={anchor}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            className={classes.menu}
            onClose={onClose}
        >
            {children}
        </Popover>
    );
};
