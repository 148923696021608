import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    rootStyles: { ...theme.custom?.detailsList.row },
}));
/**Отображение children в строку */
export const DetailsRow: React.FC<React.PropsWithChildren> = (props) => {
    let { rootStyles } = createStyles();

    return <div className={rootStyles}>{props.children}</div>;
};
