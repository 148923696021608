import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    detailsMultilineTextValue: { ...theme.custom?.detailsList.multilineData },
    detailsPreFormattedValue: { ...theme.custom?.detailsList.formattedData },
}));

export interface DetailsMultilineValueProps {
    /**Устанавливает значения FlexGrow в 1 */
    fullWidth?: boolean;
    /**Устанавливает flex свойства */
    flex?: string;
    /**Параметр контролирует перенос строки. Если true, то будет переноситься в одну строку с несколькими линиями, иначе появиться скрол. */
    preFormatted?: boolean;
}
/**Компонент для отображение информации в несколько линий */
export const DetailsMultilineTextValue: React.FC<React.PropsWithChildren<DetailsMultilineValueProps>> = (props) => {
    let { fullWidth, flex, preFormatted } = props;
    let classes = createStyles();

    let rootClasses = preFormatted ? classes.detailsPreFormattedValue : classes.detailsMultilineTextValue;
    let style: React.CSSProperties = { flex };
    if (fullWidth) {
        style.flexGrow = 1;
    }
    return (
        <div className={rootClasses} style={style}>
            {props.children}
        </div>
    );
};
