import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { SvgIcon } from 'ui';
import { translate } from 'utilities';
import { ButtonIconSize } from '../../constants';
import Theme from '../../theme/fm-theme';

import { ButtonProps } from './contained-button';

const createStyles = makeStyles((theme: Theme) => ({
    button: theme.custom?.buttons.toolbar || {},
}));
/** @deprecated Кнопка, не известно где используется */
export const ToolbarButton: React.FC<ButtonProps> = (props) => {
    let { textKey = '', frontIconType, marginLeft = 0, onClick } = props;

    let classes = createStyles();
    let style: React.CSSProperties = { marginLeft };

    return (
        <div style={style} className={classes.button} onClick={onClick}>
            {frontIconType && <SvgIcon type={frontIconType} size={ButtonIconSize} />}
            {translate(textKey)}
        </div>
    );
};
