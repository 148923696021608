import { LoadingState } from 'ui';
import { CombinedState } from '..';
import { Language, NewsView, Translations } from './entities';

export const getNews = (state: CombinedState): NewsView[] => state.sharedData.sharedDataStorage.news;

export const getCurrentNewsIndex = (state: CombinedState): number => state.sharedData.currentNewsIndex;

export const getSharedDataLoading = (state: CombinedState): LoadingState => state.sharedData.loadingState;

export const getTextErrorMessage = (state: CombinedState): string => state.sharedData.textErrorMessage;

export const getIntervalId = (state: CombinedState): number => state.sharedData.intervalId;

export const getLanguages = (state: CombinedState): Language[] => state.sharedData.sharedDataStorage.languages;

export const getTranslates = (state: CombinedState): Translations => state.sharedData.translates;

export const getCountLanguages = (state: CombinedState): number => state.sharedData.sharedDataStorage.languages.length;

export const getSwitcherDisplayStatus = (state: CombinedState): boolean =>
    state.sharedData.sharedDataStorage.showOldInterfaceSwitcher;
