import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { default as Theme } from '../../theme/fm-theme';

const useStyles = makeStyles((theme: Theme) => ({
    root: theme?.custom?.loading?.root || {},
    transparentRoot: { ...theme.custom?.loading.transparentRoot },
}));

export interface LoadingProps {
    /**Ширина контейнера лоудера */
    width?: number | string;
    /**Высота контейнера лоудера */
    height?: number | string;
    /**Параметр устанавливает прозрачный фон лоудера */
    transparent?: boolean;
}
/**Компонент отображения лоудера. Без указания высоты и ширины, встанет по центру блока */
export function Loading(props: LoadingProps): JSX.Element {
    let { width = '100%', height = '100%', transparent = false } = props;

    const classes = useStyles();

    let className = transparent ? classes.transparentRoot : classes.root;
    return (
        <div className={className} style={{ width, height }}>
            <CircularProgress />
        </div>
    );
}

export default Loading;
