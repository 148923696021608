import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as React from 'react';
import { SvgIcon } from 'ui';
import { translate } from 'utilities';
import { Theme } from '../../theme';

export enum FieldLayout {
    Horizontal,
    Vertical,
}

export const createStyles = makeStyles((theme: Theme) => ({
    fieldContainer: {
        marginBottom: theme.custom?.spacing(2),
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    verticalFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'justify',
        gap: theme.custom?.spacing(0.5),
    },
    fieldContainerNoPadding: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        ...theme.custom?.typography.text,
        color: theme.custom?.palette.text.primary,
        boxSizing: 'border-box',
        paddingRight: theme.custom?.spacing(4),
    },
    rightLabel: {
        ...theme.custom?.typography.text,
        color: theme.custom?.palette.text.primary,
        boxSizing: 'border-box',
        paddingLeft: theme.custom?.spacing(1.5),
    },
    inputPane: {
        display: 'flex',
    },
    error: {
        ...theme.custom?.typography.text,
        color: theme.custom?.palette.text.primary,
        boxSizing: 'border-box',
        paddingLeft: theme.custom?.spacing(1.5),
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.custom?.spacing(1),
            width: theme.custom?.mainSizes.input.iconSize,
            height: theme.custom?.mainSizes.input.iconSize,
        },
        '& path': {
            fill: theme.custom?.palette.levels.error,
        },
    },
    warning: {
        '& path': {
            fill: theme.custom?.palette.levels.warning,
        },
    },
}));

export interface FieldContainerProps {
    /**Ширина контейнера */
    width?: number;
    /**Ширина текста описания слева */
    labelWidth?: number;
    /**Текст описания слева */
    labelTextKey?: string;
    /**Ширина поля для ввода */
    inputWidth?: number;
    /**Ширина текста описания справа */
    rightLabelWidth?: number;
    /**Текст описания справа*/
    rightLabelText?: string;
    /**Параметр отключает отступ между children */
    disablePadding?: boolean;
    /**Текст ошибки*/
    errorText?: string;
    /** текст предупреждения */
    warningText?: string;
    alignItems?: 'baseline';
    /** Расположение составляющих поля, по умолчанию - горизонтальное */
    layout?: FieldLayout;
}

/**Контейнер для полей ввода */
export const FieldContainer: React.FC<React.PropsWithChildren<FieldContainerProps>> = (props) => {
    let {
        children,
        width,
        labelWidth,
        labelTextKey = '',
        inputWidth,
        rightLabelWidth,
        rightLabelText = '',
        disablePadding = false,
        errorText = '',
        warningText = '',
        alignItems,
        layout = FieldLayout.Horizontal,
    } = props;

    let classes = createStyles();

    let containerClass = disablePadding ? classes.fieldContainerNoPadding : classes.fieldContainer;

    if (layout === FieldLayout.Vertical) {
        containerClass = classes.verticalFieldContainer;
    }

    let fieldContainerStyle: React.CSSProperties = {};
    if (typeof width == 'number') {
        fieldContainerStyle.width = width;
    }

    if (alignItems) {
        fieldContainerStyle.alignItems = alignItems;
    }

    let labelText = translate(labelTextKey);
    let labelStyle = getWidthStyle(labelWidth);
    let inputStyle = getWidthStyle(inputWidth);
    let rightLabelStyle = getWidthStyle(rightLabelWidth);
    let errorStyle = getWidthStyle();

    return (
        <div className={containerClass} style={fieldContainerStyle}>
            {labelText.length > 0 && (
                <div className={classes.label} style={labelStyle}>
                    {labelText}
                </div>
            )}
            <div className={classes.inputPane} style={inputStyle}>
                {children}
            </div>
            {rightLabelText.length > 0 && (
                <div className={classNames(classes.label, classes.rightLabel)} style={rightLabelStyle}>
                    {rightLabelText}
                </div>
            )}
            {errorText.length > 0 && (
                <div className={classes.error} style={errorStyle}>
                    <SvgIcon type="warning" />
                    {errorText}
                </div>
            )}
            {warningText.length > 0 && (
                <div className={classNames(classes.error, classes.warning)} style={errorStyle}>
                    <SvgIcon type="warning" />
                    {warningText}
                </div>
            )}
        </div>
    );
};

function getWidthStyle(width?: number): React.CSSProperties {
    let result: React.CSSProperties = {};
    if (typeof width == 'number') {
        result.flex = `0 0 ${width}px`;
        result.width = width;
    } else {
        result.flexGrow = 1;
    }
    return result;
}

export default FieldContainer;
