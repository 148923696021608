import * as React from 'react';

export interface AppStoreIconProps {
    /**Язык иконки */
    lang: string;
}
/**Иконка "Загрузите в App Store" */
export const AppStoreIcon: React.FC<AppStoreIconProps> = (props) => {
    let { lang } = props;
    return (
        <>
            {lang == 'ru-ru' ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="livetype"
                    width="119.66407"
                    height="40"
                    viewBox="0 0 119.66407 40"
                >
                    <g>
                        <g>
                            <g>
                                <path
                                    d="M110.13477,0H9.53468c-.3667,0-.729,0-1.09473.002-.30615.002-.60986.00781-.91895.0127A13.21476,13.21476,0,0,0,5.5171.19141a6.66509,6.66509,0,0,0-1.90088.627A6.43779,6.43779,0,0,0,1.99757,1.99707,6.25844,6.25844,0,0,0,.81935,3.61816a6.60119,6.60119,0,0,0-.625,1.90332,12.993,12.993,0,0,0-.1792,2.002C.00587,7.83008.00489,8.1377,0,8.44434V31.5586c.00489.3105.00587.6113.01515.9219a12.99232,12.99232,0,0,0,.1792,2.0019,6.58756,6.58756,0,0,0,.625,1.9043A6.20778,6.20778,0,0,0,1.99757,38.001a6.27445,6.27445,0,0,0,1.61865,1.1787,6.70082,6.70082,0,0,0,1.90088.6308,13.45514,13.45514,0,0,0,2.0039.1768c.30909.0068.6128.0107.91895.0107C8.80567,40,9.168,40,9.53468,40H110.13477c.3594,0,.7246,0,1.084-.002.3047,0,.6172-.0039.9219-.0107a13.279,13.279,0,0,0,2-.1768,6.80432,6.80432,0,0,0,1.9082-.6308,6.27742,6.27742,0,0,0,1.6172-1.1787,6.39482,6.39482,0,0,0,1.1816-1.6143,6.60413,6.60413,0,0,0,.6191-1.9043,13.50643,13.50643,0,0,0,.1856-2.0019c.0039-.3106.0039-.6114.0039-.9219.0078-.3633.0078-.7246.0078-1.0938V9.53613c0-.36621,0-.72949-.0078-1.09179,0-.30664,0-.61426-.0039-.9209a13.5071,13.5071,0,0,0-.1856-2.002,6.6177,6.6177,0,0,0-.6191-1.90332,6.46619,6.46619,0,0,0-2.7988-2.7998,6.76754,6.76754,0,0,0-1.9082-.627,13.04394,13.04394,0,0,0-2-.17676c-.3047-.00488-.6172-.01074-.9219-.01269-.3594-.002-.7246-.002-1.084-.002Z"
                                    fill="#A6A6A6"
                                />
                                <path d="M8.44483,39.125c-.30468,0-.602-.0039-.90429-.0107a12.68714,12.68714,0,0,1-1.86914-.1631,5.88381,5.88381,0,0,1-1.65674-.5479,5.40573,5.40573,0,0,1-1.397-1.0166,5.32082,5.32082,0,0,1-1.02051-1.3965,5.72186,5.72186,0,0,1-.543-1.6572,12.41351,12.41351,0,0,1-.1665-1.875c-.00634-.2109-.01464-.9131-.01464-.9131V8.44434S.88185,7.75293.8877,7.5498a12.37039,12.37039,0,0,1,.16553-1.87207,5.7555,5.7555,0,0,1,.54346-1.6621A5.37349,5.37349,0,0,1,2.61183,2.61768,5.56543,5.56543,0,0,1,4.01417,1.59521a5.82309,5.82309,0,0,1,1.65332-.54394A12.58589,12.58589,0,0,1,7.543.88721L8.44532.875H111.21387l.9131.0127a12.38493,12.38493,0,0,1,1.8584.16259,5.93833,5.93833,0,0,1,1.6709.54785,5.59374,5.59374,0,0,1,2.415,2.41993,5.76267,5.76267,0,0,1,.5352,1.64892,12.995,12.995,0,0,1,.1738,1.88721c.0029.2832.0029.5874.0029.89014.0079.375.0079.73193.0079,1.09179V30.4648c0,.3633,0,.7178-.0079,1.0752,0,.3252,0,.6231-.0039.9297a12.73126,12.73126,0,0,1-.1709,1.8535,5.739,5.739,0,0,1-.54,1.67,5.48029,5.48029,0,0,1-1.0156,1.3857,5.4129,5.4129,0,0,1-1.3994,1.0225,5.86168,5.86168,0,0,1-1.668.5498,12.54218,12.54218,0,0,1-1.8692.1631c-.2929.0068-.5996.0107-.8974.0107l-1.084.002Z" />
                            </g>
                            <g id="_Group_" data-name="&lt;Group&gt;">
                                <g id="_Group_2" data-name="&lt;Group&gt;">
                                    <g id="_Group_3" data-name="&lt;Group&gt;">
                                        <path
                                            id="_Path_"
                                            data-name="&lt;Path&gt;"
                                            d="M24.76888,20.30068a4.94881,4.94881,0,0,1,2.35656-4.15206,5.06566,5.06566,0,0,0-3.99116-2.15768c-1.67924-.17626-3.30719,1.00483-4.1629,1.00483-.87227,0-2.18977-.98733-3.6085-.95814a5.31529,5.31529,0,0,0-4.47292,2.72787c-1.934,3.34842-.49141,8.26947,1.3612,10.97608.9269,1.32535,2.01018,2.8058,3.42763,2.7533,1.38706-.05753,1.9051-.88448,3.5794-.88448,1.65876,0,2.14479.88448,3.591.8511,1.48838-.02416,2.42613-1.33124,3.32051-2.66914a10.962,10.962,0,0,0,1.51842-3.09251A4.78205,4.78205,0,0,1,24.76888,20.30068Z"
                                            fill="#fff"
                                        />
                                        <path
                                            id="_Path_2"
                                            data-name="&lt;Path&gt;"
                                            d="M22.03725,12.21089a4.87248,4.87248,0,0,0,1.11452-3.49062,4.95746,4.95746,0,0,0-3.20758,1.65961,4.63634,4.63634,0,0,0-1.14371,3.36139A4.09905,4.09905,0,0,0,22.03725,12.21089Z"
                                            fill="#fff"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M42.30227,27.13965h-4.7334l-1.13672,3.35645H34.42727l4.4834-12.418h2.083l4.4834,12.418H43.438ZM38.0591,25.59082h3.752l-1.84961-5.44727h-.05176Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M55.15969,25.96973c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.44238H48.4302v1.50586h.03418a3.21162,3.21162,0,0,1,2.88281-1.60059C53.645,21.34766,55.15969,23.16406,55.15969,25.96973Zm-1.91016,0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992,0-2.375,1.23047-2.375,3.03809,0,1.82422.95508,3.0459,2.375,3.0459C52.30227,29.01563,53.24953,27.81934,53.24953,25.96973Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M65.12453,25.96973c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.44238H58.395v1.50586h.03418A3.21162,3.21162,0,0,1,61.312,21.34766C63.60988,21.34766,65.12453,23.16406,65.12453,25.96973Zm-1.91016,0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992,0-2.375,1.23047-2.375,3.03809,0,1.82422.95508,3.0459,2.375,3.0459C62.26711,29.01563,63.21438,27.81934,63.21438,25.96973Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M71.71047,27.03613c.1377,1.23145,1.334,2.04,2.96875,2.04,1.56641,0,2.69336-.80859,2.69336-1.91895,0-.96387-.67969-1.541-2.28906-1.93652l-1.60937-.3877c-2.28027-.55078-3.33887-1.61719-3.33887-3.34766,0-2.14258,1.86719-3.61426,4.51855-3.61426,2.624,0,4.42285,1.47168,4.4834,3.61426h-1.876c-.1123-1.23926-1.13672-1.9873-2.63379-1.9873s-2.52148.75684-2.52148,1.8584c0,.87793.6543,1.39453,2.25488,1.79l1.36816.33594c2.54785.60254,3.60645,1.626,3.60645,3.44238,0,2.32324-1.85059,3.77832-4.79395,3.77832-2.75391,0-4.61328-1.4209-4.7334-3.667Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M83.34621,19.2998v2.14258h1.72168v1.47168H83.34621v4.99121c0,.77539.34473,1.13672,1.10156,1.13672a5.80752,5.80752,0,0,0,.61133-.043v1.46289a5.10351,5.10351,0,0,1-1.03223.08594c-1.833,0-2.54785-.68848-2.54785-2.44434V22.91406H80.16262V21.44238H81.479V19.2998Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M86.065,25.96973c0-2.84863,1.67773-4.63867,4.29395-4.63867,2.625,0,4.29492,1.79,4.29492,4.63867,0,2.85645-1.66113,4.63867-4.29492,4.63867C87.72609,30.6084,86.065,28.82617,86.065,25.96973Zm6.69531,0c0-1.9541-.89551-3.10742-2.40137-3.10742s-2.40039,1.16211-2.40039,3.10742c0,1.96191.89453,3.10645,2.40039,3.10645S92.76027,27.93164,92.76027,25.96973Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M96.18606,21.44238h1.77246v1.541h.043a2.1594,2.1594,0,0,1,2.17773-1.63574,2.86616,2.86616,0,0,1,.63672.06934v1.73828a2.59794,2.59794,0,0,0-.835-.1123,1.87264,1.87264,0,0,0-1.93652,2.083v5.37012h-1.8584Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M109.3843,27.83691c-.25,1.64355-1.85059,2.77148-3.89844,2.77148-2.63379,0-4.26855-1.76465-4.26855-4.5957,0-2.83984,1.64355-4.68164,4.19043-4.68164,2.50488,0,4.08008,1.7207,4.08008,4.46582v.63672h-6.39453v.1123a2.358,2.358,0,0,0,2.43555,2.56445,2.04834,2.04834,0,0,0,2.09082-1.27344Zm-6.28223-2.70215h4.52637a2.1773,2.1773,0,0,0-2.2207-2.29785A2.292,2.292,0,0,0,103.10207,25.13477Z"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </g>
                        <g id="_Group_4" data-name="&lt;Group&gt;">
                            <g>
                                <path
                                    d="M36.7383,11.25342h.79785a1.0021,1.0021,0,0,0,1.125-.95117c0-.55029-.41406-.90576-1.11621-.90576a1.06357,1.06357,0,0,0-1.2041.93457h-.876a1.87057,1.87057,0,0,1,2.1084-1.74512c1.1748,0,2.002.65771,2.002,1.60449a1.386,1.386,0,0,1-1.084,1.36475v.07031a1.3898,1.3898,0,0,1,1.32715,1.44287,1.9932,1.9932,0,0,1-2.22852,1.77393,1.98335,1.98335,0,0,1-2.24121-1.76562h.88965c.05371.5708.57813.939,1.35156.939s1.27832-.41357,1.27832-.99658c0-.59131-.47559-.95557-1.30664-.95557H36.7383Z"
                                    fill="#fff"
                                />
                                <path
                                    d="M40.82912,13.42432c0-.81055.60449-1.27783,1.6748-1.34424l1.21973-.07031v-.38867c0-.47559-.31348-.74414-.92187-.74414-.49609,0-.83887.18213-.93848.50049h-.86035c.09082-.77344.81934-1.26953,1.84082-1.26953,1.12891,0,1.76563.562,1.76563,1.51318v3.07666h-.85645v-.63281h-.07031a1.515,1.515,0,0,1-1.35254.707A1.36026,1.36026,0,0,1,40.82912,13.42432Zm2.89453-.38477v-.37646L42.624,12.7334c-.62012.0415-.90137.25244-.90137.64941,0,.40527.35156.64111.835.64111A1.0615,1.0615,0,0,0,43.72365,13.03955Z"
                                    fill="#fff"
                                />
                                <path d="M46.96779,10.94287v3.75488h-.88867V10.19482H49.044v.748Z" fill="#fff" />
                                <path
                                    d="M54.42971,12.44434c0,1.43066-.72363,2.32764-1.86035,2.32764a1.46778,1.46778,0,0,1-1.38574-.75635H51.1133v2.1792h-.88867v-6h.85547V10.939h.06641a1.56437,1.56437,0,0,1,1.41406-.81885C53.70607,10.12012,54.42971,11.01318,54.42971,12.44434Zm-.91406,0c0-.95117-.4502-1.52588-1.20312-1.52588-.748,0-1.21582.58691-1.21582,1.52979,0,.93848.46777,1.52588,1.21191,1.52588C53.06545,13.97412,53.51565,13.39941,53.51565,12.44434Z"
                                    fill="#fff"
                                />
                                <path
                                    d="M55.6133,16.31055v-.76514a2.1,2.1,0,0,0,.29.0166.77034.77034,0,0,0,.81445-.62842l.06641-.21924-1.62988-4.51953h.96777L57.251,13.77148h.07031l1.125-3.57666h.94238L57.71,14.88379c-.38477,1.0957-.81055,1.44727-1.72852,1.44727C55.90725,16.33105,55.69631,16.32275,55.6133,16.31055Z"
                                    fill="#fff"
                                />
                                <path
                                    d="M60.08107,13.41992h.88477c.08691.397.459.604.97949.604.5918,0,.95508-.26074.95508-.666,0-.43408-.32617-.64111-.95508-.64111H61.2715v-.69043h.66211c.53711,0,.81055-.20654.81055-.583,0-.33057-.26465-.583-.81055-.583-.49707,0-.86035.21094-.918.56641H60.1592a1.578,1.578,0,0,1,1.77441-1.31885c1.07031,0,1.68262.48389,1.68262,1.24463a.92514.92514,0,0,1-.71094.92188v.07031a1.01015,1.01015,0,0,1,.88086,1.01318c0,.856-.72852,1.42236-1.84082,1.42236C60.96193,14.78027,60.16311,14.32959,60.08107,13.41992Z"
                                    fill="#fff"
                                />
                                <path
                                    d="M65.06594,14.69775V10.19482h.88086V13.292h.07031l2.0752-3.09717h.88086v4.50293h-.88086V11.584H68.0259l-2.0791,3.11377Z"
                                    fill="#fff"
                                />
                                <path
                                    d="M73.8257,10.94287h-1.418v3.75488h-.88965V10.94287h-1.418v-.748H73.8257Z"
                                    fill="#fff"
                                />
                                <path
                                    d="M78.61281,13.48193a1.8299,1.8299,0,0,1-1.95215,1.30273,2.04531,2.04531,0,0,1-2.08008-2.32422,2.07685,2.07685,0,0,1,2.07617-2.35254c1.25293,0,2.00977.856,2.00977,2.27V12.688H75.48684v.0498a1.18914,1.18914,0,0,0,1.19922,1.29,1.07872,1.07872,0,0,0,1.07031-.5459Zm-3.126-1.45117h2.27344a1.08625,1.08625,0,0,0-1.10742-1.1665A1.15045,1.15045,0,0,0,75.48684,12.03076Z"
                                    fill="#fff"
                                />
                                <path
                                    d="M82.38039,10.19482h2.03027c.88086,0,1.39746.44629,1.39746,1.145a.96755.96755,0,0,1-.76074.95117v.07031a1.02777,1.02777,0,0,1,.96289,1.03809c0,.78564-.59082,1.29834-1.5791,1.29834H82.38039Zm.88086.68213v1.166h.88086c.53711,0,.79395-.20654.79395-.5874,0-.35107-.22363-.57861-.69531-.57861Zm0,1.81934v1.31934H84.315c.52539,0,.80664-.23193.80664-.65771,0-.45068-.31836-.66162-.918-.66162Z"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="120" height="40" viewBox="0 0 120 40" fill="none">
                    <g clipPath="url(#clip0_1_37)">
                        <path
                            d="M110.135 3.00293e-07H9.53468C9.16798 3.00293e-07 8.80568 3.00352e-07 8.43995 0.0020003C8.1338 0.0040003 7.83009 0.0098103 7.521 0.0147003C6.84951 0.0226021 6.17961 0.0816766 5.5171 0.19141C4.85552 0.303533 4.21467 0.514916 3.61622 0.81841C3.0185 1.12447 2.47235 1.52217 1.99757 1.99707C1.5203 2.47064 1.12246 3.01802 0.81935 3.61816C0.5154 4.21711 0.304641 4.85894 0.19435 5.52148C0.0830109 6.18319 0.0230984 6.85252 0.01515 7.52348C0.00587 7.83008 0.00489 8.1377 0 8.44434V31.5586C0.00489 31.8691 0.00587 32.1699 0.01515 32.4805C0.0231008 33.1514 0.0830134 33.8207 0.19435 34.4824C0.304336 35.1453 0.515108 35.7875 0.81935 36.3867C1.12233 36.9849 1.52022 37.5301 1.99757 38.001C2.47054 38.478 3.01705 38.876 3.61622 39.1797C4.21467 39.484 4.85545 39.6966 5.5171 39.8105C6.17972 39.9194 6.84956 39.9785 7.521 39.9873C7.83009 39.9941 8.1338 39.998 8.43995 39.998C8.80567 40 9.168 40 9.53468 40H110.135C110.494 40 110.859 40 111.219 39.998C111.523 39.998 111.836 39.9941 112.141 39.9873C112.811 39.9789 113.479 39.9198 114.141 39.8105C114.804 39.6958 115.448 39.4833 116.049 39.1797C116.647 38.8758 117.193 38.4778 117.666 38.001C118.142 37.5282 118.541 36.9835 118.848 36.3867C119.15 35.7871 119.358 35.1449 119.467 34.4824C119.578 33.8206 119.64 33.1515 119.652 32.4805C119.656 32.1699 119.656 31.8691 119.656 31.5586C119.664 31.1953 119.664 30.834 119.664 30.4648V9.53613C119.664 9.16992 119.664 8.80664 119.656 8.44434C119.656 8.1377 119.656 7.83008 119.652 7.52344C119.64 6.85242 119.578 6.18324 119.467 5.52144C119.358 4.85928 119.149 4.2175 118.848 3.61812C118.23 2.4152 117.252 1.43603 116.049 0.81832C115.448 0.515568 114.804 0.304241 114.141 0.19132C113.48 0.0811031 112.811 0.0220081 112.141 0.0145603C111.836 0.0096803 111.523 0.0038203 111.219 0.0018703C110.859 -0.0001297 110.494 -0.0001297 110.135 -0.0001297V3.00293e-07Z"
                            fill="#A6A6A6"
                        />
                        <path
                            d="M8.44483 39.125C8.14015 39.125 7.84283 39.1211 7.54054 39.1143C6.91432 39.1061 6.28957 39.0516 5.6714 38.9512C5.09499 38.8519 4.5366 38.6673 4.01466 38.4033C3.4975 38.1415 3.02581 37.7983 2.61766 37.3867C2.2036 36.98 1.85888 36.5082 1.59715 35.9902C1.33254 35.4688 1.14941 34.9099 1.05415 34.333C0.951273 33.7131 0.895613 33.0863 0.887649 32.458C0.881309 32.2471 0.873009 31.5449 0.873009 31.5449V8.44434C0.873009 8.44434 0.881849 7.75293 0.887699 7.5498C0.895325 6.92248 0.950661 6.29665 1.05323 5.67773C1.14867 5.09925 1.33194 4.53875 1.59669 4.01563C1.85745 3.49794 2.20026 3.02586 2.61183 2.61768C3.02293 2.20562 3.49613 1.8606 4.01417 1.59521C4.53491 1.33209 5.09224 1.14873 5.66749 1.05127C6.28769 0.949836 6.91461 0.894996 7.543 0.88721L8.44532 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.606 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.71 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44483 39.125Z"
                            fill="black"
                        />
                        <path
                            d="M24.7689 20.3007C24.7796 19.466 25.0013 18.6476 25.4134 17.9217C25.8254 17.1957 26.4143 16.5858 27.1254 16.1486C26.6737 15.5035 26.0778 14.9725 25.3849 14.598C24.6921 14.2234 23.9215 14.0156 23.1343 13.9909C21.455 13.8147 19.8271 14.9958 18.9714 14.9958C18.0991 14.9958 16.7816 14.0084 15.3629 14.0376C14.4452 14.0673 13.5509 14.3341 12.767 14.8122C11.9831 15.2902 11.3364 15.9632 10.89 16.7655C8.95596 20.1139 10.3985 25.035 12.2512 27.7416C13.1781 29.0669 14.2613 30.5474 15.6788 30.4949C17.0658 30.4374 17.5839 29.6104 19.2582 29.6104C20.9169 29.6104 21.403 30.4949 22.8492 30.4615C24.3376 30.4373 25.2753 29.1303 26.1697 27.7924C26.8357 26.848 27.3481 25.8043 27.6881 24.6999C26.8234 24.3341 26.0855 23.7219 25.5664 22.9396C25.0472 22.1574 24.7699 21.2395 24.7689 20.3007V20.3007Z"
                            fill="white"
                        />
                        <path
                            d="M22.0372 12.2109C22.8488 11.2367 23.2486 9.9845 23.1518 8.72027C21.9119 8.85049 20.7667 9.44305 19.9442 10.3799C19.542 10.8375 19.234 11.37 19.0378 11.9468C18.8416 12.5235 18.7609 13.1333 18.8005 13.7413C19.4206 13.7477 20.0341 13.6132 20.5948 13.3482C21.1554 13.0831 21.6487 12.6942 22.0372 12.2109Z"
                            fill="white"
                        />
                        <path
                            d="M42.3023 27.1397H37.5689L36.4321 30.4961H34.4273L38.9107 18.0781H40.9937L45.4771 30.4961H43.438L42.3023 27.1397ZM38.0591 25.5908H41.8111L39.9615 20.1436H39.9097L38.0591 25.5908Z"
                            fill="white"
                        />
                        <path
                            d="M55.1597 25.9697C55.1597 28.7832 53.6538 30.5908 51.3814 30.5908C50.8057 30.6209 50.2332 30.4883 49.7294 30.2082C49.2256 29.9281 48.8109 29.5117 48.5327 29.0068H48.4897V33.4912H46.6313V21.4424H48.4302V22.9482H48.4644C48.7553 22.4458 49.1771 22.0316 49.6847 21.7497C50.1923 21.4679 50.7669 21.3289 51.3472 21.3477C53.645 21.3477 55.1597 23.1641 55.1597 25.9697ZM53.2495 25.9697C53.2495 24.1367 52.3023 22.9316 50.8569 22.9316C49.437 22.9316 48.4819 24.1621 48.4819 25.9697C48.4819 27.794 49.437 29.0156 50.8569 29.0156C52.3023 29.0156 53.2495 27.8193 53.2495 25.9697Z"
                            fill="white"
                        />
                        <path
                            d="M65.1245 25.9697C65.1245 28.7832 63.6187 30.5908 61.3462 30.5908C60.7706 30.6209 60.1981 30.4883 59.6943 30.2082C59.1905 29.9281 58.7757 29.5117 58.4976 29.0068H58.4546V33.4912H56.5962V21.4424H58.395V22.9482H58.4292C58.7201 22.4458 59.1419 22.0316 59.6495 21.7497C60.1571 21.4679 60.7317 21.3289 61.312 21.3477C63.6099 21.3477 65.1245 23.1641 65.1245 25.9697ZM63.2144 25.9697C63.2144 24.1367 62.2671 22.9316 60.8218 22.9316C59.4019 22.9316 58.4468 24.1621 58.4468 25.9697C58.4468 27.794 59.4019 29.0156 60.8218 29.0156C62.2671 29.0156 63.2144 27.8193 63.2144 25.9697H63.2144Z"
                            fill="white"
                        />
                        <path
                            d="M71.7105 27.0361C71.8482 28.2676 73.0445 29.0761 74.6792 29.0761C76.2456 29.0761 77.3726 28.2675 77.3726 27.1572C77.3726 26.1933 76.6929 25.6162 75.0835 25.2207L73.4741 24.833C71.1939 24.2822 70.1353 23.2158 70.1353 21.4853C70.1353 19.3427 72.0025 17.871 74.6538 17.871C77.2778 17.871 79.0767 19.3427 79.1372 21.4853H77.2612C77.1489 20.246 76.1245 19.498 74.6274 19.498C73.1304 19.498 72.106 20.2548 72.106 21.3564C72.106 22.2343 72.7603 22.7509 74.3608 23.1464L75.729 23.4823C78.2768 24.0849 79.3354 25.1083 79.3354 26.9247C79.3354 29.248 77.4849 30.703 74.5415 30.703C71.7876 30.703 69.9282 29.2821 69.8081 27.036L71.7105 27.0361Z"
                            fill="white"
                        />
                        <path
                            d="M83.3462 19.2998V21.4424H85.0679V22.9141H83.3462V27.9053C83.3462 28.6807 83.6909 29.042 84.4478 29.042C84.6522 29.0384 84.8562 29.0241 85.0591 28.999V30.4619C84.7188 30.5255 84.373 30.5543 84.0269 30.5478C82.1939 30.5478 81.479 29.8593 81.479 28.1035V22.9141H80.1626V21.4424H81.479V19.2998H83.3462Z"
                            fill="white"
                        />
                        <path
                            d="M86.065 25.9697C86.065 23.1211 87.7427 21.3311 90.3589 21.3311C92.9839 21.3311 94.6539 23.1211 94.6539 25.9697C94.6539 28.8262 92.9927 30.6084 90.3589 30.6084C87.7261 30.6084 86.065 28.8262 86.065 25.9697ZM92.7603 25.9697C92.7603 24.0156 91.8648 22.8623 90.3589 22.8623C88.8531 22.8623 87.9585 24.0244 87.9585 25.9697C87.9585 27.9316 88.8531 29.0762 90.3589 29.0762C91.8648 29.0762 92.7603 27.9316 92.7603 25.9697H92.7603Z"
                            fill="white"
                        />
                        <path
                            d="M96.1861 21.4424H97.9585V22.9834H98.0015C98.1214 22.5021 98.4034 22.0768 98.8 21.7789C99.1966 21.481 99.6836 21.3287 100.179 21.3476C100.393 21.3469 100.607 21.3702 100.816 21.417V23.1553C100.546 23.0726 100.264 23.0347 99.981 23.043C99.711 23.032 99.4418 23.0796 99.192 23.1825C98.9422 23.2854 98.7175 23.4411 98.5336 23.639C98.3496 23.8369 98.2106 24.0723 98.1262 24.3289C98.0418 24.5856 98.0139 24.8575 98.0444 25.126V30.4961H96.1861L96.1861 21.4424Z"
                            fill="white"
                        />
                        <path
                            d="M109.384 27.8369C109.134 29.4805 107.534 30.6084 105.486 30.6084C102.852 30.6084 101.217 28.8437 101.217 26.0127C101.217 23.1728 102.861 21.3311 105.408 21.3311C107.913 21.3311 109.488 23.0517 109.488 25.7969V26.4336H103.093V26.5459C103.064 26.8791 103.105 27.2148 103.216 27.5306C103.326 27.8464 103.502 28.1352 103.732 28.3778C103.963 28.6203 104.242 28.8111 104.552 28.9374C104.861 29.0637 105.195 29.1226 105.529 29.1103C105.968 29.1515 106.409 29.0498 106.785 28.8203C107.162 28.5909 107.455 28.246 107.62 27.8369L109.384 27.8369ZM103.102 25.1348H107.628C107.645 24.8352 107.6 24.5354 107.495 24.2541C107.39 23.9729 107.229 23.7164 107.02 23.5006C106.812 23.2849 106.561 23.1145 106.283 23.0003C106.006 22.8861 105.708 22.8305 105.408 22.8369C105.105 22.8351 104.805 22.8932 104.525 23.008C104.245 23.1227 103.99 23.2918 103.776 23.5054C103.562 23.7191 103.392 23.973 103.276 24.2527C103.16 24.5323 103.101 24.8321 103.102 25.1348V25.1348Z"
                            fill="white"
                        />
                        <path
                            d="M37.8262 8.731C38.2158 8.70304 38.6068 8.7619 38.9709 8.90333C39.335 9.04477 39.6632 9.26525 39.9318 9.54887C40.2003 9.8325 40.4026 10.1722 40.524 10.5435C40.6454 10.9147 40.6829 11.3083 40.6338 11.6958C40.6338 13.6021 39.6035 14.6978 37.8262 14.6978H35.6709V8.731H37.8262ZM36.5977 13.854H37.7227C38.0011 13.8706 38.2797 13.825 38.5382 13.7204C38.7968 13.6158 39.0287 13.4548 39.2172 13.2493C39.4057 13.0437 39.546 12.7987 39.6279 12.5321C39.7097 12.2654 39.7311 11.9839 39.6904 11.708C39.7281 11.4332 39.7046 11.1534 39.6215 10.8887C39.5384 10.6241 39.3977 10.3811 39.2097 10.1771C39.0216 9.9732 38.7908 9.8134 38.5337 9.70916C38.2766 9.60492 37.9997 9.55884 37.7227 9.57421H36.5977V13.854Z"
                            fill="white"
                        />
                        <path
                            d="M41.6807 12.4443C41.6524 12.1484 41.6862 11.8499 41.7801 11.5678C41.8739 11.2857 42.0257 11.0264 42.2256 10.8064C42.4255 10.5864 42.6693 10.4106 42.9411 10.2904C43.213 10.1701 43.5069 10.108 43.8042 10.108C44.1015 10.108 44.3955 10.1701 44.6673 10.2904C44.9392 10.4106 45.1829 10.5864 45.3828 10.8064C45.5828 11.0264 45.7345 11.2857 45.8284 11.5678C45.9222 11.8499 45.9561 12.1484 45.9278 12.4443C45.9566 12.7406 45.9231 13.0395 45.8296 13.3221C45.736 13.6046 45.5843 13.8644 45.3843 14.0848C45.1843 14.3052 44.9404 14.4814 44.6683 14.6019C44.3962 14.7224 44.1018 14.7847 43.8042 14.7847C43.5066 14.7847 43.2123 14.7224 42.9401 14.6019C42.668 14.4814 42.4241 14.3052 42.2241 14.0848C42.0241 13.8644 41.8725 13.6046 41.7789 13.3221C41.6853 13.0395 41.6518 12.7406 41.6807 12.4443V12.4443ZM45.0137 12.4443C45.0137 11.4683 44.5752 10.8975 43.8057 10.8975C43.0332 10.8975 42.5987 11.4683 42.5987 12.4444C42.5987 13.4282 43.0333 13.9946 43.8057 13.9946C44.5752 13.9946 45.0137 13.4243 45.0137 12.4443H45.0137Z"
                            fill="white"
                        />
                        <path
                            d="M51.5733 14.6978H50.6514L49.7207 11.3813H49.6504L48.7237 14.6978H47.8106L46.5694 10.1948H47.4707L48.2774 13.6308H48.3438L49.2696 10.1948H50.1221L51.0479 13.6308H51.1182L51.9209 10.1948H52.8096L51.5733 14.6978Z"
                            fill="white"
                        />
                        <path
                            d="M53.8535 10.1948H54.709V10.9101H54.7754C54.8881 10.6532 55.0781 10.4379 55.3189 10.2941C55.5598 10.1502 55.8395 10.0852 56.1192 10.1079C56.3383 10.0914 56.5583 10.1245 56.7629 10.2046C56.9675 10.2847 57.1514 10.4098 57.3011 10.5706C57.4508 10.7314 57.5624 10.9239 57.6276 11.1337C57.6928 11.3435 57.7099 11.5653 57.6777 11.7827V14.6977H56.7891V12.0059C56.7891 11.2822 56.4746 10.9224 55.8174 10.9224C55.6686 10.9154 55.5201 10.9407 55.3821 10.9966C55.244 11.0524 55.1197 11.1374 55.0176 11.2458C54.9154 11.3542 54.8379 11.4834 54.7904 11.6245C54.7429 11.7656 54.7264 11.9154 54.7422 12.0635V14.6978H53.8535L53.8535 10.1948Z"
                            fill="white"
                        />
                        <path d="M59.0938 8.437H59.9824V14.6977H59.0938V8.437Z" fill="white" />
                        <path
                            d="M61.2178 12.4443C61.1895 12.1484 61.2234 11.8498 61.3172 11.5677C61.4111 11.2857 61.5629 11.0263 61.7628 10.8063C61.9628 10.5863 62.2065 10.4106 62.4784 10.2903C62.7503 10.17 63.0443 10.1079 63.3416 10.1079C63.6389 10.1079 63.9329 10.17 64.2047 10.2903C64.4766 10.4106 64.7203 10.5863 64.9203 10.8063C65.1203 11.0263 65.272 11.2857 65.3659 11.5677C65.4598 11.8498 65.4936 12.1484 65.4653 12.4443C65.4942 12.7406 65.4607 13.0396 65.3671 13.3221C65.2734 13.6046 65.1217 13.8644 64.9217 14.0848C64.7217 14.3052 64.4778 14.4814 64.2057 14.6019C63.9335 14.7224 63.6392 14.7847 63.3416 14.7847C63.0439 14.7847 62.7496 14.7224 62.4775 14.6019C62.2053 14.4814 61.9614 14.3052 61.7614 14.0848C61.5614 13.8644 61.4097 13.6046 61.3161 13.3221C61.2225 13.0396 61.189 12.7406 61.2178 12.4443V12.4443ZM64.5508 12.4443C64.5508 11.4683 64.1123 10.8975 63.3428 10.8975C62.5703 10.8975 62.1358 11.4683 62.1358 12.4444C62.1358 13.4282 62.5704 13.9946 63.3428 13.9946C64.1123 13.9946 64.5508 13.4243 64.5508 12.4443H64.5508Z"
                            fill="white"
                        />
                        <path
                            d="M66.4009 13.4243C66.4009 12.6138 67.0044 12.1465 68.0757 12.0801L69.2954 12.0098V11.6211C69.2954 11.1455 68.981 10.877 68.3736 10.877C67.8775 10.877 67.5337 11.0591 67.4351 11.3774H66.5747C66.6655 10.604 67.3931 10.1079 68.4146 10.1079C69.5435 10.1079 70.1802 10.6699 70.1802 11.6211V14.6978H69.3247V14.0649H69.2544C69.1117 14.2919 68.9113 14.477 68.6737 14.6012C68.4361 14.7254 68.1697 14.7843 67.9019 14.7719C67.7128 14.7916 67.5218 14.7714 67.341 14.7127C67.1603 14.654 66.9938 14.5581 66.8524 14.4312C66.711 14.3042 66.5977 14.149 66.52 13.9756C66.4422 13.8022 66.4016 13.6144 66.4009 13.4243V13.4243ZM69.2954 13.0395V12.6631L68.1958 12.7334C67.5757 12.7749 67.2944 12.9858 67.2944 13.3828C67.2944 13.7881 67.646 14.0239 68.1294 14.0239C68.2711 14.0383 68.4142 14.024 68.5502 13.9819C68.6862 13.9398 68.8123 13.8708 68.9211 13.7789C69.0299 13.6871 69.1191 13.5743 69.1834 13.4473C69.2477 13.3203 69.2858 13.1816 69.2954 13.0395V13.0395Z"
                            fill="white"
                        />
                        <path
                            d="M71.3482 12.4443C71.3482 11.0215 72.0796 10.1201 73.2173 10.1201C73.4987 10.1072 73.778 10.1746 74.0225 10.3145C74.2671 10.4543 74.4667 10.661 74.5982 10.9101H74.6646V8.437H75.5532V14.6977H74.7017V13.9863H74.6314C74.4898 14.2338 74.2831 14.4378 74.0339 14.5763C73.7846 14.7148 73.5023 14.7825 73.2173 14.7719C72.0718 14.772 71.3482 13.8706 71.3482 12.4443ZM72.2662 12.4443C72.2662 13.3994 72.7164 13.9741 73.4693 13.9741C74.2183 13.9741 74.6812 13.3911 74.6812 12.4482C74.6812 11.5098 74.2134 10.9185 73.4693 10.9185C72.7212 10.9185 72.2661 11.4971 72.2661 12.4443H72.2662Z"
                            fill="white"
                        />
                        <path
                            d="M79.23 12.4443C79.2017 12.1484 79.2355 11.8499 79.3294 11.5678C79.4232 11.2857 79.575 11.0264 79.7749 10.8064C79.9749 10.5864 80.2186 10.4106 80.4904 10.2904C80.7623 10.1701 81.0563 10.108 81.3535 10.108C81.6508 10.108 81.9448 10.1701 82.2166 10.2904C82.4885 10.4106 82.7322 10.5864 82.9321 10.8064C83.1321 11.0264 83.2838 11.2857 83.3777 11.5678C83.4715 11.8499 83.5054 12.1484 83.4771 12.4443C83.5059 12.7406 83.4725 13.0395 83.3789 13.3221C83.2853 13.6046 83.1336 13.8644 82.9336 14.0848C82.7336 14.3052 82.4897 14.4814 82.2176 14.6019C81.9455 14.7224 81.6512 14.7847 81.3535 14.7847C81.0559 14.7847 80.7616 14.7224 80.4895 14.6019C80.2173 14.4814 79.9734 14.3052 79.7734 14.0848C79.5734 13.8644 79.4218 13.6046 79.3282 13.3221C79.2346 13.0395 79.2012 12.7406 79.23 12.4443V12.4443ZM82.563 12.4443C82.563 11.4683 82.1245 10.8975 81.355 10.8975C80.5825 10.8975 80.148 11.4683 80.148 12.4444C80.148 13.4282 80.5826 13.9946 81.355 13.9946C82.1245 13.9946 82.563 13.4243 82.563 12.4443Z"
                            fill="white"
                        />
                        <path
                            d="M84.6694 10.1948H85.5249V10.9101H85.5913C85.704 10.6532 85.894 10.4379 86.1349 10.2941C86.3757 10.1502 86.6555 10.0852 86.9351 10.1079C87.1542 10.0914 87.3742 10.1245 87.5788 10.2046C87.7834 10.2847 87.9673 10.4098 88.117 10.5706C88.2667 10.7314 88.3783 10.9239 88.4435 11.1337C88.5087 11.3435 88.5258 11.5653 88.4937 11.7827V14.6977H87.605V12.0059C87.605 11.2822 87.2906 10.9224 86.6333 10.9224C86.4846 10.9154 86.3361 10.9407 86.198 10.9966C86.0599 11.0524 85.9356 11.1374 85.8335 11.2458C85.7313 11.3542 85.6539 11.4834 85.6063 11.6245C85.5588 11.7656 85.5424 11.9154 85.5581 12.0635V14.6978H84.6694V10.1948Z"
                            fill="white"
                        />
                        <path
                            d="M93.5152 9.07373V10.2153H94.4908V10.9639H93.5152V13.2793C93.5152 13.751 93.7095 13.9575 94.1519 13.9575C94.2651 13.9572 94.3783 13.9503 94.4908 13.937V14.6772C94.3312 14.7058 94.1695 14.721 94.0073 14.7226C93.0191 14.7226 92.6255 14.375 92.6255 13.5068V10.9638H91.9107V10.2153H92.6255V9.07373H93.5152Z"
                            fill="white"
                        />
                        <path
                            d="M95.7046 8.437H96.5855V10.9185H96.6558C96.7739 10.6591 96.9691 10.4425 97.2148 10.2982C97.4605 10.1538 97.7448 10.0888 98.0288 10.1118C98.2467 10.0999 98.4646 10.1363 98.6669 10.2184C98.8692 10.3004 99.0509 10.4261 99.199 10.5864C99.3471 10.7468 99.458 10.9378 99.5238 11.146C99.5896 11.3541 99.6086 11.5742 99.5796 11.7905V14.6978H98.69V12.0098C98.69 11.2905 98.355 10.9263 97.7271 10.9263C97.5744 10.9137 97.4207 10.9347 97.277 10.9878C97.1332 11.0408 97.0027 11.1247 96.8947 11.2334C96.7868 11.3421 96.7038 11.4732 96.6518 11.6173C96.5997 11.7614 96.5797 11.9152 96.5933 12.0679V14.6977H95.7047L95.7046 8.437Z"
                            fill="white"
                        />
                        <path
                            d="M104.761 13.4819C104.641 13.8935 104.379 14.2495 104.022 14.4876C103.666 14.7258 103.236 14.8309 102.81 14.7847C102.513 14.7925 102.219 14.7357 101.946 14.6181C101.674 14.5006 101.43 14.3252 101.232 14.1041C101.034 13.8829 100.887 13.6214 100.8 13.3375C100.713 13.0537 100.689 12.7544 100.73 12.4605C100.69 12.1656 100.715 11.8656 100.801 11.581C100.888 11.2963 101.034 11.0335 101.231 10.8104C101.428 10.5874 101.671 10.4092 101.942 10.288C102.214 10.1668 102.509 10.1054 102.806 10.1079C104.059 10.1079 104.815 10.9639 104.815 12.3779V12.688H101.635V12.7378C101.621 12.903 101.642 13.0694 101.696 13.2261C101.75 13.3829 101.837 13.5266 101.95 13.648C102.062 13.7695 102.2 13.866 102.352 13.9314C102.504 13.9968 102.669 14.0296 102.834 14.0278C103.047 14.0533 103.262 14.015 103.453 13.9178C103.644 13.8206 103.801 13.6689 103.906 13.4819L104.761 13.4819ZM101.635 12.0308H103.91C103.921 11.8796 103.9 11.7278 103.849 11.5851C103.798 11.4424 103.718 11.3119 103.614 11.2021C103.509 11.0922 103.383 11.0054 103.243 10.9472C103.103 10.889 102.953 10.8608 102.801 10.8643C102.648 10.8623 102.495 10.8912 102.353 10.9491C102.21 11.0071 102.081 11.0929 101.972 11.2016C101.864 11.3104 101.778 11.4397 101.72 11.5821C101.662 11.7245 101.633 11.8771 101.635 12.0308H101.635Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1_37">
                            <rect width="119.664" height="40" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </>
    );
};
