import React, { useCallback, useState } from 'react';
import { EmailInput } from '../inputs/email';
import FieldContainer from './field-container';
import { Validatable } from '../index';

export interface EmailFieldProps {
    value: string;
    valueValid: boolean;
    autoFocus?: boolean;
    required?: boolean;
    labelWidth?: number;
    labelTextKey?: string;
    inputWidth?: number;
    errorText?: string;
    maxLength?: number;
    onChange?: (value: string, valid: boolean) => void;
}

export const EmailField = React.forwardRef<Validatable, EmailFieldProps>((props, ref) => {
    const [errorText, setErrorText] = useState('');

    const onEmailChange = useCallback(
        (value: string, valid: boolean, errorText: string) => {
            setErrorText(errorText);
            props.onChange?.(value, valid);
        },
        [props.onChange],
    );

    return (
        <FieldContainer
            labelWidth={props.labelWidth}
            labelTextKey={props.labelTextKey ?? 'Email'}
            inputWidth={props.inputWidth}
            errorText={errorText}
        >
            <EmailInput
                ref={ref}
                autoFocus={props.autoFocus}
                required={props.required}
                value={props.value}
                valueValid={props.valueValid}
                maxLength={props.maxLength}
                onChange={onEmailChange}
            />
        </FieldContainer>
    );
});

EmailField.displayName = 'EmailField';
