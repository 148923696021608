import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { translate } from 'utilities';
import { SvgIcon } from 'ui';
import { Theme, combineStyles } from '../../theme';

export const createStyles = makeStyles((theme: Theme) => ({
    root: theme.custom?.searchField.filterRoot || {},
    fullWidth: theme.custom?.searchField.fullWidth || {},
    input: theme.custom?.searchField.input || {},
    resetIconBlock: theme.custom?.searchField.resetIconBlock || {},
    typeIconBlock: theme.custom?.searchField.typeIconBlock || {},
}));

export interface Props {
    /**Значение инпута */
    value: string;
    /**Параметр определяет ширину инпута */
    fullWidth?: boolean;
    /**Коллбек функция при изменении инпута */
    onChange?: (value: string) => void;
}
/**Поле для фильтрации. Кнопка стереть появляется, если в инпуте есть значение. */
export const FilterInput: React.FC<Props> = (props) => {
    let { value, fullWidth, onChange } = props;
    let classes = createStyles();

    const onChangeHandler = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (onChange) {
                onChange(event.target.value);
            }
        },
        [onChange],
    );

    const onResetHandler = React.useCallback((): void => {
        if (onChange) {
            onChange('');
        }
    }, [onChange]);

    let rootClass = classes.root;
    if (fullWidth) {
        rootClass = combineStyles(rootClass, classes.fullWidth);
    }
    return (
        <div className={rootClass}>
            <div className={classes.typeIconBlock}>
                <SvgIcon type="filter" />
            </div>
            <input
                placeholder={translate('Filter')}
                className={classes.input}
                value={value}
                onChange={onChangeHandler}
            />
            {value.length > 0 && (
                <div className={classes.resetIconBlock} onClick={onResetHandler}>
                    <SvgIcon type="clear" />
                </div>
            )}
        </div>
    );
};
