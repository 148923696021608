import { createTheme } from './theme-creator';

export let defaultTheme = createTheme({});

export let defaultFmrTheme = createTheme(
    {},
    {
        colors: {
            white: '#FFFFFF',
            grey10: '#E4E5E9',
            grey20: '#CACBD2',
            grey30: '#AFB1BC',
            grey40: '#9596A5',
            grey50: '#7A7C8F',
            grey60: '#636575',
            grey70: '#4C4E5B',
            grey80: '#363740',
            grey90: '#2A2B32',
            black: '#111111',
            color1: 'navy',
            color2: 'crimson',
            color3: 'teal',
            color4: 'purple',
            color5: 'darkorange',
            color6: 'darkgreen',
            color7: 'indigo',
            color8: 'maroon',
            color9: 'royalblue',
            color10: 'mediumvioletred',
            customColorsContrastText: '#FFFFFF',
            transparent: 'rgba(0, 0, 0, 0)',
            partialTransparent: 'rgba(54, 55, 64, 0.6)',
            shadow: '0px 4px 16px rgba(1, 23, 34, 0.16)',
            scroll: '#CACBD2',
            scrollBackground: '#E4E5E9',
        },
        primary: {
            main: '#999585',
            hover: '#7F7C6E',
            fm3: '#389FCC',
            contrastText: '#FFFFFF',
        },
        alert: {
            main: '#EB5757',
            hover: '#E82E2E',
        },
        actions: {
            hover: '#E4E5E9',
            select: '#CACBD2',
        },
        levels: {
            success: '#27AE60',
            error: '#EB5757',
            warning: '#E2B207',
            info: '#389FCC',
            debug: '#4C4E5B',
            system: '#9596A5',
        },
        background: {
            default: '#F1F1F1',
            main: '#FFFFFF',
            toolbar: '#E4E5E9',
            page: '#C4C5C9',
        },
        divider: '#D0DAE6',
        sidebar: {
            background: '#363740',
            hoverBackground: '#363740',
            selectBackground: '#4C4E5B',
            border: '#4C4E5B',
            titleText: '#9596A5',
            text: '#AFB1BC',
            hoverText: '#FFFFFF',
            selectText: '#FFFFFF',
            buttonText: '#CACBD2',
            scroll: '#7A7C8F',
            scrollBackground: 'rgba(0, 0, 0, 0)',
            toolbar: '#4C4E5B',
        },
        text: {
            primary: '#4C4E5B',
            secondary: '#7A7C8F',
            disabled: '#AFB1BC',
            placeholder: '#AFB1BC',
        },
    },
);

export let darkTheme = createTheme(
    {},
    {
        colors: {
            white: '#111111',
            grey10: '#2A2B32',
            grey20: '#363740',
            grey30: '#4C4E5B',
            grey40: '#636575',
            grey50: '#7A7C8F',
            grey60: '#9596A5',
            grey70: '#AFB1BC',
            grey80: '#CACBD2',
            grey90: '#E4E5E9',
            black: '#FFFFFF',
            color1: '#0E9594',
            color2: '#542E71',
            color3: '#9A031E',
            color4: '#E36414',
            color5: '#26547C',
            color6: '#D1AC00',
            color7: '#A27035',
            color8: '#0B6E4F',
            color9: '#562C2C',
            color10: '#494949',
            customColorsContrastText: '#FFFFFF',
            transparent: 'rgba(0, 0, 0, 0)',
            partialTransparent: 'rgba(255, 255, 255, 0.15)',
            shadow: '0px 4px 16px rgba(255, 255, 255, 0.16)',
            scroll: '#7A7C8F',
            scrollBackground: 'rgba(0, 0, 0, 0)',
        },
        primary: {
            main: '#389FCC',
            hover: '#2680A7',
            fm3: '#389FCC',
            contrastText: '#FFFFFF',
        },
        alert: {
            main: '#EB5757',
            hover: '#D14F4F',
        },
        actions: {
            hover: '#363740',
            select: '#4C4E5B',
        },
        levels: {
            success: '#27AE60',
            error: '#EB5757',
            warning: '#E2B207',
            info: '#389FCC',
            debug: '#636575',
            system: '#4C4E5B',
        },
        background: {
            default: '#3E3F49',
            main: '#2A2B32',
            toolbar: '#363740',
            page: '#4C4E5B',
        },
        divider: '#636575',
        sidebar: {
            background: '#2A2B32',
            hoverBackground: '#2A2B32',
            selectBackground: '#4C4E5B',
            border: '#4C4E5B',
            titleText: '#AFB1BC',
            text: '#CACBD2',
            hoverText: '#FFFFFF',
            selectText: '#FFFFFF',
            buttonText: '#CACBD2',
            scroll: '#7A7C8F',
            scrollBackground: 'rgba(0, 0, 0, 0)',
            toolbar: '#363740',
        },
        text: {
            primary: '#CACBD2',
            secondary: '#9596A5',
            disabled: '#636575',
            placeholder: '#9596A5',
        },
    },
);

export let contrastTheme = createTheme(
    {},
    {
        colors: {
            white: '#FFFFFF',
            grey10: '#E4E5E9',
            grey20: '#CACBD2',
            grey30: '#AFB1BC',
            grey40: '#9596A5',
            grey50: '#7A7C8F',
            grey60: '#636575',
            grey70: '#4C4E5B',
            grey80: '#363740',
            grey90: '#2A2B32',
            black: '#111111',
            color1: '#0E9594',
            color2: '#542E71',
            color3: '#9A031E',
            color4: '#E36414',
            color5: '#26547C',
            color6: '#D1AC00',
            color7: '#A27035',
            color8: '#0B6E4F',
            color9: '#562C2C',
            color10: '#494949',
            customColorsContrastText: '#FFFFFF',
            transparent: 'rgba(0, 0, 0, 0)',
            partialTransparent: 'rgba(54, 55, 64, 0.6)',
            shadow: '0px 4px 16px rgba(1, 23, 34, 0.16)',
            scroll: '#CACBD2',
            scrollBackground: '#E4E5E9',
        },
        primary: {
            main: '#389FCC',
            hover: '#2680A7',
            fm3: '#389FCC',
            contrastText: '#FFFFFF',
        },
        alert: {
            main: '#EB5757',
            hover: '#D14F4F',
        },
        actions: {
            hover: '#CACBD2',
            select: '#AFB1BC',
        },
        levels: {
            success: '#27AE60',
            error: '#EB5757',
            warning: '#E2B207',
            info: '#389FCC',
            debug: '#4C4E5B',
            system: '#9596A5',
        },
        background: {
            default: '#E4E5E9',
            main: '#FFFFFF',
            toolbar: '#CACBD2',
            page: '#C4C5C9',
        },
        divider: '#D0DAE6',
        sidebar: {
            background: '#363740',
            hoverBackground: '#4C4E5B',
            selectBackground: '#636575',
            border: '#4C4E5B',
            titleText: '#AFB1BC',
            text: '#FFFFFF',
            hoverText: '#FFFFFF',
            selectText: '#FFFFFF',
            buttonText: '#CACBD2',
            scroll: '#7A7C8F',
            scrollBackground: 'rgba(0, 0, 0, 0)',
            toolbar: '#E4E5E9',
        },
        text: {
            primary: '#2A2B32',
            secondary: '#363740',
            disabled: '#636575',
            placeholder: '#636575',
        },
    },
    {
        fontFamily: '"Montserrat", sans-serif',
        baseFontSize: 14,
        h1: {
            fontSize: 28,
            fontWeight: 700,
            letterSpacing: '-0.01em',
        },
        h2: {
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: '-0.01em',
        },
        h3: {
            fontSize: 20,
            fontWeight: 700,
            letterSpacing: '-0.01em',
        },
        h4: {
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: '-0.01em',
        },
        h5: {
            fontSize: 14,
            fontWeight: 700,
        },
        button: {
            fontSize: 14,
            fontWeight: 600,
        },
        input: {
            fontSize: 14,
            fontWeight: 600,
        },
        text: {
            fontSize: 14,
            fontWeight: 600,
        },
        smallText: {
            fontSize: 12,
            fontWeight: 600,
        },
        tinyText: {
            fontSize: 10,
            fontWeight: 600,
        },
        label: {
            fontWeight: 700,
            letterSpacing: '0.05em',
            textTransform: 'uppercase',
        },
        infoMessage: {
            fontSize: 16,
            fontWeight: 600,
        },
    },
);
