import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SvgIconType, SvgIcon } from 'ui';
import { translate } from 'utilities';
import { ButtonIconSize } from '../../constants';
import { Theme, combineStyles } from '../../theme';

let ButtonSize = 16;

const createStyles = makeStyles((theme: Theme) => {
    if (theme.custom) {
        ButtonSize = theme.custom.mainSizes.button.iconSize * 0.8;
    }

    return {
        button: theme.custom?.buttons.contained || {},
        icon: { ...theme.custom?.buttons.containedIcon },
        loading: {
            marginRight: theme.custom?.mainSizes.common.baseSize || 8,
            width: theme.custom?.mainSizes.button.iconSize || 20,
            height: theme.custom?.mainSizes.button.iconSize || 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
                margin: 0,
            },
            '& circle': {
                stroke: theme.custom?.palette.primary.contrastText,
            },
        },
    };
});

export interface ButtonProps {
    /**Ключ для перевода, будет отображено на кнопке */
    textKey?: string;
    /**Иконка на кнопке */
    frontIconType?: SvgIconType;
    /**Добавляет внешний отступ сверху */
    marginTop?: number;
    /**Добавляет margin-left от кнопки */
    marginLeft?: number;
    /**Параметр убирает минимальную ширину */
    disableMinWidth?: boolean;
    /**Убирате внешний отступ справа от кнопки */
    noMargin?: boolean;
    /**Отображение loader */
    showLoadingIcon?: boolean;
    /**Отключить кнопку во время загрузки */
    disableWhileLoading?: boolean;
    /**Функция колбек при нажатии на кнопку */
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}
export const ContainedButton: React.FC<ButtonProps> = (props) => {
    let {
        textKey = '',
        frontIconType,
        disableMinWidth,
        marginTop,
        marginLeft,
        noMargin,
        showLoadingIcon,
        disableWhileLoading,
        onClick = () => undefined,
    } = props;

    let classes = createStyles();

    const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        if (showLoadingIcon && disableWhileLoading) {
            return;
        }

        onClick(event);
    };

    let style: React.CSSProperties = { marginTop, marginLeft };
    if (noMargin) {
        style.margin = 0;
    }
    if (disableMinWidth) {
        style.minWidth = 0;
    }

    let rootStyle = classes.button;
    if (textKey.length == 0) {
        rootStyle = combineStyles(rootStyle, classes.icon);
    }
    return (
        <div style={style} className={rootStyle} onClick={clickHandler}>
            {frontIconType && !showLoadingIcon && <SvgIcon type={frontIconType} size={ButtonIconSize} />}
            {showLoadingIcon && (
                <div className={classes.loading}>
                    <CircularProgress size={ButtonSize} />
                </div>
            )}
            {translate(textKey)}
        </div>
    );
};
