import React from 'react';

import { SortOrder } from 'entities';

import { FilterColumn, instanceOfTableColumn, TableProps, TableState, ToolbarProps } from '.';
import TableContainer from './table-container';

/** Таблица для отображения данных. В шапке таблицы находится тулбар, в котором можно выпонять поиск записи, фитрацию записей.
 *  В тулбаре есть встроенная кнопка "Добавить" и возможность добавить кастомные кнопки. */
export const Table = <T,>(props: TableProps<T>): JSX.Element => {
    let [filterColumns, setFilterColumns] = React.useState<FilterColumn<T>[]>([]);

    const onSortingStateChange = (sortingKey: keyof T, sortingOrder: SortOrder): void => {
        let newState: TableState<T> = {
            ...props.state,
            sortingKey,
            sortingOrder,
        };
        props.updateState(newState);
    };

    const onSortingOrderChange = (sortingOrder: SortOrder): void => {
        let newState: TableState<T> = {
            ...props.state,
            sortingOrder: sortingOrder,
        };
        props.updateState(newState);
    };

    const onSelectionChange = (selectedId: number | null): void => {
        let selectedRecord = props.records.find((item) => Number(item[props.idKey]) == selectedId);
        if (selectedRecord) {
            props.onRowClick?.(selectedRecord);
        }
        let newState: TableState<T> = {
            ...props.state,
            selectedId,
        };
        props.updateState(newState);
    };

    const onToolbarPropsChange = (payload: ToolbarProps<T>): void => {
        setFilterColumns(payload.filterColumns);
        let filterColumnsState = { ...props.state.filterColumnsState };
        payload.filterColumns.forEach((item) => (filterColumnsState[item.key] = item.active));
        let newState: TableState<T> = {
            ...props.state,
            filterText: payload.filterText,
            filterColumnsState,
            caseSensitiveFilter: payload.caseSensitivity,
            wholeMatchFilter: payload.wholeMatch,
        };
        props.updateState(newState);
    };

    React.useEffect(() => {
        let newFilterColumns: FilterColumn<T>[] = [];

        props.columns.forEach((column) => {
            if (instanceOfTableColumn(column) && column.useForFilter) {
                newFilterColumns.push({
                    key: column.key,
                    active: !!props.state.filterColumnsState[column.key],
                    label: column.title,
                });
            }
        });
        setFilterColumns(newFilterColumns);
    }, [props.columns]);

    return (
        <TableContainer
            idKey={props.idKey}
            records={props.records}
            columns={props.columns}
            tableProps={{
                footerItems: props.footerItems,
                showLoading: props.showLoading,
                errorText: props.errorText,
                sortingKey: props.state.sortingKey,
                sortingOrder: props.state.sortingOrder,
                selectedId: props.state.selectedId,
                showFooter: true,
                needScrollToSelectedChangeFlag: props.needScrollToSelectedChangeFlag,
                onSortingStateChange,
                onSortingOrderChange,
                onSelectionChange,
            }}
            toolbarProps={{
                filterText: props.state.filterText,
                customFilters: props.customFilters,
                filterColumns,
                caseSensitivity: props.state.caseSensitiveFilter,
                wholeMatch: props.state.wholeMatchFilter,
                onCreateClick: props.onCreateClick,
                showCreateButton: props.showCreateButton ?? true,
                customButtons: props.customButtons,
                alternativeToolbar: props.alternativeToolbar,
                tags: props.tags,
                onRemoveTag: props.onRemoveTag,
                showExportButton: props.showExportButton,
            }}
            fileNameForExport={props.fileNameForExport}
            onToolbarPropsChange={onToolbarPropsChange}
        />
    );
};
