import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { makeStyles } from '@material-ui/styles';

import { SortOrder } from 'entities';
import { Theme, combineStyles } from 'theme';
import { EmptyListPane } from 'ui';
import { translate } from 'utilities';

import { TableColumn, TotalCountType, instanceOfTableColumn, NumberTableColumn } from '.';
import Body from './body';
import { TableProps, fillColumnsToDisplay } from './entities';
import Footer from './footer';
import Header from './header';
import { TotalRow } from './total-row';
import { LowerToolbar } from './lower-toolbar';
import { CheckBoxState } from '../icons/state-ckeckbox-icon';

const useStyles = makeStyles((theme: Theme) => ({
    baseTable: {
        flex: '1 1 0',
        borderRadius: theme.custom?.mainSizes.common.mediumBorderRadius,
        backgroundColor: theme.custom?.palette.background.main,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        overflow: 'hidden',
    },
    withBorder: {
        border: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
    },
    bodyContainer: {
        flex: '1 1 0',
    },
}));

/**Компонент - полноценная таблица, без тулбара */
export const BaseTable = <T,>(props: TableProps<T>): JSX.Element => {
    let {
        idKey,
        columns,
        records,
        selectedId = null,
        onSelectionChange,
        errorText = '',
        showLoading = false,
        sortingKey,
        sortingOrder,
        showBorder,
        showFooter,
        showCheckboxes: showCheckboxes,
        checkedData,
        updateCheckedData,
        needScrollToSelectedChangeFlag,
        needAppendFooterItems: needAppendFooter,
        showTotalRow,
        infoText,
        showLowerToolbar = false,
        lowerToolbarItems = null,
    } = props;
    let classes = useStyles();

    let headerRef = React.useRef<HTMLDivElement>(null);
    let totalRowRef = React.useRef<HTMLDivElement>(null);
    let [hasScroll, setHasScroll] = React.useState<boolean>(false);
    let [rowWidth, setRowWidth] = React.useState<number>(0);
    let [sortedRecords, setSortedRecords] = React.useState<T[]>(records);
    let [recordsReady, setRecordsReady] = React.useState(false);
    let [totalRowData, setTotalRowData] = React.useState<Partial<Record<keyof T, number>>>({});
    let [headerCheckboxState, setHeaderCheckboxState] = React.useState<CheckBoxState>(CheckBoxState.Unchecked);

    const updateSortingSettings = React.useCallback(
        (key: keyof T) => {
            if (key == sortingKey) {
                props.onSortingOrderChange?.(
                    sortingOrder == SortOrder.Ascension ? SortOrder.Descension : SortOrder.Ascension,
                );
                return;
            }

            let column = columns.find((column) => instanceOfTableColumn(column) && column.key == key);
            let newOrder = (column as TableColumn<T>)?.defaultSortOrder || SortOrder.Ascension;
            props.onSortingStateChange?.(key, newOrder);
        },
        [sortingKey, sortingOrder, props.onSortingStateChange, props.onSortingOrderChange],
    );

    const checkAllRecords = () => {
        if (showCheckboxes) {
            const checkedRecords: Record<string, boolean> = {};
            if (headerCheckboxState === CheckBoxState.Checked) {
                if (checkedData) {
                    for (let record of Object.keys(checkedData)) {
                        checkedRecords[record] = false;
                    }
                    updateCheckedData?.(checkedRecords);
                }
            } else {
                for (let record of records) {
                    checkedRecords[String(record[idKey])] = true;
                }
                updateCheckedData?.(checkedRecords);
            }
        }
    };

    React.useEffect(() => {
        setRecordsReady(false);
    }, [showLoading]);

    React.useEffect(() => {
        if (showLoading) {
            return;
        }

        let result = [...records];

        if (!sortingKey) {
            setSortedRecords(result);
            setRecordsReady(true);
            props.updateRecordsForExport?.(result);
            return;
        }

        let keyForSorting: keyof T;
        keyForSorting = sortingKey;

        result.sort((item1, item2) => {
            let value1 =
                typeof item1[keyForSorting] == 'string'
                    ? (item1[keyForSorting] as unknown as string).toLowerCase()
                    : item1[keyForSorting];
            let value2 =
                typeof item2[keyForSorting] == 'string'
                    ? (item2[keyForSorting] as unknown as string).toLowerCase()
                    : item2[keyForSorting];

            if (value1 > value2) {
                return sortingOrder == SortOrder.Ascension ? 1 : -1;
            }

            if (value1 < value2) {
                return sortingOrder == SortOrder.Ascension ? -1 : 1;
            }

            return 0;
        });

        setSortedRecords(result);
        setRecordsReady(true);
        props.updateRecordsForExport?.(result);
    }, [records, sortingKey, sortingOrder]);

    React.useEffect(() => {
        if (!showTotalRow) {
            return;
        }

        let totalRowColumnsToDisplay: TableColumn<T>[] = [];
        props.columns.forEach((column) => fillColumnsToDisplay(column, totalRowColumnsToDisplay));

        let newTotalRowData: Partial<Record<keyof T, number>> = {};
        totalRowColumnsToDisplay.forEach((column) => {
            if (!(column instanceof NumberTableColumn) || column.totalCountType == TotalCountType.None) {
                return;
            }

            let result = records.reduce<number>(
                (accumulator, value) => accumulator + parseFloat(value[column.key] as unknown as string),
                0,
            );
            if (column.totalCountType == TotalCountType.Average) {
                result /= records.length;
            }

            newTotalRowData[column.key] = result;
        });

        setTotalRowData(newTotalRowData);
    }, [records, columns, showTotalRow]);

    React.useEffect(() => {
        if (showCheckboxes && checkedData && records.length > 0) {
            let checkedDataCount = 0;
            for (let checkedRecord of Object.keys(checkedData)) {
                if (checkedData[checkedRecord]) {
                    checkedDataCount++;
                }
            }

            if (checkedDataCount === records.length) {
                setHeaderCheckboxState(CheckBoxState.Checked);
                return;
            }
            if (checkedDataCount === 0) {
                setHeaderCheckboxState(CheckBoxState.Unchecked);
                return;
            }
            setHeaderCheckboxState(CheckBoxState.Middle);
        }
    }, [records, checkedData, showCheckboxes]);

    let configErrorText = '';
    if (columns.length == 0) {
        configErrorText = translate('TableConfigError');
    }

    if (configErrorText) {
        return (
            <div className={classes.baseTable}>
                <EmptyListPane translationKey={configErrorText} />
            </div>
        );
    }

    let footerItems = [...(props.footerItems ?? [])];
    if (needAppendFooter) {
        footerItems.push({ label: translate('Shown'), value: sortedRecords.length });
    }

    if (sortedRecords.length == 0 && !infoText) {
        infoText = translate('NoData');
    }

    let tableClasses = classes.baseTable;
    if (showBorder) {
        tableClasses = combineStyles(tableClasses, classes.withBorder);
    }
    return (
        <div className={tableClasses}>
            <Header
                columns={columns}
                rowWidth={rowWidth}
                containerRef={headerRef}
                sortingKey={props.sortingKey}
                sortingOrder={props.sortingOrder}
                hasScroll={hasScroll}
                showCheckboxes={showCheckboxes}
                updateSortingSettings={updateSortingSettings}
                checkboxState={headerCheckboxState}
                onCheckboxClick={checkAllRecords}
            />
            <div className={classes.bodyContainer}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Body
                            idKey={idKey}
                            records={sortedRecords}
                            columns={columns}
                            width={width}
                            height={height}
                            headerRef={headerRef}
                            totalRowRef={totalRowRef}
                            setHasScroll={setHasScroll}
                            setRowWidth={setRowWidth}
                            selectedId={selectedId}
                            onSelectionChange={onSelectionChange}
                            infoText={infoText}
                            errorText={errorText}
                            showLoading={!recordsReady}
                            showCheckboxes={showCheckboxes}
                            checkedData={checkedData}
                            updateCheckedData={updateCheckedData}
                            needScrollToSelectedChangeFlag={needScrollToSelectedChangeFlag}
                        />
                    )}
                </AutoSizer>
            </div>
            {showTotalRow && records.length > 1 && (
                <TotalRow
                    containerRef={totalRowRef}
                    rowWidth={rowWidth}
                    columns={columns}
                    showCheckboxes={showCheckboxes ?? false}
                    data={totalRowData}
                    hasScroll={hasScroll}
                />
            )}
            {showFooter && <Footer items={footerItems} />}
            {showLowerToolbar && <LowerToolbar>{lowerToolbarItems}</LowerToolbar>}
        </div>
    );
};

export default BaseTable;
