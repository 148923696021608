import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    applySelectionToView,
    defaultLanguage,
    defaultTranslates,
    SharedDataStorage,
    SharedDataStorageState,
} from './entities';
import { LoadingState } from 'ui';
import { getSharedData } from './api';
import { toast } from 'react-toastify';

export const loadSharedData = createAsyncThunk('login/loadSharedData', async (none, { dispatch }) => {
    const response = await getSharedData();

    let currentNewsIndex = 0;
    let newCurrentNewsIndex = 0;

    if (localStorage.getItem('currentNewsIndex')) {
        currentNewsIndex = Number(localStorage.getItem('currentNewsIndex'));
        newCurrentNewsIndex = currentNewsIndex + 1;
    }

    if (newCurrentNewsIndex > response.news.length - 1) {
        newCurrentNewsIndex = 0;
    }

    localStorage.setItem('currentNewsIndex', newCurrentNewsIndex.toString());

    let newsView = applySelectionToView({
        newsView: response.news,
        newCurrentNewsIndex,
        oldCurrentNewsIndex: currentNewsIndex,
    });

    dispatch(setCurrentNewsIndex(newCurrentNewsIndex));

    return { ...response, news: newsView };
});

const initialState: SharedDataStorageState = {
    sharedDataStorage: {
        showOldInterfaceSwitcher: false,
        news: [],
        languages: [defaultLanguage],
    },
    loadingState: LoadingState.InProgress,
    textErrorMessage: '',

    translates: defaultTranslates,
    currentNewsIndex: 0,
    intervalId: 0,
};

const slice = createSlice({
    name: 'SharedDataStorage',
    initialState,
    reducers: {
        setCurrentNewsIndex: (state, action: PayloadAction<number>) => {
            state.currentNewsIndex = action.payload;
        },
        setSelectedItemByIndex: (state, action: PayloadAction<number>) => {
            const oldCurrentNewsIndex = state.currentNewsIndex;
            let newCurrentNewsIndex = action.payload;
            if (newCurrentNewsIndex == oldCurrentNewsIndex) {
                return;
            }

            let newsView = applySelectionToView({
                newsView: state.sharedDataStorage.news,
                newCurrentNewsIndex,
                oldCurrentNewsIndex,
            });

            localStorage.setItem('currentNewsIndex', newCurrentNewsIndex.toString());
            state.currentNewsIndex = newCurrentNewsIndex;
            state.sharedDataStorage.news = newsView;
        },
        showNextNewsImage: (state) => {
            const oldCurrentNewsIndex = state.currentNewsIndex;
            let newCurrentNewsIndex = state.currentNewsIndex + 1;
            let newsView = state.sharedDataStorage.news;
            if (newCurrentNewsIndex > newsView.length - 1) {
                newCurrentNewsIndex = 0;
            }

            newsView = applySelectionToView({ newsView, newCurrentNewsIndex, oldCurrentNewsIndex });

            localStorage.setItem('currentNewsIndex', newCurrentNewsIndex.toString());
            state.currentNewsIndex = newCurrentNewsIndex;
            state.sharedDataStorage.news = newsView;
        },
        showPrevNewsImage: (state) => {
            const oldCurrentNewsIndex = state.currentNewsIndex;
            let newCurrentNewsIndex = state.currentNewsIndex - 1;
            let newsView = state.sharedDataStorage.news;
            if (newCurrentNewsIndex < 0) {
                newCurrentNewsIndex = newsView.length - 1;
            }

            newsView = applySelectionToView({ newsView, newCurrentNewsIndex, oldCurrentNewsIndex });

            localStorage.setItem('currentNewsIndex', newCurrentNewsIndex.toString());
            state.currentNewsIndex = newCurrentNewsIndex;
            state.sharedDataStorage.news = newsView;
        },
        setIntervalId: (state, action: PayloadAction<number>) => {
            state.intervalId = action.payload;
        },
        updateTranslations: (state, action: PayloadAction<string>) => {
            const language = action.payload;
            let currentTranslations = state.sharedDataStorage.languages.find((item) => item.code == language);

            if (!currentTranslations) {
                return;
            }

            state.translates = currentTranslations.translates;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadSharedData.pending, (state) => {
            state.loadingState = LoadingState.InProgress;
            state.textErrorMessage = '';
        });
        builder.addCase(loadSharedData.fulfilled, (state, action: PayloadAction<SharedDataStorage>) => {
            state.loadingState = LoadingState.Success;
            state.sharedDataStorage = action.payload;
        });
        builder.addCase(loadSharedData.rejected, (state, action) => {
            const errorText = action.error.message ?? 'Unknown Error';
            state.loadingState = LoadingState.Failure;
            toast.error(errorText);
        });
    },
});

export const sharedDataStorageReducer = slice.reducer;

export const {
    setCurrentNewsIndex,
    setIntervalId,
    setSelectedItemByIndex,
    showNextNewsImage,
    showPrevNewsImage,
    updateTranslations,
} = slice.actions;
