import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme, combineStyles } from 'theme';
import { CheckboxIcon } from 'ui';
import { getFullDateTimeString } from 'utilities';

import { AnyColumn, TableColumn, TableColumnDataType } from '.';
import { fillColumnsToDisplay, getCellStyle } from './entities';
import { Cell, CheckboxCell } from './cell';

let RowHeight = 36;

const useStyles = makeStyles((theme: Theme) => {
    if (theme.custom) {
        RowHeight = theme.custom.mainSizes.table.rowHeight;
    }

    return {
        row: {
            boxSizing: 'border-box',
            height: theme.custom?.mainSizes.table.rowHeight,
            borderBottom: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
            display: 'flex',
            alignItems: 'stretch',
            overflow: 'hidden',
            '&:last-child': {
                borderBottom: 'none',
            },
            '&:hover': {
                backgroundColor: theme.custom?.palette.actions.hover,
            },
        },
        selectedRow: {
            backgroundColor: theme.custom?.palette.actions.select,
            '&:hover': {
                backgroundColor: theme.custom?.palette.actions.select,
            },
        },
        clickable: {
            cursor: 'pointer',
        },
    };
});

export interface RowProps<T> {
    /**Параметр - ключ (может быть любая колонка) идентификации строки. Нужен для коллбек функций */
    idKey: keyof T;
    /**Одна запись из данных */
    record: T;
    /**Параметр, задающий колонки */
    columns: AnyColumn<T>[];
    /**Дополнительные стили для контейнера компонента*/
    style: React.CSSProperties;
    /**Параметр, отвечающий за отображение дополнительных стилей, если строка выбрана */
    selected: boolean;
    /**Параметр, отвечающий за отображение чекбокса */
    showCheckbox?: boolean;
    /**Параметр, отвечающий за состояние чекбокса */
    checked?: boolean;
    /**Коллбек функция при смене выбранной строки */
    onSelectionChange?: (selectedId: number | null) => void;
    /**Коллбек функция при смене состояния чекбокса */
    updateChecked?: (id: string, checked: boolean) => void;
}
/**Компонент - строчка таблицы */
export const Row = <T,>(props: RowProps<T>): JSX.Element => {
    let classes = useStyles();

    let selectRecord = React.useCallback(() => {
        props.onSelectionChange?.(Number(props.record[props.idKey]));
    }, [props.onSelectionChange]);

    let rowClasses = classes.row;
    if (props.selected) {
        rowClasses = combineStyles(rowClasses, classes.selectedRow);
    }
    if (props.onSelectionChange) {
        rowClasses = combineStyles(rowClasses, classes.clickable);
    }

    let columnsToDisplay: TableColumn<T>[] = [];
    props.columns.forEach((column) => fillColumnsToDisplay(column, columnsToDisplay));

    return (
        <div className={rowClasses} style={props.style} onClick={selectRecord}>
            {props.showCheckbox && (
                <CheckboxCell
                    style={{ flex: `0 0 ${RowHeight}px` }}
                    onClick={() => props.updateChecked?.(String(props.record[props.idKey]), !props.checked)}
                >
                    <CheckboxIcon checked={props.checked ?? false} />
                </CheckboxCell>
            )}
            {columnsToDisplay.map((column) => {
                if (!column.visible) {
                    return null;
                }

                let keyForRender = column.id;

                if (column.renderer) {
                    return column.renderer({
                        ...props.record,
                        key: keyForRender,
                        style: getCellStyle(column, RowHeight),
                    });
                }

                let title = '';
                if (column.valueType == TableColumnDataType.Text) {
                    title = props.record[column.key] as unknown as string;
                }

                let text = props.record[column.key] ?? '';
                if (column.valueType == TableColumnDataType.Date) {
                    text = getFullDateTimeString(text as unknown as Date);
                }
                if (column.textRenderer) {
                    text = column.textRenderer(props.record);
                    title = text;
                }

                return (
                    <Cell
                        key={keyForRender}
                        style={getCellStyle(column, RowHeight)}
                        disablePadding={column.valueType == TableColumnDataType.Icon}
                        centerContent={column.centered}
                        title={title}
                        useTextStyles={column.valueType == TableColumnDataType.Text}
                    >
                        {String(text)}
                    </Cell>
                );
            })}
        </div>
    );
};

export default React.memo(Row) as typeof Row;
