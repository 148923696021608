import { SortOrder } from 'entities';
import { ReactNode } from 'react';
import { FileInputFieldProps } from 'ui';
import { getDotNetDateTimeString } from 'utilities';
import { TextTag } from './entities';

export { Cell as TableCell } from './cell';
export { Table } from './table';
export { CompactTable } from './compact-table';
export { PeriodWithObjectToolbar } from './alternative-toolbars/period-with-object-toolbar';

const DefaultMinWidth = 150;

export enum TableColumnDataType {
    Text,
    Number,
    Icon,
    Date,
}

export enum TotalCountType {
    None,
    Sum,
    Average,
}

interface PartialTableCellRendererProps {
    key: string | number;
    style: React.CSSProperties;
}

export type TableCellRendererProps<T> = T & PartialTableCellRendererProps;

interface BaseTableProps<T> {
    /**Задание поле записи, в качестве идентификатора*/
    idKey: keyof T;
    /**Задание записей таблицы*/
    records: T[];
    /**Задание колонок таблицы*/
    columns: AnyColumn<T>[];
}

export interface TableProps<T> extends BaseTableProps<T> {
    /**Параметр для отображение информации в футере таблицы.*/
    footerItems: FooterItem[];
    /**Параметр для добавление кастомных фильтров.*/
    customFilters?: CustomFilter<T>[];
    /**Параметр для отображения загрузки таблицы.*/
    showLoading?: boolean;
    /**Параметр для отображения ошибки.*/
    errorText?: string;
    /**Задание имени экспортируемого файла по кнопке "Экспорт".*/
    fileNameForExport?: string;
    /**Параметр, задающий небходимость скрола к записи после её создания.*/
    needScrollToSelectedChangeFlag?: boolean;
    /**Параметр внутреннего состояния таблицы.*/
    state: TableState<T>;
    /**Параметр, задающий небходимость отображения кнопки "Добавить".*/
    showCreateButton?: boolean;
    /** Массив с кастомными кнопками в шапке таблицы.*/
    customButtons?: ReactNode;
    /** Коллбек при изменении состояния таблицы.*/
    updateState: (newState: TableState<T>) => void;
    /** Коллбек при клике на кнопку "Добавить".*/
    onCreateClick?: () => void;
    /** Коллбек при клике на запись в таблице.*/
    onRowClick?: (record: T) => void;
    alternativeToolbar?: ReactNode;
    tags?: TextTag[];
    onRemoveTag?: (key: number) => void;
    showExportButton?: boolean;
}

export interface TableState<T> {
    filterText: string;
    customFiltersState: Record<string, boolean>;
    filterColumnsState: Partial<Record<keyof T, boolean>>;
    caseSensitiveFilter: boolean;
    wholeMatchFilter: boolean;
    sortingKey: keyof T | null;
    sortingOrder: SortOrder;
    selectedId: number | null;
    checkingState: Record<string, boolean>;
}

export interface CompactTableProps<T> extends BaseTableProps<T> {
    /**Ширина таблицы */
    width?: number;
    /**Высота таблицы */
    height?: number;
    /**Параметр, отвечающий за отображение тулбара */
    showToolbar?: boolean;
    /**Текст инпута фильтра */
    filterText?: string;
    infoText?: string;
    /**Сообщение ошибки */
    errorText?: string;
    /**Параметр, отвечающий за отображение лоудера */
    showLoading?: boolean;
    /**Параметр, отвечающий за отображение чекбоксов у строк */
    showCheckboxes?: boolean;
    /**Состояние чекбоксов*/
    checkedData?: Record<string, boolean>;
    /**Параметр, отвечающий за отображение футера таблицы*/
    showFooter?: boolean;
    /**Данные для футера*/
    footerItems?: FooterItem[];
    /**Параметр, отвечающий за отображение кнопки экспорта */
    showExportButton?: boolean;
    /**Строка, которая будет использована в названии файла при экспорте */
    fileNameForExport?: string;
    /**Параметр, отвечающий за отображение суммы элементов в числовой колонне */
    showTotalRow?: boolean;
    /**Задание колонны, по которой будет происходить сортировка */
    defaultSortingKey?: keyof T;
    /**Функция коллбек при изменнении состояния чекбоксов */
    updateCheckedData?: (data: Record<string, boolean>) => void;
    /**Параметр, определяет отображение нижнего тулбара */
    showLowerToolbar?: boolean;
    /**Элементы, которые необходимо отобразть в нижнем тулбаре */
    lowerToolbarItems?: ReactNode;
    /** Массив с кастомными кнопками в шапке таблицы.*/
    customButtons?: ReactNode;
    /** Коллбек при клике на запись в таблице.*/
    onRowClick?: (record: T) => void;
    /** Отключает возможность выбирать записи таблицы */
    disabledRowClick?: boolean;
    /**ID выбранной записи */
    selectedId?: number;
}

export interface TableColumn<T> {
    id: string;
    key: keyof T;
    title: string;
    valueType: TableColumnDataType;
    minWidth: number;
    widthScalable: boolean;
    visible: boolean;
    useForFilter: boolean;
    defaultSortOrder: SortOrder;
    totalCountType: TotalCountType;
    centered?: boolean;
    exportTitle?: string;
    renderer?: (record: TableCellRendererProps<T>) => JSX.Element;
    textRenderer?: (record: T) => string;
    exportRenderer?: (record: T) => string | number;
}

export interface TableGridProps<T> {
    /**Данные для отображения в компоненте Footer */
    footerItems?: FooterItem[];
    /**ID выбранной строки */
    selectedId: number | null;
    infoText?: string;
    /**Параметр для отображения сообщения ошибки*/
    errorText?: string;
    /**Параметр для отображения лоудера*/
    showLoading?: boolean;
    /**Задание колонны для сортировки*/
    sortingKey: keyof T | null;
    /**Порядок сортировки. Enum SortOrder[Ascension |
    Descension] */
    sortingOrder?: SortOrder;
    /**Параметр, отвечающий за отображение Footer */
    showFooter?: boolean;
    /**Параметр, отвечающий за отображение чекбосков с строчках */
    showCheckboxes?: boolean;
    /**Данные о отмеченных чекбоксах*/
    checkedData?: Record<string, boolean>;
    /**При смене флага будет скролить к выбранной строке*/
    needScrollToSelectedChangeFlag?: boolean;
    /**Коллбек функция вызывается при клике на строчку таблицы */
    onSelectionChange?: (selectedId: number | null) => void;
    /**Коллбек функция вызывается при изменении колоны для сортировки*/
    onSortingStateChange?: (sortingKey: keyof T, sortingOrder: SortOrder) => void;
    /**Коллбек функция вызывается при изменении порядка сортировки*/
    onSortingOrderChange?: (sortingOrder: SortOrder) => void;
    /**Коллбек функция вызывается при изменении состояниев чекбоксов в строках*/
    updateCheckedData?: (data: Record<string, boolean>) => void;
    /**Параметр отвечает за отображение нижнего тулбара */
    showLowerToolbar?: boolean;
    /**Элементы для отображения в нижнем тулбаре */
    lowerToolbarItems?: ReactNode;
}

export interface FooterItem {
    label: string;
    value: string | number;
}

export interface ToolbarProps<T> {
    /**Текст в инпуте поиска*/
    filterText: string;
    /**Добавляет кастомные фильтры*/
    customFilters?: CustomFilter<T>[];
    /**Состояния фильтров по кастомным колонкам*/
    filterColumns: FilterColumn<T>[];
    /**Парметр, отвечающий за учет регистра при поиске */
    caseSensitivity: boolean;
    /**Парметр, отвечающий за полное соответствие при поиске */
    wholeMatch: boolean;
    /**Парметр, отвечающий за отображение кнопки "Добавить" */
    showCreateButton?: boolean;
    /**Парметр, отвечающий за отображение кнопки "Экспорт" */
    showExportButton?: boolean;
    /**Парметр, отвечающий за отображение кастомных кнопок */
    customButtons?: ReactNode;
    /**Коллбек при нажатии на кнопку добавить*/
    onCreateClick?: () => void;
    alternativeToolbar?: ReactNode;
    tags?: TextTag[];
    onRemoveTag?: (key: number) => void;
}

export interface FilterColumn<T> {
    key: keyof T;
    active: boolean;
    label: string;
}

export interface CustomFilter<T> {
    id: string;
    label: string;
    filterFunction: (record: T) => boolean;
}

type BaseTableColumnConstructorProps<T> = Pick<TableColumn<T>, 'key' | 'valueType'> &
    Partial<Omit<TableColumn<T>, 'key' | 'valueType'>>;

class BaseTableColumn<T> implements TableColumn<T> {
    public id: string;
    public key: keyof T;
    public title: string;
    public minWidth: number;
    public useForFilter: boolean;
    public defaultSortOrder: SortOrder;
    public valueType: TableColumnDataType;
    public widthScalable: boolean;
    public visible: boolean;
    public centered: boolean;
    public totalCountType: TotalCountType;
    public children?: TableColumn<T>[];
    public exportTitle?: string;
    public exportRenderer?: (record: T) => string | number;

    public renderer?: (record: TableCellRendererProps<T>) => JSX.Element;
    public textRenderer?: (record: T) => string;

    public constructor(props: BaseTableColumnConstructorProps<T>) {
        this.id = props.id ?? String(props.key);
        this.key = props.key;
        this.title = props.title || '';
        this.minWidth = props.minWidth ?? DefaultMinWidth;
        this.useForFilter = props.useForFilter ?? false;
        this.defaultSortOrder = props.defaultSortOrder ?? SortOrder.Ascension;
        this.renderer = props.renderer;
        this.textRenderer = props.textRenderer;
        this.valueType = props.valueType;
        this.widthScalable = props.widthScalable ?? false;
        this.visible = props.visible ?? false;
        this.centered = props.centered ?? false;
        this.exportTitle = props.exportTitle;
        this.exportRenderer = props.exportRenderer;
        this.totalCountType = props.totalCountType ?? TotalCountType.None;
    }
}

type TableColumnConstructorProps<T> = Omit<BaseTableColumnConstructorProps<T>, 'valueType'>;

export class StringTableColumn<T> extends BaseTableColumn<T> {
    public constructor(props: TableColumnConstructorProps<T>) {
        super({
            ...props,
            useForFilter: props.useForFilter ?? true,
            valueType: TableColumnDataType.Text,
            widthScalable: props.widthScalable ?? true,
            visible: props.visible ?? true,
        });
    }
}

export class NumberTableColumn<T> extends BaseTableColumn<T> {
    public constructor(props: TableColumnConstructorProps<T>) {
        super({
            ...props,
            valueType: TableColumnDataType.Number,
            visible: props.visible ?? true,
            totalCountType: props.totalCountType ?? TotalCountType.Sum,
        });
    }
}

type IconTableColumnConstructorProps<T> = Pick<Required<TableColumn<T>>, 'renderer'> &
    Omit<TableColumnConstructorProps<T>, 'renderer'>;

export class IconTableColumn<T> extends BaseTableColumn<T> {
    public constructor(props: IconTableColumnConstructorProps<T>) {
        super({
            ...props,
            valueType: TableColumnDataType.Icon,
            visible: true,
        });
    }
}

export class DateTableColumn<T> extends BaseTableColumn<T> {
    public constructor(props: TableColumnConstructorProps<T>) {
        super({
            ...props,
            useForFilter: false,
            valueType: TableColumnDataType.Date,
            minWidth: props.minWidth ?? 200,
            widthScalable: false,
            visible: props.visible ?? true,
            exportRenderer: (record) => getDotNetDateTimeString(record[props.key] as Date),
        });
    }
}

interface ColumnWithChildren<T> {
    id: string;
    title: string;
    children: TableColumn<T>[];
}

export class TableColumnWithChildren<T> implements ColumnWithChildren<T> {
    public id: string;
    public title: string;
    public children: TableColumn<T>[];

    public constructor(props: ColumnWithChildren<T>) {
        this.id = props.id;
        this.title = props.title;
        this.children = props.children;
    }
}

export type AnyColumn<T> = TableColumn<T> | ColumnWithChildren<T>;

export const instanceOfTableColumn = <T>(object: AnyColumn<T>): object is TableColumn<T> => 'key' in object;
