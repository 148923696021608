import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { combineStyles, Theme } from 'theme';
import { CheckboxIcon } from 'ui';

import { ListItemInnerData } from './entities';

const useStyles = makeStyles((theme: Theme) => ({
    listItemRoot: { ...theme.custom?.listPane.item },
    listItemIconBlock: { ...theme.custom?.listPane.iconBlock },
    listItemTextBlock: { ...theme.custom?.listPane.textBlock },
    clickable: { ...theme.custom?.generalStyles.clickable },
}));

interface ListItemProps extends ListItemInnerData {
    index: number;
    onClick: (index: number) => void;
}
const ListItem: React.FC<ListItemProps> = (props) => {
    let { text, checked, index, onClick } = props;
    let classes = useStyles();

    const onItemClick = () => onClick(index);

    let itemClasses = combineStyles(classes.listItemRoot, classes.clickable);
    return (
        <div className={itemClasses} onClick={onItemClick}>
            <div className={classes.listItemIconBlock}>
                <CheckboxIcon checked={checked} />
            </div>
            <div className={classes.listItemTextBlock}>{text}</div>
        </div>
    );
};

export default ListItem;
