import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { SvgIcon } from 'ui';
import { translate } from 'utilities';

import { AnyColumn, NumberTableColumn, TableColumn, TotalCountType } from '.';
import { Cell } from './cell';
import { fillColumnsToDisplay, getCellStyle } from './entities';

let RowHeight = 36;
let ScrollWidth = 17;

const useStyles = makeStyles((theme: Theme) => {
    if (theme.custom) {
        RowHeight = theme.custom.mainSizes.table.rowHeight;
        ScrollWidth = theme.custom.mainSizes.common.scrollWidth;
    }

    return {
        rowContainer: {
            boxSizing: 'border-box',
            borderTop: `${theme.custom?.mainSizes.common.defaultBorderSize}px solid ${theme.custom?.palette.divider}`,
            display: 'flex',
            alignItems: 'stretch',
            overflow: 'hidden',
        },
        innerContainer: {
            display: 'flex',
        },
    };
});

export interface TotalRowProps<T> {
    /**Установка контейнера компонента, как ref */
    containerRef: React.RefObject<HTMLDivElement>;
    /**Ширина строки */
    rowWidth: number;
    /**Параметр отвечает за отступ слева, если в таблице используются чекбоксы  */
    showCheckboxes: boolean;
    /**Колонки таблицы */
    columns: AnyColumn<T>[];
    /**Данные по колонне. (Участвуют только NumberColumn) */
    data: Partial<Record<keyof T, number>>;
    /**Параметр оторбражения скрола в таблице*/
    hasScroll: boolean;
}
/**Отображение дополнительных данных под таблицей.
 * В зависимости от totalCountType в классе колон, либо отображаться не будет, или будет отображаться сумма всех значений, либо среднее число. */
export const TotalRow = <T,>(props: TotalRowProps<T>): JSX.Element => {
    let classes = useStyles();

    let columnsToDisplay: TableColumn<T>[] = [];
    props.columns.forEach((column) => fillColumnsToDisplay(column, columnsToDisplay));
    let labelPrinted = false;

    return (
        <div ref={props.containerRef} className={classes.rowContainer}>
            <div
                className={classes.innerContainer}
                style={{ width: props.hasScroll ? props.rowWidth + ScrollWidth : props.rowWidth }}
            >
                {props.showCheckboxes && (
                    <Cell style={{ flex: `0 0 ${RowHeight}px` }}>
                        <SvgIcon type="empty" />
                    </Cell>
                )}
                {columnsToDisplay.map((column) => {
                    if (column instanceof NumberTableColumn && column.totalCountType != TotalCountType.None) {
                        return (
                            <Cell
                                key={column.id}
                                style={getCellStyle(column, RowHeight)}
                                centerContent={column.centered}
                            >
                                {props.data[column.key] ?? '-'}
                            </Cell>
                        );
                    }

                    if (!labelPrinted) {
                        labelPrinted = true;
                        return (
                            <Cell
                                key={column.id}
                                style={getCellStyle(column, RowHeight)}
                                centerContent={column.centered}
                            >
                                {translate('InTotal')}
                            </Cell>
                        );
                    }

                    return (
                        <Cell key={column.id} style={getCellStyle(column, RowHeight)} centerContent={column.centered}>
                            -
                        </Cell>
                    );
                })}
                {props.hasScroll && <div style={{ flex: `0 0 ${ScrollWidth}px` }} />}
            </div>
        </div>
    );
};
