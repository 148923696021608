import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { DefaultTableCellIconSize, InputIconSize } from '../../../constants';
import Theme from '../../../theme/login-theme';
import { useLoginDispatch } from '../../store';
import { getAuthData, getEmptyPasswordField } from '../../store/auth/selectors';
import { ErrorIcon } from '../icons/errorIcon';
import { SvgIcon } from '../icons/svg-icon';
import { login, setPasswordErrorVisible } from '../../store/auth/slice';

export const createStyles = makeStyles((theme: Theme) => ({
    root: theme.custom?.inputField.root || {},
    fullWidth: theme.custom?.inputField.fullWidth || {},
    input: theme.custom?.inputField.input || {},
    typeIconBlock: theme.custom?.inputField.typeIconBlock || {},
    iconContainer: theme.custom?.inputField.iconContainer || {},
    error: theme.custom?.inputField.error || {},
    inputField: theme.custom?.inputField.inputField || {},
    additionalInputStyle: theme.custom?.inputField.additionalPasswordInputStyle || {},
    dividerBlock: theme.custom?.dividerBlock || {},
    visibilityIcon: theme.custom?.visibilityIcon || {},
}));

interface PasswordInputProps {
    value: string;
    fullWidth?: boolean;
    onChange?: (value: string) => void;
    errorText?: string;
    placeholder?: string;
    tooltipHideText?: string;
    tooltipShowText?: string;
}

export const PasswordField: React.FC<PasswordInputProps> = (props) => {
    let { value, fullWidth, onChange, errorText, placeholder, tooltipHideText, tooltipShowText } = props;
    let classes = createStyles();
    const dispatch = useLoginDispatch();
    const [showSymbols, setShowSymbols] = React.useState<boolean>(true);
    const [typeInput, setTypeInput] = React.useState<string>('password');
    const passwordData = useSelector(getAuthData).password;
    const emptyPasswordField = useSelector(getEmptyPasswordField);

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
        if (onChange) {
            onChange(event.target.value);
        }
    }, []);

    const onBlurHandler = React.useCallback((): void => {
        if (passwordData == '') {
            dispatch(setPasswordErrorVisible(true));
        }
    }, [passwordData]);

    const onKeyUp = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key == 'Enter') {
            dispatch(login()).catch((error) => console.error(error));
        }
    }, []);

    const onPasswordVisibilityChange = React.useCallback(() => {
        if (!showSymbols) {
            setShowSymbols(true);
            setTypeInput('password');
        } else {
            setShowSymbols(false);
            setTypeInput('text');
        }
    }, [showSymbols]);

    const inputRef = React.useRef<HTMLInputElement>(null);
    const focusInput = () => {
        inputRef.current?.focus();
    };

    let rootClass = classes.root;
    if (fullWidth) {
        rootClass += ` ${classes.fullWidth}`;
    }

    return (
        <>
            <div className={classes.typeIconBlock}>
                <SvgIcon type="lock" size={InputIconSize} />
            </div>
            <div className={classes.dividerBlock} />
            <div className={`${rootClass} ${classes.additionalInputStyle}`} onClick={focusInput}>
                <input
                    ref={inputRef}
                    placeholder={placeholder}
                    className={`${classes.input} ${classes.inputField}`}
                    value={value}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                    onKeyUp={onKeyUp}
                    type={typeInput}
                />
                {emptyPasswordField && (
                    <div className={classes.error} title={errorText}>
                        <ErrorIcon />
                    </div>
                )}
                {!emptyPasswordField && (
                    <div
                        className={classes.iconContainer}
                        onClick={onPasswordVisibilityChange}
                        title={showSymbols ? tooltipShowText : tooltipHideText}
                    >
                        {showSymbols ? (
                            <div className={classes.visibilityIcon}>
                                <SvgIcon type={'eye'} size={DefaultTableCellIconSize} />
                            </div>
                        ) : (
                            <div className={classes.visibilityIcon}>
                                <SvgIcon type={'eye-off'} size={DefaultTableCellIconSize} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
