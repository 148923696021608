import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { LoadingState } from 'ui';
import Loading from '../../components/loading';
import { useLoginDispatch } from '../../store';
import {
    getLanguages,
    getSharedDataLoading,
    getSwitcherDisplayStatus,
    getTranslates,
} from '../../store/sharedData/selectors';
import { getAuthData, getConfig, getConfigLoading } from '../../store/auth/selectors';
import { Content } from './content';
import { loadConfig, login, setLoginField, setPasswordField, setStateOldInterface } from '../../store/auth/slice';
import { FormAuth } from './menu-auth/menu-auth';
import { loadSharedData, updateTranslations } from '../../store/sharedData/slice';
import { returnLanguage } from '../../store/sharedData/entities';

const createStyles = makeStyles(() => ({
    container: {
        width: '100%',
        display: 'flex',
        fontFamily: '"Montserrat", sans-serif',
    },
}));

export const LoginPage: React.FC = () => {
    const dispatch = useLoginDispatch();
    let classes = createStyles();
    const authorizationData = useSelector(getAuthData);
    const switcherDisplayStatus = useSelector(getSwitcherDisplayStatus);
    const configLoading = useSelector(getConfigLoading);
    const sharedDataLoading = useSelector(getSharedDataLoading);
    const [autoLogin, setAutoLogin] = React.useState<boolean>(false);

    let translates = useSelector(getTranslates);
    const languages = useSelector(getLanguages);
    const config = useSelector(getConfig);

    let currentLanguage = languages.find((item) => item.code == authorizationData.language);

    if (currentLanguage) {
        translates = currentLanguage.translates;
    }
    React.useEffect(() => {
        dispatch(loadConfig()).catch((error) => console.error(error));
    }, []);

    React.useEffect(() => {
        if (config && sharedDataLoading === LoadingState.Success) {
            const params = new URLSearchParams(document.location.search);
            const loginParam = params.get('login');
            const passwordParam = params.get('password');

            let newIntParam = params.get('newint') === 'true' ? false : true;
            if (!switcherDisplayStatus || !params.get('newint')) {
                newIntParam = false;
            }

            const autoLoginParam = params.get('autologin') === 'true' ? true : false;

            if (loginParam !== null) {
                dispatch(setLoginField(loginParam));
            }
            if (passwordParam !== null) {
                dispatch(setPasswordField(passwordParam));
            }

            dispatch(setStateOldInterface(newIntParam));

            const localStorageLang = localStorage.getItem('lang')?.toLocaleLowerCase() || '';
            const browserLanguage = window.navigator.language.toLocaleLowerCase();
            const language = returnLanguage(languages, browserLanguage, localStorageLang);
            dispatch(updateTranslations(language));

            if (autoLoginParam) {
                dispatch(login()).catch((error) => console.error(error));
            } else {
                setAutoLogin(autoLoginParam);
            }
        }
    }, [config, sharedDataLoading]);

    React.useEffect(() => {
        if (config && !autoLogin) {
            dispatch(loadSharedData()).catch((error) => console.error(error));
        }
    }, [config, autoLogin]);

    React.useEffect(() => {
        document.title = translates.SoftwareName;
    }, [translates]);

    if (autoLogin || configLoading !== LoadingState.Success || sharedDataLoading === LoadingState.InProgress) {
        return <Loading />;
    }

    return (
        <div className={classes.container}>
            <FormAuth />
            <Content />
        </div>
    );
};
