import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';
import { toast as reactToast } from 'react-toastify';
import { Messages } from 'entities';
import { DefaultScrollWidth } from './constants';
import axios, { AxiosResponse } from 'axios';

export const getDotNetDateTimeString = (date: Date): string => {
    try {
        return formatDate(date, 'yyyy-MM-dd HH:mm:ss');
    } catch {
        return '0001-01-01 00:00:00';
    }
};

export const parseDotNetDateTimeString = (date: string): Date => {
    return parseDate(date, 'yyyy-MM-dd HH:mm:ss', new Date(1, 1, 1, 0, 0, 0));
};

export const copyTextToClipboard = (text: string): void => {
    const textArea = document.createElement('textarea');

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        // TODO: Вставить оповещения для пользователей
        document.execCommand('copy');
    } catch (err) {}

    document.body.removeChild(textArea);
};

const ZERO = 0;
const TEXT_WIDTH_MULTIPLIER = 1.64;
// TODO: getTextWidth работает криво, расчитывает сильно в меньшую сторону (например: 64 вместо 104, 531 вместо 865)
export const getTextWidth = (text: string, font: string): number => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width * TEXT_WIDTH_MULTIPLIER;
    }
    return ZERO;
};

export function createDeepCopy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
}

export function getRootUrl(): string {
    const url = new URL(window.location.href);
    const host = url.host;
    const protocol = url.protocol;
    return `${protocol}//${host}/`;
}

let translations: Messages = {};
export const setTranslations = (data: Messages): void => {
    translations = data;
};
export const translate = (key: string): string => translations[key] || key;

export interface Toast {
    (text: string): void;

    success: (text: string) => void;
    error: (text: string) => void;
    info: (text: string) => void;
}

export let toast = function (text) {
    reactToast(text);
} as Toast;
toast.success = (text) => reactToast.success(text);
toast.error = (text) => reactToast.error(text);
toast.info = (text) => reactToast.info(text);

export function setToast(newToast: Toast): void {
    toast = newToast;
}

export function calculateColumnWidths(width: number, sizes: (number | string)[], hasScroll = false): number[] {
    let staticWidths: number[] = [];
    let staticWidthsSum = 0;
    let variablePartsSum = 0;
    for (let i = 0; i < sizes.length; ++i) {
        let value = sizes[i];
        if (typeof value == 'string') {
            let intValue = parseInt(value);
            staticWidthsSum += intValue;
            staticWidths.push(intValue);
        } else {
            variablePartsSum += value;
            staticWidths.push(0);
        }
    }
    let variableWidth = width - staticWidthsSum;

    let result: number[] = [];
    for (let i = 0; i < sizes.length; ++i) {
        let value = sizes[i];
        if (typeof value == 'string') {
            result.push(staticWidths[i]);
        } else {
            result.push((variableWidth / variablePartsSum) * value);
        }
    }

    if (hasScroll) {
        result[result.length - 1] -= DefaultScrollWidth;
    }

    return result;
}

interface CalculateTableWidthsProps {
    width: number;
    sizes: (number | string)[];
    hasScroll?: boolean;
}

export function calculateTableWidths(props: CalculateTableWidthsProps): { header: number[]; row: number[] } {
    let { width, sizes, hasScroll = false } = props;

    if (hasScroll) {
        width -= DefaultScrollWidth;
    }
    let header = calculateColumnWidths(width, sizes);
    let row = [...header];
    if (hasScroll) {
        header[header.length - 1] += DefaultScrollWidth;
    }

    return { header, row };
}

export async function getErrorFromFetchResponse(response: Response): Promise<Error> {
    let message;
    try {
        let json = await response.json();
        message = json.Message || json.message;
        if (message === undefined && typeof json == 'string') {
            message = json;
        }
    } catch {}
    if (message) {
        let error = new Error(message);
        error.name = '';
        return error;
    } else {
        return new Error(response.statusText);
    }
}

export function isEven(num: number): boolean {
    return num % 2 == 0;
}

export function isLastRow(rowIndex: number, rowsCount: number): boolean {
    return rowIndex == rowsCount - 1;
}

export type EmptyObject = Record<string, never>;

export async function getDotNetCoreErrorFromResponse(response: Response): Promise<Error> {
    let responseText = await response.text();
    let resultText: string = '';
    try {
        let consoleData = JSON.parse(responseText);
        resultText = consoleData?.message;
    } catch {
        console.error('API Error', responseText);
    }

    if (typeof resultText == 'string' && resultText.length > 0) {
        return new Error(resultText);
    }

    switch (response.status) {
        case 400:
            resultText = translate('BadRequestHttpStatusError');
            break;
        case 401:
            resultText = translate('UnauthorizedHttpStatusError');
            break;
        case 403:
            resultText = translate('ForbiddenHttpStatusError');
            break;
        case 404:
            resultText = translate('NotFoundHttpStatusError');
            break;
        case 500:
            resultText = translate('InternalServerErrorHttpStatusError');
            break;
        default:
            resultText = translate('UnknownHttpStatusError');
            break;
    }

    return new Error(resultText);
}

export function getFullDateTimeFormat(): string {
    let dateFormatText = 'MaskDate';
    let dateFormat = translate(dateFormatText);
    if (dateFormat == dateFormatText) {
        dateFormat = 'dd.MM.yyyy HH:mm:ss';
    }
    return dateFormat;
}

export function getFullDateFormat(): string {
    let dateFormatText = 'DateFormatForDateFns';
    let dateFormat = translate(dateFormatText);
    if (dateFormat == dateFormatText) {
        dateFormat = 'dd.MM.yyyy';
    }
    return dateFormat;
}

export function getFullDateTimeString(value: Date, allowOnlyRecentDates = true): string {
    if (allowOnlyRecentDates) {
        let year = value.getFullYear();
        if (year < 2010) {
            return '-';
        }
    }
    return formatDate(value, getFullDateTimeFormat());
}

export function getFullDateString(value: Date, allowOnlyRecentDates = true): string {
    if (allowOnlyRecentDates) {
        let year = value.getFullYear();
        if (year < 2010) {
            return '-';
        }
    }
    return formatDate(value, getFullDateFormat());
}

export function copyDate(target: Date, source: Date): Date {
    let result = new Date(target);

    result.setDate(source.getDate());
    result.setMonth(source.getMonth());
    result.setFullYear(source.getFullYear());

    return result;
}

export function copyTime(target: Date, source: Date): Date {
    let result = new Date(target);

    result.setHours(source.getHours());
    result.setMinutes(source.getMinutes());
    result.setSeconds(source.getSeconds());

    return result;
}

export function getEstimateDaysForAccountText(daysCount: number, blocked: boolean): string {
    if (blocked) {
        return translate('Banned');
    }

    if (daysCount == 2147483646) {
        return '∞';
    }

    return daysCount.toString();
}

export function downloadExcelFile(fileName: string): void {
    let link = document.createElement('a');
    link.href = '/exp_excel/' + fileName;
    link.click();
}

export function generateGuid(): string {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

axios.defaults.validateStatus = () => true;

export const getErrorText = (response: AxiosResponse): string =>
    response.data?.message ?? `${response.status} ${response.statusText}`;

export const checkAxiosError = (response: AxiosResponse): void => {
    if (response.status < 200 || 300 <= response.status) {
        throw new Error(getErrorText(response));
    }
};

export const autoSaveFileFromServer = (response: AxiosResponse) => {
    const filename = response.headers['content-disposition'].split('filename=')[1];
    const type = response.headers['content-type'];
    const blob = new Blob([response.data], { type: type });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = decodeURIComponent(filename);
    link.click();

    URL.revokeObjectURL(link.href);
};

const SecondsInMinute = 60;
const SecondsInHour = 60 * 60;
const SecondsInDay = 24 * 60 * 60;

export const getDuration = (
    value: number,
    timeUnits: { showDays: boolean; showHours: boolean; showMinutes: boolean; showSeconds: boolean },
): { days: number; hours: number; minutes: number; seconds: number } => {
    const days = timeUnits.showDays ? Math.trunc(value / SecondsInDay) : 0;
    const hours = timeUnits.showHours ? Math.trunc((value - days * SecondsInDay) / SecondsInHour) : 0;
    const minutes = timeUnits.showMinutes
        ? Math.trunc((value - days * SecondsInDay - hours * SecondsInHour) / SecondsInMinute)
        : 0;
    let seconds = 0;
    if (timeUnits.showSeconds) {
        if (timeUnits.showMinutes) {
            seconds = value % SecondsInMinute;
        } else {
            seconds = value;
        }
    }
    return { days, hours, minutes, seconds };
};

export const declensionForDay = (licenseExpirationDays: number): string => {
    let daysMod10 = licenseExpirationDays % 10;
    let daysMod100 = licenseExpirationDays % 100;
    let licenseLabelText = `${licenseExpirationDays} `;
    switch (true) {
        case daysMod10 == 0:
        case daysMod100 > 10 && daysMod100 < 15:
            return (licenseLabelText += translate('Days5'));
        case daysMod10 == 1:
            return (licenseLabelText += translate('Day1'));
        case daysMod10 < 5:
            return (licenseLabelText += translate('Days234'));
        default:
            return (licenseLabelText += translate('Days5'));
    }
};
