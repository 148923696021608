import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from '../../theme';

const createStyles = makeStyles((theme: Theme) => ({
    tabPaneClassName: { ...theme.custom?.tabPane.root },
}));

export const TabPane: React.FC<React.PropsWithChildren> = (props) => {
    let { children } = props;
    let { tabPaneClassName } = createStyles();

    return <div className={tabPaneClassName}>{children}</div>;
};
