import axios from 'axios';
import { checkAxiosError, getRootUrl } from 'utilities';
import { Language, NewsApi, SharedDataStorage } from './entities';

export interface SharedDataStorageApi {
    showOldInterfaceSwitcher: boolean;
    news: NewsApi[];
    languages: Language[];
}

export const getSharedData = async (): Promise<SharedDataStorage> => {
    const response = await axios.get<SharedDataStorageApi>(`${getRootUrl()}api/v2/loginpage/settings`);

    checkAxiosError(response);

    const newsView = response.data.news.map((item) => {
        return {
            caption: item.caption,
            previewImagePath: item.previewImagePath,
            imageHDPath: item.imageHDPath,
            imageFullHDPath: item.imageFullHDPath,
            imageQuadHDPath: item.imageQuadHDPath,
            image4KPath: item.image4KPath,
            body: item.body,
            link: item.link,
            selected: false,
        };
    });

    return { ...response.data, news: newsView };
};
