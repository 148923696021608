import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { Theme } from 'theme';
import { translate } from 'utilities';

const createStyles = makeStyles((theme: Theme) => ({
    container: {
        position: 'relative',
        width: 'inherit',
        height: 'inherit',
        display: 'inherit',
        flexDirection: 'inherit',
        borderRadius: 'inherit',
    },
    loaderContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        backgroundColor: 'rgba(54, 55, 64, 0.6)',
        borderRadius: 'inherit',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.custom?.palette.colors.grey10,
        gap: `${theme.custom?.spacing(2)}px 0`,
        padding: `${theme.custom?.spacing(5)}px ${theme.custom?.spacing(13)}px`,
        borderRadius: theme.custom?.mainSizes.common.mediumBorderRadius,
    },
    text: {
        color: theme.custom?.palette.text.primary,
        fontFamily: theme.custom?.typography.fontFamily,
    },
}));

interface ContainerWithLoaderProps {
    container: (content: ReactNode) => ReactNode;
    open: boolean;
    children: ReactNode;
    textKey?: string;
    customLoader?: React.ReactNode;
}

export function ContainerWithLoader({ children, open, container, textKey, customLoader }: ContainerWithLoaderProps) {
    const styles = createStyles();

    const loaderPane = customLoader || (
        <div className={styles.loaderContainer}>
            <div className={styles.loader}>
                <CircularProgress />
                <div className={styles.text}>{translate(textKey || 'Loading')}</div>
            </div>
        </div>
    );

    return (
        <>
            {container(
                <div className={styles.container}>
                    {open && loaderPane}
                    {children}
                </div>,
            )}
        </>
    );
}
