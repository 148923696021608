import * as React from 'react';
import Input from '@material-ui/core/Input';

import { translate } from 'utilities';
import { FieldContainerProps, FieldContainer } from './field-container';
import { useImperativeHandle } from 'react';
import { Validatable } from '../index';

export interface TextFieldProps {
    /** Устанавливает значение инпута.*/
    value?: string;
    /** Функция колбек вызывается при изменении инпута.*/
    onChange?: (value: string, valid: boolean) => void;
    /** Устанавливает ширину компонента.*/
    width?: number;
    /** Устанавливает ширину label.*/
    labelWidth?: number;
    /** Устанавливает ключ для перевода в label.*/
    labelTextKey?: string;
    /**Устанавливает ширину input.*/
    inputWidth?: number;
    /** Параметр, отвечающий за установку автофокуса в input.*/
    autoFocus?: boolean;
    /** Параметр, отвечающий за установку отступа снизу, если элементов несколько.*/
    disablePadding?: boolean;
    /** Устанавливает максимальное значение символов в input.*/
    maxLength?: number;
    /** Параметр, отвечающий за отображение input с несколькими линиями.*/
    multiline?: boolean;
    /** Количество линий в инпуте.*/
    rowsCount?: number;
    /** Максимальное количество линий в инпуте.*/
    maxRowsCount?: number;
    /** Параметр указывает необходимость заполненности поля.*/
    required?: boolean;
}

/**
 Этот компонент отображает input с label.
 Также появляется ошибка если поле должно быть обязательно заполненно, функция на onChange вернет valid=false.
 У компонента есть hover эффект, фокус состояние и состояние при ошибке.
 При ошибке также появится отдельное поле с описанием ошибки.
 **/
export const TextField = React.forwardRef<Validatable, TextFieldProps>((props, ref) => {
    let {
        value = '',
        onChange,
        width,
        labelWidth,
        labelTextKey,
        inputWidth,
        autoFocus,
        disablePadding,
        maxLength,
        multiline,
        rowsCount = 1,
        maxRowsCount = 1,
        required,
    } = props;

    let [valid, setValid] = React.useState<boolean>(true);
    let [errorText, setErrorText] = React.useState<string>('');

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (!onChange) {
            return;
        }

        let value = event.target.value;
        if (typeof maxLength == 'number' && value.length > maxLength) {
            value = value.slice(0, maxLength);
        }

        if (required && value.length === 0) {
            onChange(value, false);
            return;
        }

        setValid(true);
        setErrorText('');
        onChange(value, true);
    }, []);

    const validate = (): boolean => {
        if (required && value.length === 0) {
            setValid(false);
            setErrorText(translate('FieldCanNotBeEmpty'));

            return false;
        } else {
            setValid(true);
            setErrorText('');

            return true;
        }
    };

    useImperativeHandle<Validatable, Validatable>(
        ref,
        () => ({
            validate: () => {
                return validate();
            },
        }),
        [required, value],
    );

    let containerProps: FieldContainerProps = {
        width,
        labelWidth,
        labelTextKey,
        disablePadding,
        inputWidth,
        errorText,
    };

    return (
        <FieldContainer {...containerProps}>
            <Input
                disableUnderline
                fullWidth
                autoFocus={autoFocus}
                value={value}
                onChange={onChangeHandler}
                multiline={multiline}
                rows={rowsCount}
                rowsMax={maxRowsCount}
                onBlur={validate}
                error={!valid}
            />
        </FieldContainer>
    );
});

TextField.displayName = 'TextField';
