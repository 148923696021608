import React from 'react';

import { SortOrder } from 'entities';

import { CompactTableProps, FilterColumn, ToolbarProps, instanceOfTableColumn } from '.';
import TableContainer from './table-container';

/**Таблица с неполным функционалом по умолчанию. Используется, где таблица не основной контент (диалоговые окна).
 * Нет коллбека при нажатии на строку, нет кнопки создать, нет кастомных кнопок. */
export const CompactTable = <T,>(props: CompactTableProps<T>): JSX.Element => {
    let [toolbarProps, setToolbarProps] = React.useState<ToolbarProps<T>>({
        filterText: props.filterText ?? '',
        filterColumns: [],
        caseSensitivity: false,
        wholeMatch: false,
        showCreateButton: false,
        showExportButton: props.showExportButton,
        onCreateClick: () => undefined,
        customButtons: props.customButtons,
    });
    let [sortingKey, setSortingKey] = React.useState<keyof T | null>(props.defaultSortingKey ?? null);
    let [sortingOrder, setSortingOrder] = React.useState<SortOrder>(SortOrder.Ascension);
    let [selectedId, setSelectedId] = React.useState<number | null>(props.selectedId || null);
    let [disabledSelect] = React.useState(props.disabledRowClick ?? true);

    const updateSortingSettings = React.useCallback((sortingKey: keyof T, sortingOrder: SortOrder) => {
        setSortingKey(sortingKey);
        setSortingOrder(sortingOrder);
    }, []);

    const updateSortingOrder = React.useCallback((sortingOrder: SortOrder) => {
        setSortingOrder(sortingOrder);
    }, []);

    const onSelectionChange = (selectedId: number | null): void => {
        let selectedRecord = props.records.find((item) => Number(item[props.idKey]) == selectedId);
        if (selectedRecord) {
            props.onRowClick?.(selectedRecord);
        }
        setSelectedId(selectedId);
    };

    React.useEffect(() => {
        let newFilterColumns: FilterColumn<T>[] = [];
        props.columns.forEach((column) => {
            if (!instanceOfTableColumn(column)) {
                return;
            }

            if (column.key == sortingKey) {
                setSortingOrder(column.defaultSortOrder);
            }

            if (column.useForFilter) {
                newFilterColumns.push({
                    key: column.key,
                    active: true,
                    label: column.title,
                });
            }
        });
        setToolbarProps({
            ...toolbarProps,
            filterColumns: newFilterColumns,
        });
    }, [props.columns]);

    let containerStyle: React.CSSProperties = {
        flex: '1 1 0',
        display: 'flex',
        flexDirection: 'column',
    };
    if (props.width) {
        containerStyle.width = props.width;
    }
    if (props.height) {
        containerStyle.flex = `0 0 ${props.height}px`;
    }

    return (
        <div style={containerStyle}>
            <TableContainer
                idKey={props.idKey}
                showBorder
                records={props.records}
                columns={props.columns}
                showToolbar={props.showToolbar}
                toolbarProps={toolbarProps}
                tableProps={{
                    infoText: props.infoText,
                    errorText: props.errorText,
                    showLoading: props.showLoading,
                    sortingKey,
                    sortingOrder,
                    selectedId: selectedId,
                    showFooter: props.showFooter,
                    showCheckboxes: props.showCheckboxes,
                    showLowerToolbar: props.showLowerToolbar,
                    lowerToolbarItems: props.lowerToolbarItems,
                    checkedData: props.checkedData,
                    updateCheckedData: props.updateCheckedData,
                    footerItems: props.footerItems,
                    onSortingStateChange: updateSortingSettings,
                    onSortingOrderChange: updateSortingOrder,
                    onSelectionChange: disabledSelect ? undefined : onSelectionChange,
                }}
                onToolbarPropsChange={setToolbarProps}
                needAppendFooterItems={false}
                fileNameForExport={props.fileNameForExport}
                showTotalRow={props.showTotalRow}
            />
        </div>
    );
};
