export const EMPTY_STRING_LENGTH: number = 0;
export const ZERO: number = 0;
export const EMPTY_ARRAY_LENGTH: number = 0;
export const FIRST_ELEMENT_INDEX: number = 0;
export const SECOND_ELEMENT_INDEX: number = 1;
export const ONE_ELEMENT: number = 1;
export const TWO_ELEMENT: number = 2;
export const ABSENT_IN_ARRAY: number = -1;
export const SORT_FIRST_HIGHER: number = 1;
export const SORT_SECOND_HIGHER: number = -1;
export const SORT_EQUAL: number = 0;
export const NOTHING_FOUND_INDEX_RESULT: number = -1;
export const NOTHING_SELECTED_INDEX_VTABLE = 0;
export const DEFAULT_DIALOG_CONTENT_PADDING_VALUE = 2;
export const DEFAULT_VIRTUALIZED_OVERSCAN_ROW_COUNT = 30;

/* common */
export const DefaultScrollWidth = 8;
export const DefaultToolbarHeight = 40;
export const DefaultToolbarIconSize = 32;
export const ToolbarPaneHeight = 62;
export const ToolbarBottomMargin = 16;
export const ToolbarHeight = ToolbarPaneHeight + ToolbarBottomMargin;
export const TwoPaneSelectToolbarWidth = 100;
export const DefaultReloadTimer = 30_000;
/* numbers */
export const MaxInt32Value = 2147483647;
export const MinInt32Value = -2147483648;
/* table */
export const DefaultTableHeaderHeight = 48;
export const DefaultTableRowHeight = 36;
export const DefaultTableCellIconSize = 20;
export const TableIconCellSize = `${DefaultTableRowHeight}px`;
export const DefaultTableBottomHeight = DefaultTableHeaderHeight;
export const TableIconCellWidth = DefaultTableRowHeight;
export const TableBorderSize = 1;
export const DefaultTableHeight = 200;
/* tree */
export const TreeRowHeight = 36;
export const TreeIconBlockSize = 24;
export const TreeIconSize = 16;
/* navigation menu */
export const NavigationMenuFullWidth = 330;
export const NavigationMenuIconsOnlyWidth = 64;
export const NavigationMenuIconSize = 20;
export const NavigationMenuBottomIconSize = 24;
/* inputs */
export const InputIconSize = 20;
export const FormFiledIconSize = 24;
export const InputHeight = 36;
export const SwitcherInputWidth = 60;
/* buttons */
export const ButtonIconSize = InputIconSize;
export const ButtonIndent = 12;
export const ButtonIndentForQuestions = 16;
export const FilterSettingsIconIndent = 8;
export const ButtonInnerPadding = 10;
/* dialogs */
export const DialogHeaderButtonSize = 24;
export const LabelWidth = 200;
export const InputWidth = 300;
/* admin page */
export const PageTitleIconSize = DialogHeaderButtonSize;
/* retranslator page */
export const RetranslatorPageSidebarWidth = 400;
/* other */
export const StatsMetricIconSize = 16;
export const MenuIconSize = 16;
export const PageTitleHeight = 52;
export const DetailsTitleHeight = PageTitleHeight;
/* login page */
export const PathToImages = '/img/new/';