import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from 'theme';
import { TimePeriodInputValue } from 'entities';
import { OutlinedButton, TimePeriodInput } from 'ui';
import { toast, translate } from 'utilities';

const useStyles = makeStyles((theme: Theme) => ({
    toolbarFilterPane: {
        display: 'flex',
        gap: theme.custom?.spacing(1),
        flexWrap: 'wrap',
    },
}));

interface PeriodWithObjectToolbarProps {
    timePeriod: TimePeriodInputValue;
    onTimePeriodChange: (value: TimePeriodInputValue) => void;
    onClickApply: () => void;
    onClickSelectObjects: () => void;
}

export const PeriodWithObjectToolbar: React.FC<PeriodWithObjectToolbarProps> = ({
    timePeriod,
    onTimePeriodChange,
    onClickApply,
    onClickSelectObjects,
}) => {
    const classes = useStyles();

    const [isPeriodValid, setIsPeriodValid] = useState(true);

    const onPeriodChange = (value: TimePeriodInputValue) => {
        if (value.start.dateValue > value.end.dateValue) {
            setIsPeriodValid(false);
        } else {
            setIsPeriodValid(true);
        }
        onTimePeriodChange(value);
    };

    const onApply = () => {
        if (!isPeriodValid) {
            toast.info(translate('PeriodFilledIncorrectly'));
        } else {
            onClickApply();
        }
    };

    return (
        <div className={classes.toolbarFilterPane}>
            <TimePeriodInput
                value={timePeriod}
                onChange={onPeriodChange}
                allowStartDateEmpty={true}
                allowEndDateEmpty={true}
            />
            <OutlinedButton textKey="SelectObjects" noMargin onClick={onClickSelectObjects} />
            <OutlinedButton frontIconType="accept" textKey="Apply" onClick={onApply} />
        </div>
    );
};
