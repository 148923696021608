import React from 'react';
import { DialogContent, DialogToolbar, Loading } from '..';
/**Компонент для отображения загрузки в окне */
export const DialogLoadingContent: React.FC = () => {
    return (
        <>
            <DialogContent>
                <Loading transparent />
            </DialogContent>
            <DialogToolbar></DialogToolbar>
        </>
    );
};
