import React from 'react';
import { SvgIcon, SvgIconType, Tree } from 'ui';
import { createEmptyTreeData, createTreeKey, EntityType, TreeData, TreeNode } from 'entities';
import Popover from '@material-ui/core/Popover';
import { useStyles } from './dropdown-select';
import classNames from 'classnames';

export const ItemsCountToShowFilter = 15;

interface DropdownTreeSelectProps {
    value: number;
    onChange?: (itemId: number) => void;
    width?: number;
    showAddOption?: boolean;
    onAddClick?: () => void;
    treeData: TreeData;
    itemType: EntityType;
    title?: string;
}

export const DropdownTreeSelect: React.FC<DropdownTreeSelectProps> = (props) => {    
    const { value: itemId, onChange, width, treeData, itemType, title } = props;
    
    const classes = useStyles();
    const [selectedKey, setSelectedKey] = React.useState<string>('');
    const [sourceTreeData, setSourceTreeData] = React.useState<TreeData>(treeData);
    const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);

    const inputRef = React.useRef<HTMLDivElement>(null);

    const [selectedObject, setSelectedObject] = React.useState<TreeNode | null>(null);

    const openPopover = () => setPopoverOpen(true);

    const closePopover = () => setPopoverOpen(false);

    React.useEffect(() => {
        let key = createTreeKey(itemType, itemId);
        setSelectedObject(treeData.nodes[key]);
        setSelectedKey(key);
    }, [itemId, treeData]);

    const onNodeClick = (node: TreeNode) => {        
        if (node.type == itemType) {
            setSelectedObject(node);

            if (onChange) {
                onChange(node.id);
            }
            setSelectedKey(node.key);

            closePopover();
        }
    };

    let inputClasses = classes.container;
    if (popoverOpen) {
        inputClasses = classNames(classes.container, classes.containerWithFocus);
    }
    let inputStyle: React.CSSProperties = { width };

    let inputIconType: SvgIconType = popoverOpen ? 'chevron-up' : 'chevron-down';

    let popoverContainerStyle: React.CSSProperties = {
        minWidth: 200,
        height: '65vh',
    };

    React.useEffect(() => {
        setSourceTreeData(treeData ?? createEmptyTreeData());
    }, [treeData]);

    let inputText = selectedObject !== undefined ? selectedObject?.text : title;
    let inputTextStyle = classes.inputText;
    if (!selectedObject) {
        inputTextStyle = classNames(classes.inputText, classes.placeholder);
    }

    return (
        <>
            <div
                ref={inputRef}
                className={inputClasses}
                style={inputStyle}
                tabIndex={0}
                onClick={openPopover}
                title={inputText}
            >
                <div className={inputTextStyle}>{inputText}</div>
                <SvgIcon type={inputIconType} />
            </div>
            <Popover
                anchorEl={inputRef.current}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                elevation={0}
                className={classes.muiPopover}
                open={popoverOpen}
                getContentAnchorEl={null}
                onClose={closePopover}
            >
                <div className={classes.popoverInnerContainer} style={popoverContainerStyle}>
                    <Tree
                        selectedKey={selectedKey}
                        forbidSelectCompany={true}
                        forbidSelectGroup={true}
                        allowExpand
                        expandAll={true}
                        data={sourceTreeData}
                        onNodeClick={onNodeClick}
                    />
                </div>
            </Popover>
        </>
    );
};
