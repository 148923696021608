import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from 'ui';
import { AuthState } from './entities';
import { RootState } from '..';
import { auth, getConfig } from './api';
import { toast } from 'react-toastify';
import { getRootUrl } from 'utilities';

export const login = createAsyncThunk('login/authorization', async (none, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (state.auth.authData.login == '') {
        dispatch(setLoginErrorVisible(true));
        return;
    }

    if (state.auth.authData.password == '') {
        dispatch(setPasswordErrorVisible(true));
        return;
    }

    if (!state.auth.authData) {
        return;
    }

    let signinData = await auth(state.auth.authData);

    if (signinData.need2FA) {
        localStorage.setItem('token2FA', signinData.token2FA);
        localStorage.setItem('egrUrl', signinData.egrUrl);
        document.location.href = `/newinterface/mainx/buildmainx/2fa.html`;
        return;
    }

    if (signinData.objectsCount == 0 && signinData.egrObjectsCount > 0) {
        localStorage.setItem('sessionId', signinData.sessionId);
        localStorage.setItem('egrUrl', signinData.egrUrl);
        document.location.href = signinData.egrUrl;
        return;
    }

    let locationUrl = `${getRootUrl()}newinterface/mainx/buildmainx/index.html`;

    if (state.auth.oldInterface) {
        locationUrl = `${getRootUrl()}main.aspx`;
    } else {
        localStorage.setItem('sessionId', signinData.sessionId);
        localStorage.setItem('egrUrl', signinData.egrUrl);
    }

    document.location.href = locationUrl;
});

export const loadConfig = createAsyncThunk('login/loadConfig', async () => {
    return await getConfig();
});

const getTimezoneOffsetHours = () => (new Date().getTimezoneOffset() / 60) * -1; //умножение на '-1' тк getTimezoneOffset() возвращает отрицательное значение

const initialState: AuthState = {
    authData: {
        login: '',
        password: '',
        language: '',
        timezoneOffsetHours: getTimezoneOffsetHours(),
    },
    signinData: {
        objectsCount: 0,
        egrObjectsCount: 0,
        egrUrl: '',
        need2FA: false,
        token2FA: '',
        sessionId: '',
    },
    configCreated: false,
    isLoadingConfig: LoadingState.InProgress,
    isLoadingAuth: LoadingState.Success,
    oldInterface: false,
    errorMessageText: '',
    loginErrorVisible: false,
    passwordErrorVisible: false,
};

const slice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        setLoginField: (state, action: PayloadAction<string>) => {
            state.authData.login = action.payload;
            if (action.payload) {
                state.loginErrorVisible = false;
            }
        },
        setPasswordField: (state, action: PayloadAction<string>) => {
            state.authData.password = action.payload;
            if (action.payload) {
                state.passwordErrorVisible = false;
            }
        },
        setSelectedLanguage: (state, action: PayloadAction<string>) => {
            state.authData.language = action.payload;
        },
        setStateOldInterface: (state, action: PayloadAction<boolean>) => {
            state.oldInterface = action.payload;
        },
        setLoginErrorVisible: (state, action: PayloadAction<boolean>) => {
            state.loginErrorVisible = action.payload;
        },
        setPasswordErrorVisible: (state, action: PayloadAction<boolean>) => {
            state.passwordErrorVisible = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadConfig.pending, (state) => {
            state.isLoadingConfig = LoadingState.InProgress;
            state.errorMessageText = '';
        });
        builder.addCase(loadConfig.fulfilled, (state, action) => {
            state.isLoadingConfig = LoadingState.Success;
            const config = action.payload;
            if (!config) {
                document.location.href = `${getRootUrl()}install/index.html`;
                return;
            }
            state.configCreated = config;
        });
        builder.addCase(loadConfig.rejected, (state, action) => {
            const errorText = action.error.message ?? 'Unknown Error';
            state.isLoadingConfig = LoadingState.Failure;
            state.errorMessageText = errorText;
            toast.error(errorText);
        });

        builder.addCase(login.pending, (state) => {
            state.isLoadingAuth = LoadingState.InProgress;
            state.errorMessageText = '';
        });
        builder.addCase(login.fulfilled, (state) => {
            state.isLoadingAuth = LoadingState.Success;
        });
        builder.addCase(login.rejected, (state, action) => {
            const errorText = action.error.message ?? 'Unknown Error';
            state.isLoadingAuth = LoadingState.Failure;
            state.errorMessageText = errorText;
            toast.error(errorText);
        });
    },
});

export const authorizationReducer = slice.reducer;

export const {
    setLoginField,
    setPasswordField,
    setSelectedLanguage,
    setStateOldInterface,
    setLoginErrorVisible,
    setPasswordErrorVisible,
} = slice.actions;
