import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { SvgIconType, SvgIcon } from 'ui';
import { ButtonIconSize } from '../../constants';
import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    button: { ...theme.custom?.buttons.toolbarIcon },
}));

export interface ToolbarIconButtonProps {
    /**Иконка кнопки */
    iconType: SvgIconType;
    /**Отступ слева */
    marginLeft?: number;
    /**Обработчик клика */
    onClick?: () => void;
}
/** @deprecated Кнопка, не понятно где используется */
export const ToolbarIconButton: React.FC<ToolbarIconButtonProps> = (props) => {
    let { iconType, marginLeft, onClick } = props;

    let classes = createStyles();

    return (
        <div className={classes.button} style={{ marginLeft }} onClick={onClick}>
            <SvgIcon type={iconType} size={ButtonIconSize} />
        </div>
    );
};
