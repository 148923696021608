import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SvgIcon } from 'ui';
import { translate } from 'utilities';
import { ButtonIconSize } from '../../constants';
import { Theme, combineStyles } from '../../theme';

import { ButtonProps } from './contained-button';

const createStyles = makeStyles((theme: Theme) => ({
    button: theme.custom?.buttons.outlined || {},
    icon: { ...theme.custom?.buttons.outlinedIcon },
    loading: {
        marginRight: theme.custom?.mainSizes.common.baseSize || 8,
        width: theme.custom?.mainSizes.button.iconSize || 20,
        height: theme.custom?.mainSizes.button.iconSize || 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            margin: 0,
        },
    },
}));
/**Выделенная кнопка */
export const OutlinedButton: React.FC<ButtonProps> = (props) => {
    let {
        textKey = '',
        frontIconType,
        marginLeft = 0,
        showLoadingIcon,
        disableWhileLoading,
        onClick = () => undefined,
    } = props;

    let classes = createStyles();

    const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        if (showLoadingIcon && disableWhileLoading) {
            return;
        }

        onClick(event);
    };

    let style: React.CSSProperties = { marginLeft };
    if (props.noMargin) {
        style.marginRight = 0;
    }
    let rootStyle = classes.button;
    if (textKey.length == 0) {
        rootStyle = combineStyles(rootStyle, classes.icon);
    }
    return (
        <div style={style} className={rootStyle} onClick={clickHandler}>
            {frontIconType && !showLoadingIcon && <SvgIcon type={frontIconType} size={ButtonIconSize} />}
            {frontIconType && showLoadingIcon && (
                <div className={classes.loading}>
                    <CircularProgress size={16} />
                </div>
            )}
            {translate(textKey)}
        </div>
    );
};
