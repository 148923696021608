import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultTheme, ThemesState } from './entities';
import { defaultTheme } from '../../../theme/built-in-themes';

const initialState: ThemesState = {
    themes: {
        default: {
            themeName: 'Default',
            value: defaultTheme,
        },
    },
    currentTheme: DefaultTheme,
};

const slice = createSlice({
    name: 'LoginTheme',
    initialState,
    reducers: {
        setCurrentTheme: (state, action: PayloadAction<string>) => {
            state.currentTheme = action.payload;
        },
    },
});

export const loginTheme = slice.reducer;

export const { setCurrentTheme } = slice.actions;
