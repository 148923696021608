import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from '../../theme';
import { FieldContainerProps, FieldContainer } from './field-container';
import { OutlinedButton, SvgIconType } from 'ui';
import { translate } from 'utilities';

const createStyles = makeStyles((theme: Theme) => ({
    textClassName: { ...theme.custom?.fieldContainer.simpleText },
}));

export interface FileInputFieldProps {
    /**Обработчик срабатывает при добавлении файла */
    onChange: (fileInput: File | null) => void;
    /**Ширина компонента */
    width?: number;
    /**Ширина поля описания */
    labelWidth?: number;
    /**Текст поля описания */
    labelTextKey?: string;
    /**Ширина поля для загрузки файла */
    inputWidth: number;
    /**Не используется */
    required?: boolean;
    /**Принимаемые типы файлов*/
    accept?: string;
    showLabel?: boolean;
    textKey?: string;
}
/**Поле для загрузки файлов */
export const FileInputField: React.FC<FileInputFieldProps> = (props) => {
    let { width, labelWidth, labelTextKey, inputWidth, showLabel, textKey = 'Download', onChange } = props;
    let { textClassName } = createStyles();
    const fileInput = React.useRef<HTMLInputElement>(null);

    let [fileName, setFileName] = React.useState<string | undefined>(translate('ErrorNotSelectedFile'));

    const onChangeHandler = React.useCallback(() => {
        let value;
        if (fileInput.current?.files) {
            value = fileInput.current?.files[0]?.name ?? translate('ErrorNotSelectedFile');
        }
        setFileName(value);
        let files = fileInput.current?.files;
        if (!files) {
            return;
        }
        let file = files[0];
        if (file) {
            onChange(file);
        } else {
            onChange(null);
        }
    }, [fileInput]);

    let containerProps: FieldContainerProps = {
        width,
        labelWidth,
        labelTextKey,
        inputWidth,
    };

    const ButtonWidth = 105;

    return (
        <FieldContainer {...containerProps}>
            {showLabel && (
                <div className={textClassName} style={{ width: `${inputWidth - ButtonWidth}px` }}>
                    <div
                        style={{
                            width: '284px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {fileName}
                    </div>
                    <form action="" encType="text/plain"></form>
                </div>
            )}

            <input
                accept={props.accept ?? 'text/*'}
                style={{ display: 'none' }}
                id="contained-button-file"
                ref={fileInput}
                onChange={onChangeHandler}
                type="file"
            />
            <label htmlFor="contained-button-file">
                <OutlinedButton textKey={textKey} frontIconType="download" />
            </label>
        </FieldContainer>
    );
};
