import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { FieldContainer, InputSwitcherIcon } from 'ui';
import { FieldContainerProps } from './field-container';
import { SwitcherInputWidth } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
    switchLabel: { ...theme.custom?.switcherField.label },
}));

export interface SwitcherFieldProps {
    /**Состояние переключателя */
    active?: boolean;
    /**Ширина поля */
    width?: number;
    /**Обработчик изменения состояния переключателя */
    onChange?: (value: boolean) => void;
    /**Ширина поля описания справа */
    rightLabelWidth?: number;
    /**Текст поля описания справа */
    rightLabelText?: string;
    labelWidth?: number;
    labelTextKey?: string;
    /**Параметр отключает отступы */
    disablePadding?: boolean;
}
/**Поле с переключателем */
export const SwitcherField: React.FC<SwitcherFieldProps> = (props) => {
    let { active, width, onChange, rightLabelWidth, rightLabelText = '', disablePadding, labelWidth, labelTextKey } = props;
    let { switchLabel } = useStyles();

    const onChangeHandler = () => {
        if (onChange) {
            onChange(!active);
        }
    };

    let containerProps: FieldContainerProps = {
        width,
        labelWidth,
        labelTextKey,
        rightLabelWidth,
        rightLabelText,
        disablePadding,
        inputWidth: SwitcherInputWidth,
    };

    return (
        <FieldContainer {...containerProps}>
            <div className={switchLabel} onClick={onChangeHandler}>
                <InputSwitcherIcon active={!!active} />
            </div>
        </FieldContainer>
    );
};
