import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { SvgIcon } from 'ui';
import { translate } from 'utilities';
import { ButtonIconSize } from '../../constants';
import Theme from '../../theme/fm-theme';
import classNames from 'classnames';

const createStyles = makeStyles((theme: Theme) => ({
    button: theme.custom?.buttons.delete || {},
    disableIconMargin: {
        '& svg': {
            marginRight: 0,
        },
    },
}));

export interface DeleteButtonProps {
    /**Текст в кнопке */
    textKey?: string;
    /**Отступ слева */
    marginLeft?: number;
    /**Скрыть/отобразить иконку */
    hideIcon?: boolean;
    /**Функция - обработчик клика */
    onClick?: () => void;
    /**Скрыть/отобразить текст на кнопке */
    hideText?: boolean;
    /**Убирате внешний отступ справа от кнопки */
    noMargin?: boolean;
    /**Параметр убирает минимальную ширину */
    disableMinWidth?: boolean;
}
/**Кнопка дя удаления */
export const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
    let {
        textKey,
        marginLeft = 0,
        hideIcon,
        onClick,
        hideText = false,
        noMargin = false,
        disableMinWidth = false,
    } = props;

    let classes = createStyles();

    let containerClass = classes.button;

    let style: React.CSSProperties = { marginLeft };
    if (noMargin) {
        style.margin = 0;
    }
    if (disableMinWidth) {
        style.minWidth = 'unset';
    }
    let text = textKey ? translate(textKey) : translate('Delete');
    if (hideIcon) {
        style.justifyContent = 'center';
    }
    if (hideText) {
        text = '';
        containerClass = classNames(containerClass, classes.disableIconMargin);
    }
    return (
        <div style={style} className={containerClass} onClick={onClick}>
            {!hideIcon && <SvgIcon type="delete" size={ButtonIconSize} />}
            {text}
        </div>
    );
};
