import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { CheckboxIcon, FieldContainer } from 'ui';
import { translate } from 'utilities';

const useStyles = makeStyles((theme: Theme) => ({
    checkboxInput: { ...theme.custom?.fieldContainer.checkboxInput },
}));
export interface CheckboxFieldProps {
    /**Состояние чекбокса  */
    checked?: boolean;
    /**Обработчик изменения состояния чекбокса */
    onChange?: (value: boolean) => void;
    /**Ширина левого лейбла */
    labelWidth?: number;
    /**Текст левого лейбла */
    labelTextKey?: string;
    /**Ширина чекбокса*/
    inputWidth?: number;
    /**Ширина правого лейбла */
    rightLabelWidth?: number;
    /**Текст правого лейбла */
    rightLabelTextKey?: string;
}
/**Поле с чекбоксом и описанием */
export const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
    let {
        checked,
        onChange,
        labelWidth = 0,
        labelTextKey = '',
        inputWidth,
        rightLabelWidth = 0,
        rightLabelTextKey = '',
    } = props;
    let classes = useStyles();

    const onChangeHandler = () => {
        if (onChange) {
            onChange(!checked);
        }
    };

    return (
        <FieldContainer
            labelTextKey={labelTextKey}
            labelWidth={labelWidth}
            inputWidth={inputWidth}
            rightLabelText={translate(rightLabelTextKey)}
            rightLabelWidth={rightLabelWidth}
        >
            <div className={classes.checkboxInput} onClick={onChangeHandler}>
                <CheckboxIcon checked={!!checked} />
            </div>
        </FieldContainer>
    );
};
