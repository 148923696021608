import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme, combineStyles } from '../../theme';

const createStyles = makeStyles((theme: Theme) => ({
    textTabClassName: { ...theme.custom?.tabPane.textTab },
    textClassName: { ...theme.custom?.tabPane.text },
    activeTextClassName: { ...theme.custom?.tabPane.activeText },
    markerClassName: { ...theme.custom?.tabPane.marker },
    activeMarkerClassName: { ...theme.custom?.tabPane.activeMarker },
}));

interface TextTabProps {
    text: string;
    active?: boolean;
    onClick?: () => void;
}
export const TextTab: React.FC<TextTabProps> = (props) => {
    let { text, active, onClick } = props;
    let { textTabClassName, textClassName, activeTextClassName, markerClassName, activeMarkerClassName } =
        createStyles();

    const clickHandler = () => {
        if (onClick) {
            onClick();
        }
    };

    let textClasses = textClassName;
    let markerClasses = markerClassName;
    if (active) {
        textClasses = combineStyles(textClasses, activeTextClassName);
        markerClasses = combineStyles(markerClasses, activeMarkerClassName);
    }
    return (
        <div className={textTabClassName} onClick={clickHandler}>
            <div className={textClasses}>{text}</div>
            <div className={markerClasses} />
        </div>
    );
};
