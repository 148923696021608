import * as React from 'react';
import { default as IconButtonMUI } from '@material-ui/core/IconButton';
import { Icon, IconType, IconColorType } from 'ui';

export interface Props {
    /** Тип иконки */
    type: IconType;
    /** Обработчик клика */
    onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
    /** Уменьшенная иконка */
    iconMini?: boolean;
    /** Цвет иконки */
    color?: IconColorType;
    /**Ссылка на кнопку */
    ref?: React.RefObject<HTMLButtonElement>;
    /**Сделать элемент активным/не активным */
    disabled?: boolean;
}

/** @deprecated Use contained, outlined or delete button. */
export function IconButton(props: Props): JSX.Element {
    const ICON_MINI = 20;
    const ICON_NORMAL = 24;
    let size = ICON_NORMAL;
    if (Boolean(props.iconMini)) {
        size = ICON_MINI;
    }
    const color = props.disabled ? 'disabled' : props.color;

    return (
        <IconButtonMUI
            color="inherit"
            aria-label="open drawer"
            onClick={props.onClick}
            edge="start"
            ref={props.ref}
            disabled={props.disabled}
        >
            <Icon type={props.type} color={color} size={size} />
        </IconButtonMUI>
    );
}

IconButton.defaultProps = {
    color: 'primary',
};
