import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenuSwitcherIcon } from 'ui';
import { translate } from 'utilities';
import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    menuItem: { ...theme.custom?.popupMenu.item },
    activeStyle: { ...theme.custom?.svgIcon.activeSwitcher },
    disabledStyle: { ...theme.custom?.svgIcon.disabledSwitcher },
}));

export interface SwitcherMenuItemProps {
    /**Параметр управляет состоянием checkbox*/
    active: boolean;
    /**label, задается ключ для перевода*/
    textKey: string;
    /**Коллбек функция при нажатии на checkbox*/
    onClick?: () => void;
}
/**Компонент для Menu с checkbox */
export const SwitcherMenuItem: React.FC<SwitcherMenuItemProps> = (props) => {
    let { active, textKey, onClick } = props;
    let classes = createStyles();

    return (
        <div className={classes.menuItem} onClick={onClick}>
            <MenuSwitcherIcon active={active} />
            {translate(textKey)}
        </div>
    );
};
