import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';

import { Theme } from 'theme';
import { FilterInput, Toolbar } from 'ui';

import { useStyles } from './dropdown-select';

const localUseStyles = makeStyles((theme: Theme) => ({
    itemDropdownText: {
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
    },
    itemDropdownTitle: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
    },
    item: {
        ...theme.custom?.typography.text,
        margin: 1,
        flex: `0 0 ${theme.custom?.mainSizes.list.rowHeight}px`,
        borderRadius: theme.custom?.mainSizes.common.smallBorderRadius,
        padding: `0 ${theme.custom?.mainSizes.common.baseSize}px`,
        alignItems: 'center',
        gap: theme.custom?.mainSizes.common.baseSize,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.custom?.palette.actions.hover,
        },
        '& svg': {
            width: theme.custom?.mainSizes.input.iconSize,
            height: theme.custom?.mainSizes.input.iconSize,
        },
        '& path': {
            fill: theme.custom?.palette.text.secondary,
        },
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        lineHeight: `${theme.custom?.mainSizes.list.rowHeight}px`,
    },
    popoverInnerContainer: {
        padding: theme.custom?.mainSizes.common.baseSize,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '479px',
    },
}));

export interface DropdownItem {
    key: string;
    value: string;
}

interface DropdownSelectProps {
    items?: DropdownItem[];
    onSelect?: (item: DropdownItem) => void;
    title?: string;
    width?: number;
    currentValue?: number;
}
export const DropdownRowDataSelect: React.FC<DropdownSelectProps> = (props) => {
    let { items = [], onSelect = () => undefined, title, currentValue } = props;
    let classes = useStyles();
    let localClasses = localUseStyles();

    let commonClasses = { ...classes, ...localClasses };

    let inputRef = React.useRef<HTMLDivElement>(null);
    let [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
    let [currentItem, setCurrentItem] = React.useState<DropdownItem | null>(null);
    let [filterValue, setFilterValue] = React.useState<string>('');
    let [itemsView, setItemsView] = React.useState<DropdownItem[]>([]);

    const openPopover = () => setPopoverOpen(true);

    const closePopover = () => setPopoverOpen(false);

    const selectItem = (item: DropdownItem) => {
        closePopover();
        onSelect(item);
        setCurrentItem(item);
    };

    React.useEffect(() => {
        if (filterValue == '') {
            setItemsView([...items]);
            return;
        }

        let lowerCaseFilterValue = filterValue.toLowerCase();
        let newItemsView = items.filter((item) => item.value.toLowerCase().includes(lowerCaseFilterValue));
        setItemsView(newItemsView);
    }, [items, filterValue]);

    React.useEffect(() => {
        if (currentValue) {
            const filteredItems = items.filter((item) => Number(item.key) == currentValue);
            setCurrentItem(filteredItems[0]);
        }
    }, [items]);

    const ItemsCountToShowFilter = 15;

    return (
        <>
            <div ref={inputRef} tabIndex={0} onClick={openPopover}>
                <div className={currentItem ? commonClasses.itemDropdownText : commonClasses.itemDropdownTitle}>
                    {currentItem ? currentItem.value : title}
                </div>
            </div>
            <Popover
                anchorEl={inputRef.current}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                elevation={0}
                className={commonClasses.muiPopover}
                open={popoverOpen}
                getContentAnchorEl={null}
                onClose={closePopover}
            >
                <div className={commonClasses.popoverInnerContainer}>
                    {items.length > ItemsCountToShowFilter && (
                        <Toolbar>
                            <FilterInput fullWidth value={filterValue} onChange={setFilterValue} />
                        </Toolbar>
                    )}
                    <div className={commonClasses.itemsContainer}>
                        {itemsView.map((item) => {
                            const onClick = () => selectItem(item);

                            return (
                                <div key={item.key} className={commonClasses.item} onClick={onClick} title={item.value}>
                                    {item.value}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Popover>
        </>
    );
};
