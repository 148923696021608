import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/styles';

import { FilterInput, SvgIcon, SvgIconType, Toolbar } from 'ui';
import { InputHeight as ItemHeight } from '../../constants';
import { Theme, combineStyles } from '../../theme';

const createStyles = makeStyles((theme: Theme) => ({
    muiPopoverClassName: { ...theme.custom?.selectField.muiPopover },
    inputClassName: { ...theme.custom?.selectField.inputPane },
    focusedInputClassName: { ...theme.custom?.selectField.focusedInput },
    inputTextClassName: { ...theme.custom?.selectField.text },
    inputIconClassName: { ...theme.custom?.selectField.icon },
    itemsContainerClassName: { ...theme.custom?.selectField.itemsContainer },
    itemClassName: { ...theme.custom?.selectField.item },
    selectedItemClassName: { ...theme.custom?.selectField.selectedItem },
    itemTextClassName: { ...theme.custom?.selectField.itemText },
    itemIconClassName: { ...theme.custom?.selectField.itemIcon },
    noMarginClassName: { ...theme.custom?.generalStyles.noMargin },
}));

export interface DropdownListItem {
    id: string;
    name: string;
    hidden?: boolean;
}

interface DropdownListProps {
    items: DropdownListItem[];
    selectedId: string;
    width?: number;
    popoverMinWidth?: number;
    noMarginItems?: boolean;
    hideFilter?: boolean;
    onChange?: (item: DropdownListItem) => void;
}
/**
 * @deprecated Use DropdownSelect or DropdownMultiselect instead.
 */
export const DropdownList: React.FC<DropdownListProps> = (props) => {
    let { items, selectedId, width, popoverMinWidth, noMarginItems, hideFilter, onChange } = props;
    let {
        muiPopoverClassName,
        inputClassName,
        focusedInputClassName,
        inputTextClassName,
        inputIconClassName,
        itemsContainerClassName,
        itemClassName,
        selectedItemClassName,
        itemTextClassName,
        itemIconClassName,
        noMarginClassName,
    } = createStyles();

    let inputRef = React.useRef<HTMLDivElement>(null);
    let itemsPaneRef = React.useRef<HTMLDivElement>(null);
    let [selectedItem, setSelectedItem] = React.useState<DropdownListItem | undefined>(undefined);
    let [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
    let [filterValue, setFilterValue] = React.useState<string>('');
    let [viewItems, setViewItems] = React.useState<DropdownListItem[]>([]);

    const openPopover = () => setPopoverOpen(true);

    const closePopover = () => setPopoverOpen(false);

    const onFilterValueChange = (value: string) => {
        // update filter value
        setFilterValue(value);

        // update view items
        if (value.length == 0) {
            setViewItems(items);
            return;
        }

        let lowerCaseValue = value.toLowerCase();
        setViewItems(items.filter((item) => item.name.toLowerCase().includes(lowerCaseValue)));
    };

    const selectItem = (item: DropdownListItem) => {
        closePopover();
        if (onChange) {
            onChange(item);
        }
    };

    React.useEffect(() => {
        // updpate selected item
        let newSelectedItem = items.find((item) => item.id == selectedId);

        if (!newSelectedItem) {
            newSelectedItem = items[0];
            selectItem(newSelectedItem);
        }

        setSelectedItem(newSelectedItem);

        // update view items
        if (filterValue.length == 0) {
            setViewItems(items);
            return;
        }

        let lowerCaseFilterValue = filterValue.toLowerCase();
        setViewItems(items.filter((item) => item.name.toLowerCase().includes(lowerCaseFilterValue)));
    }, [items, selectedId]);

    React.useEffect(() => {
        if (popoverOpen) {
            setTimeout(() => {
                let index = viewItems.findIndex((item) => item.id == selectedItem?.id);
                if (itemsPaneRef.current) {
                    // 3 - число строк, которые должны оставаться над выбранной при автопрокрутке
                    itemsPaneRef.current.scrollTop = (index - 3) * ItemHeight;
                }
            }, 100);
        }
    }, [popoverOpen]);

    let inputClasses = inputClassName;
    if (popoverOpen) {
        inputClasses = combineStyles(inputClasses, focusedInputClassName);
    }
    let inputStyle: React.CSSProperties = {};
    if (width) {
        inputStyle.flex = `0 0 ${width}px`;
    }
    let inputIconType: SvgIconType = popoverOpen ? 'chevron-up' : 'chevron-down';
    return (
        <>
            <div ref={inputRef} className={inputClasses} style={inputStyle} tabIndex={0} onClick={openPopover}>
                <div className={inputTextClassName}>{selectedItem?.name}</div>
                <div className={inputIconClassName}>
                    <SvgIcon type={inputIconType} />
                </div>
            </div>
            <Popover
                anchorEl={inputRef.current}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                className={muiPopoverClassName}
                elevation={0}
                open={popoverOpen}
                getContentAnchorEl={null}
                onClose={closePopover}
            >
                <div style={{ minWidth: popoverMinWidth }}>
                    {!hideFilter && (
                        <Toolbar>
                            <FilterInput value={filterValue} onChange={onFilterValueChange} />
                        </Toolbar>
                    )}
                    <div ref={itemsPaneRef} className={itemsContainerClassName}>
                        {viewItems.map((item) => {
                            if (item.hidden) {
                                return null;
                            }

                            let styles = itemClassName;
                            let selected = item.id == selectedItem?.id;
                            if (selected) {
                                styles = combineStyles(styles, selectedItemClassName);
                            }
                            if (noMarginItems) {
                                styles = combineStyles(styles, noMarginClassName);
                            }

                            const onClick = () => selectItem(item);

                            return (
                                <div key={item.id} className={styles} onClick={onClick}>
                                    <div className={itemTextClassName}>{item.name}</div>
                                    {selected && (
                                        <div className={itemIconClassName}>
                                            <SvgIcon type="accept" />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Popover>
        </>
    );
};
