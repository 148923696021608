import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import { ThemeProvider } from '@material-ui/styles';
import { ToastProvider } from '../ui/toast-provider';
import { LoginPage } from './pages/login';
import { defaultDarkTheme } from '../theme/login-theme-creator';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={defaultDarkTheme}>
                <LoginPage />
                <ToastProvider />
            </ThemeProvider>
        </Provider>
    );
};

createRoot(document.getElementById('root') ?? document.body).render(<App />);
