import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from '../../theme';

const createStyles = makeStyles((theme: Theme) => ({
    rootClassName: { ...theme.custom?.detailsPane.root },
}));
/**Компонент для отображение children в колонну */
export const DetailsPane: React.FC<React.PropsWithChildren> = (props) => {
    let { children } = props;
    let { rootClassName } = createStyles();

    return <div className={rootClassName}>{children}</div>;
};
