import { LoadingState } from 'ui';

export * from './tree';
export * from './date-time';
export * from './table';
export * from './excel';

export interface Messages {
    [key: string]: string;
}

export interface UserPermissions {
    [key: string]: PermissionsItem;
}

export interface PermissionsItem {
    view: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
}

export type EmptyObject = Record<string, never>;

export enum MapType {
    DefaultServerSettings = 0,
    OSM = 1,
    Google = 2,
    Yandex = 3,
    Gis2 = 4,
    GisServer = 5,
    Bing = 6,
    Visicom = 7,
    Wikimapia = 8,
    Kosmosnimki = 9,
}

export interface AbstractApiResponse {
    loaded: boolean;
    errorText: string;
}

export interface DefaultApiResponse {
    loadingState: LoadingState;
    errorText: string;
}

export interface ApiResponse<T> extends AbstractApiResponse {
    value: T;
}

export enum PermissionsType {
    NotSet = 0,
    ServerAdmin = 1,
    CompanyAdmin = 2,
    User = 3,
    Operator = 4,
    Custom = 5,
    Dealer = 6,
}
