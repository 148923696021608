import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { getHours, getMinutes, getSeconds } from 'date-fns';

import {
    DateTimeInputValue,
    TimePeriodInputValue,
    TimePeriodType,
    getLast3Hours,
    getTimePeriod,
    getTimePeriodInputValue,
} from 'entities';
import { Theme } from 'theme';
import { DropdownItem, DropdownSelect } from 'ui';
import { getFullDateTimeString, translate } from 'utilities';

import DateTimeInput from './date-time-controlled';
const DateTimeInputWidth = 220;

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        gap: theme.custom?.mainSizes.common.baseSize || 8,
    },
}));

interface TimePeriodInputProps {
    value?: TimePeriodInputValue;
    onChange?: (value: TimePeriodInputValue) => void;
    disableHours?: boolean;
    disableDays?: boolean;
    disableWeeks?: boolean;
    disableMonths?: boolean;
    allowStartDateEmpty?: boolean;
    allowEndDateEmpty?: boolean;
}
export const TimePeriodInput: React.FC<TimePeriodInputProps> = (props) => {
    let {
        value = getTimePeriodInputValue(getLast3Hours()),
        onChange = () => undefined,
        disableHours,
        disableDays,
        disableWeeks,
        disableMonths,
    } = props;
    let classes = useStyles();

    let [availablePeriods, setAvailablePeriods] = React.useState<DropdownItem[]>([]);

    const onTypeChange = (item: DropdownItem) => {
        let newValue = getTimePeriod(item.key as TimePeriodType);
        onChange(getTimePeriodInputValue(newValue));
    };

    const onStartDateChange = (dateValue: DateTimeInputValue) => {
        let isTimePeriodValid = value.end.dateValue > dateValue.dateValue;
        const endValue = { ...value.end };
        if (!isTimePeriodValid && value.valid) {
            endValue.dateValue = new Date(dateValue.dateValue);
            endValue.dateValue.setHours(
                getHours(value.end.dateValue),
                getMinutes(value.end.dateValue),
                getSeconds(value.end.dateValue),
            );
            endValue.textValue = getFullDateTimeString(endValue.dateValue);

            isTimePeriodValid = endValue.dateValue > dateValue.dateValue;
        }

        let newValue: TimePeriodInputValue = {
            valid: isTimePeriodValid && dateValue.valid && value.end.valid,
            type: value.type,
            start: dateValue,
            end: endValue,
        };

        onChange(newValue);
    };

    const onEndDateChange = (dateValue: DateTimeInputValue) => {
        let isTimePeriodValid = value.start.dateValue < dateValue.dateValue;

        const startValue = { ...value.start };

        if (!isTimePeriodValid && value.valid) {
            startValue.dateValue = new Date(dateValue.dateValue);

            startValue.dateValue.setHours(
                getHours(value.start.dateValue),
                getMinutes(value.start.dateValue),
                getSeconds(value.start.dateValue),
            );
            startValue.textValue = getFullDateTimeString(startValue.dateValue);

            isTimePeriodValid = startValue.dateValue < dateValue.dateValue;
        }

        let newValue: TimePeriodInputValue = {
            valid: isTimePeriodValid && dateValue.valid && value.start.valid,
            type: value.type,
            start: startValue,
            end: dateValue,
        };
        onChange(newValue);
    };

    React.useEffect(() => {
        let result: DropdownItem[] = [];
        if (!disableHours) {
            result.push({ key: TimePeriodType.Last3Hours, value: translate(TimePeriodType.Last3Hours) });
            result.push({ key: TimePeriodType.Last24Hours, value: translate(TimePeriodType.Last24Hours) });
        }
        if (!disableDays) {
            result.push({ key: TimePeriodType.CurrentDay, value: translate(TimePeriodType.CurrentDay) });
            result.push({ key: TimePeriodType.LastDay, value: translate(TimePeriodType.LastDay) });
        }
        if (!disableWeeks) {
            result.push({ key: TimePeriodType.CurrentWeek, value: translate(TimePeriodType.CurrentWeek) });
            result.push({ key: TimePeriodType.LastWeek, value: translate(TimePeriodType.LastWeek) });
        }
        if (!disableMonths) {
            result.push({ key: TimePeriodType.CurrentMonth, value: translate(TimePeriodType.CurrentMonth) });
            result.push({ key: TimePeriodType.LastMonth, value: translate(TimePeriodType.LastMonth) });
        }
        setAvailablePeriods(result);
    }, [disableHours, disableDays, disableWeeks, disableMonths]);

    return (
        <div className={classes.container}>
            <DateTimeInput
                value={value.start}
                onChange={onStartDateChange}
                width={DateTimeInputWidth}
                popoverAlign="left"
                allowEmpty={props.allowStartDateEmpty}
                externalValid={value.valid}
            />
            <DropdownSelect items={availablePeriods} onSelect={onTypeChange} title={translate('Period')} width={110} />
            <DateTimeInput
                value={value.end}
                onChange={onEndDateChange}
                width={DateTimeInputWidth}
                popoverAlign="right"
                allowEmpty={props.allowEndDateEmpty}
                externalValid={value.valid}
            />
        </div>
    );
};
