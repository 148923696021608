import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Theme from '../../theme/fm-theme';

import { NotificationType } from './default';

const createStyles = makeStyles((theme: Theme) => ({
    icon: theme.custom?.notifications.icon || {},
    success: theme.custom?.notifications.successIcon || {},
    error: theme.custom?.notifications.errorIcon || {},
}));

interface NotificationIcon {
    type: NotificationType;
}
export const NotificationIcon: React.FC<NotificationIcon> = ({ type }) => {
    let classes = createStyles();

    let className = classes.icon + ' ';
    let data: React.ReactNode = null;
    switch (type) {
        case NotificationType.Success:
            className += classes.success;
            data = (
                <>
                    <circle cx="24" cy="24" r="20" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M36.4609 16.634C37.2153 17.4408 37.1728 18.7064 36.366 19.4609L23.116 31.4609C22.3472 32.1797 21.1528 32.1797 20.384 31.4609L13.634 25.279C12.8272 24.5246 12.7847 23.259 13.5392 22.4522C14.2936 21.6454 15.5592 21.6029 16.366 22.3573L21.75 27.2619L33.634 16.5392C34.4408 15.7847 35.7064 15.8272 36.4609 16.634Z"
                    />
                </>
            );
            break;
        case NotificationType.Error:
            className += classes.error;
            data = (
                <>
                    <path
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20.7267 5.91456L4.51305 34.0341C4.17876 34.6355 4.00189 35.3173 4.00002 36.0118C3.99814 36.7063 4.17134 37.3892 4.50238 37.9925C4.83342 38.5959 5.31076 39.0986 5.88692 39.4508C6.46307 39.803 7.11795 39.9924 7.7864 40H40.2136C40.882 39.9924 41.5369 39.803 42.1131 39.4508C42.6892 39.0986 43.1666 38.5959 43.4976 37.9925C43.8287 37.3892 44.0019 36.7063 44 36.0118C43.9981 35.3173 43.8212 34.6355 43.4869 34.0341L27.2733 5.91456C26.9321 5.33011 26.4516 4.84689 25.8782 4.51154C25.3049 4.17618 24.658 4 24 4C23.342 4 22.6951 4.17618 22.1218 4.51154C21.5484 4.84689 21.0679 5.33011 20.7267 5.91456Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.99 14C25.0891 14 25.9801 14.8954 25.9801 16V26C25.9801 27.1046 25.0891 28 23.99 28C22.891 28 22 27.1046 22 26V16C22 14.8954 22.891 14 23.99 14Z"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22 34C22 32.8954 22.891 32 23.99 32H24.01C25.109 32 26 32.8954 26 34C26 35.1046 25.109 36 24.01 36H23.99C22.891 36 22 35.1046 22 34Z"
                    />
                </>
            );
            break;
    }
    return <svg className={className}>{data}</svg>;
};
