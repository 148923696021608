import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme, combineStyles } from 'theme';

import { SvgIcon } from './icons';

const useStyles = makeStyles((theme: Theme) => ({
    tagRoot: {
        ...theme.custom?.typography.text,
        borderRadius: theme.custom?.mainSizes.common.tinyBorderRadius,
        padding: `0 ${theme.custom?.mainSizes.tag.indent}px`,
        height: theme.custom?.mainSizes.tag.height,
        display: 'flex',
        alignItems: 'center',
        gap: theme.custom?.mainSizes.tag.indent,
        color: theme.custom?.palette.colors.customColorsContrastText,
        '& svg': {
            width: theme.custom?.mainSizes.tag.iconSize,
        },
        '& path': {
            fill: theme.custom?.palette.colors.customColorsContrastText,
        },
    },
    tagColor1: {
        backgroundColor: theme.custom?.palette.colors.color1,
    },
    tagColor2: {
        backgroundColor: theme.custom?.palette.colors.color2,
    },
    tagColor3: {
        backgroundColor: theme.custom?.palette.colors.color3,
    },
    tagColor4: {
        backgroundColor: theme.custom?.palette.colors.color4,
    },
    tagColor5: {
        backgroundColor: theme.custom?.palette.colors.color5,
    },
    tagColor6: {
        backgroundColor: theme.custom?.palette.colors.color6,
    },
    tagColor7: {
        backgroundColor: theme.custom?.palette.colors.color7,
    },
    tagColor8: {
        backgroundColor: theme.custom?.palette.colors.color8,
    },
    tagColor9: {
        backgroundColor: theme.custom?.palette.colors.color9,
    },
    tagColor10: {
        backgroundColor: theme.custom?.palette.colors.color10,
    },
    clickable: { ...theme.custom?.generalStyles.clickable },
}));

interface TagProps {
    /** Color number from 1 to 10. All numbers out of interval will be displayed as color number 10. */
    colorNumber?: number;
    clickable?: boolean;
    onClick?: () => void;
}
const Tag: React.FC<PropsWithChildren<TagProps>> = (props) => {
    let classes = useStyles();

    const onClick = () => props.onClick && props.onClick();

    let colorClasses: string;
    switch (props.colorNumber) {
        case 1:
            colorClasses = classes.tagColor1;
            break;
        case 2:
            colorClasses = classes.tagColor2;
            break;
        case 3:
            colorClasses = classes.tagColor3;
            break;
        case 4:
            colorClasses = classes.tagColor4;
            break;
        case 5:
            colorClasses = classes.tagColor5;
            break;
        case 6:
            colorClasses = classes.tagColor6;
            break;
        case 7:
            colorClasses = classes.tagColor7;
            break;
        case 8:
            colorClasses = classes.tagColor8;
            break;
        case 9:
            colorClasses = classes.tagColor9;
            break;
        default:
            colorClasses = classes.tagColor10;
            break;
    }
    let rootClasses = combineStyles(classes.tagRoot, colorClasses);
    if (props.clickable) {
        rootClasses = combineStyles(rootClasses, classes.clickable);
    }
    return (
        <div className={rootClasses} onClick={onClick}>
            {props.children}
        </div>
    );
};

interface TextTagProps {
    text: string;
    colorNumber: number;
}
export const TextTag: React.FC<TextTagProps> = (props) => {
    return (
        <Tag clickable={false} colorNumber={props.colorNumber}>
            {props.text}
        </Tag>
    );
};

interface TextTagWithCloseProps extends TextTagProps {
    onClose: () => void;
}
export const TextTagWithClose: React.FC<TextTagWithCloseProps> = (props) => {
    return (
        <Tag clickable colorNumber={props.colorNumber} onClick={props.onClose}>
            {props.text}
            <SvgIcon type="close" />
        </Tag>
    );
};
