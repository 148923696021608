import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { SvgIcon } from 'ui';
import { DialogHeaderButtonSize } from '../../constants';
import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    root: { ...theme.custom?.dialog.tabTitle.root },
    tabsPane: { ...theme.custom?.dialog.tabTitle.tabsPane },
    closePane: { ...theme.custom?.dialog.tabTitle.closePane },
}));

export interface DialogTabTitleProps {
    /**Обработчик клика на закрывающую кнопку */
    onClose: () => void;
}
/**Компонент создает шапку для табов в диалоговом окне */
export const DialogTabTitle: React.FC<React.PropsWithChildren<DialogTabTitleProps>> = (props) => {
    let { children, onClose } = props;
    let classes = createStyles();

    return (
        <div className={classes.root}>
            <div className={classes.tabsPane}>{children}</div>
            <div className={classes.closePane} onClick={onClose}>
                <SvgIcon type="dialog-close" size={DialogHeaderButtonSize} />
            </div>
        </div>
    );
};
