export { default as Theme, UiTreeStyles } from './fm-theme';
export { defaultTheme, darkTheme, contrastTheme } from './built-in-themes';

export enum ThemeNames {
    Default = 'DefaultTheme',
    Dark = 'DarkTheme',
    Contrast = 'ContrastTheme',
}

/**
 * @deprecated Use classNames or DropdownMultiselect instead.
 */
export function combineStyles(...args: string[]): string {
    return [...args].join(' ');
}
