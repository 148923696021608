import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { LoadingState } from 'ui';
import Theme from '../../../../theme/login-theme';
import { SvgIcon } from '../../../components/icons/svg-icon';
import { PreviewsPane } from '../../../components/slider/slider';
import { useLoginDispatch } from '../../../store';
import { getBackgroundImagePath } from '../../../store/sharedData/entities';
import {
    getCurrentNewsIndex,
    getIntervalId,
    getSharedDataLoading,
    getNews,
    getTranslates,
} from '../../../store/sharedData/selectors';
import { setIntervalId, showNextNewsImage, showPrevNewsImage } from '../../../store/sharedData/slice';

const createStyles = makeStyles((theme: Theme) => ({
    contentBlock: theme.custom?.content.contentBlock || {},
    contentLeft: theme.custom?.content.contentLeft || {},
    contentLeftNotNav: theme.custom?.content.contentLeftNotNav || {},
    contentCenter: theme.custom?.content.contentCenter || {},
    contentRight: theme.custom?.content.contentRight || {},
    contentRightNotNav: theme.custom?.content.contentRightNotNav || {},
    description: theme.custom?.content.description || {},
    imageList: theme.custom?.content.imageList || {},
    nextButton: theme.custom?.content.nextButton || {},
    prevButton: theme.custom?.content.prevButton || {},
    descriptionTitle: theme.custom?.content.descriptionTitle || {},
    descriptionText: theme.custom?.content.descriptionText || {},
    descriptionLink: theme.custom?.content.descriptionLink || {},
    descriptionLinkColor: theme.custom?.content.descriptionLinkColor || {},
    previewList: theme.custom?.content.previewList || {},
}));

export const Content: React.FC = () => {
    const dispatch = useLoginDispatch();
    const classes = createStyles();
    const sharedDataLoading = useSelector(getSharedDataLoading);
    const newsView = useSelector(getNews);
    const currentNewsIndex = useSelector(getCurrentNewsIndex);
    const currentNews = newsView[currentNewsIndex];
    let translations = useSelector(getTranslates);
    let intervalId = useSelector(getIntervalId);

    const changeIntervalId = React.useCallback(
        (intervalId: number) => {
            clearInterval(intervalId);
            let newIntervalId = setInterval(() => {
                dispatch(showNextNewsImage());
            }, 10_000);
            dispatch(setIntervalId(Number(newIntervalId)));
        },
        [intervalId],
    );

    let backgroundImage = '';
    let windowWidth = window.innerWidth;

    backgroundImage = getBackgroundImagePath(windowWidth, currentNews);

    const preparedText = currentNews?.body.map((text: string, index: number) => {
        return (
            <React.Fragment key={index}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
                <br />
            </React.Fragment>
        );
    });

    const onPressNextButton = () => {
        dispatch(showNextNewsImage());
        changeIntervalId(intervalId);
    };

    const onPressPrevButton = () => {
        dispatch(showPrevNewsImage());
        changeIntervalId(intervalId);
    };

    if (!newsView.length) {
        return (
            <div
                className={classes.contentBlock}
                style={{
                    backgroundImage: `url(/img/login_backimage.png)`,
                }}
            >
                <div
                    className={classes.contentCenter}
                    style={{
                        flexDirection: 'column-reverse',
                    }}
                >
                    {sharedDataLoading === LoadingState.Failure && (
                        <div
                            className={classes.previewList}
                            style={{
                                flexDirection: 'row-reverse',
                                padding: '30px',
                            }} //TODO: подумать над отображением текста тк переводы получаем в одном запросе с медиа файлами
                        >
                            <p>Не удалось загрузить медиа файлы</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            className={classes.contentBlock}
            style={{
                backgroundImage: `url("${backgroundImage}")`,
            }}
        >
            {newsView.length > 1 ? (
                <div className={classes.contentLeft} onClick={onPressPrevButton}>
                    <div className={classes.prevButton}>
                        <SvgIcon type="chevron-left" />
                    </div>
                </div>
            ) : (
                <div className={classes.contentLeftNotNav} />
            )}
            <div className={classes.contentCenter}>
                <div className={classes.description}>
                    <div className={classes.descriptionTitle}>{currentNews?.caption}</div>
                    <div className={classes.descriptionText}>{preparedText}</div>
                </div>
                <div className={classes.imageList}>
                    <div className={classes.descriptionLink}>
                        {currentNews?.link !== '' && (
                            <a
                                href={currentNews?.link}
                                rel="noreferrer"
                                target="_blank"
                                className={classes.descriptionLinkColor}
                            >
                                {translations.MoreDetails}
                            </a>
                        )}
                    </div>
                    {newsView.length > 1 && (
                        <div className={classes.previewList}>
                            <PreviewsPane newsView={newsView} />
                        </div>
                    )}
                </div>
            </div>
            {newsView.length > 1 ? (
                <div className={classes.contentRight} onClick={onPressNextButton}>
                    <div className={classes.nextButton}>
                        <SvgIcon type="chevron-right" />
                    </div>
                </div>
            ) : (
                <div className={classes.contentRightNotNav} />
            )}
        </div>
    );
};
