import React from 'react';
import Input from '@material-ui/core/Input';
import { FieldContainerProps, FieldContainer } from 'ui';

export interface HexRfidFieldProps {
    value: string;
    onChange: (value: string) => void;
    width?: number;
    labelWidth?: number;
    labelTextKey?: string;
    inputWidth?: number;
    autoFocus?: boolean;
    disablePadding?: boolean;
    maxLength?: number;
}

export const HexRfidField: React.FC<HexRfidFieldProps> = (props: HexRfidFieldProps) => {
    let { value, onChange, width, labelWidth, labelTextKey, inputWidth, autoFocus, disablePadding, maxLength } = props;

    const onChangeHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/[^0-9A-Fa-f]/g, '');

        if (typeof maxLength === 'number' && value.length > maxLength) {
            return;
        }

        onChange(value === '' ? '0' : BigInt('0x' + value).toString());
    }, []);

    let containerProps: FieldContainerProps = {
        width,
        labelWidth,
        labelTextKey,
        disablePadding,
        inputWidth,
    };

    const bigIntValue = BigInt(value).toString(16);

    return (
        <FieldContainer {...containerProps}>
            <Input
                disableUnderline
                fullWidth
                autoFocus={autoFocus}
                value={bigIntValue}
                onChange={onChangeHandler}
                inputProps={{
                    style: {
                        textTransform: 'uppercase',
                    },
                }}
            />
        </FieldContainer>
    );
};
