import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Colors, Size } from '.';
import Theme from '../theme/fm-theme';
import { translate } from '../utilities';
import { SvgIcon } from './icons';

const createClasses = makeStyles((theme: Theme) => ({
    errorPane: {
        flex: '1 1 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.custom?.spacing(1),
            width: theme.custom?.mainSizes.table.iconSize,
            height: theme.custom?.mainSizes.table.iconSize,
        },
    },
    text: {
        ...theme.custom?.typography.infoMessage,
        paddingTop: theme.spacing(0.5),
        color: theme.custom?.palette.text.disabled,
        display: 'flex',
    },
}));

export interface ErrorPaneProps extends Partial<Size> {
    /**Сообщение ошибки (будет переведено) */
    translationKey?: string;
}
/**Панель для отображения текста ошибки */
export const ErrorPane: React.FC<ErrorPaneProps> = ({ translationKey, width, height }) => {
    let classes = createClasses();

    let style: React.CSSProperties = {};
    if (width === undefined || height === undefined) {
        style.flex = '1 1 0';
    } else {
        style.width = width;
        style.height = height;
    }

    return (
        <div className={classes.errorPane} style={style}>
            <SvgIcon type="error" color={Colors.Error} />
            <div className={classes.text}>
                {`${translate('Error')}: `}
                {translate(translationKey || 'NoData')}
            </div>
        </div>
    );
};
