import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { translate } from 'utilities';
import { ContainedButton, SvgIcon } from 'ui';
import Theme from '../../theme/fm-theme';

export const createStyles = makeStyles((theme: Theme) => ({
    searchRoot: theme.custom?.searchField.root || {},
    fullWidth: theme.custom?.searchField.fullWidth || {},
    inputBlock: { ...theme.custom?.searchField.inputBlock },
    input: theme.custom?.searchField.input || {},
    resetIconBlock: theme.custom?.searchField.resetIconBlock || {},
    sidebarButton: { ...theme.custom?.retranslatorMainMenu.addButton },
}));

export interface Props {
    /**Значение поля поиска */
    value: string;
    /**Добавляет flex-grow к контейнеру компонента*/
    fullWidth?: boolean;
    /**Добавляет иконку к кнопке "Поиск" */
    forSidebar?: boolean;
    /**Обработчик изменения поля поиска */
    onChange?: (value: string) => void;
    /**Обработчик нажатия на кнопку */
    onSearch?: () => void;
}
/**Поле для поиска */
export const SearchInput: React.FC<Props> = (props) => {
    let { value, fullWidth, forSidebar, onChange, onSearch } = props;
    let classes = createStyles();

    const onChangeHandler = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (onChange) {
                onChange(event.target.value);
            }
        },
        [onChange],
    );

    const searchOnEnter = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>): void => {
            if (event.key == 'Enter') {
                if (onSearch) {
                    onSearch();
                }
            }
        },
        [onSearch],
    );

    const onResetHandler = React.useCallback((): void => {
        if (onChange) {
            onChange('');
        }
    }, [onChange]);

    const searchOnClick = React.useCallback((): void => {
        if (onSearch) {
            onSearch();
        }
    }, [onSearch]);

    let button = <ContainedButton disableMinWidth textKey="Find" onClick={searchOnClick} />;
    if (forSidebar === true) {
        button = (
            <div className={classes.sidebarButton} onClick={searchOnClick}>
                <SvgIcon type="plus" />
                {translate('Find')}
            </div>
        );
    }

    let rootClass = classes.searchRoot;
    const rootStyle: React.CSSProperties = {};
    if (fullWidth) {
        rootClass += ` ${classes.fullWidth}`;
        rootStyle.width = 'auto';
    }
    return (
        <div className={rootClass} style={rootStyle}>
            <div className={classes.inputBlock}>
                <input
                    placeholder={translate('Search')}
                    className={classes.input}
                    value={value}
                    onChange={onChangeHandler}
                    onKeyUp={searchOnEnter}
                />
                {value.length > 0 && (
                    <div className={classes.resetIconBlock} onClick={onResetHandler}>
                        <SvgIcon type="close" />
                    </div>
                )}
            </div>
            {button}
        </div>
    );
};
