import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ButtonIconSize } from '../../../constants';
import Theme from '../../../theme/fm-theme';
import { SvgIcon, SvgIconType } from '../icons/svg-icon';

const createStyles = makeStyles((theme: Theme) => ({
    button: theme.custom?.buttons.contained || {},
    icon: theme.custom?.buttons.containedIcon || {},
}));

export interface ButtonProps {
    textKey?: string;
    frontIconType?: SvgIconType;
    marginTop?: number;
    marginLeft?: number;
    disableMinWidth?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
export const EnterButton: React.FC<ButtonProps> = (props) => {
    let { textKey = '', frontIconType, marginTop = 0, marginLeft = 0, disableMinWidth, onClick } = props;

    let classes = createStyles();

    let style: React.CSSProperties = { marginTop, marginLeft };

    if (!frontIconType) {
        style.justifyContent = 'center';
    }
    if (disableMinWidth) {
        style.minWidth = 0;
    }
    return (
        <div style={style} className={textKey.length > 0 ? classes.button : classes.icon} onClick={onClick}>
            {frontIconType && <SvgIcon type={frontIconType} size={ButtonIconSize} />}
            {textKey}
        </div>
    );
};
