import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { Colors } from '..';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        ...theme.custom?.typography.text,
        boxSizing: 'border-box',
        color: theme.custom?.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        '& svg': {
            width: theme.custom?.mainSizes.table.iconSize,
            height: theme.custom?.mainSizes.table.iconSize,
        },
    },
    checkboxCell: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& svg': {
            width: theme.custom?.mainSizes.table.iconSize,
            height: theme.custom?.mainSizes.table.iconSize,
        },
    },
    withPadding: {
        padding: `${theme.custom?.spacing(1)}px ${theme.custom?.spacing(2)}px`,
    },
    innerCell: {
        display: 'flex',
        gap: theme.custom?.spacing(1),
    },
    textInnerCell: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    centered: {
        justifyContent: 'center',
    },
    errorText: {
        color: theme.custom?.palette.levels.error,
    },
    warningText: {
        color: theme.custom?.palette.levels.warning,
    },
    successText: {
        color: theme.custom?.palette.levels.success,
    },
}));

export interface CellProps {
    /**Стили ячейки */
    style: React.CSSProperties;
    /**Параметр, убирающий падаинги  */
    disablePadding?: boolean;
    /**Параметр центрирует children  */
    centerContent?: boolean;
    /**Параметр принимает текст для встроенного tooltip*/
    title?: string;
    /**Параметр устанавливает дополнительные стили на отображение children.
     * Делает текст в строчку и скрывает, все что не влезает
     */
    useTextStyles?: boolean;
    /** Цвет текста в ячейке */
    textColor?: Colors;
}

const LocalCell = (props: React.PropsWithChildren<CellProps>): JSX.Element => {
    let classes = useStyles();

    let cellClasses = classes.cell;
    if (!props.disablePadding) {
        cellClasses = classNames(cellClasses, classes.withPadding);
    }
    if (props.centerContent) {
        cellClasses = classNames(cellClasses, classes.centered);
    }

    let innerClasses = props.useTextStyles ? classes.textInnerCell : classes.innerCell;
    switch (props.textColor) {
        case Colors.Error:
            innerClasses = classNames(innerClasses, classes.errorText);
            break;
        case Colors.Warning:
            innerClasses = classNames(innerClasses, classes.warningText);
            break;
        case Colors.Success:
            innerClasses = classNames(innerClasses, classes.successText);
            break;
    }

    return (
        <div className={cellClasses} style={props.style} title={props.title}>
            <div className={innerClasses}>{props.children}</div>
        </div>
    );
};

export interface CheckboxCellProps {
    /**Стили для ячийки */
    style: React.CSSProperties;
    /**Коллбек функция активируется на клик по ячейке*/
    onClick: () => void;
}

const LocalCheckboxCell = (props: React.PropsWithChildren<CheckboxCellProps>): JSX.Element => {
    let classes = useStyles();

    const onCellClick = () => props.onClick?.();

    return (
        <div className={classes.checkboxCell} style={props.style} onClick={onCellClick}>
            <div className={classes.innerCell}>{props.children}</div>
        </div>
    );
};
/**Ячейка в таблице с тултипом */
export const Cell = React.memo(LocalCell) as typeof LocalCell;
/**Ячейка в таблице с коллбеком */
export const CheckboxCell = React.memo(LocalCheckboxCell) as typeof LocalCheckboxCell;
