import { makeStyles } from '@material-ui/styles';
import React, { PropsWithChildren } from 'react';
import { Theme } from 'theme';

const createClasses = makeStyles((theme: Theme) => ({
    rootClasses: {
        padding: `${theme.custom?.spacing(2)}px ${theme.custom?.spacing(2)}px`,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.custom?.palette.background.toolbar,
    },
}));

export function LowerToolbar({ children }: PropsWithChildren) {
    const classes = createClasses();
    return <div className={classes.rootClasses}>{children}</div>;
}
