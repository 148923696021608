import React, { MouseEvent } from 'react';
import { SplitterDots } from './splitter-dots';
import { SplitterArrow } from './splitter-arrow';
import { makeStyles } from '@material-ui/core/styles';
import Theme from '../../theme/fm-theme';
import { SplitPaneLayout } from './split-pane';

const splitterStyles = makeStyles((theme: Theme) => ({
    splitter: {
        background: theme.custom?.palette.colors.grey20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        '&.is-vertical': {
            width: theme.custom?.mainSizes.splitter.size,
            height: '100%',
            flexDirection: 'column',

            '&.is-wide': {
                width: theme.custom?.mainSizes.splitter.sizeWithCollapseButton,
            },

            '& $dots': {
                transform: 'rotate(90deg)',
            },

            '& $arrow': {
                transform: 'rotate(90deg)',
            },

            '& $collapseButton': {
                width: '100%',
                height: theme.custom?.mainSizes.splitter.collapseButtonSize,

                '&.collapse-pane1': {
                    '&.is-collapsed': {
                        '& $arrow': {
                            transform: 'rotate(-90deg)',
                        },
                    },
                },

                '&.collapse-pane2': {
                    '& $arrow': {
                        transform: 'rotate(-90deg)',
                    },

                    '&.is-collapsed': {
                        '& $arrow': {
                            transform: 'rotate(90deg)',
                        },
                    },
                },
            },
        },

        '&.is-horizontal': {
            width: '100%',
            height: theme.custom?.mainSizes.splitter.size,

            '&.is-wide': {
                height: theme.custom?.mainSizes.splitter.sizeWithCollapseButton,
            },

            '& $collapseButton': {
                width: theme.custom?.mainSizes.splitter.collapseButtonSize,
                height: '100%',

                '&.collapse-pane1': {
                    '& $arrow': {
                        transform: 'rotate(180deg)',
                    },

                    '&.is-collapsed': {
                        '& $arrow': {
                            transform: 'rotate(0deg)',
                        },
                    },
                },

                '&.collapse-pane2': {
                    '&.is-collapsed': {
                        '& $arrow': {
                            transform: 'rotate(180deg)',
                        },
                    },
                },
            },
        },
    },
    collapseButton: {
        background: 'none',
        border: 'none',
        padding: theme.custom?.mainSizes.splitter.collapseButtonPadding,
        outline: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
    },
    innerWrapper: {
        background: theme.custom?.palette.colors.grey10,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.custom?.mainSizes.splitter.collapseButtonRadius,
    },
    arrow: {
        position: 'relative',
        zIndex: 2,

        '& path': {
            fill: theme.custom?.palette.text.primary,
        },
    },
    dots: {
        display: 'block',

        '& circle': {
            fill: theme.custom?.palette.text.primary,
        },
    },
}));

export interface SplitterProps {
    /** Вертикальное или горизонтальное отображение. Значение по умолчанию - vertical */
    layout?: SplitPaneLayout;
    /** Отображение кнопки "Свернуть / развернуть панель". Значение по умолчанию - false */
    showCollapseButton?: boolean;
    /**  */
    isCollapsed?: boolean;
    /** Какая панель должна сворачиваться при нажатии на кнопку. Значение по умолчанию - main для vertical и secondary для horizontal */
    collapsiblePane?: 'pane1' | 'pane2';
    /** Можно ли ресайзить панель. Значение по умолчанию - true. Принимает false, когда панель свернута */
    isResizeable?: boolean;
    onCollapseButtonClick?: () => void;
}

export const Splitter: React.FC<SplitterProps> = (props: SplitterProps) => {
    const { layout, showCollapseButton, isCollapsed, collapsiblePane, onCollapseButtonClick, isResizeable } = props;
    const classes = splitterStyles();

    const onClickHandler = () => {
        onCollapseButtonClick && onCollapseButtonClick();
    };

    const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        if (!isResizeable) {
            e.stopPropagation();
        }
    };

    return (
        <div
            className={`${classes.splitter} is-${layout}${showCollapseButton ? ' is-wide' : ''}`}
            onMouseDown={onMouseDown}
        >
            {showCollapseButton && (
                <>
                    {isResizeable && <SplitterDots className={classes.dots} />}

                    <button
                        type="button"
                        className={`${classes.collapseButton}${isCollapsed ? ' is-collapsed' : ''}${
                            collapsiblePane ? ` collapse-${collapsiblePane}` : ''
                        }`}
                        onClick={onClickHandler}
                    >
                        <div className={classes.innerWrapper}>
                            <SplitterArrow className={classes.arrow} />
                        </div>
                    </button>
                </>
            )}

            {isResizeable && <SplitterDots className={classes.dots} />}
        </div>
    );
};
