import React from 'react';
import { FixedSizeGrid, GridOnScrollProps } from 'react-window';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { EmptyListPane, ErrorPane, Loading } from 'ui';

import { AnyColumn } from '.';
import { getRowWidth } from './entities';
import Row from './row';

let RowHeight = 36;
let ScrollWidth = 17;

const useStyles = makeStyles((theme: Theme) => {
    if (theme.custom) {
        RowHeight = theme.custom.mainSizes.table.rowHeight;
        ScrollWidth = theme.custom.mainSizes.common.scrollWidth;
    }

    return {};
});

export interface BodyProps<T> {
    /**Параметр - поле, по которому идентифицировать строчку */
    idKey: keyof T;
    /**Массив записей таблицы */
    records: T[];
    /**Колонки таблицы */
    columns: AnyColumn<T>[];
    /**Ширина таблицы */
    width: number;
    /**Высота таблицы*/
    height: number;
    /**Ссылка на Header */
    headerRef: React.RefObject<HTMLDivElement>;
    /**Ссылка на элемент отображающий количество записей */
    totalRowRef: React.RefObject<HTMLDivElement>;
    /**Выбранная строка */
    selectedId: number | null;
    /**Текст с информацией (перекрывает записи таблицы, переводиться) */
    infoText?: string;
    /**Текст с описанием ошибки (перекрывает записи таблицы, переводиться) */
    errorText: string;
    /**Параметр, отвечающий за отображение лоудера*/
    showLoading: boolean;
    /**Параметр, отвечающий за отображение чекбоксов у строк */
    showCheckboxes?: boolean;
    /**Хеш таблица с выбранными чекбоксами */
    checkedData?: Record<string, boolean>;
    /**При изменнении этого параметра будет всегда скролить к выбранной строчке */
    needScrollToSelectedChangeFlag?: boolean;
    /**Коллбек функция устанавливает отображение скрола */
    setHasScroll: (value: boolean) => void;
    /**Коллбек функция устанавливает ширину строки */
    setRowWidth: (value: number) => void;
    /**Коллбек функция вызывается при выборе строки */
    onSelectionChange?: (selectedId: number | null) => void;
    /**Функция коллбек для изменения хеш таблицы с выбранными чекбоксами */
    updateCheckedData?: (data: Record<string, boolean>) => void;
}
/**Компонент отображает записи таблицы */
export const Body = <T,>(props: BodyProps<T>): JSX.Element => {
    let {
        idKey,
        records,
        columns,
        selectedId,
        width,
        height,
        onSelectionChange,
        infoText,
        errorText,
        showLoading,
        showCheckboxes,
        checkedData,
        updateCheckedData,
    } = props;
    useStyles();

    let gridRef = React.useRef<FixedSizeGrid>(null);

    const onScroll = React.useCallback(
        (eventProps: GridOnScrollProps) => {
            props.headerRef.current?.scroll({ left: eventProps.scrollLeft });
            props.totalRowRef.current?.scroll({ left: eventProps.scrollLeft });
        },
        [props.headerRef, props.totalRowRef],
    );

    const onCheckedChange = React.useCallback(
        (id: string, value: boolean) => {
            updateCheckedData?.({
                ...checkedData,
                [id]: value,
            });
        },
        [checkedData],
    );

    let rowCount = records.length;
    let hasScroll = rowCount * RowHeight > height;
    let rowWidth = getRowWidth({
        columns,
        width,
        hasScroll,
        scrollWidth: ScrollWidth,
        iconCellWidth: RowHeight,
    });

    React.useEffect(() => {
        props.setHasScroll(hasScroll);
        props.setRowWidth(rowWidth);
    }, [width, height, rowCount]);

    React.useEffect(() => {
        scrollToSelected({ selectedId, idKey, records, gridRef });
    }, [props.needScrollToSelectedChangeFlag]);

    React.useLayoutEffect(() => {
        scrollToSelected({ selectedId, idKey, records, gridRef });
    }, [props.records, props.selectedId]);

    if (showLoading) {
        return <Loading transparent width={width} height={height} />;
    }

    if (errorText) {
        return <ErrorPane width={width} height={height} translationKey={errorText} />;
    }

    if (infoText) {
        return <EmptyListPane width={width} height={height} translationKey={infoText} />;
    }

    return (
        <FixedSizeGrid
            ref={gridRef}
            width={width}
            height={height}
            rowCount={rowCount}
            rowHeight={RowHeight}
            columnCount={1}
            columnWidth={rowWidth}
            onScroll={onScroll}
        >
            {({ rowIndex, style }) => {
                let record = records[rowIndex];
                return (
                    <Row
                        idKey={idKey}
                        record={record}
                        columns={columns}
                        style={style}
                        selected={Number(record[idKey]) == selectedId}
                        showCheckbox={showCheckboxes}
                        checked={checkedData?.[String(record[idKey])] === true}
                        onSelectionChange={onSelectionChange}
                        updateChecked={onCheckedChange}
                    />
                );
            }}
        </FixedSizeGrid>
    );
};

interface ScrollToSelectedProps<T> {
    selectedId: number | null;
    idKey: keyof T;
    records: T[];
    gridRef: React.RefObject<FixedSizeGrid>;
}

function scrollToSelected<T>(props: ScrollToSelectedProps<T>): void {
    let { selectedId, records, idKey, gridRef } = props;

    if (!selectedId) {
        return;
    }

    let indexToScrollTo = records.findIndex((record) => Number(record[idKey]) == selectedId);
    if (indexToScrollTo < 0) {
        return;
    }

    gridRef.current?.scrollToItem({
        rowIndex: indexToScrollTo,
        align: 'smart',
    });
}

export default Body;
