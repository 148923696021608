import React from 'react';
import { DialogContent, DialogToolbar, ErrorPane } from '..';

export interface DialogErrorContentProps {
    /**Текст ошибки */
    errorText: string;
}
/**Компонент для отображения ошибки в окне */
export const DialogErrorContent: React.FC<DialogErrorContentProps> = ({ errorText }) => {
    return (
        <>
            <DialogContent>
                <ErrorPane translationKey={errorText} />
            </DialogContent>
            <DialogToolbar></DialogToolbar>
        </>
    );
};
