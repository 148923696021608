import { Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Theme from '../../../theme/login-theme';

export const createStyles = makeStyles((theme: Theme) => ({
    switch: theme.custom?.loginSwitch.switch || {},
    switchBlock: theme.custom?.loginSwitch.switchBlock || {},
}));

interface LoginSwitchProps {
    checked?: boolean;
    onChange?: (value: boolean) => void;
    translate: string;
}

export const SwitchOldInterface: React.FC<LoginSwitchProps> = (props) => {
    let { checked, onChange, translate } = props;
    const classes = createStyles();

    const updateSwitchOldInterface = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (onChange) {
            onChange(checked);
        }
    };

    return (
        <div className={classes.switchBlock}>
            <div className={classes.switch}>
                <Switch checked={checked} onChange={updateSwitchOldInterface} />
            </div>
            <div>{translate}</div>
        </div>
    );
};
