import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';
import classNames from 'classnames';

const createStyles = makeStyles((theme: Theme) => ({
    svgStyle: { ...theme.custom?.svgIcon.checkbox },
    middleState: {
        '& path': {
            fill: theme.custom?.palette.text.disabled,
        },
    },
}));

export enum CheckBoxState {
    Unchecked = 0,
    Checked = 1,
    Middle = 2,
}

export interface CheckboxProps {
    /**Состояние чекбокса */
    checkboxState: CheckBoxState | undefined;
}
/**Иконка checkbox с 3мя состояниями */
export function StateCheckboxIcon({ checkboxState = CheckBoxState.Unchecked }: CheckboxProps) {
    const { svgStyle, middleState } = createStyles();
    let checkboxClasses = svgStyle;
    if (checkboxState === CheckBoxState.Middle) {
        checkboxClasses = classNames(checkboxClasses, middleState);
    }

    return (
        <svg
            className={checkboxClasses}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" />
            {checkboxState !== CheckBoxState.Unchecked && (
                <path d="M4.52352 7.42832C4.35219 7.27838 4.12893 7.19703 3.89854 7.20058C3.66815 7.20413 3.44765 7.29233 3.28129 7.44747C3.11493 7.60262 3.015 7.81324 3.00156 8.03709C2.98811 8.26094 3.06214 8.48146 3.20879 8.65442L6.23566 11.7246C6.32052 11.8112 6.42249 11.8802 6.53545 11.9276C6.64841 11.9749 6.77003 11.9995 6.89302 12C7.01535 12.0007 7.13658 11.9774 7.24951 11.9317C7.36243 11.8859 7.46475 11.8185 7.55038 11.7334L12.7405 6.51413C12.8245 6.43012 12.8906 6.33085 12.9351 6.22198C12.9796 6.11312 13.0016 5.99679 12.9999 5.87963C12.9982 5.76248 12.9728 5.6468 12.9252 5.53919C12.8775 5.43159 12.8086 5.33417 12.7223 5.2525C12.6359 5.17082 12.5339 5.1065 12.4221 5.06319C12.3102 5.01988 12.1906 4.99844 12.0703 5.00009C11.9499 5.00174 11.831 5.02645 11.7204 5.0728C11.6098 5.11916 11.5097 5.18626 11.4258 5.27027L6.90215 9.83211L4.52352 7.42832Z" />
            )}
        </svg>
    );
}
