import {
    createTreeKey,
    DeviceTreeNode,
    EntityType,
    fillLevels,
    GeozoneType,
    isGroupOrCompany,
    parseApiId,
    TreeData,
    TreeGeozoneNode,
    TreeNode,
    TreeState,
} from 'entities';

export interface ReactTreeData {
    topNodesKeys: string[];
    nodes: ReactTreeNodesData;
    viewKeys: string[];
}

export type ReactTreeNodesData = Record<string, ReactTreeNode>;

export interface ReactTreeNode {
    key: string;
    id: number;
    type: EntityType;
    level: number;
    text: string;
    searchValues: string[];
    parentKey: string;
    childrenKeys: string[];
    expanded: boolean;
    checked: boolean;
    selected: boolean;
    disabled: boolean;
    excluded: boolean;
}

export interface ReactTreeSettings {
    /**Возможность сворачивать/разворачивать группы */
    allowExpand: boolean;
    /**Возможность отмечать объекты */
    allowCheck: boolean;
    /**Возможность выделить объект */
    allowSelect: boolean;
    /**Запрещает выделять объект типа Компания */
    forbidSelectCompany: boolean;
    /**Запрещает выделять объект типа Группы */
    forbidSelectGroup: boolean;
    /**Параметр отвечает за раскрытие всего дерева при первом рендере */
    expandAll: boolean;
    /**Цветовая схема */
    colorScheme: ReactTreeColorScheme;
}

export interface ReactTreeNodeSettings {
    allowExpand: boolean;
    allowCheck: boolean;
    allowSelect: boolean;
    colorScheme: ReactTreeColorScheme;
}

export enum ReactTreeColorScheme {
    Default = 'Default',
    Navigation = 'Navigation',
}

export interface ReactTreeNodeActions {
    onExpandClick: (key: string) => void;
    onCheckClick: (key: string) => void;
    onSelect: (key: string) => void;
    onNodeClick?: (node: TreeNode) => void;
}

export function createEmptyReactTreeData(): ReactTreeData {
    return {
        topNodesKeys: [],
        nodes: {},
        viewKeys: [],
    };
}

export function parseReactTreeProps(props: TreeData, state: TreeState, settings: ReactTreeSettings): ReactTreeData {
    let { topNodesKeys, nodes } = props;
    let result = createEmptyReactTreeData();

    result.topNodesKeys = [...topNodesKeys];

    let parentsMap: Record<string, string> = {};
    let tempNodes = Object.keys(nodes).map((key) => nodes[key]);
    tempNodes.forEach((item) => {
        let disabled = !canNodeTypeBeSelected(settings, item.type);

        result.nodes[item.key] = {
            ...item,
            searchValues: getSearchValues(item),
            expanded: state.expandedKeys.includes(item.key),
            checked: state.checkedKeys.includes(item.key),
            selected: state.selectedKey == item.key,
            disabled,
            excluded: item.excluded === true,
        };

        item.childrenKeys.forEach((childKey) => (parentsMap[childKey] = item.key));
    });
    if (state.selectedKey) {
        expandParents(result.nodes, state.selectedKey);
    }

    result.viewKeys = createView(topNodesKeys, result.nodes);

    return result;
}

function getSearchValues(item: TreeNode): string[] {
    const result = [item.text.toLowerCase()];
    if (item.type === EntityType.Device && (item as DeviceTreeNode).imei) {
        result.push((item as DeviceTreeNode).imei.toLowerCase());
    }
    return result;
}

export function expandParents(
    data: ReactTreeNodesData,
    key: string,
): { nodes: ReactTreeNodesData; expandedKeys: string[] } {
    let expandedKeys: string[] = [];
    let item = data[key];
    while (item.parentKey) {
        let parent = data[item.parentKey];
        if (!parent || parent.expanded) {
            break;
        }
        parent.expanded = true;
        expandedKeys.push(parent.key);
        item = parent;
    }

    return { nodes: { ...data }, expandedKeys };
}

export function createView(topNodesKeys: string[], nodes: ReactTreeNodesData): string[] {
    let result: string[] = [];
    topNodesKeys.forEach((key) => addNodeWithChildren(nodes, key, result));
    return result;
}

function addNodeWithChildren(nodes: ReactTreeNodesData, key: string, result: string[]): void {
    result.push(key);

    let item = nodes[key];
    if (item.childrenKeys.length == 0 || !item.expanded) {
        return;
    }

    item.childrenKeys.forEach((childKey) => addNodeWithChildren(nodes, childKey, result));
}

export function triggerExpand(nodes: ReactTreeNodesData, key: string): ReactTreeNodesData {
    return {
        ...nodes,
        [key]: {
            ...nodes[key],
            expanded: !nodes[key].expanded,
        },
    };
}

export function triggerCheckWithChildren(nodes: ReactTreeNodesData, key: string): ReactTreeNodesData {
    let newValue = !nodes[key].checked;
    let result: ReactTreeNodesData = { ...nodes };

    setCheckedWithChildren(key, newValue, result);
    if (newValue) {
        checkParentsIfAllChildrenChecked(result[key].parentKey, result);
    } else {
        uncheckParents(result[key].parentKey, result);
    }

    return result;
}

function setCheckedWithChildren(key: string, checked: boolean, result: ReactTreeNodesData): void {
    result[key] = {
        ...result[key],
        checked,
    };

    result[key].childrenKeys.forEach((childKey) => setCheckedWithChildren(childKey, checked, result));
}

function uncheckParents(parentKey: string, result: ReactTreeNodesData): void {
    let parent = result[parentKey];

    if (!parent) {
        return;
    }

    result[parentKey] = {
        ...parent,
        checked: false,
    };

    uncheckParents(parent.parentKey, result);
}

function checkParentsIfAllChildrenChecked(parentKey: string, result: ReactTreeNodesData): void {
    if (!parentKey) {
        return;
    }

    let parent = result[parentKey];

    if (!parent) {
        return;
    }

    for (let key of parent.childrenKeys) {
        if (!result[key].checked) {
            return;
        }
    }

    result[parentKey] = {
        ...parent,
        checked: true,
    };

    checkParentsIfAllChildrenChecked(parent.parentKey, result);
}

export function selectByKey(nodes: ReactTreeNodesData, newKey: string, oldKey: string): ReactTreeNodesData {
    if (newKey == oldKey) {
        return nodes;
    }

    return {
        ...nodes,
        [newKey]: {
            ...nodes[newKey],
            selected: true,
        },
        [oldKey]: {
            ...nodes[oldKey],
            selected: false,
        },
    };
}

export function getExpandedKeys(nodes: ReactTreeNodesData): string[] {
    let result: string[] = [];

    let keys = Object.keys(nodes);
    keys.forEach((key) => {
        if (nodes[key].expanded) {
            result.push(key);
        }
    });

    return result;
}

export function getCheckedKeys(nodes: ReactTreeNodesData): string[] {
    let result: string[] = [];

    let keys = Object.keys(nodes);
    keys.forEach((key) => {
        if (nodes[key].checked) {
            result.push(key);
        }
    });

    return result;
}

export function getSearchOrder(nodes: ReactTreeNodesData, topNodesKeys: string[]): string[] {
    let result: string[] = [];
    topNodesKeys.forEach((key) => addKeysToSearchOrder(nodes, key, result));
    return result;
}

function addKeysToSearchOrder(nodes: ReactTreeNodesData, key: string, result: string[]) {
    let node = nodes[key];

    if (!node) {
        return;
    }

    result.push(key);
    node.childrenKeys.forEach((childKey) => addKeysToSearchOrder(nodes, childKey, result));

    return result;
}

export interface SearchProps {
    searchOrder: string[];
    selectedKey: string;
    nodes: ReactTreeNodesData;
    searchValue: string;
}
export function search(props: SearchProps): string {
    let { searchOrder, selectedKey, nodes, searchValue } = props;

    let startIndex = searchOrder.findIndex((key) => key == selectedKey) + 1;
    let lowerCaseValue = searchValue.toLowerCase();

    for (let i = startIndex; i < searchOrder.length; ++i) {
        let node = nodes[searchOrder[i]];
        for (let j = 0; j < node.searchValues.length; ++j) {
            if (node.searchValues[j].includes(lowerCaseValue)) {
                return node.key;
            }
        }
    }

    for (let i = 0; i < startIndex; ++i) {
        let node = nodes[searchOrder[i]];
        for (let j = 0; j < node.searchValues.length; ++j) {
            if (node.searchValues[j].includes(lowerCaseValue)) {
                return node.key;
            }
        }
    }

    return '';
}

export function canNodeTypeBeSelected(settings: ReactTreeSettings, nodeType: EntityType): boolean {
    if (!settings.allowSelect) {
        return false;
    }

    if (nodeType == EntityType.Company && settings.forbidSelectCompany) {
        return false;
    }

    if (nodeType == EntityType.Group && settings.forbidSelectGroup) {
        return false;
    }

    return true;
}

export type ApiTree = Record<string, ApiTreeNode>;

interface ApiTreeNode {
    name: string;
    parentId: string;
    children: string[];
    imei?: string;
    geoType?: GeozoneType;
}

export function treeTransform(value: ApiTree): TreeData {
    // Create result tree
    let result: TreeData = {
        topNodesKeys: [],
        nodes: {},
    };

    // Fill top node keys
    let rootNode = value['0-0'];
    for (let key of rootNode.children) {
        let apiId = parseApiId(key);
        if (apiId.type != EntityType.Unknown) {
            result.topNodesKeys.push(createTreeKey(apiId.type, apiId.id));
        }
    }

    // Create nodes
    let parentMap: Record<string, string> = {};
    for (let key of Object.keys(value)) {
        let apiId = parseApiId(key);
        if (apiId.type == EntityType.Unknown) {
            continue;
        }

        let treeKey = createTreeKey(apiId.type, apiId.id);
        let apiItem = value[key];
        let treeNode: TreeNode = {
            key: treeKey,
            id: apiId.id,
            level: 0,
            type: apiId.type,
            text: apiItem.name,
            parentKey: '',
            childrenKeys: [],
        };
        if (treeNode.type === EntityType.Device) {
            (treeNode as DeviceTreeNode).imei = apiItem.imei ?? '';
        }
        if (treeNode.type === EntityType.GeoZone) {
            (treeNode as TreeGeozoneNode).geozoneType = apiItem.geoType ?? GeozoneType.Unknown;
        }

        for (let childKey of apiItem.children) {
            let apiChildId = parseApiId(childKey);
            if (apiChildId.type != EntityType.Unknown) {
                let treeChildKey = createTreeKey(apiChildId.type, apiChildId.id);
                treeNode.childrenKeys.push(treeChildKey);
                parentMap[treeChildKey] = treeKey;
            }
        }

        result.nodes[treeKey] = treeNode;
    }

    // Fill parents
    for (let key of Object.keys(parentMap)) {
        let node = result.nodes[key];
        if (node) {
            node.parentKey = parentMap[key];
        }
    }

    // Fill levels
    let level = 0;
    for (let key of result.topNodesKeys) {
        fillLevels(result, key, level);
    }

    return result;
}

export function setExpandedForAllNodes(
    treeData: ReactTreeData,
    treeState: TreeState,
    value: boolean,
): { treeData: ReactTreeData; treeState: TreeState } {
    const result: { treeData: ReactTreeData; treeState: TreeState } = {
        treeData: {
            topNodesKeys: treeData.topNodesKeys,
            nodes: { ...treeData.nodes },
            viewKeys: [],
        },
        treeState: { ...treeState },
    };

    for (let key of result.treeData.topNodesKeys) {
        setExpandedRecursively(key, result.treeData.nodes, value);
    }
    result.treeData.viewKeys = createView(result.treeData.topNodesKeys, result.treeData.nodes);
    result.treeState.expandedKeys = getExpandedKeys(result.treeData.nodes);

    return result;
}

function setExpandedRecursively(nodeKey: string, nodes: ReactTreeNodesData, value: boolean): void {
    const node = nodes[nodeKey];
    if (node.childrenKeys.length > 0) {
        nodes[nodeKey] = {
            ...node,
            expanded: value,
        };
    }

    for (let key of node.childrenKeys) {
        setExpandedRecursively(key, nodes, value);
    }
}

export function setCheckedForAllNodes(
    treeData: ReactTreeData,
    treeState: TreeState,
    value: boolean,
): { treeData: ReactTreeData; treeState: TreeState } {
    const result: { treeData: ReactTreeData; treeState: TreeState } = {
        treeData: {
            topNodesKeys: treeData.topNodesKeys,
            nodes: { ...treeData.nodes },
            viewKeys: treeData.viewKeys,
        },
        treeState: { ...treeState },
    };

    for (let key of result.treeData.topNodesKeys) {
        setCheckedRecursively(key, result.treeData.nodes, value);
    }
    result.treeState.checkedKeys = getCheckedKeys(result.treeData.nodes);

    return result;
}

function setCheckedRecursively(nodeKey: string, nodes: ReactTreeNodesData, value: boolean): void {
    const node = nodes[nodeKey];
    nodes[nodeKey] = {
        ...node,
        checked: value,
    };

    if (!node.childrenKeys) {
        return;
    }

    for (let key of node.childrenKeys) {
        setCheckedRecursively(key, nodes, value);
    }
}

/** Подсчет количества выбранных элементов, которые не являются группами или компаниями */
export function getCheckedItemsCount(nodes: ReactTreeNodesData): number {
    let result = 0;

    for (let key of Object.keys(nodes)) {
        const node = nodes[key];

        if (!node.checked || isGroupOrCompany(node)) {
            continue;
        }

        ++result;
    }

    return result;
}
