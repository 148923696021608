import { LoadingState } from 'ui';
import { CombinedState } from '..';
import { AuthData } from './entities';

export const getAuthData = (state: CombinedState): AuthData => state.auth.authData;

export const getConfig = (state: CombinedState): boolean => state.auth.configCreated;

export const getConfigLoading = (state: CombinedState): LoadingState => state.auth.isLoadingConfig;

export const getOldInterface = (state: CombinedState): boolean => state.auth.oldInterface;

export const getTextErrorMessage = (state: CombinedState): string => state.auth.errorMessageText;

export const getEmptyLoginField = (state: CombinedState): boolean => state.auth.loginErrorVisible;

export const getEmptyPasswordField = (state: CombinedState): boolean => state.auth.passwordErrorVisible;
