import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { translate } from 'utilities';
import { ContainedButton } from '..';
import Theme from '../../theme/fm-theme';

import { NotificationIcon } from './icon';

const createStyles = makeStyles((theme: Theme) => ({
    container: theme.custom?.notifications.container || {},
    dataPane: theme.custom?.notifications.dataPane || {},
    titlePane: theme.custom?.notifications.titlePane || {},
    textPane: theme.custom?.notifications.textPane || {},
}));

export enum NotificationType {
    Success = 1,
    Error = 2,
    Info = 3,
}

interface NotificationProps {
    type: NotificationType;
    titleKey: string;
    textKey: string;
    buttonTextKey: string;
}
export const Notification: React.FC<NotificationProps> = (props) => {
    let { type, titleKey, textKey, buttonTextKey } = props;
    let classes = createStyles();

    return (
        <div className={classes.container}>
            <NotificationIcon type={type} />
            <div className={classes.dataPane}>
                <div className={classes.titlePane}>{translate(titleKey)}</div>
                <div className={classes.textPane}>{translate(textKey)}</div>
            </div>
            <ContainedButton textKey={translate(buttonTextKey)} />
        </div>
    );
};
