import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    TreeData,
    TreeNode,
    TreeState,
    createEmptyTreeData,
    createEmptyTreeState,
    extractCheckedObjects,
    mergeTrees,
    EntityType,
} from 'entities';
import { ContainedButton, Tree } from 'ui';
import { translate } from 'utilities';
import { Theme } from 'theme';

const createStyles = makeStyles((theme: Theme) => ({
    growingContainerClassName: { ...theme.custom?.twoPaneSelect.growingContainer },
    treePaneClassName: { ...theme.custom?.twoPaneSelect.treePane },
    treePaneHeaderClassName: { ...theme.custom?.twoPaneSelect.treePaneHeader },
    toolbarClassName: { ...theme.custom?.twoPaneSelect.panesToolbar },
}));

export interface TwoPaneTreeSelectProps {
    /**Заголовок левой панели */
    availableTreePaneHeaderName?: string;
    /**Заголовок правой панели */
    selectedTreePaneHeaderName?: string;
    /**Данные для отображения в левой панели */
    treeData: TreeData;
    /**Данные для отображения в правой панели */
    selectedTreeData?: TreeData;
    /**Определяет каке типы объектов переносить */
    nodeTypeToSelect?: EntityType;
    /**Обработчик кнопок переноса */
    onSelect?: (nodes: TreeNode[]) => void;
    /**Обраотчик смены состояния выделенных объектов деревьев. Если хотябы один объект выделен, hasChecked=true */
    hasChecked?: (hasChecked: boolean) => void;
}
/**Выбор объектов в виде двух деревьев. С левой стороны доступные объекты, с правой выбранные объекты */
export const TwoPaneTreeSelect: React.FC<TwoPaneTreeSelectProps> = (props) => {
    let {
        availableTreePaneHeaderName = 'AvailableObjects',
        selectedTreePaneHeaderName = 'SelectedObjects',
        treeData,
        selectedTreeData,
        onSelect,
        hasChecked,
        nodeTypeToSelect,
    } = props;
    let { growingContainerClassName, treePaneClassName, treePaneHeaderClassName, toolbarClassName } = createStyles();

    let [sourceTreeData, setSourceTreeData] = React.useState<TreeData>(treeData);
    let [sourceTreeState, setSourceTreeState] = React.useState<TreeState>(createEmptyTreeState());
    let [stateForSourceTree, setStateForSourceTree] = React.useState<TreeState>(createEmptyTreeState());
    let [targetTreeData, setTargetTreeData] = React.useState<TreeData>(selectedTreeData ?? createEmptyTreeData());
    let [targetTreeState, setTargetTreeState] = React.useState<TreeState>(createEmptyTreeState());

    let [hasCheckedKeysOfSourceTree, setHasCheckedKeysOfSourceTree] = React.useState(false);
    let [hasCheckedKeysOfTargetTree, setHasCheckedKeysOfTargetTree] = React.useState(false);

    const selectCheckedObjects = () => {
        let { originalTree, extractedTree } = extractCheckedObjects({
            treeData: sourceTreeData,
            treeState: sourceTreeState,
            nodeType: nodeTypeToSelect,
        });

        let resultTree = mergeTrees(targetTreeData, extractedTree);
        setSourceTreeData(originalTree);
        setTargetTreeData(resultTree);
        setStateForSourceTree({
            ...sourceTreeState,
            checkedKeys: [],
        });

        if (onSelect) {
            let allNodes = Object.keys(resultTree.nodes).map((key) => resultTree.nodes[key]);
            onSelect(allNodes);
        }
        setHasCheckedKeysOfSourceTree(false);
    };

    const deselectCheckedObjects = () => {
        let { originalTree, extractedTree } = extractCheckedObjects({
            treeData: targetTreeData,
            treeState: targetTreeState,
            nodeType: nodeTypeToSelect,
        });

        setSourceTreeData(mergeTrees(sourceTreeData, extractedTree));
        setTargetTreeData(originalTree);
        setStateForSourceTree({
            ...sourceTreeState,
            checkedKeys: [],
        });

        if (onSelect) {
            let allNodes = Object.keys(originalTree.nodes).map((key) => originalTree.nodes[key]);
            onSelect(allNodes);
        }
        setHasCheckedKeysOfTargetTree(false);
    };

    React.useEffect(() => {
        setSourceTreeData(treeData ?? createEmptyTreeData());
        setSourceTreeState(createEmptyTreeState());
        setTargetTreeData(selectedTreeData ?? createEmptyTreeData());
        setTargetTreeState(createEmptyTreeState());
    }, [treeData, selectedTreeData]);

    React.useEffect(() => {
        setHasCheckedKeysOfSourceTree(sourceTreeState.checkedKeys.length !== 0);
    }, [sourceTreeState]);

    React.useEffect(() => {
        setHasCheckedKeysOfTargetTree(targetTreeState.checkedKeys.length !== 0);
    }, [targetTreeState]);

    React.useEffect(() => {
        if (hasChecked) {
            hasChecked(hasCheckedKeysOfSourceTree || hasCheckedKeysOfTargetTree);
        }
    }, [hasCheckedKeysOfSourceTree, hasCheckedKeysOfTargetTree]);

    return (
        <div className={growingContainerClassName}>
            <div className={treePaneClassName}>
                <div className={treePaneHeaderClassName}>{translate(availableTreePaneHeaderName)}</div>
                <Tree
                    allowExpand
                    allowCheck
                    data={sourceTreeData}
                    state={stateForSourceTree}
                    onStateChange={(state) => setSourceTreeState(state)}
                />
            </div>
            <div className={toolbarClassName}>
                <ContainedButton noMargin frontIconType="chevron-right" onClick={selectCheckedObjects} />
                <ContainedButton frontIconType="chevron-left" onClick={deselectCheckedObjects} />
            </div>
            <div className={treePaneClassName}>
                <div className={treePaneHeaderClassName}>{translate(selectedTreePaneHeaderName)}</div>
                <Tree allowCheck expandAll data={targetTreeData} onStateChange={(state) => setTargetTreeState(state)} />
            </div>
        </div>
    );
};
