import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { translate } from 'utilities';
import { OutlinedButton, ContainedButton, SvgIconType } from '..';
import { ButtonIndentForQuestions } from '../../constants';
import Theme from '../../theme/fm-theme';

import { NotificationType } from './default';
import { NotificationIcon } from './icon';

const createStyles = makeStyles((theme: Theme) => ({
    container: theme.custom?.notifications.container || {},
    dataPane: theme.custom?.notifications.dataPane || {},
    titlePane: theme.custom?.notifications.titlePane || {},
    textPane: theme.custom?.notifications.textPane || {},
}));

interface QuestionNotificationProps {
    type: NotificationType;
    titleKey: string;
    textKey: string;
    noButtonTextKey: string;
    noButtonIcon: SvgIconType;
    yesButtonTextKey: string;
    yesButtonIcon: SvgIconType;
    showLoadingOnYesButton?: boolean;
    noButtonAction?: () => void;
    yesButtonAction?: () => void;
}
export const QuestionNotification: React.FC<QuestionNotificationProps> = (props) => {
    let { type, titleKey, textKey, noButtonTextKey, yesButtonTextKey, noButtonAction, yesButtonAction } = props;
    let classes = createStyles();

    const onNoButtonClick = () => {
        if (noButtonAction) {
            noButtonAction();
        }
    };

    const onYesButtonClick = () => {
        if (yesButtonAction) {
            yesButtonAction();
        }
    };

    return (
        <div className={classes.container}>
            <NotificationIcon type={type} />
            <div className={classes.dataPane}>
                <div className={classes.titlePane}>{translate(titleKey)}</div>
                <div className={classes.textPane}>{translate(textKey)}</div>
            </div>
            <OutlinedButton
                frontIconType={props.noButtonIcon}
                textKey={translate(noButtonTextKey)}
                marginLeft={ButtonIndentForQuestions * 3}
                onClick={onNoButtonClick}
            />
            <ContainedButton
                showLoadingIcon={props.showLoadingOnYesButton}
                frontIconType={props.yesButtonIcon}
                textKey={translate(yesButtonTextKey)}
                marginLeft={ButtonIndentForQuestions}
                onClick={onYesButtonClick}
            />
        </div>
    );
};
