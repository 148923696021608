import AutoSizer from 'react-virtualized-auto-sizer';
import React from 'react';
import { FixedSizeGrid } from 'react-window';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import { FilterInput, Toolbar } from 'ui';

import { ListData, ListItemInnerData, filterItems, getCheckedIds } from './entities';
import ListItem from './list-item';

let ListItemHeight = 36;
let ScrollWidth = 17;

const useStyles = makeStyles((theme: Theme) => {
    if (theme.custom) {
        ListItemHeight = theme.custom.mainSizes.list.rowHeight;
        ScrollWidth = theme.custom.mainSizes.common.scrollWidth;
    }

    return {
        listPaneRoot: { ...theme.custom?.listPane.root },
        listBlock: { ...theme.custom?.listPane.listBlock },
    };
});

interface ListPaneProps extends ListData {
    onCheckedChange: (checkedIds: string[]) => void;
}
export const ListPane: React.FC<ListPaneProps> = (props) => {
    let { items, checkedIds, onCheckedChange } = props;
    let classes = useStyles();

    let [innerItems, setInnerItems] = React.useState<ListItemInnerData[]>([]);
    let [filteredItems, setFilteredItems] = React.useState<ListItemInnerData[]>([]);
    let [filterValue, setFilterValue] = React.useState<string>('');

    const onItemClick = (index: number) => {
        let newFilteredItems = [...filteredItems];
        let targetItem = { ...filteredItems[index] };

        newFilteredItems[index] = {
            ...targetItem,
            checked: !targetItem.checked,
        };

        setFilteredItems(newFilteredItems);

        let newCheckedIds = getCheckedIds(newFilteredItems);
        onCheckedChange(newCheckedIds);
    };

    const onFilterValueChange = (newValue: string) => {
        setFilterValue(newValue);
    };

    React.useEffect(() => {
        let newFilteredItems = filterItems(innerItems, filterValue);
        setFilteredItems(newFilteredItems);
    }, [filterValue]);

    React.useEffect(() => {
        let newInnerItems: ListItemInnerData[] = [];

        for (let item of items) {
            newInnerItems.push({
                ...item,
                checked: checkedIds.includes(item.id),
            });
        }
        setInnerItems(newInnerItems);

        let newFilteredItems = filterItems(newInnerItems, filterValue);
        setFilteredItems(newFilteredItems);
    }, [items, checkedIds]);

    return (
        <div className={classes.listPaneRoot}>
            <Toolbar>
                <FilterInput fullWidth value={filterValue} onChange={onFilterValueChange} />
            </Toolbar>
            <div className={classes.listBlock}>
                <AutoSizer>
                    {({ width, height }) => {
                        let gridWidth = width;
                        let gridHeight = height;
                        let hasScroll = gridHeight < filteredItems.length * ListItemHeight;
                        let gridRowWidth = gridWidth;
                        if (hasScroll) {
                            gridRowWidth -= ScrollWidth;
                        }

                        return (
                            <FixedSizeGrid
                                width={gridWidth}
                                height={gridHeight}
                                columnCount={1}
                                columnWidth={gridRowWidth}
                                rowCount={filteredItems.length}
                                rowHeight={ListItemHeight}
                            >
                                {({ rowIndex, style }) => (
                                    <div style={style}>
                                        <ListItem index={rowIndex} onClick={onItemClick} {...filteredItems[rowIndex]} />
                                    </div>
                                )}
                            </FixedSizeGrid>
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
};
