import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Theme from '../../../theme/login-theme';
import { useLoginDispatch } from '../../store';
import { NewsView } from '../../store/sharedData/entities';
import { setIntervalId, setSelectedItemByIndex, showNextNewsImage } from '../../store/sharedData/slice';
import { useSelector } from 'react-redux';
import { getIntervalId } from '../../store/sharedData/selectors';

const createStyles = makeStyles((theme: Theme) => ({
    imageItem: theme.custom?.content.imageItem || {},
    selectedImage: theme.custom?.content.selectedImage || {},
}));

interface PreviewsPaneProps {
    newsView: NewsView[];
}

export const PreviewsPane: React.FC<PreviewsPaneProps> = ({ newsView }) => {
    let classes = createStyles();
    const dispatch = useLoginDispatch();
    const intervalId = useSelector(getIntervalId);

    const onPreviewClick = React.useCallback(
        (index: number): void => {
            dispatch(setSelectedItemByIndex(index));

            clearInterval(intervalId);

            let newIntervalId = setInterval(() => {
                dispatch(showNextNewsImage());
            }, 10_000);
            dispatch(setIntervalId(Number(newIntervalId)));
        },
        [intervalId],
    );

    React.useEffect(() => {
        const localStorageNewsIndex = localStorage.getItem('currentNewsIndex');
        dispatch(setSelectedItemByIndex(Number(localStorageNewsIndex)));

        let newIntervalId = setInterval(() => {
            dispatch(showNextNewsImage());
        }, 10_000);
        dispatch(setIntervalId(Number(newIntervalId)));

        return () => clearInterval(newIntervalId);
    }, []);

    return (
        <>
            {newsView.length != 0 &&
                newsView.map((item, index) => {
                    return (
                        <div
                            className={`${item.selected ? classes.selectedImage : classes.imageItem}`}
                            style={{
                                backgroundImage: `url("${item.previewImagePath}")`,
                            }}
                            key={index}
                            onClick={() => onPreviewClick(index)}
                        />
                    );
                })}
        </>
    );
};
