import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Theme from '../../../theme/login-theme';
import { ErrorIcon } from '../icons/errorIcon';

const createStyles = makeStyles((theme: Theme) => ({
    errorIconBlock: theme.custom?.popUpError.errorIconBlock || {},
    dividerBlock: theme.custom?.dividerBlock || {},
    errorBlock: theme.custom?.popUpError.errorBlock || {},
    errorText: theme.custom?.popUpError.errorText || {},
}));

interface PopUpErrorProps {
    textErrorMessage: string;
}

export const PopUpError: React.FC<PopUpErrorProps> = ({ textErrorMessage }) => {
    let classes = createStyles();

    return (
        <div className={classes.errorBlock}>
            <div className={classes.errorIconBlock}>
                <ErrorIcon />
            </div>
            <div className={classes.dividerBlock} />
            <div className={classes.errorText}>{textErrorMessage}</div>
        </div>
    );
};
