import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Theme from '../../theme/fm-theme';

const createStyles = makeStyles((theme: Theme) => ({
    rootStyles: { ...theme.custom?.detailsList.row },
}));
/**Пустая строка */
export const DetailsEmptyRow: React.FC = () => {
    let { rootStyles } = createStyles();

    return <div className={rootStyles} />;
};
