import * as React from 'react';

import { Icon, Props as IconProps } from './icon';

export interface Props extends IconProps {
    /** Цвет иконки (строка для CSS) */
    iconColor: string | undefined;
}
/** @deprecated Use SvgIcon instead. */
export function IconColored(props: Props): JSX.Element {
    const { iconColor, ...iconProps } = props;

    return (
        <div style={{ color: iconColor, display: 'flex' }}>
            <Icon {...iconProps} />
        </div>
    );
}

/** @deprecated Use SvgIcon instead. */
// eslint-disable-next-line react/display-name
export const IconColoredWithRef = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
    <div ref={ref}>
        <IconColored {...props} />
    </div>
));

IconColored.defaultProps = {
    iconColor: 'black',
};
