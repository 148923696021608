import {
    getRootUrl,
    getErrorFromFetchResponse,
} from 'utilities';

export enum ExcelDataType {
    Number = 1,
    String = 2,
}

export interface ExcelData {
    cells: ExcelCell[][];
    name?: string;
}

export interface ExcelCell {
    type: ExcelDataType;
    value: string | number;
}

interface GetExcelDataProps<T> {
    collection: T[];
    fields: (keyof T)[];
    captions: string[];
    parsers?: {
        [key in keyof T]?: (value: T[key]) => string;
    };
    renderers?: {
        [key in keyof T]?: (record: T) => string | number;
    }
}
export function getExcelData<T>(props: GetExcelDataProps<T>): ExcelData {
    let {
        collection,
        fields,
        captions,
        parsers,
        renderers,
    } = props;

    let result: ExcelData = { cells: [] };

    let captionRow: ExcelCell[] = [];
    for (let text of captions) {
        captionRow.push({
            type: ExcelDataType.String,
            value: text,
        });
    }
    result.cells.push(captionRow);

    for (let item of collection) {
        let row: ExcelCell[] = [];
        for (let key of fields) {
            let parser = parsers && parsers[key];
            let renderer = renderers && renderers[key];
            let value: string | number;
            switch (true) {
                case parser != undefined:
                    value = parser?.(item[key]) ?? '';
                    break;
                case renderer != undefined:
                    value = renderer?.(item) ?? '';
                    break;
                default:
                    value = String(item[key]);
                    break;
            }
            row.push({
                type: typeof item[key] == 'number' ? ExcelDataType.Number : ExcelDataType.String,
                value,
            });
        }
        result.cells.push(row);
    }

    return result;
}

export const getExcelFile = async (data: ExcelData): Promise<string> => {
    const response = await fetch(`${getRootUrl()}api/core/v3/export/excel`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status >= 200 && response.status < 300) {
        return response.json();
    }

    throw await getErrorFromFetchResponse(response);
};
