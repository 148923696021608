export interface ListData {
    items: ListItemData[];
    checkedIds: string[];
}

export interface ListItemData {
    id: string;
    text: string;
}

export interface ListItemInnerData extends ListItemData {
    checked: boolean;
}

export function getCheckedIds(listItems: ListItemInnerData[]): string[] {
    let result: string[] = [];

    for (let item of listItems) {
        if (item.checked) {
            result.push(item.id);
        }
    }

    return result;
}

export function filterItems(listItems: ListItemInnerData[], filterValue: string): ListItemInnerData[] {
    if (filterValue.length == 0) {
        return listItems;
    }

    let filterText = filterValue.toLowerCase();
    let result = listItems.filter((item) => item.text.toLowerCase().includes(filterText));

    return result;
}

export function insertItemInOrder(collection: ListItemData[], target: ListItemData): void {
    let insertIndex = collection.findIndex((item) => item.text > target.text);

    if (insertIndex < 0) {
        collection.push(target);
        return;
    }

    collection.splice(insertIndex, 0, target);
}
